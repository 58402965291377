export const TEST_ID = 'distanceInput';

export const UNIT_OPTIONS = [
    {
        label: 'FT',
        type: '',
        value: 'FEET'
    },
    {
        label: 'M',
        type: '',
        value: 'METERS'
    }
];

export const MAX_FEET = 99.0;
