import { TEST_ID } from './constants';
import type { FunctionComponent, MouseEvent, Props } from './types';

import styles from './styles.module.scss';

const SidebarItem: FunctionComponent<Props> = ({
    isActive,
    isDisabled,
    isInvalid,
    onClick,
    testId = TEST_ID,
    text
}) => {
    let tabVariant = '';

    if (isInvalid) {
        tabVariant = styles.invalid;
    } else if (isDisabled) {
        tabVariant = styles.disabled;
    } else if (isActive) {
        tabVariant = styles.active;
    }

    return (
        <a
            onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault();

                if (!isDisabled) {
                    onClick?.(event);
                }
            }}
            className={`${styles.sidebarItem} ${tabVariant}`}
            data-testid={testId}
        >
            {text}
        </a>
    );
};

export default SidebarItem;
