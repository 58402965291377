import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderReportPDF');

    return {
        APPLICATION_SITE: (number: string) => t('applicationSite', { number }),
        CHEMICAL: t('chemical'),
        HEADER: (number: string) => t('header', { number }),
        NO_CHEMICALS: t('noChemicals'),
        NO_SITES: t('noSites')
    };
};

export default useTranslation;
