import SectionColumn from 'components/pdf/SectionColumn';
import InvoiceText from 'components/pdf/invoice/InvoiceText';

import useDateFormat from 'hooks/useDateFormat';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

const InvoiceDetailsColumn: FunctionComponent = () => {
    const { formatDate } = useDateFormat();

    const { CREATED_ON, INVOICE_DETAILS } = useTranslation();

    const currentDate = formatDate(new Date());

    return (
        <SectionColumn title={INVOICE_DETAILS}>
            <InvoiceText text={`${CREATED_ON(currentDate)}`} />
        </SectionColumn>
    );
};

export default InvoiceDetailsColumn;
