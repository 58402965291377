export const TEST_ID = 'areaDropdown';
export const DROPDOWN_ITEMS = [
    {
        label: 'Acres',
        type: 'string',
        value: 'ACRE'
    },
    {
        label: 'Hectares',
        type: 'string',
        value: 'HECTARE'
    }
];
