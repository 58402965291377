import { Fragment, useMemo } from 'react';

import { Image } from '@react-pdf/renderer';

import Document from 'components/pdf/Document';
import HorizontalFlexDivider from 'components/pdf/HorizontalFlexDivider';
import PageWithHeaderOnly from 'components/pdf/PageWithHeaderOnly';
import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';
import WorkOrderApplicationSiteBoundary from 'components/pdf/WorkOrderApplicationSiteBoundary';
import WorkOrderApplicationSites from 'components/pdf/WorkOrderApplicationSites';
import WorkOrderChemicalInformation from 'components/pdf/WorkOrderChemicalInformation';
import WorkOrderCustomerGrower from 'components/pdf/WorkOrderCustomerGrower';
import WorkOrderDetails from 'components/pdf/WorkOrderDetails';
import WorkOrderNotes from 'components/pdf/WorkOrderNotes';
import WorkOrderSiteCommodity from 'components/pdf/WorkOrderSiteCommodity';

import useTranslation from './hooks/useTranslation';

import type { ApplicationSite, FunctionComponent, Props } from './types';

const WorkOrderReportPDF: FunctionComponent<Props> = ({ workOrder }) => {
    const { APPLICATION_SITE, CHEMICAL, HEADER, NO_CHEMICALS, NO_SITES } = useTranslation();

    const { applicationSites, chemicals, customer, grower, key, notes } = workOrder;

    const chemicalComponents = useMemo(() => {
        if (chemicals.length === 0) {
            return (
                <>
                    <Section>
                        <SectionColumn title={CHEMICAL}>
                            <Text>{NO_CHEMICALS}</Text>
                        </SectionColumn>
                    </Section>

                    <HorizontalFlexDivider />
                </>
            );
        }

        return chemicals.map((chemical, chemicalIndex) => (
            <Fragment key={chemicalIndex}>
                <WorkOrderChemicalInformation chemical={chemical} />

                <HorizontalFlexDivider />
            </Fragment>
        ));
    }, [CHEMICAL, NO_CHEMICALS, chemicals]);

    const applicationSiteComponents = useMemo(() => {
        if (applicationSites.length === 0) {
            return (
                <>
                    <Section>
                        <SectionColumn title={APPLICATION_SITE('')}>
                            <Text>{NO_SITES}</Text>
                        </SectionColumn>
                    </Section>

                    <HorizontalFlexDivider />
                </>
            );
        }

        return applicationSites
            .reduce((accumulator, _, index, sites) => {
                if (index % 2 === 0) {
                    accumulator.push(sites.slice(index, index + 2));
                }

                return accumulator;
            }, [] as ApplicationSite[][])
            .map((sitePair, siteIndex) => (
                <Fragment key={siteIndex}>
                    <Section>
                        {sitePair.at(0) && (
                            <WorkOrderApplicationSites
                                title={APPLICATION_SITE(
                                    siteIndex === 0 ? '' : ` (${2 * siteIndex + 1})`
                                )}
                                site={sitePair.at(0)}
                            />
                        )}

                        {sitePair.at(1) && (
                            <WorkOrderApplicationSites
                                title={
                                    sitePair.at(1)
                                        ? APPLICATION_SITE(` (${2 * siteIndex + 2})`)
                                        : undefined
                                }
                                site={sitePair.at(1)}
                            />
                        )}
                    </Section>

                    <HorizontalFlexDivider />
                </Fragment>
            ));
    }, [applicationSites, APPLICATION_SITE, NO_SITES]);

    return (
        <Document>
            <PageWithHeaderOnly headerText={HEADER(`${key}`)} wrap={true}>
                <WorkOrderDetails workOrder={workOrder} />

                <HorizontalFlexDivider />

                <WorkOrderCustomerGrower customer={customer} grower={grower} />

                <HorizontalFlexDivider />

                {applicationSiteComponents}

                <WorkOrderSiteCommodity workOrder={workOrder} />

                <HorizontalFlexDivider />

                {chemicalComponents}

                <WorkOrderNotes notes={notes} />
            </PageWithHeaderOnly>

            {applicationSites.map((site, index) => {
                if (
                    (site.boundary === null || site.boundary?.length === 0) &&
                    !site.coordinates?.latitude &&
                    !site.coordinates?.longitude
                ) {
                    return null;
                }

                return (
                    <PageWithHeaderOnly key={key}>
                        <WorkOrderApplicationSiteBoundary index={index} site={site} />

                        {site.imageUrl && <Image src={site.imageUrl} />}
                    </PageWithHeaderOnly>
                );
            })}
        </Document>
    );
};

export default WorkOrderReportPDF;
