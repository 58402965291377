import OneColumnContainer from 'components/OneColumnContainer';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const PageWithSidebarContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID
}) => (
    <OneColumnContainer
        className={`${className} ${styles.pageWithSidebarContainer}`}
        testId={testId}
    >
        {children}
    </OneColumnContainer>
);

export default PageWithSidebarContainer;
