import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('mapsListPage');

    return {
        GENERATE_BUTTON: t('generateButton'),
        NO_MAPS: t('noMaps'),
        SELECT_DATE: t('selectDate'),
        TITLE: t('title')
    };
};

export default useTranslation;
