import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightReportListRow: FunctionComponent<Props> = ({
    children,
    className = '',
    isEditable = false,
    testId = TEST_ID
}) => {
    const viewOnlyClass = !isEditable ? styles.flightReportListRowViewOnly : '';

    return (
        <div
            className={`${className} ${styles.flightReportListRow} ${viewOnlyClass}`}
            data-testid={testId}
        >
            {children}
        </div>
    );
};

export default FlightReportListRow;
