import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportPage');

    return {
        APPLICATION_DETAILS: t('applicationDetails'),
        NOTES: t('notes'),
        PRODUCT_DETAILS: t('productDetails'),
        TITLE: t('title')
    };
};

export default useTranslation;
