import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfPageNumberOfN');

    return {
        PAGE_NUMBER: (pageNumber: number, totalPages: number) =>
            t('pageNumber', { pageNumber, totalPages })
    };
};

export default useTranslation;
