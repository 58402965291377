import { useState } from 'react';

import JohnDeereExportModal from 'components/JohnDeereExportModal';
import OptionsItem from 'components/OptionsButton/OptionsItem';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ShareToJohnDeere: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    jobId,
    mapType,
    productUsageReportId,
    testId = TEST_ID
}) => {
    const { JOHN_DEERE } = useTranslation();

    const [showExportModal, setShowExportModal] = useState(false);

    return (
        <>
            {showExportModal && (
                <JohnDeereExportModal
                    aam={aam}
                    chemicalNames={chemicalNames}
                    jobId={jobId}
                    mapType={mapType}
                    onClose={() => setShowExportModal(false)}
                    productUsageReportId={productUsageReportId}
                />
            )}

            <OptionsItem
                onClick={() => {
                    setShowExportModal(true);
                }}
                testId={testId}
                text={JOHN_DEERE}
            />
        </>
    );
};

export default ShareToJohnDeere;
