import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ListHeader: FunctionComponent<Props> = ({
    className = '',
    onClick,
    testId = TEST_ID,
    text
}) => (
    <span className={`${className} ${styles.listHeader}`} data-testid={testId} onClick={onClick}>
        {text}
    </span>
);

export default ListHeader;
