import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceCompanyColumn');

    return {
        ADDRESS: t('address'),
        CITY: t('city'),
        COMPANY: t('company'),
        COUNTRY: t('country'),
        NAME: t('name'),
        STATE: t('state'),
        ZIPCODE: t('zipcode')
    };
};

export default useTranslation;
