import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('completeLabel');

    return {
        COMPLETE: t('complete')
    };
};

export default useTranslation;
