import { useCallback } from 'react';

import ContentText from 'components/ContentText';
import DeleteButton from 'components/DeleteButton';
import ModalOverlay from 'components/ModalOverlay';
import ModalTitle from 'components/ModalTitle';
import VerticalContainer from 'components/VerticalContainer';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DeleteConfirmationModal: FunctionComponent<Props> = ({
    onClose,
    onConfirm,
    testId = TEST_ID,
    text,
    title
}) => {
    const { BUTTON_TEXT } = useTranslation();

    const handleDelete = useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose, onConfirm]);

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer className={styles.deleteConfirmationModalContainer}>
                <ModalTitle text={title} />

                <ContentText className={styles.subText} text={text} />

                <DeleteButton onClick={handleDelete} text={BUTTON_TEXT} />
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default DeleteConfirmationModal;
