import { useCallback } from 'react';

import useTranslation from './useTranslation';

const useAvatarInitials = () => {
    const { NOT_APPLICABLE } = useTranslation();

    const getInitials = useCallback(
        (name: string) => {
            if (/^\d+$/.test(name)) {
                return NOT_APPLICABLE;
            }

            const nameArray = name.split(' ');

            if (nameArray.length === 1) {
                return nameArray[0].charAt(0);
            } else if (nameArray.length >= 2) {
                return nameArray[0].charAt(0) + nameArray[nameArray.length - 1].charAt(0);
            }

            return name;
        },
        [NOT_APPLICABLE]
    );

    return { getInitials };
};

export default useAvatarInitials;
