import ContentText from 'components/ContentText';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const ContentLabeledText: FunctionComponent<Props> = ({ label, testId = TEST_ID, text }) => (
    <ContentText testId={testId} text={`${label}: ${text}`} />
);

export default ContentLabeledText;
