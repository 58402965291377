import { useCallback, useEffect, useState } from 'react';
import { registerSW } from 'virtual:pwa-register';

import useInterval from 'hooks/useInterval';

import { ONE_HOUR } from './constants';

const useServiceWorker = () => {
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const [swScriptUrl, setSwScriptUrl] = useState<string>('');
    const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>();

    const handleInterval = useCallback(async () => {
        if (!registration) {
            return;
        }

        const { installing, update } = registration;

        if (!(!installing && navigator)) {
            return;
        }

        if ('connection' in navigator && !navigator.onLine) {
            return;
        }

        const resp = await fetch(swScriptUrl, {
            cache: 'no-store',
            headers: {
                cache: 'no-store',
                'cache-control': 'no-cache'
            }
        });

        if (resp?.status === 200) {
            await update();
        }
    }, [registration, swScriptUrl]);

    useInterval(handleInterval, isRegistered ? ONE_HOUR : null);

    // Automatically check for updates to service worker
    // https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
    const updateSW = registerSW({
        immediate: true,

        onOfflineReady: () => console.log('offline ready'),
        onRegisteredSW(swUrl, r) {
            if (!r) {
                return;
            }

            setRegistration(r);
            setSwScriptUrl(swUrl);
            setIsRegistered(true);
        }
    });

    useEffect(() => {
        updateSW();
    });
};

export default useServiceWorker;
