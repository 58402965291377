import { AddButton } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithAddButton: FunctionComponent<Props> = ({
    children,
    className = '',
    isDisabled = false,
    onClick,
    testId = TEST_ID
}) => (
    <div className={`${className} ${styles.withAddButton}`} data-testid={testId}>
        {children}

        <AddButton className={styles.addButton} isDisabled={isDisabled} onClick={onClick} />
    </div>
);

export default WithAddButton;
