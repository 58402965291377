import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createFaaReportPage');

    return {
        COMPANY_DETAILS: t('companyDetails'),
        ERROR_FETCHING_FAA_REPORT: t('errorFetchingFaaReport'),
        ERROR_UPDATING_FAA_REPORT: t('errorUpdatingFaaReport'),
        FLIGHT_DETAILS: t('flightDetails'),
        FORM_ERROR_MESSAGE: t('formErrorMessage'),
        OPERATION_DETAILS: t('operationDetails'),
        SAVE_REPORT: t('saveReport'),
        TITLE: t('title'),
        UAS_TYPE_AND_MODEL: t('uasTypeAndModel')
    };
};

export default useTranslation;
