import { useEffect } from 'react';

import FlightDetails from 'components/FlightDetails';
import SideBarContentContainer from 'components/SideBarContentContainer';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const AsFlownPage: FunctionComponent<Props> = ({ aamId, onLoad, onUnload, testId = TEST_ID }) => {
    const { alternateFlights, flights, loadFlights } = useData();

    useEffect(() => {
        if (flights.length === 0 && aamId) {
            loadFlights(aamId);
        }

        onLoad?.();

        return () => {
            onUnload?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SideBarContentContainer testId={testId}>
            <FlightDetails
                alternateFlights={alternateFlights}
                flights={flights}
                key={Math.random().toString()}
            />
        </SideBarContentContainer>
    );
};

export default AsFlownPage;
