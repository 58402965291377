import { INPUT_CHARS_MAX } from 'config';
import { object, string } from 'yup';
import 'yup-phone-lite';

import useTranslation from 'components/CompanyDetailsForm/hooks/useTranslation';

import useMapboxGeocoding from 'hooks/useMapboxGeocoding';

const useSchema = () => {
    const { ERRORS } = useTranslation();
    const { validateAddress } = useMapboxGeocoding();

    return object({
        companyName: string().required(ERRORS.NAME_REQUIRED).max(INPUT_CHARS_MAX, ERRORS.NAME_MAX),
        location: object({
            address1: string()
                .required(ERRORS.ADDRESS_REQUIRED)
                .max(INPUT_CHARS_MAX, ERRORS.ADDRESS_MAX),
            address2: string().notRequired().max(INPUT_CHARS_MAX, ERRORS.ADDRESS_2_MAX),
            city: string().required(ERRORS.CITY_REQUIRED).max(INPUT_CHARS_MAX, ERRORS.CITY_MAX),
            state: string().required(ERRORS.STATE_REQUIRED),
            zipCode: string().required(ERRORS.ZIP_CODE_REQUIRED)
        }).test({
            message: ERRORS.LOCATION_INVALID,
            name: 'validAddress',
            test: async value =>
                // disable this validator when testing, to prevent network calls to Mapbox
                // which fails when running in a GitHub Action
                import.meta.env.MODE !== 'test' &&
                value.address1 &&
                value.city &&
                value.state &&
                value.zipCode
                    ? await validateAddress(value)
                    : true
        }),
        phone: string().phone(null, ERRORS.PHONE_INVALID).notRequired()
    });
};

export default useSchema;
