import ListHeader from 'components/ListHeader';
import ListHeaderLinkButton from 'components/ListHeaderLinkButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightLogListHeader: FunctionComponent<Props> = ({
    hasCurrentlySelectedFlights,
    onSelectAll,
    testId = TEST_ID
}) => {
    const {
        APPLICATION_TYPE,
        DRONE,
        FILE_TYPE,
        FLIGHT_DURATION,
        FLIGHT_TIME,
        LOCATION,
        SELECT_ALL,
        SELECT_NONE
    } = useTranslation();
    const selectAllText = hasCurrentlySelectedFlights ? SELECT_NONE : SELECT_ALL;

    return (
        <div className={styles.flightLogListHeader} data-testid={testId}>
            <ListHeaderLinkButton onClick={onSelectAll} text={selectAllText} />

            <ListHeader text={FLIGHT_TIME} />

            <ListHeader text={DRONE} />

            <ListHeader text={FLIGHT_DURATION} />

            <ListHeader text={LOCATION} />

            <ListHeader text={APPLICATION_TYPE} />

            <ListHeader text={FILE_TYPE} />
        </div>
    );
};

export default FlightLogListHeader;
