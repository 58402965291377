import TableColumn from 'components/pdf/TableColumn';
import TableRow from 'components/pdf/TableRow';
import InvoiceTableText from 'components/pdf/invoice/table/InvoiceTableText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceTableChemicalCostRow: FunctionComponent<Props> = ({ chemicalCost, chemicalList }) => {
    const { CHEMICAL_COST } = useTranslation();

    if (!chemicalCost) {
        return null;
    }

    return (
        <TableRow>
            <TableColumn style={styles.itemColumn} withDivider={false}>
                <InvoiceTableText text={CHEMICAL_COST} />
            </TableColumn>

            <TableColumn style={styles.descriptionColumn} withDivider={false}>
                <InvoiceTableText text={chemicalList} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableText text={`$ ${chemicalCost?.toFixed(2)}`} />
            </TableColumn>
        </TableRow>
    );
};

export default InvoiceTableChemicalCostRow;
