import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('organizationDetails');

    return {
        ACCEPTED: t('accepted'),
        BILLING_ADDRESS: t('billingAddress'),
        BUTTON: t('button'),
        COMPANY_DETAILS: t('companyDetails'),
        EMAIL_ADDRESS: t('emailAddress'),
        EQUIPMENT: t('equipment'),
        EQUIPMENT_MAKE: t('equipmentMake'),
        EQUIPMENT_MODEL: t('equipmentModel'),
        IN_REVIEW: t('inReview'),
        LOADING: t('loading'),
        NO_CONTACT: t('noContact'),
        PERSON_OF_CONTACT: t('personOfContact'),
        PLACEHOLDER: t('placeholder'),
        REGISTRATION_NUMBER: t('registrationNumber'),
        SERIAL_NUMBER: t('serialNumber'),
        TITLE: t('title')
    };
};

export default useTranslation;
