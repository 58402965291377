import { useCallback, useState } from 'react';

import HighlightTab from 'components/HighlightTab';
import HighlightTabs from 'components/HighlightTabs';

import useTranslation from './hooks/useTranslation';

import { INITIAL_TAB, TEST_ID } from './constants';
import type { FunctionComponent, Props, TeamDetailsTab } from './types';

import styles from './styles.module.scss';

const TeamDetailsTabs: FunctionComponent<Props> = ({ className, onChange, testId = TEST_ID }) => {
    const [tab, setTab] = useState<TeamDetailsTab>(INITIAL_TAB);
    const { ACTIVE } = useTranslation();

    const handleTabClick = useCallback(
        (value: TeamDetailsTab) => {
            setTab(value);
            onChange(value);
        },
        [onChange]
    );

    return (
        <HighlightTabs className={className} selectedClass={styles[tab] || ''} testId={testId}>
            <HighlightTab
                isActive={tab === 'active'}
                onClick={() => handleTabClick('active')}
                text={ACTIVE}
            />
        </HighlightTabs>
    );
};

export default TeamDetailsTabs;
