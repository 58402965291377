import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfWorkOrderDetails');

    return {
        APPLICATOR: t('applicator'),
        ATTACHED_WORK_ORDER: t('attachedWorkOrder'),
        EXPIRATION_DATE: t('expirationDate'),
        NO: t('no'),
        PROPOSED_DATE: t('proposedDate'),
        SCHEDULED_DATE: t('scheduledDate'),
        STATUS: t('status'),
        TIME: t('time'),
        TITLE: t('title'),
        WORK_ORDER_NUMBER: t('workOrderNumber'),
        YES: t('yes')
    };
};

export default useTranslation;
