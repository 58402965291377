import { atom } from 'recoil';

const isLoggingOutAtom = atom({
    default: false,
    key: 'isLoggingOut'
});

const state = {
    atoms: {
        isLoggingOutAtom
    }
};

export default state;
