import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('copyButton');

    return {
        COPY_ERROR: t('copyError')
    };
};

export default useTranslation;
