import SectionColumn from 'components/pdf/SectionColumn';
import InvoiceText from 'components/pdf/invoice/InvoiceText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const InvoiceApplicationDateColumn: FunctionComponent<Props> = ({ applicationDate }) => {
    const { APPLICATION_DATE, SCHEDULED_FOR } = useTranslation();

    return (
        <SectionColumn title={APPLICATION_DATE}>
            <InvoiceText text={SCHEDULED_FOR(applicationDate)} />
        </SectionColumn>
    );
};

export default InvoiceApplicationDateColumn;
