import { useEffect, useRef } from 'react';

import { UseIntervalHook } from './types';

const useInterval: UseIntervalHook = (callback, delay) => {
    const savedCallback = useRef<VoidFunction>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => {
            savedCallback?.current?.();
        };

        if (delay !== null) {
            const id = setInterval(tick, delay);

            return () => clearInterval(id);
        }
    }, [delay]);
};

export default useInterval;
