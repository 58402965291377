import { INPUT_CHARS_MAX, WORK_ORDER_ACRE_MAX, WORK_ORDER_TARGET_SPRAY_GA_AC_MAX } from 'config';
import { number, object, string } from 'yup';

import useTranslation from 'components/work-order/SiteCommodity/hooks/useTranslation';

import useConvert from 'hooks/useConvert';

import type { WorkOrderAcreageUnit, WorkOrderTargetRateUnit } from './types';

const useSchema = () => {
    const { convertAreaToAcres, convertTargetRateToAcresPerGallon } = useConvert();
    const { ERRORS } = useTranslation();

    return object({
        commodity: string().ensure().max(INPUT_CHARS_MAX, ERRORS.COMMODITY_MAX),
        preHarvestInterval: number().nullable(),
        proposedAcres: number()
            .nullable()
            .test({
                message: ERRORS.PROPOSED_ACRES_MIN,
                name: 'min',
                test: value => (value ? value > 0 : true)
            })
            .test({
                message: ERRORS.PROPOSED_ACRES_MAX,
                name: 'max',
                test: (value, context) => {
                    if (value) {
                        const unit: WorkOrderAcreageUnit = context.parent
                            .proposedAcresUnit as WorkOrderAcreageUnit;

                        return convertAreaToAcres(value, unit) < WORK_ORDER_ACRE_MAX;
                    }

                    return true;
                }
            }),
        proposedAcresUnit: string().required(),
        reEntryIntervalDays: number().nullable(),
        reEntryIntervalHours: number().nullable(),
        targetSprayRate: number()
            .nullable()
            .test({
                message: ERRORS.TARGET_SPRAY_RATE_MIN,
                name: 'min',
                test: value => (value ? value > 0 : true)
            })
            .test({
                message: ERRORS.TARGET_SPRAY_RATE_MAX,
                name: 'max',
                test: (value, context) => {
                    if (value) {
                        const unit: WorkOrderTargetRateUnit = context.parent
                            .targetSprayRateUnit as WorkOrderTargetRateUnit;

                        return (
                            convertTargetRateToAcresPerGallon(value, unit) <
                            WORK_ORDER_TARGET_SPRAY_GA_AC_MAX
                        );
                    }

                    return true;
                }
            }),
        targetSprayRateUnit: string().required()
    });
};

export default useSchema;
