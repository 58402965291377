import { useCallback, useMemo, useState } from 'react';

import DisplayResourcePage from 'components/DisplayResourcePage';
import ResourcePageNavigationButtons from 'components/ResourcePageNavigationButtons';
import SidebarOrDropdownLayout from 'components/SidebarOrDropdownLayout';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const DisplayResourceWithNavMenuPage: FunctionComponent<Props> = ({
    children,
    contentMap,
    defaultSection,
    fetchResource,
    headerActions,
    notFoundText,
    onResourceFetched,
    resourceId,
    sideBarItems,
    testId = TEST_ID,
    title
}) => {
    const [section, setSection] = useState<string>(defaultSection);

    const onSectionChange = useCallback(
        (section: string) => {
            setSection(section);
        },
        [setSection]
    );

    const previousSection = sideBarItems[section]?.previousSection;

    const handleBackButton = useMemo(
        () =>
            previousSection
                ? () => {
                      onSectionChange(previousSection);
                  }
                : undefined,
        [onSectionChange, previousSection]
    );

    const nextSection = sideBarItems[section]?.nextSection;

    const handleNextButton = useMemo(
        () =>
            nextSection
                ? () => {
                      onSectionChange(nextSection);
                  }
                : undefined,
        [nextSection, onSectionChange]
    );

    const pageContent = useMemo(
        () => (
            <>
                <ResourcePageNavigationButtons onBack={handleBackButton} onNext={handleNextButton}>
                    {contentMap[section]}
                </ResourcePageNavigationButtons>

                {children}
            </>
        ),
        [children, contentMap, handleBackButton, handleNextButton, section]
    );

    return (
        <DisplayResourcePage
            fetchResource={fetchResource}
            headerActions={headerActions}
            notFoundText={notFoundText}
            onResourceFetched={onResourceFetched}
            resourceId={resourceId}
            testId={testId}
            title={title}
        >
            <SidebarOrDropdownLayout
                currentSection={section}
                onSectionChange={onSectionChange}
                sidebarItems={sideBarItems}
            >
                {pageContent}
            </SidebarOrDropdownLayout>
        </DisplayResourcePage>
    );
};

export default DisplayResourceWithNavMenuPage;
