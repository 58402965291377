import Page from 'components/pdf/Page';
import PageFooter from 'components/pdf/PageFooter';
import PageHeader from 'components/pdf/PageHeader';

import type { FunctionComponent, Props } from './types';

const PageWithHeaderAndFooter: FunctionComponent<Props> = ({
    children,
    headerText = '',
    wrap = false
}) => (
    <Page wrap={wrap}>
        <PageHeader headerText={headerText} />

        {children}

        <PageFooter />
    </Page>
);

export default PageWithHeaderAndFooter;
