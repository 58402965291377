import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const HeadingText: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text = ''
}) => (
    <h4 className={`${className} ${styles.headingText}`} data-testid={testId}>
        {children || text}
    </h4>
);

export default HeadingText;
