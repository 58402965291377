import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderTabs');

    return {
        PAST_JOBS: t('pastJobs'),
        UPCOMING_JOBS: t('upcomingJobs')
    };
};

export default useTranslation;
