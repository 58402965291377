import { useDevice } from 'components/DeviceProvider';
import ListHeader from 'components/ListHeader';
import VerticalContainer from 'components/VerticalContainer';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapListContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID
}) => {
    const { deviceType } = useDevice();
    const { APPLICATION_DATE, CONTACT, GROWER, MAP_TYPE, PRODUCT_USAGE_REPORT, SITE_NAME } =
        useTranslation();

    return (
        <VerticalContainer className={`${styles.mapListContainer} ${className}`} testId={testId}>
            {deviceType === 'desktop' && (
                <div className={styles.labelContainer}>
                    <ListHeader text={APPLICATION_DATE} />

                    <ListHeader text={CONTACT} />

                    <ListHeader text={GROWER} />

                    <ListHeader text={SITE_NAME} />

                    <ListHeader text={PRODUCT_USAGE_REPORT} />

                    <ListHeader text={MAP_TYPE} />
                </div>
            )}

            <VerticalContainer className={styles.contentContainer}>{children}</VerticalContainer>
        </VerticalContainer>
    );
};

export default MapListContainer;
