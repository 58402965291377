const translations = {
    areaUnitType: 'Area Unit Type',
    associatedApplicationDate: 'Associated Application Date',
    back: 'Back',
    distanceUnitType: 'Distance Unit Type',
    drySpreaderApplication: 'Dry Spreader Application',
    errorImportingKmlFile: 'Error importing your kml files, please try again later',
    fileType: 'File Type',
    fileUploads: 'File Uploads',
    kml: 'KML',
    kmlFileMustExist: 'You must provide a kml file from your dji drone',
    saveToFlightLog: 'Save to Flight Log',
    selectUnit: 'Select Unit',
    startDateEmptyError: 'You must provide a start date and time for the kml file',
    unitExplanation: 'The units selected should match the default units in your Agras account.'
};

export default translations;
