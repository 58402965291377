import { Field, getIn } from 'formik';

import ErrorMessageText from 'components/ErrorMessageText';

import type { FieldProps, FunctionComponent, Props } from './types';

const ErrorMessageList: FunctionComponent<Props> = ({ className, ignoreTouch = false, name }) => (
    <Field name={name}>
        {({ form }: FieldProps) => {
            const errors = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            const visibleError = (touch || ignoreTouch) && errors;

            if (typeof errors === 'string') {
                return <ErrorMessageText className={className} text={errors} />;
            } else if (!Array.isArray(errors)) {
                return null;
            }

            // de-dupe
            const errorList = [...new Set(errors)];

            return (
                visibleError &&
                errorList.map((error, index) => (
                    <ErrorMessageText className={className} key={index} text={error} />
                ))
            );
        }}
    </Field>
);

export default ErrorMessageList;
