import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { CONTROLLER_API } from './constants';
import type { ControllerFormType } from './types';

const useData = () => {
    const { authenticatedGet, authenticatedPut } = useFetch();

    const fetchController = useCallback(
        async (id: string) => {
            const { data, error } = await authenticatedGet(`${CONTROLLER_API}/${id}`);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedGet]
    );

    const editController = useCallback(
        async (values: ControllerFormType, id: string) => {
            const { data, error } = await authenticatedPut(
                `${CONTROLLER_API}/${id}`,
                JSON.stringify(values)
            );

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPut]
    );

    return {
        editController,
        fetchController
    };
};

export default useData;
