import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { AIRCRAFT_API } from './constants';
import type { AircraftFormType } from './types';

const useData = () => {
    const { authenticatedGet, authenticatedPut } = useFetch();

    const fetchAircraft = useCallback(
        async (id: string) => {
            const { data, error } = await authenticatedGet(`${AIRCRAFT_API}/${id}`);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedGet]
    );

    const editAircraft = useCallback(
        async (values: AircraftFormType, id: string) => {
            const { data, error } = await authenticatedPut(
                `${AIRCRAFT_API}/${id}`,
                JSON.stringify(values)
            );

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPut]
    );

    return {
        editAircraft,
        fetchAircraft
    };
};

export default useData;
