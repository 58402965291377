import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useAccessToken from 'hooks/useAccessToken';

import { FEATURE_MAP } from './constants';
import { AvailableFeatures } from './types';

const useFeatureAccess = () => {
    const { isAuthenticated } = useAuth0();
    const { decodedToken, fetchToken } = useAccessToken();

    useEffect(() => {
        if (isAuthenticated) {
            fetchToken();
        }
    }, [isAuthenticated, fetchToken]);

    const featureAccess = Object.keys(FEATURE_MAP).reduce(
        (acc, featureKey) => {
            acc[FEATURE_MAP[featureKey]] = decodedToken?.features?.includes(featureKey) ?? false;

            return acc;
        },
        {} as Record<AvailableFeatures, boolean>
    );

    return featureAccess;
};

export default useFeatureAccess;
