import { useCallback } from 'react';

import { generatePagedQuery } from '@@utils/string';

import useAircraft from 'hooks/useAircraft';
import useFetch from 'hooks/useFetch';

import { PRODUCT_USAGE_REPORT_API, WORK_ORDER_API } from './constants';
import {
    FetchProductUsageReport,
    ProductUsageReportApiResponse,
    ProductUsageReportDraft
} from './types';

const useProductUsageReports = () => {
    const { authenticatedDelete, authenticatedGet, authenticatedPost, authenticatedPut } =
        useFetch();
    const { fetchAircraft } = useAircraft();

    const createProductUsageReportFromDraft = useCallback(
        async (workOrderId: string, draft: ProductUsageReportDraft) => {
            const { data, error } = await authenticatedPost(
                `${WORK_ORDER_API}/${workOrderId}/product-usage-reports`,
                JSON.stringify(draft)
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPost]
    );

    const updateProductUsageReportFromDraft = useCallback(
        async (id: string, draft: ProductUsageReportDraft) => {
            const { data, error } = await authenticatedPut(
                `${PRODUCT_USAGE_REPORT_API}/${id}`,
                JSON.stringify(draft)
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPut]
    );

    const deleteProductUsageReport = useCallback(
        async (id: string) => {
            const { error } = await authenticatedDelete(`${PRODUCT_USAGE_REPORT_API}/${id}`);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }
        },
        [authenticatedDelete]
    );

    const fetchProductUsageReport: FetchProductUsageReport = useCallback(
        async (id: string) => {
            const output = await authenticatedGet(`${PRODUCT_USAGE_REPORT_API}/${id}`);

            if (output.data) {
                const droneId = output.data.droneId;

                if (droneId) {
                    const drone = await fetchAircraft(droneId);

                    if (drone) {
                        output.data = {
                            ...output.data,
                            drone: drone.data
                        };
                    } else {
                        return drone;
                    }
                }
            }

            return output;
        },
        [authenticatedGet, fetchAircraft]
    );

    const fetchProductUsageReports = useCallback(
        async (pageToken?: string, pageSize = 10) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const uri = `${PRODUCT_USAGE_REPORT_API}?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri, null);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as ProductUsageReportApiResponse;
        },
        [authenticatedGet]
    );

    const fetchProductUsageReportsForWorkOrder = useCallback(
        async (workOrderId: string, pageToken?: string, pageSize = 10) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const uri = `${WORK_ORDER_API}/${workOrderId}/product-usage-reports?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri, null);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as ProductUsageReportApiResponse;
        },
        [authenticatedGet]
    );

    const fetchAllProductUsageReportsForWorkOrder = useCallback(
        async (workOrderId: string) => {
            try {
                let productUsageReportResponse =
                    await fetchProductUsageReportsForWorkOrder(workOrderId);

                let productUsageReports = productUsageReportResponse.objects;

                while (productUsageReportResponse.hasNext) {
                    productUsageReportResponse = await fetchProductUsageReportsForWorkOrder(
                        workOrderId,
                        productUsageReportResponse.nextPageToken
                    );
                    productUsageReports = productUsageReports.concat(
                        productUsageReportResponse.objects
                    );
                }

                return {
                    data: productUsageReports,
                    error: undefined
                };
            } catch (e) {
                return {
                    data: undefined,
                    error: e
                };
            }
        },
        [fetchProductUsageReportsForWorkOrder]
    );

    const doesWorkOrderHaveProductUsageReport = useCallback(
        async (workOrderId: string) => {
            const data = await fetchProductUsageReportsForWorkOrder(workOrderId);

            return data.objects.length > 0;
        },
        [fetchProductUsageReportsForWorkOrder]
    );

    return {
        createProductUsageReportFromDraft,
        deleteProductUsageReport,
        doesWorkOrderHaveProductUsageReport,
        fetchAllProductUsageReportsForWorkOrder,
        fetchProductUsageReport,
        fetchProductUsageReports,
        fetchProductUsageReportsForWorkOrder,
        updateProductUsageReportFromDraft
    };
};

export default useProductUsageReports;
