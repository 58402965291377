import useFileDownload from 'hooks/useFileDownload';

import { WORK_ORDER_FILE_DOWNLOAD_API } from './constants';

const useWorkOrderFileDownload = () => {
    const { getDownloadLink } = useFileDownload({ downloadApi: WORK_ORDER_FILE_DOWNLOAD_API });

    return { getDownloadLink };
};

export default useWorkOrderFileDownload;
