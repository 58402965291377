import { useCallback, useState } from 'react';

import HighlightTab from 'components/HighlightTab';
import HighlightTabs from 'components/HighlightTabs';

import useTranslation from './hooks/useTranslation';

import { INITIAL_TAB, TEST_ID } from './constants';
import { FlightLogListTab, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightLogListTabs: FunctionComponent<Props> = ({
    className = '',
    onChange,
    testId = TEST_ID
}) => {
    const [tab, setTab] = useState(INITIAL_TAB);

    const { FLIGHTS } = useTranslation();

    const handleTabClick = useCallback(
        (value: FlightLogListTab) => {
            setTab(value);
            onChange?.(value);
        },
        [onChange]
    );

    return (
        <HighlightTabs className={className} selectedClass={styles[tab] || ''} testId={testId}>
            <HighlightTab
                isActive={tab === 'flights'}
                onClick={() => handleTabClick('flights')}
                text={FLIGHTS}
            />
        </HighlightTabs>
    );
};

export default FlightLogListTabs;
