const translations = {
    airTemperature: 'Air Temperature',
    applicationDate: 'Application Date',
    applicationSite: 'Application Site',
    applicatorName: 'Applicator',
    droneSerialNumber: 'Drone',
    endTime: 'End Time',
    mph: 'MPH',
    startTime: 'Start Time',
    weather: 'Weather',
    windDirection: 'Wind Direction',
    windGustSpeed: 'Wind Gust Speed',
    windSpeed: 'Wind Speed',
    workOrderNumber: 'Work Order Number'
};

export default translations;
