import useUsers from 'hooks/useUsers';

import type { UserApiResponse } from './types';

const useData = () => {
    const { fetchTeamMembers } = useUsers();

    return async (pageToken?: string, pageSize = 10) => {
        const response = await fetchTeamMembers(pageToken, pageSize);

        const { data, error } = response;

        if (error) {
            throw new Error(JSON.stringify(error));
        }

        return data as UserApiResponse;
    };
};

export default useData;
