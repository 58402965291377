import TableColumn from 'components/pdf/TableColumn';
import TableRow from 'components/pdf/TableRow';
import InvoiceTableText from 'components/pdf/invoice/table/InvoiceTableText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceTableOtherItemRows: FunctionComponent<Props> = ({ invoiceItems }) => {
    const { OTHER } = useTranslation();

    return invoiceItems.map(({ amountDollars, description }, index) => (
        <TableRow key={index}>
            <TableColumn style={styles.itemColumn} withDivider={false}>
                <InvoiceTableText text={OTHER} />
            </TableColumn>

            <TableColumn style={styles.descriptionColumn} withDivider={false}>
                <InvoiceTableText text={description} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableText text={`$ ${amountDollars?.toFixed(2)}`} />
            </TableColumn>
        </TableRow>
    ));
};

export default InvoiceTableOtherItemRows;
