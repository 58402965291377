import { forwardRef } from 'react';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const Divider = forwardRef<HTMLHRElement, Props>(({ className, testId = TEST_ID }, ref) => (
    <hr className={`${className} ${styles.divider}`} data-testid={testId} ref={ref} />
));

Divider.displayName = 'Divider';

export default Divider;
