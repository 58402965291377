import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContentText from 'components/ContentText';
import EditableSection from 'components/EditableSection';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, PersonOfContact, Props } from './types';

const PersonOfContactEditCard: FunctionComponent<Props> = ({ editable = false }) => {
    const { fetchPersonOfContact, personOfContact } = useCurrentOrganization();
    const navigate = useNavigate();
    const { editPersonOfContactPage } = usePageRoutes();
    const { LOADING, NO_CONTACT, TITLE } = useTranslation();

    const [contact, setContact] = useState<PersonOfContact>();

    useEffect(() => {
        const fetch = async () => {
            await fetchPersonOfContact(false);
        };

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setContact(personOfContact);
    }, [personOfContact]);

    const handleEdit = useCallback(() => {
        navigate(editPersonOfContactPage);
    }, [navigate, editPersonOfContactPage]);

    if (contact === undefined) {
        return (
            <EditableSection title={TITLE}>
                <ContentText text={LOADING} />
            </EditableSection>
        );
    }

    if (contact === null) {
        return (
            <EditableSection editable={editable} onEdit={handleEdit} title={TITLE}>
                <ContentText text={NO_CONTACT} />
            </EditableSection>
        );
    }

    const { email: contactEmail, firstName, lastName, phoneNumber: contactPhone } = contact;

    const contactPhoneSections = contactPhone
        ? contactPhone.match(/^(\d{3})(\d{3})(\d{4})$/)
        : null;
    const contactPhoneFormatted =
        contactPhoneSections !== null
            ? `(${contactPhoneSections[1]}) ${contactPhoneSections[2]}-${contactPhoneSections[3]}`
            : '';

    return (
        <EditableSection editable={editable} onEdit={handleEdit} title={TITLE}>
            <ContentText text={`${firstName} ${lastName}`} />

            <ContentText text={contactPhoneFormatted} />

            <ContentText text={contactEmail} />
        </EditableSection>
    );
};

export default PersonOfContactEditCard;
