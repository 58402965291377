import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('newProductUsageReportPage');

    return {
        APPLICATION_DETAILS: t('applicationDetails'),
        FETCH_DRAFT_ERROR: t('fetchDraftError'),
        FINAL_SUBMIT_ERROR: t('finalSubmitError'),
        NOTES: t('notes'),
        PRODUCT_DETAILS: t('productDetails'),
        SAVE_AS_DRAFT: t('saveAsDraft'),
        SUBMIT_FORM_ERROR: t('submitFormError'),
        SUBMIT_REPORT: t('submitReport'),
        TITLE: t('title')
    };
};

export default useTranslation;
