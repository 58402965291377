import AccordionHeader from 'components/AccordionHeader';
import ContentText from 'components/ContentText';
import HorizontalContainer from 'components/HorizontalContainer';
import VerticalContainer from 'components/VerticalContainer';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const AccordionContainer: FunctionComponent<Props> = ({
    children,
    expandDisabled = false,
    headerComponent,
    isOpen = false,
    onClose,
    onOpen,
    testId = TEST_ID,
    text = ''
}) => {
    const containerClass = isOpen ? styles.open : styles.close;

    return (
        <VerticalContainer className={styles.accordionContainer} testId={testId}>
            <HorizontalContainer className={styles.accordionHeader} testId={testId}>
                <ContentText className={styles.contentText} text={text} />

                <AccordionHeader
                    isEnabled={!expandDisabled}
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                >
                    {headerComponent}
                </AccordionHeader>
            </HorizontalContainer>

            <div className={containerClass}>{children}</div>
        </VerticalContainer>
    );
};

export default AccordionContainer;
