import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { AIRCRAFT_MODELS_API } from './constants';
import type { AircraftModelsApiResponse } from './types';

const useData = () => {
    const { authenticatedGet } = useFetch();
    const { addQuery } = useQuerystring({});

    const fetchModels = async (droneMake?: string) => {
        let queryString = '';

        if (droneMake) {
            const query = addQuery({ droneMake });

            queryString = `?${query.toString()}`;
        }

        const uri = `${AIRCRAFT_MODELS_API}${queryString}`;
        const response = await authenticatedGet(uri, null);

        const { data, error } = response;

        if (error) {
            throw new Error(JSON.stringify(error));
        }

        return data as AircraftModelsApiResponse;
    };

    return {
        fetchModels
    };
};

export default useData;
