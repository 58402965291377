const translations = {
    createFaaReportButton: 'Create FAA Report',
    createFaaReportError: 'Error creating FAA Report',
    deleteFaaReportError: 'Error deleting FAA Report',
    error: 'There was an error retrieving your reports try again later',
    noData: 'No Reports Available',
    title: 'FAA Reports'
};

export default translations;
