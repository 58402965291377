import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightList');

    return {
        DATA_TYPE: t('dataType'),
        DRONES: t('drone'),
        SPRAY_TYPE: t('sprayType'),
        TIMESTAMP: t('timestamp'),
        TYPE: t('type')
    };
};

export default useTranslation;
