import { Text, View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const DataLabel: FunctionComponent<Props> = ({ text }) => (
    <View style={styles.dataLabel}>
        <Text style={styles.dataLabelText}>{text}</Text>
    </View>
);

export default DataLabel;
