import ColoredLabel from 'components/colored-labels/ColoredLabel';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WetLabel: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const { WET } = useTranslation();

    return (
        <ColoredLabel className={`${className} ${styles.wetLabel}`} testId={testId} text={WET} />
    );
};

export default WetLabel;
