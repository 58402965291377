import { type FunctionComponent, useEffect } from 'react';

import PageLoader from 'components/PageLoader';

import useLogout from 'hooks/useLogout';

const LogoutPage: FunctionComponent = () => {
    const { logout } = useLogout();

    useEffect(() => {
        logout();
    }, [logout]);

    return <PageLoader />;
};

export default LogoutPage;
