import type { Style } from './types';

const styles: Style = {
    borderTop: '1pt',
    borderTopColor: '#9e9e9e',
    height: 0,
    width: '100%'
};

export default styles;
