import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportDeleteConfirmationModal');

    return {
        BUTTON_TEXT: t('buttonText'),
        CONFIRM_DELETE_PRODUCT_USAGE_REPORT: t('confirmDeleteProductUsageReport'),
        CONFIRM_DELETE_PRODUCT_USAGE_REPORT_MESSAGE: t('confirmDeleteProductUsageReportMessage'),
        CONFIRM_DELETE_PRODUCT_USAGE_REPORT_SUBTEXT_MESSAGE: t(
            'confirmDeleteProductUsageReportSubtextMessage'
        )
    };
};

export default useTranslation;
