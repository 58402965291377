import ContentLabeledText from 'components/ContentLabeledText';
import ItemCard from 'components/ItemCard';
import LargeSemiboldText from 'components/LargeSemiboldText';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DroneSelectCard: FunctionComponent<Props> = ({
    isDisabled = false,
    isEditable,
    make,
    model,
    nickname,
    registrationNumber,
    selectNumber,
    testId = TEST_ID
}) => {
    const { MAKE, MODEL, NICKNAME, REGISTRATION_NUMBER, UAS } = useTranslation();
    const viewOnlyClass = isEditable ? '' : styles.viewOnlyCard;
    const disabledClass = isDisabled ? styles.disabledCard : '';

    return (
        <ItemCard
            className={`${styles.droneSelectCard} ${viewOnlyClass} ${disabledClass}`}
            testId={testId}
        >
            {!isEditable && <LargeSemiboldText text={UAS(selectNumber ?? 1)} />}

            <ContentLabeledText label={NICKNAME} text={nickname} />

            <ContentLabeledText label={MAKE} text={make} />

            <ContentLabeledText label={MODEL} text={model} />

            <ContentLabeledText label={REGISTRATION_NUMBER} text={registrationNumber} />
        </ItemCard>
    );
};

export default DroneSelectCard;
