import { Outlet } from 'react-router-dom';

import TopBottomContainer from 'components/TopBottomContainer';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const AccountPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => (
    <div className={styles.accountPage} data-testid={testId}>
        <TopBottomContainer className={styles.topBottomContainer}>
            <Outlet />
        </TopBottomContainer>
    </div>
);

export default AccountPage;
