import type { Style } from './types';

const styles: Record<string, Style> = {
    columnDivider: {
        borderLeft: '1pt solid #b9b9b9'
    },
    tableColumn: {
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%'
    }
};

export default styles;
