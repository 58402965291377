import { useDevice } from 'components/DeviceProvider';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ColoredLabel: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text
}) => {
    const { deviceType } = useDevice();

    return (
        <div className={`${styles.coloredLabel} ${className}`} data-testid={testId}>
            {deviceType === 'desktop' && (children || text)}
        </div>
    );
};

export default ColoredLabel;
