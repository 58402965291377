import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formMultiFileUploadFileListContainer');

    return {
        HEADER: (fileCount: string) => t('header', { fileCount })
    };
};

export default useTranslation;
