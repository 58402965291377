import { Page as PdfPage } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const Page: FunctionComponent<Props> = ({ children, wrap = false }) => (
    <PdfPage size="LETTER" style={styles} wrap={wrap}>
        {children}
    </PdfPage>
);

export default Page;
