const translations = {
    days: 'DAYS',
    gpa: 'Ga/Ac',
    hours: 'HOURS',
    preHarvestInterval: 'Pre-Harvest Interval: ',
    proposedAcres: 'Proposed Acres: ',
    reEntryInterval: 'Re-Entry Interval: ',
    siteCommodity: 'Site Commodity: ',
    targetSprayRate: 'Target Spray Rate: ',
    title: 'Site Commodity'
};

export default translations;
