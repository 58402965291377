import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const UnitLabel: FunctionComponent<Props> = ({ className = '', label, testId = TEST_ID }) => (
    <span className={`${styles.unitLabel} ${className}`} data-testid={testId}>
        {label}
    </span>
);

export default UnitLabel;
