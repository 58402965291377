import { useEffect } from 'react';

import { UseEventHook } from './types';

const useEvent: UseEventHook = ({ event, handler }) => {
    useEffect(() => {
        document.addEventListener(event, handler);

        return () => {
            document.removeEventListener(event, handler);
        };
    }, [event, handler]);
};

export default useEvent;
