import { array, boolean, date, number, object, string } from 'yup';

import useTranslation from 'components/NewProductUsageReport/ApplicationDetailsForm/hooks/useTranslation';

const useSchema = () => {
    const { ERRORS } = useTranslation();

    return object({
        applicationDate: date().required(ERRORS.APPLICATION_DATE_REQUIRED),
        applicationSites: array(),
        droneId: string().ensure().optional(),
        endTime: string()
            .required(ERRORS.END_TIME_REQUIRED)
            .ensure()
            .test({
                message: ERRORS.END_TIME_GREATER_THAN_START_TIME,
                name: 'direction',
                test: (value, context) =>
                    value && context.parent.startTime ? value >= context.parent.startTime : true
            }),
        endTimeTimeZone: string().ensure().required(),
        noWind: boolean().optional(),
        startTime: string().ensure().required(ERRORS.START_TIME_REQUIRED),
        startTimeTimeZone: string().required(),
        temperature: number().required(ERRORS.TEMPERATURE_REQUIRED),
        temperatureUnit: string().ensure().required(),
        windDirection: string().ensure().required(ERRORS.WIND_DIRECTION_REQUIRED),
        windGustSpeed: number()
            .required(ERRORS.WIND_GUST_REQUIRED)
            .min(0, ERRORS.WIND_SPEED_NOT_NEGATIVE),
        windGustSpeedUnit: string().ensure().required(),
        windSpeed: number()
            .required(ERRORS.WIND_SPEED_REQUIRED)
            .min(0, ERRORS.WIND_SPEED_NOT_NEGATIVE),
        windSpeedUnit: string().ensure().required()
    });
};

export default useSchema;
