import { ReactNode, useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { frontendToAreaRateUnitMap, rateUnitMap } from '@@types/units';

import MultiPartFormWithFetchedResource from 'components/MultipartFormWithFetchedResource';
import ApplicationDetailsForm from 'components/NewProductUsageReport/ApplicationDetailsForm';
import NotesForm from 'components/NewProductUsageReport/NotesForm';
import ProductDetailsForm from 'components/NewProductUsageReport/ProductDetailsForm';

import useBack from 'hooks/useBack';
import useConvert from 'hooks/useConvert';
import useCurrentTime from 'hooks/useCurrentTime';
import usePageRoutes from 'hooks/usePageRoutes';
import useProductUsageReportDrafts from 'hooks/useProductUsageReportDrafts';
import { MassUnit, ProductUsageReportDraftResponse } from 'hooks/useProductUsageReportDrafts/types';
import useProductUsageReports from 'hooks/useProductUsageReports';
import { ProductUsageReport } from 'hooks/useProductUsageReports/types';

import useProductUsageReportOptions from './hooks/useProductUsageReportOptions';
import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import { defaultProductUsageReport, defaultProductUsageReportOptions } from './defaults';
import {
    AmountUnit,
    ChemicalAppliedForm,
    FunctionComponent,
    ProductUsageReportForm,
    ProductUsageReportOptions,
    ProductUsageReportSection,
    Props,
    SideBarItem,
    VolumeUnit
} from './types';

const NewProductUsageReportPage: FunctionComponent<Props> = ({ isEditMode = false }) => {
    const { productUsageReportsPage } = usePageRoutes();

    const back = useBack(productUsageReportsPage);

    const { draftId, productUsageReportId, workOrderId } = useParams();
    const { fetchProductUsageReportOptions } = useProductUsageReportOptions();

    const {
        APPLICATION_DETAILS,
        FETCH_DRAFT_ERROR,
        FINAL_SUBMIT_ERROR,
        NOTES,
        PRODUCT_DETAILS,
        SAVE_AS_DRAFT,
        SUBMIT_REPORT,
        TITLE
    } = useTranslation();

    const saveButtonTextMap: Record<ProductUsageReportSection, string> = {
        applicationDetails: isEditMode ? SUBMIT_REPORT : SAVE_AS_DRAFT,
        notes: SUBMIT_REPORT,
        productDetails: isEditMode ? SUBMIT_REPORT : SAVE_AS_DRAFT
    };

    const customNextTextMap: Record<ProductUsageReportSection, string | undefined> = {
        applicationDetails: undefined,
        notes: isEditMode ? undefined : SAVE_AS_DRAFT,
        productDetails: undefined
    };

    const [productUsageReportOptions, setProductUsageReportOptions] =
        useState<ProductUsageReportOptions>(defaultProductUsageReportOptions);

    const { schemaMap } = useSchema();

    const { getCurrentTime, getCurrentTimeZone } = useCurrentTime();
    const [defaultValues, setDefaultValues] = useState<ProductUsageReportForm>();
    const productUsageReport = useRef<ProductUsageReport>();

    const [draftProductUsageReportId, setDraftProductUsageReportId] = useState<string>();

    const {
        createProductUsageReportFromDraft,
        fetchProductUsageReport,
        updateProductUsageReportFromDraft
    } = useProductUsageReports();

    const {
        createPayloadFromForm,
        createProductUsageReportDraftFromForm,
        deleteProductUsageReportDraft,
        fetchProductUsageReportDraftForm,
        fetchProductUsageReportForm,
        updateProductUsageReportDraftFromForm
    } = useProductUsageReportDrafts();

    const {
        calculateAmountApplied,
        convertDryMass,
        convertLiquidVolume,
        convertSquareMetersToAcres
    } = useConvert();

    const getDefaultChemicalsFromOptions = useCallback(
        (options: ProductUsageReportOptions) => {
            const defaultChemicalSource = options.chemicals;
            const { proposedAcres } = options;

            return defaultChemicalSource.map(({ id, labelName, sprayRate, sprayRateUnit }) => {
                const areaRateUnit = sprayRateUnit
                    ? frontendToAreaRateUnitMap[sprayRateUnit]
                    : 'GALLONS_PER_ACRE';

                const amountUnitDerivedFromRateUnit = areaRateUnit
                    ? (rateUnitMap[areaRateUnit] as AmountUnit)
                    : ('GALLONS' as AmountUnit);

                const purWetChemical = productUsageReport.current?.chemicalsApplied.find(
                    chemicalApplied => chemicalApplied.chemical.id === id
                );

                const purDryChemical = productUsageReport.current?.dryChemicalsApplied.find(
                    chemicalApplied => chemicalApplied.chemical.id === id
                );

                const getAmountUnit = () => {
                    if (productUsageReport.current?.chemicalsApplied) {
                        if (purDryChemical) {
                            const dryChemicalAmountUnit =
                                productUsageReport.current?.dryChemicalsApplied.find(
                                    chemicalApplied => chemicalApplied.chemical.id === id
                                )?.preferredMassUnits;

                            return dryChemicalAmountUnit;
                        }

                        if (purWetChemical) {
                            const wetChemicalAmountUnit =
                                productUsageReport.current?.chemicalsApplied.find(
                                    chemicalApplied => chemicalApplied.chemical.id === id
                                )?.preferredVolumeUnits;

                            return wetChemicalAmountUnit;
                        }
                    }

                    return amountUnitDerivedFromRateUnit;
                };

                const getAmount = () => {
                    if (sprayRate && productUsageReport.current) {
                        if (sprayRateUnit?.includes('Ac')) {
                            const totalAreaInAcres = convertSquareMetersToAcres(
                                productUsageReport.current.totalAreaAppliedSquareMeters
                            );

                            if (purWetChemical) {
                                return convertLiquidVolume(
                                    sprayRate * totalAreaInAcres,
                                    amountUnitDerivedFromRateUnit as VolumeUnit,
                                    getAmountUnit() as VolumeUnit
                                );
                            }
                            if (purDryChemical) {
                                return convertDryMass(
                                    sprayRate * totalAreaInAcres,
                                    amountUnitDerivedFromRateUnit as MassUnit,
                                    getAmountUnit() as MassUnit
                                );
                            }
                        }

                        return sprayRate * productUsageReport.current.totalAreaAppliedSquareMeters;
                    }

                    return calculateAmountApplied(
                        sprayRate ?? 0.0,
                        areaRateUnit,
                        proposedAcres ?? 0.0
                    );
                };

                return {
                    amountApplied: getAmount(),
                    chemicalId: id,
                    label: labelName,
                    preferredAmountUnits: getAmountUnit(),
                    preferredRateUnits: areaRateUnit,
                    rateApplied: sprayRate
                } as ChemicalAppliedForm;
            });
        },
        [convertDryMass, convertLiquidVolume, calculateAmountApplied, convertSquareMetersToAcres]
    );

    const setInitialDefaultValues = useCallback(
        (options?: ProductUsageReportOptions) => {
            const currentOptions = options ?? productUsageReportOptions;
            const { proposedAcres } = currentOptions;
            const defaultChemicals = getDefaultChemicalsFromOptions(currentOptions);

            const newDefaults = {
                ...defaultProductUsageReport,
                chemicalsApplied: defaultChemicals,
                endTimeTimeZone: getCurrentTimeZone,
                startTime: getCurrentTime(),
                startTimeTimeZone: getCurrentTimeZone,
                totalAreaAppliedAcres: proposedAcres
            };

            setDefaultValues(newDefaults);
        },
        [
            getCurrentTime,
            getCurrentTimeZone,
            getDefaultChemicalsFromOptions,
            productUsageReportOptions
        ]
    );

    const loadDraft = useCallback(
        async (productUsageReportOptions: ProductUsageReportOptions) => {
            try {
                if (draftId) {
                    const draftProductUsageReport = await fetchProductUsageReportDraftForm(draftId);

                    setDefaultValues(draftProductUsageReport);
                    setDraftProductUsageReportId(draftId);

                    if (draftProductUsageReport.chemicalsApplied.length === 0) {
                        setDefaultValues({
                            ...draftProductUsageReport,
                            chemicalsApplied:
                                getDefaultChemicalsFromOptions(productUsageReportOptions)
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                alert(FETCH_DRAFT_ERROR);
                setInitialDefaultValues();
            }
        },
        [
            draftId,
            fetchProductUsageReportDraftForm,
            getDefaultChemicalsFromOptions,
            FETCH_DRAFT_ERROR,
            setInitialDefaultValues
        ]
    );

    const sideBarItems: Record<ProductUsageReportSection, SideBarItem> = {
        applicationDetails: {
            index: 0,
            isDisabled: false,
            isInvalid: false,
            isViewOnly: false,
            nextSection: 'productDetails',
            title: APPLICATION_DETAILS
        },
        notes: {
            index: 2,
            isDisabled: false,
            isInvalid: false,
            isViewOnly: false,
            previousSection: 'productDetails',
            title: NOTES
        },
        productDetails: {
            index: 1,
            isDisabled: false,
            isInvalid: false,
            isViewOnly: false,
            nextSection: 'notes',
            previousSection: 'applicationDetails',
            title: PRODUCT_DETAILS
        }
    };

    const contentMap: Record<ProductUsageReportSection, ReactNode> = {
        applicationDetails: (
            <ApplicationDetailsForm productUsageReportOptions={productUsageReportOptions} />
        ),
        notes: <NotesForm />,
        productDetails: <ProductDetailsForm />
    };

    const firstPage: ProductUsageReportSection = 'applicationDetails';

    const onProductUsageReportOptionsFetched = useCallback(
        (data: ProductUsageReportOptions) => {
            setInitialDefaultValues(data);

            if (draftId) {
                loadDraft(data);
            } else if (productUsageReport.current) {
                const productUsageReportForm = fetchProductUsageReportForm(
                    productUsageReport.current as unknown as ProductUsageReportDraftResponse
                );

                setDefaultValues({
                    ...productUsageReportForm,
                    chemicalsApplied: getDefaultChemicalsFromOptions(data)
                });
            }
            setProductUsageReportOptions(data);
        },
        [
            setInitialDefaultValues,
            draftId,
            productUsageReport,
            loadDraft,
            fetchProductUsageReportForm,
            getDefaultChemicalsFromOptions
        ]
    );

    const onFormSubmit = useCallback(
        async (
            values: ProductUsageReportForm,
            finalSubmit: boolean,
            section: string,
            onNext?: VoidFunction
        ) => {
            if (!finalSubmit && onNext) {
                onNext();

                return;
            }

            try {
                const productUsageReportSection: ProductUsageReportSection =
                    section as ProductUsageReportSection;

                if ((productUsageReportSection === 'notes' || isEditMode) && finalSubmit) {
                    const payload = createPayloadFromForm(values);

                    if (isEditMode && productUsageReportId) {
                        await updateProductUsageReportFromDraft(productUsageReportId, payload);
                    } else {
                        await createProductUsageReportFromDraft(workOrderId as string, payload);
                    }

                    if (draftProductUsageReportId) {
                        await deleteProductUsageReportDraft(draftProductUsageReportId);
                    }

                    back();

                    return;
                } else if (!draftProductUsageReportId) {
                    const draft = await createProductUsageReportDraftFromForm(
                        values,
                        workOrderId as string
                    );

                    setDraftProductUsageReportId(draft.id);
                    back();
                } else {
                    await updateProductUsageReportDraftFromForm(values, draftProductUsageReportId);
                    back();
                }
            } catch (e) {
                console.log(e);
                alert(FINAL_SUBMIT_ERROR);
            }
        },
        [
            draftProductUsageReportId,
            createPayloadFromForm,
            isEditMode,
            productUsageReportId,
            back,
            updateProductUsageReportFromDraft,
            createProductUsageReportFromDraft,
            workOrderId,
            deleteProductUsageReportDraft,
            createProductUsageReportDraftFromForm,
            updateProductUsageReportDraftFromForm,
            FINAL_SUBMIT_ERROR
        ]
    );

    const fetchProductUsageReportOptionsWithWorkOrderIdOrProductUsageReportId = useCallback(
        async (workOrderId: string) => {
            if (isEditMode && productUsageReportId) {
                const { data, error } = await fetchProductUsageReport(productUsageReportId);

                if (error) {
                    throw new Error(JSON.stringify(error));
                }

                productUsageReport.current = data;

                return await fetchProductUsageReportOptions(data.workOrderId);
            }

            return await fetchProductUsageReportOptions(workOrderId);
        },
        [fetchProductUsageReport, fetchProductUsageReportOptions, isEditMode, productUsageReportId]
    );

    return (
        <MultiPartFormWithFetchedResource
            canFinalSubmit={true}
            contentMap={contentMap}
            customNextText={customNextTextMap}
            defaultSection={firstPage}
            fetchResource={fetchProductUsageReportOptionsWithWorkOrderIdOrProductUsageReportId}
            initialValues={defaultValues}
            notFoundText={TITLE}
            onResourceFetched={onProductUsageReportOptionsFetched}
            onSubmit={onFormSubmit}
            resourceId={workOrderId}
            saveButtonText={saveButtonTextMap}
            sideBarItems={sideBarItems}
            title={TITLE}
            validationSchemas={schemaMap}
        />
    );
};

export default NewProductUsageReportPage;
