const translations = {
    description: 'Select an applicable organization and field to exchange data between systems.',
    disconnectFromJohnDeere: 'Disconnect from John Deere',
    exportError: 'Error exporting map to John Deere. Please try again later.',
    fieldError: 'Error fetching fields for your organization. Please try again later.',
    mapTitle: 'Drone Application ({{date}})',
    organizationError: 'Error fetching organizations from John Deere. Please try again later.',
    selectField: 'Select Field',
    title: 'Share to John Deere',
    upload: 'Upload'
};

export default translations;
