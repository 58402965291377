import TableColumn from 'components/pdf/TableColumn';
import TableRow from 'components/pdf/TableRow';
import InvoiceTableText from 'components/pdf/invoice/table/InvoiceTableText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceTableFieldFlatFeeRow: FunctionComponent<Props> = ({ fieldApplicationFlatFee }) => {
    const { FIELD_APPLICATION, FLAT_FEE } = useTranslation();

    if (!fieldApplicationFlatFee) {
        return null;
    }

    return (
        <TableRow>
            <TableColumn style={styles.itemColumn} withDivider={false}>
                <InvoiceTableText text={FIELD_APPLICATION} />
            </TableColumn>

            <TableColumn style={styles.descriptionColumn} withDivider={false}>
                <InvoiceTableText text={FLAT_FEE} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableText text={`$ ${fieldApplicationFlatFee?.toFixed(2)}`} />
            </TableColumn>
        </TableRow>
    );
};

export default InvoiceTableFieldFlatFeeRow;
