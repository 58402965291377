import type { Style } from './types';

const styles: Record<string, Style> = {
    descriptionColumn: {
        flex: 2
    },
    itemColumn: {
        flex: 1.5
    },
    totalColumn: {
        flex: 1
    }
};

export default styles;
