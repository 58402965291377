import Header from 'components/pdf/Header';
import PageNumberOfN from 'components/pdf/PageNumberOfN';
import InvoiceHeaderText from 'components/pdf/invoice/InvoiceHeaderText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

const InvoicePageHeader: FunctionComponent = () => {
    const { HEADER } = useTranslation();

    return (
        <Header>
            <InvoiceHeaderText text={HEADER} />

            <PageNumberOfN />
        </Header>
    );
};

export default InvoicePageHeader;
