import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Label: FunctionComponent<Props> = ({ className = '', testId = TEST_ID, text = '' }) => (
    <label className={`${className} ${styles.label}`} data-testid={testId}>
        {text}
    </label>
);

export default Label;
