import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pendingLabel');

    return {
        PENDING: t('pending')
    };
};

export default useTranslation;
