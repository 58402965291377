import Tabs from 'components/Tabs';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const VerticalTabs: FunctionComponent<Props> = ({ children, className, testId = TEST_ID }) => (
    <Tabs className={`${className} ${styles.verticalTabs}`} testId={testId}>
        {children}
    </Tabs>
);

export default VerticalTabs;
