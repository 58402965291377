import Copyright from 'components/pdf/Copyright';
import Footer from 'components/pdf/Footer';

import type { FunctionComponent } from './types';

const PageFooter: FunctionComponent = () => (
    <Footer>
        <Copyright />
    </Footer>
);

export default PageFooter;
