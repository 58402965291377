import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useJohnDeere from 'hooks/useJohnDeere';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { FunctionComponent } from './types';

const JohnDeereRedirectPage: FunctionComponent = () => {
    const { getPreviousPageFromUrl, setTokenFromRedirect } = useJohnDeere();

    const { homePage } = usePageRoutes();

    const navigate = useNavigate();

    const { ERROR_MESSAGE } = useTranslation();

    const setTokenAndRedirectBack = useCallback(async () => {
        try {
            await setTokenFromRedirect();
        } catch (e) {
            console.error(e);
            alert(ERROR_MESSAGE);
        }

        const previousPage = getPreviousPageFromUrl();

        navigate(previousPage ?? homePage);
    }, [getPreviousPageFromUrl, navigate, homePage, setTokenFromRedirect, ERROR_MESSAGE]);

    useEffect(
        () => {
            setTokenAndRedirectBack();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return null;
};

export default JohnDeereRedirectPage;
