import useFetch from 'hooks/useFetch';

import { CONTROLLER_API } from './constants';
import type { ControllerFormType } from './types';

const useData = () => {
    const { authenticatedPost } = useFetch();

    const createController = async (values: ControllerFormType) => {
        const response = await authenticatedPost(CONTROLLER_API, JSON.stringify(values));

        const { data, error } = response;

        if (error) {
            throw new Error(JSON.stringify(error));
        }

        return data;
    };

    return {
        createController
    };
};

export default useData;
