import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import FlightDetails from 'components/FlightDetails';
import NotFoundErrorPage from 'components/NotFoundErrorPage';
import Page from 'components/Page';

import useBack from 'hooks/useBack';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { REMOVE_FERRYING_LINES, TEST_ID } from './constants';
import type { Flight, FunctionComponent, Props } from './types';

const FlightPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { flightLogPage } = usePageRoutes();
    const back = useBack(flightLogPage);
    const { fetch } = useData();
    const { flightId } = useParams();
    const { TITLE } = useTranslation();

    const [flight, setFlight] = useState<Flight>();
    const [alternateFlight, setAlternateFlight] = useState<Flight>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFlight = async () => {
            if (flightId) {
                try {
                    const flightData = await fetch(flightId);
                    const altFlight = await fetch(flightId, REMOVE_FERRYING_LINES);

                    setFlight(flightData);
                    setAlternateFlight(altFlight);
                } catch (error) {
                    alert(error);
                    console.error(error);
                }
            }

            setLoading(false);
        };

        fetchFlight();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page isLoading={loading} onBack={back} testId={testId} title={TITLE}>
            {flight ? (
                <FlightDetails
                    alternateFlights={alternateFlight ? [alternateFlight] : []}
                    flights={[flight]}
                />
            ) : (
                <NotFoundErrorPage resourceName={TITLE} />
            )}
        </Page>
    );
};

export default FlightPage;
