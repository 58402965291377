import TableColumn from 'components/pdf/TableColumn';
import InvoiceTableFooterText from 'components/pdf/invoice/table/InvoiceTableFooterText';
import InvoiceTableHighlightedRow from 'components/pdf/invoice/table/InvoiceTableHighlightedRow';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceTableFooter: FunctionComponent<Props> = ({ estimatedTotal }) => {
    const { ESTIMATED_TOTAL } = useTranslation();

    return (
        <InvoiceTableHighlightedRow>
            <TableColumn style={styles.estimatedTotalColumn} withDivider={false}>
                <InvoiceTableFooterText text={ESTIMATED_TOTAL} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableFooterText text={`$ ${estimatedTotal?.toFixed()}`} />
            </TableColumn>
        </InvoiceTableHighlightedRow>
    );
};

export default InvoiceTableFooter;
