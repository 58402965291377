import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightOverviewTab');

    return {
        CLOSE: t('close')
    };
};

export default useTranslation;
