import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderListPageLayoutHeader');

    return {
        CREATE_WORK_ORDER: t('createWorkOrder')
    };
};

export default useTranslation;
