export const formatPhoneNumberForApi = (value?: string | null) => {
    if (!value || value?.trim() === '') {
        return null;
    }

    return value.replaceAll(/[-(). ]/g, '');
};

export const generateOptionsList1toN = (length: number) =>
    Array.from({ length }, (_, i) => i).map(value =>
        value === 0
            ? { label: '', value: null }
            : {
                  label: value.toString(),
                  value
              }
    );

export const trimAndNullIfEmptyOrUndefined = (value: string | number | null | undefined) => {
    if (value === null) {
        return value;
    }

    if (typeof value === 'number') {
        if (value !== undefined) {
            return value;
        }
    }

    if (typeof value === 'string') {
        if (value?.trim()) {
            return value.trim();
        }
    }

    return null;
};

export const emptyIfNullOrUndefined = (value: string | null | undefined) => {
    if (value === null || value === undefined) {
        return '';
    }

    return value;
};

export const nullIfEmpty = (value: string | null) => (value === '' ? null : value);
