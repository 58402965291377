import type { Map } from 'mapbox-gl';

/* 
  Implements the IControl interface that Mapbox GL JS requires

  https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol

  This is essentially a placeholder, it is expected that the Mapbox
  component adding this control handles creating a Portal using
  the _container property of this control
*/
export class CustomMapboxControl {
    public _container: HTMLElement;

    constructor() {
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl';
    }

    onAdd(_map: Map) {
        return this._container;
    }

    onRemove() {
        this._container?.parentNode?.removeChild(this._container);
    }
}
