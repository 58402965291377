const fillColor = '#91bf54';
const strokeColor = '#9ffb25';

const drawStyles = [
    // ACTIVE (being drawn)
    // line stroke
    {
        filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
        id: 'gl-draw-line',
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': strokeColor,
            'line-dasharray': [0.2, 2],
            'line-width': 2
        },
        type: 'line'
    },
    // polygon fill
    {
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        id: 'gl-draw-polygon-fill',
        paint: {
            'fill-color': fillColor,
            'fill-opacity': 0.2,
            'fill-outline-color': fillColor
        },
        type: 'fill'
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        id: 'gl-draw-polygon-stroke-active',
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': strokeColor,
            'line-dasharray': [0.2, 2],
            'line-width': 2
        },
        type: 'line'
    },
    // vertex point halos
    {
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        id: 'gl-draw-polygon-and-line-vertex-halo-active',
        paint: {
            'circle-color': '#FFF',
            'circle-radius': 5
        },
        type: 'circle'
    },
    // vertex points
    {
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        id: 'gl-draw-polygon-and-line-vertex-active',
        paint: {
            'circle-color': strokeColor,
            'circle-radius': 3
        },
        type: 'circle'
    },
    {
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature']],
        id: 'pin-drop',
        paint: {
            'circle-color': strokeColor,
            'circle-radius': 10
        },
        type: 'circle'
    }
];

export default drawStyles;
