const translations = {
    delete: 'Delete',
    droneName: 'Name',
    edit: 'Edit',
    make: 'Make',
    model: 'Model',
    serialNumber: 'Serial Number'
};

export default translations;
