import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Table from 'components/pdf/Table';
import TableColumn from 'components/pdf/TableColumn';
import TableHeader from 'components/pdf/TableHeader';
import TableHeaderText from 'components/pdf/TableHeaderText';
import TableRow from 'components/pdf/TableRow';
import TableText from 'components/pdf/TableText';
import Text from 'components/pdf/Text';

import useConvert from 'hooks/useConvert';
import useRoundFormat from 'hooks/useRoundFormat';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const ProductUsageReportProductDetails: FunctionComponent<Props> = ({ productDetails, title }) => {
    const {
        convertKilogramsPerSquareMeterToPreferredUnits,
        convertKilogramsToPreferredUnits,
        convertLitersPerSquareMeterToPreferredRate,
        convertLitersToGallons,
        convertPreferredRateToLabel,
        convertSquareMetersToAcres,
        massLabels
    } = useConvert();
    const roundFormat = useRoundFormat();
    const { AMOUNT_APPLIED, CARRIER, CHEMICAL, RATE, TOTAL_ACRES } = useTranslation();

    const { carrier, chemicalsApplied, dryChemicalsApplied, totalAreaAppliedSquareMeters } =
        productDetails;

    return (
        <Section>
            <SectionColumn title={title}>
                <Text>
                    {TOTAL_ACRES}

                    {roundFormat(convertSquareMetersToAcres(totalAreaAppliedSquareMeters))}
                </Text>

                <Text>
                    {CARRIER}

                    {carrier}
                </Text>

                <Table>
                    <TableHeader>
                        <TableColumn style={{ flex: 1.5 }} withDivider={false}>
                            <TableHeaderText text={CHEMICAL} />
                        </TableColumn>

                        <TableColumn>
                            <TableHeaderText text={RATE} />
                        </TableColumn>

                        <TableColumn>
                            <TableHeaderText text={AMOUNT_APPLIED} />
                        </TableColumn>
                    </TableHeader>

                    <TableColumn withDivider={false}>
                        {chemicalsApplied.map(
                            ({
                                chemical,
                                preferredRateUnits,
                                preferredVolumeUnits,
                                rateAppliedLitersPerSquareMeter,
                                volumeAppliedLiters
                            }) => (
                                <TableRow key={chemical.id} withDivider={true}>
                                    <TableColumn style={{ flex: 1.5 }} withDivider={false}>
                                        <TableText>{chemical.labelName}</TableText>
                                    </TableColumn>

                                    <TableColumn>
                                        <TableText>
                                            {`${convertLitersPerSquareMeterToPreferredRate(rateAppliedLitersPerSquareMeter, preferredRateUnits)} ${convertPreferredRateToLabel(preferredRateUnits)}`}
                                        </TableText>
                                    </TableColumn>

                                    <TableColumn>
                                        <TableText>
                                            {`${roundFormat(preferredVolumeUnits === 'LITERS' ? volumeAppliedLiters : convertLitersToGallons(volumeAppliedLiters))} ${preferredVolumeUnits === 'LITERS' ? 'L' : 'Ga'}`}
                                        </TableText>
                                    </TableColumn>
                                </TableRow>
                            )
                        )}

                        {dryChemicalsApplied.map(
                            ({
                                chemical,
                                massAppliedKilograms,
                                preferredMassUnits,
                                preferredRateUnits,
                                rateAppliedKilogramPerSquareMeter
                            }) => (
                                <TableRow key={chemical.id} withDivider={true}>
                                    <TableColumn style={{ flex: 1.5 }} withDivider={false}>
                                        <TableText>{chemical.labelName}</TableText>
                                    </TableColumn>

                                    <TableColumn>
                                        <TableText>
                                            {`${convertKilogramsPerSquareMeterToPreferredUnits(rateAppliedKilogramPerSquareMeter, preferredRateUnits)} ${convertPreferredRateToLabel(preferredRateUnits)}`}
                                        </TableText>
                                    </TableColumn>

                                    <TableColumn>
                                        <TableText>
                                            {`${convertKilogramsToPreferredUnits(massAppliedKilograms, preferredMassUnits)} ${massLabels[preferredMassUnits as keyof typeof massLabels]}`}
                                        </TableText>
                                    </TableColumn>
                                </TableRow>
                            )
                        )}
                    </TableColumn>
                </Table>
            </SectionColumn>
        </Section>
    );
};

export default ProductUsageReportProductDetails;
