import ColoredLabel from 'components/colored-labels/ColoredLabel';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DryLabel: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const { DRY } = useTranslation();

    return (
        <ColoredLabel className={`${className} ${styles.dryLabel}`} testId={testId} text={DRY} />
    );
};

export default DryLabel;
