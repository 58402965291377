import { NavLink } from 'react-router-dom';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FooterNavigationLink: FunctionComponent<Props> = ({ className, label, link, onClick }) => (
    <NavLink
        className={({ isActive }) =>
            `${className} ${styles.footerNavigationLink} ${
                isActive ? styles.linkActive : styles.linkInactive
            }`
        }
        end
        onClick={onClick}
        to={link}
    >
        {label}
    </NavLink>
);

export default FooterNavigationLink;
