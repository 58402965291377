import { atom } from 'recoil';

import { Organizations } from './types';

const organizationsAtom = atom<Organizations | null>({
    default: null,
    key: 'organizations'
});

const state = {
    atoms: {
        organizationsAtom
    }
};

export default state;
