import { WORK_ORDER_NOTES_CHAR_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderNotes');

    return {
        ERROR_NOTES_MAX: t('errorNotesMax', { charMax: WORK_ORDER_NOTES_CHAR_MAX }),
        LABEL: t('label')
    };
};

export default useTranslation;
