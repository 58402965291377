const translations = {
    chemical: 'Chemical Information',
    commodity: 'Site Commodity',
    customer: 'Customer Information',
    details: 'Work Order Details',
    grower: 'Grower Information',
    notes: 'Notes',
    site: 'Application Site'
};

export default translations;
