import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightReportDeleteModal');

    return {
        BUTTON_TEXT: t('buttonText'),
        CONFIRM_DELETE: t('confirmDelete'),
        CONFIRM_DELETE_MESSAGE: t('confirmDeleteMessage')
    };
};

export default useTranslation;
