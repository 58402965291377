import { NavArrowDown, Square } from 'iconoir-react';
import { useMemo } from 'react';

import { Accordion, Group } from '@mantine/core';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Legend: FunctionComponent<Props> = ({ legend, testId = TEST_ID }) => {
    const { LEGEND } = useTranslation();

    const { getDisplayLegendItems } = useAsAppliedMaps();

    const displayLegend = useMemo(
        () => getDisplayLegendItems(legend),
        [getDisplayLegendItems, legend]
    );

    return (
        <Accordion
            styles={{
                label: {
                    fontWeight: 400
                }
            }}
            // @ts-expect-error implementation will later be changed
            chevron={<NavArrowDown className={styles.icon} />}
            className={styles.container}
            data-testid={testId}
        >
            <Accordion.Item className={styles.legendDropdownContainer} value="legend">
                <Accordion.Control>
                    <p className={styles.accordionTitle}>{LEGEND}</p>
                </Accordion.Control>

                <Accordion.Panel className={styles.legendDropdownPanel}>
                    {displayLegend.map((item, index) => (
                        <Group key={index}>
                            {
                                // @ts-expect-error implementation will later be changed
                                <Square
                                    style={{
                                        backgroundColor: item.color,
                                        color: item.color
                                    }}
                                />
                            }

                            {item.text}
                        </Group>
                    ))}
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};

export default Legend;
