const translations = {
    contact: 'Contact',
    createProductUsageReport: 'Create Product Usage Report',
    grower: 'Grower',
    phoneNumber: 'Phone Number',
    productUsageReportError: 'Error Fetching List Of Product Usage Reports',
    productUsageReports: 'Product Usage Reports',
    siteName: 'Site Name',
    status: 'Status',
    time: 'Time'
};

export default translations;
