import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceTableFieldRateRow');

    return {
        ACRES: t('acres'),
        FIELD_APPLICATION: t('fieldApplication'),
        HOURS: t('hours'),
        PER_ACRE: t('perAcre'),
        PER_HOUR: t('perHour')
    };
};

export default useTranslation;
