import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('companyContactFields');

    return {
        COMPANY: t('company'),
        CONTACT: t('contact'),
        PHONE_NUMBER: t('phoneNumber')
    };
};

export default useTranslation;
