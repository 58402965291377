const translations = {
    addChemical: 'Add Chemical',
    errors: {
        activeIngredientCharsMax: 'Please shorten the active ingredient name',
        activeIngredientRequired: 'Please specify an active ingredient',
        activeIngredientsMax: 'Too many active ingredients',
        labelNameRequired: 'Please provide a label name',
        rateMax: 'Rate cannot exceed {{rateMax}} {{unit}}'
    }
};

export default translations;
