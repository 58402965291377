import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceTableHeader');

    return {
        DESCRIPTION: t('description'),
        ITEM: t('item'),
        TOTAL: t('total')
    };
};

export default useTranslation;
