import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('johnDeereExportModal');

    return {
        DESCRIPTION: t('description'),
        DISCONNECT_FROM_JOHN_DEERE: t('disconnectFromJohnDeere'),
        EXPORT_ERROR: t('exportError'),
        FIELD_ERROR: t('fieldError'),
        MAP_TITLE: (date: string) =>
            t('mapTitle', {
                date: date
            }),
        ORGANIZATION_ERROR: t('organizationError'),
        SELECT_FIELD: t('selectField'),
        TITLE: t('title'),
        UPLOAD: t('upload')
    };
};

export default useTranslation;
