import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { CHEMICAL_PRODUCT_LOOKUP_API } from './constants';

const useChemical = () => {
    const { authenticatedGet } = useFetch();
    const { addQuery } = useQuerystring({});

    const fetchChemicalProduct = useCallback(
        async (id: string) => {
            const { data, error } = await authenticatedGet(`${CHEMICAL_PRODUCT_LOOKUP_API}/${id}`);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedGet]
    );

    const fetchChemicalProducts = useCallback(
        async (labelName: string, signal?: unknown) => {
            const query = addQuery({
                labelName: labelName,
                size: '25'
            });

            const uri = `${CHEMICAL_PRODUCT_LOOKUP_API}?${query}`;
            const { data, error } = await authenticatedGet(uri, { signal });

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data.objects;
        },
        [authenticatedGet, addQuery]
    );

    return {
        fetchChemicalProduct,
        fetchChemicalProducts
    };
};

export default useChemical;
