import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('useAvatarInitials');

    return {
        NOT_APPLICABLE: t('notApplicable')
    };
};

export default useTranslation;
