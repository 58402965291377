import { ArrowRightIcon } from '@rantizo-software/rantizo-ui';

import IconButton from 'components/IconButton';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ArrowRightIconButton: FunctionComponent<Props> = ({
    className,
    isDisabled,
    onClick,
    testId = TEST_ID
}) => (
    <IconButton
        className={`${className} ${styles.arrowRightIconButton}`}
        isDisabled={isDisabled}
        onClick={onClick}
        testId={testId}
    >
        <ArrowRightIcon className={styles.arrowRightIcon} />
    </IconButton>
);

export default ArrowRightIconButton;
