import { NavLink } from 'react-router-dom';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const NavigationLink: FunctionComponent<Props> = ({
    className = '',
    isExactMatch = false,
    onClick,
    testId = TEST_ID,
    text = '',
    to
}) => (
    <NavLink
        className={({ isActive }) =>
            `${className} ${styles.navigationLink} ${isActive ? styles.linkActive : ''}`
        }
        data-testid={testId}
        end={isExactMatch}
        onClick={onClick}
        to={to}
    >
        {text}
    </NavLink>
);

export default NavigationLink;
