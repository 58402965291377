import { type FunctionComponent, useCallback, useEffect, useState } from 'react';

import { userSortFirstName } from '@@utils/sort';
import { Loader } from '@mantine/core';

import TabContainer from 'components/TabContainer';
import TeamDetailsTabs from 'components/TeamDetailsTabs';
import TeamMemberList from 'components/TeamMemberList';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { INITIAL_TAB } from './constants';
import { TeamDetailsTab, UserApiResponse } from './types';

import styles from './styles.module.scss';

const TeamDetails: FunctionComponent = () => {
    const { TITLE } = useTranslation();

    const fetchTeamMembers = useData();

    const [, setTab] = useState<TeamDetailsTab>(INITIAL_TAB);
    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState<UserApiResponse>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });

    const fetchUsers = useCallback(
        async (hasMore: boolean, nextToken: string) => {
            let response: UserApiResponse;

            if (hasMore && nextToken) {
                response = await fetchTeamMembers(nextToken);
            } else {
                response = await fetchTeamMembers();
            }

            const { hasNext, nextPageToken, objects: newMembers } = response;

            setUsers(u => {
                const newUsers = [...u.objects, ...newMembers];

                newUsers.sort(userSortFirstName);

                return { hasNext, nextPageToken, objects: newUsers };
            });
        },
        [fetchTeamMembers]
    );

    const initialUserFetch = async () => {
        await fetchUsers(false, '');
    };

    const handleNext = useCallback(
        async () => await fetchUsers(users.hasNext, users.nextPageToken),
        [fetchUsers, users.hasNext, users.nextPageToken]
    );

    const handleTabChange = useCallback((value: TeamDetailsTab) => {
        setTab(value);
    }, []);

    useEffect(() => {
        if (loading) {
            initialUserFetch();
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const teamMembers = users.objects;

    return (
        <TabContainer title={TITLE}>
            <TeamDetailsTabs onChange={handleTabChange} />

            {teamMembers.length === 0 && <Loader className={styles.loader} />}

            {teamMembers.length > 0 && (
                <TeamMemberList hasMore={users.hasNext} items={teamMembers} onNext={handleNext} />
            )}
        </TabContainer>
    );
};

export default TeamDetails;
