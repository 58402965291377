import { useCallback, useEffect, useMemo, useState } from 'react';

import BackNextButtons from 'components/BackNextButtons';
import DroneReport from 'components/DroneReport';
import FormSectionContainer from 'components/FormSectionContainer';
import LargeSemiboldText from 'components/LargeSemiboldText';
import PageLoader from 'components/PageLoader';
import SideBarContentContainer from 'components/SideBarContentContainer';

import useDroneReport from 'hooks/useDroneReport';
import useForm from 'hooks/useForm';
import useUsers from 'hooks/useUsers';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

type DroneReportsToDroneMap = { droneId: string; droneReportId?: string };

const FlightDetails: FunctionComponent<Props> = ({
    droneIds,
    faaReportId,
    isEditable,
    isSaveDisabled,
    onBack,
    onSave,
    testId = TEST_ID
}) => {
    const { FLIGHT_DETAILS, SAVE_REPORT } = useTranslation();

    const [organizationMembers, setOrganizationMembers] = useState<string[]>([]);
    const [droneReportMaps, setDroneReportMaps] = useState<DroneReportsToDroneMap[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const formSchema = {};
    const form = useForm(formSchema);

    const { fetchDroneReports } = useDroneReport();
    const { fetchTeamMembers } = useUsers();

    const fetchDroneReportsData = useCallback(
        async (hasNext: boolean, nextToken: string) => {
            try {
                setIsLoading(true);
                const response = await fetchDroneReports(
                    faaReportId,
                    { droneIds },
                    hasNext ? nextToken : undefined
                );

                const droneReportMap = droneIds.map(droneId => ({
                    droneId,
                    droneReportId: response.objects.find(
                        ({ droneId: droneReportDroneId }) => droneReportDroneId === droneId
                    )?.id
                }));

                setDroneReportMaps(droneReportMap);
            } catch (error) {
                console.error(error);
                alert(JSON.stringify(error));
            } finally {
                setIsLoading(false);
            }
        },

        [droneIds, faaReportId, fetchDroneReports]
    );

    const fetchOrganizationMembers = useCallback(async () => {
        setIsLoading(true);
        let hasNext = true;
        let pageToken = undefined;

        const orgMembers: string[] = [];

        while (hasNext) {
            const { data, error } = await fetchTeamMembers(pageToken, 100);

            if (data) {
                const { hasNext: newHasNext, nextPageToken, objects } = data;

                orgMembers.push(
                    ...objects.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
                );

                hasNext = newHasNext;
                pageToken = nextPageToken;
            }
            if (error) {
                setIsLoading(false);
                console.error(error);
                hasNext = false;
                alert(JSON.stringify(error));
            }
        }
        setOrganizationMembers(orgMembers);
        setIsLoading(false);
    }, [fetchTeamMembers]);

    const droneReportItems = useMemo(
        () =>
            droneReportMaps.map(({ droneId, droneReportId }) => (
                <DroneReport
                    droneId={droneId}
                    droneReportId={droneReportId || ''}
                    faaReportId={faaReportId}
                    isEditable={isEditable}
                    key={droneReportId}
                    orgMembers={organizationMembers}
                />
            )),
        [droneReportMaps, faaReportId, isEditable, organizationMembers]
    );

    useEffect(() => {
        if (droneReportMaps.length === 0) {
            fetchDroneReportsData(false, '');
            fetchOrganizationMembers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBack = useCallback(async () => {
        onBack?.({ form });
    }, [form, onBack]);

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer className={styles.formContainer}>
                <LargeSemiboldText text={FLIGHT_DETAILS} />

                {isLoading ? <PageLoader /> : droneReportItems}

                <BackNextButtons
                    isNextDisabled={isSaveDisabled}
                    isNextLoading={false}
                    nextText={SAVE_REPORT}
                    onBack={handleBack}
                    onNext={isEditable ? onSave : undefined}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default FlightDetails;
