import { useId } from 'react';

import LargeText from 'components/LargeText';
import Input from 'components/form/Input';

import { AUTOCOMPLETE, DATE_SEPARATOR, TEST_ID } from './constants';
import { Props } from './types';
import useTranslation from './useTranslation';

import styles from './styles.module.scss';

const DateRangePickerInputs = ({
    endValue,
    onBlur,
    onChange,
    onEndClick,
    onStartClick,
    refs,
    startValue,
    testId = TEST_ID
}: Props) => {
    const { endInputRef, startInputRef } = refs;

    const startInputId = useId();
    const endInputId = useId();
    const { END_DATE_PLACEHOLDER, START_DATE_PLACEHOLDER } = useTranslation();

    return (
        <div className={styles.inputsRow} data-testid={testId}>
            <Input
                autocomplete={AUTOCOMPLETE}
                className={styles.dateInput}
                id={startInputId}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onStartClick}
                placeholder={START_DATE_PLACEHOLDER}
                ref={startInputRef}
                testId={`${TEST_ID}-start`}
                value={startValue}
            />

            <LargeText className={styles.seperator} text={DATE_SEPARATOR} />

            <Input
                autocomplete={AUTOCOMPLETE}
                className={styles.dateInput}
                id={endInputId}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onEndClick}
                placeholder={END_DATE_PLACEHOLDER}
                ref={endInputRef}
                testId={`${TEST_ID}-end`}
                value={endValue}
            />
        </div>
    );
};

export default DateRangePickerInputs;
