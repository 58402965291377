import { useState } from 'react';

import { CloseButton } from '@mantine/core';

import BodyText from 'components/BodyText';
import HorizontalContainer from 'components/HorizontalContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const BaseNotification: FunctionComponent<Props> = ({
    className = '',
    icon,
    testId = TEST_ID,
    text
}) => {
    const [isClosed, setIsClosed] = useState(false);

    return (
        <HorizontalContainer
            className={`${className} ${isClosed ? styles.closed : styles.baseNotificationContainer}`}
            testId={testId}
        >
            <HorizontalContainer className={styles.iconTextContainer}>
                <div className={styles.iconContainer}>{icon}</div>

                <BodyText className={styles.notificationText} text={text} />
            </HorizontalContainer>

            <CloseButton
                onClick={() => {
                    setIsClosed(true);
                }}
                className={styles.closeButton}
            />
        </HorizontalContainer>
    );
};

export default BaseNotification;
