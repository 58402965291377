import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfWorkOrderApplicationSiteBoundary');

    return {
        APPLICATION_SITE: (number: number) => t('applicationSite', { number })
    };
};

export default useTranslation;
