import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import unitedStatesTimeZones, { timeZoneLabelToZoneMap } from '@@types/timezones';

const useCurrentTime = () => {
    const getCurrentTimeZone = useMemo(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // eslint-disable-next-line
        if (unitedStatesTimeZones.hasOwnProperty(timeZone)) {
            return timeZoneLabelToZoneMap[unitedStatesTimeZones[timeZone]];
        } else {
            return timeZoneLabelToZoneMap.PT;
        }
    }, []);

    const getCurrentTime = useCallback(() => {
        const now = new Date();

        return format(now, 'HH:mm') + ':00';
    }, []);

    return { getCurrentTime, getCurrentTimeZone };
};

export default useCurrentTime;
