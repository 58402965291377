import { useRef } from 'react';

import useRecoil from 'hooks/useRecoil';

import { UseGlobalStoreHook } from './types';

const useGlobalStore: UseGlobalStoreHook = ({ namespace }) => {
    const storeMap = useRef({
        recoil: useRecoil
    }).current;

    return storeMap.recoil({ namespace });
};

export default useGlobalStore;
