const translations = {
    addApplicationSite: 'Add Application Site',
    errors: {
        address1Max: 'Address cannot exceed {{charMax}} characters',
        address1Required: 'Address is required',
        address2Max: 'Address Line 2 cannot exceed {{charMax}} characters',
        boundaryInvalid: 'Application site has a boundary with an invalid shape',
        boundaryMaxArea: 'Site boundaries cannot exceed {{boundaryAcreMax}} acres',
        boundaryMaxPolygons:
            'Application Site cannot have more than {{boundaryMaxPolygons}} polygons',
        boundarySpread:
            'Site boundaries must cover an area less than {{boundarySpreadAcres}} acres',
        cityMax: 'City cannot exceed {{charMax}} characters',
        cityRequired: 'City is required',
        latitudeInvalid: 'Latitude is invalid',
        latitudeRequired: 'Please provide a latitude',
        locationInvalid: 'The given address cannot be verified',
        longitudeInvalid: 'Longitude is invalid',
        longitudeRequired: 'Please provide a longitude',
        maxBoundaryDistance: 'The boundary must be within {{maxMiles}} miles of the address',
        maxCoordinateDistance: 'The coordinates must be within {{maxMiles}} miles of the address',
        maxOneBoundary: 'You can have a maximum of one boundary or a point',
        onlyOneLocationMarker: 'There can only be one location marker or multiple polygons.',
        siteNameMax: 'Site name cannod exceed {{charMax}} characters',
        siteNameRequired: 'Please enter a site name',
        sitesMax: 'Cannot have more than {{siteMax}} application sites',
        stateRequired: 'State is required',
        zipCodeRequired: 'Zip is required'
    }
};

export default translations;
