import { atom, useRecoilState } from 'recoil';

import type { NewOrganizationForm } from './types';

export const NewOrganizationFormAtom = atom<NewOrganizationForm>({
    default: {
        companyName: '',
        location: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: ''
        },
        phone: ''
    },
    key: 'NewOrganizationFormAtom'
});

export const useNewOrganizationForm = () => useRecoilState(NewOrganizationFormAtom);
