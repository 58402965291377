import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('djiFileUploadTab');

    return {
        AREA_UNIT_TYPE: t('areaUnitType'),
        ASSOCIATED_APPLICATION_DATE: t('associatedApplicationDate'),
        BACK: t('back'),
        DISTANCE_UNIT_TYPE: t('distanceUnitType'),
        DRY_SPREADER_APPLICATION: t('drySpreaderApplication'),
        ERROR_IMPORTING_KML_FILE: t('errorImportingKmlFile'),
        FILE_TYPE: t('fileType'),
        FILE_UPLOADS: t('fileUploads'),
        KML: t('kml'),
        KML_FILE_MUST_EXIST: t('kmlFileMustExist'),
        SAVE_TO_FLIGHT_LOG: t('saveToFlightLog'),
        SELECT_UNIT: t('selectUnit'),
        START_DATE_EMPTY_ERROR: t('startDateEmptyError'),
        UNIT_EXPLANATION: t('unitExplanation')
    };
};

export default useTranslation;
