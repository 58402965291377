'use client';

import usePilots from 'hooks/usePilots';

import { FunctionComponent, Props } from './types';

const PilotsProvider: FunctionComponent<Props> = ({ children, value }) => {
    const { context } = usePilots();

    return <context.Provider value={value}>{children}</context.Provider>;
};

PilotsProvider.displayName = 'PilotsProvider';

export default PilotsProvider;
