import { forwardRef } from 'react';

import Input from 'components/form/Input';

import { TEST_ID } from './constants';
import { Props } from './types';

import styles from './styles.module.scss';

const NumberInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        hasError = false,
        isDisabled = false,
        isEditable = true,
        isRequired = false,
        name = '',
        onChange,
        onKeyUp,
        onSubmit,
        pattern = '',
        placeholder = '',
        testId = TEST_ID,
        value
    } = props;

    return (
        <Input
            className={`${className} ${styles.numberInput}`}
            hasError={hasError}
            isDisabled={isDisabled}
            isEditable={isEditable}
            isRequired={isRequired}
            name={name}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onSubmit={onSubmit}
            pattern={pattern}
            placeholder={placeholder}
            ref={ref}
            testId={testId}
            type="number"
            value={value}
        />
    );
});

NumberInput.displayName = 'NumberInput';

export default NumberInput;
