import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productDetails');

    return {
        AC: t('ac'),
        CARRIER: t('carrier'),
        TOTAL_ACRES_APPLIED: t('totalAcresApplied'),
        TOTAL_PRODUCT_USED: t('totalProductUsed')
    };
};

export default useTranslation;
