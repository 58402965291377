import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('applicationDetails');

    return {
        AIR_TEMPERATURE: t('airTemperature'),
        APPLICATION_DATE: t('applicationDate'),
        APPLICATION_SITE: t('applicationSite'),
        APPLICATOR_NAME: t('applicatorName'),
        DRONE_SERIAL_NUMBER: t('droneSerialNumber'),
        END_TIME: t('endTime'),
        MPH: t('mph'),
        START_TIME: t('startTime'),
        WEATHER: t('weather'),
        WIND_DIRECTION: t('windDirection'),
        WIND_GUST_SPEED: t('windGustSpeed'),
        WIND_SPEED: t('windSpeed'),
        WORK_ORDER_NUMBER: t('workOrderNumber')
    };
};

export default useTranslation;
