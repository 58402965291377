import { useCallback, useState } from 'react';

import { useNewOrganizationForm } from '@@state/Organizations';

import CompanyDetailsForm from 'components/CompanyDetailsForm';

import useCurrentOrganization from 'hooks/useCurrentOrganization';

import useCreateOrganization from './hooks/useCreateOrganization';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, OrganizationRequest, Props } from './types';

const NewOrganizationPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { setIsNewOrganization } = useCurrentOrganization();
    const { BUTTON, DESCRIPTION, TITLE } = useTranslation();
    const createOrganization = useCreateOrganization();
    const [formValues, setFormValues] = useNewOrganizationForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
        async (values: OrganizationRequest) => await createOrganization(values),
        [createOrganization]
    );

    const onSubmit = useCallback(
        async (values: OrganizationRequest) => {
            setLoading(true);
            setFormValues(values);
            setIsNewOrganization(true);
            await handleSubmit(values);
            setIsNewOrganization(true);
            setLoading(false);
        },
        [handleSubmit, setFormValues, setIsNewOrganization]
    );

    return (
        <CompanyDetailsForm
            buttonText={BUTTON}
            description={DESCRIPTION}
            initialValues={formValues}
            isLoading={loading}
            isOnboarding={true}
            onSubmit={onSubmit}
            testId={testId}
            title={TITLE}
        />
    );
};

export default NewOrganizationPage;
