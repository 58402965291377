import type { Style } from './types';

const styles: Style = {
    alignSelf: 'center',
    flex: 1,
    fontFamily: 'Inter',
    fontSize: '11pt'
};

export default styles;
