import { TEST_ID } from './constants';

import styles from './styles.module.scss';

const ModalTitle = ({ className = '', testId = TEST_ID, text = '' }) => (
    <h3 className={`${className} ${styles.modalTitle}`} data-testid={testId}>
        {text}
    </h3>
);

export default ModalTitle;
