const translations = {
    activationLinkSent:
        'An account activation link has been sent to the email address you provided. This link will expire in 1 hour.',
    apiError: 'Something went wrong. Please try again.',
    emailResent: 'Successfully resent the verification email, please check your inbox',
    noLink: 'Didn’t get the link?',
    sendAgain: 'Send again',
    title: 'Verify your Email'
};

export default translations;
