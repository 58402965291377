import { FlightQueryFlightModification } from 'generatedTypes/aam/models';
import { useCallback } from 'react';

import useFlights from 'hooks/useFlights';

const useData = () => {
    const { fetchFlight } = useFlights();

    const fetch = useCallback(
        async (id: string, modification?: FlightQueryFlightModification) => {
            const flight = await fetchFlight(id, modification);

            return flight;
        },
        [fetchFlight]
    );

    return {
        fetch
    };
};

export default useData;
