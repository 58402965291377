import { useCallback } from 'react';

import BodyText from 'components/BodyText';
import Chip from 'components/Chip';
import NegativeChip from 'components/NegativeChip';

import useTranslation from './hooks/useTranslation';

import { BULK_ACTIONS_CHIP_TEXT, BULK_SELECT_CHIP_TEXT, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ListFooter: FunctionComponent<Props> = ({
    children,
    isEnabled = true,
    onDelete,
    onError,
    onSelectToggle,
    selectedItems,
    testId = TEST_ID,
    totalItemCount
}) => {
    const { ALL, DELETE_ERROR, NONE, NUMBER_SELECTED } = useTranslation();

    const handleDelete = useCallback(async () => {
        try {
            for (const id of selectedItems) {
                await onDelete({ id });
            }
        } catch (e) {
            console.log(e);
            onError({ message: DELETE_ERROR });
        }
    }, [DELETE_ERROR, onDelete, onError, selectedItems]);

    const selectedItemsCount = selectedItems.length;

    return (
        <div className={styles.listFooter} data-testid={testId}>
            <Chip
                isDisabled={!totalItemCount || !isEnabled}
                onClick={onSelectToggle}
                testId={BULK_SELECT_CHIP_TEXT}
                text={selectedItems.length === 0 ? ALL : NONE}
            />

            {children}

            <BodyText
                className={styles.bodyText}
                text={NUMBER_SELECTED(selectedItemsCount, totalItemCount)}
            />

            <NegativeChip
                className={styles.negativeChip}
                isEnabled={selectedItems.length > 0}
                onClick={handleDelete}
                testId={BULK_ACTIONS_CHIP_TEXT}
                text="Delete Selected"
            />
        </div>
    );
};

export default ListFooter;
