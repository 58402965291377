import LabelPrimaryText from 'components/LabelPrimaryText';
import FormLabelContainer from 'components/form/FormLabelContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const FormLabel: FunctionComponent<Props> = ({
    children,
    className = '',
    label,
    testId = TEST_ID
}) => (
    <FormLabelContainer className={className} testId={testId}>
        <LabelPrimaryText text={label} />

        {children}
    </FormLabelContainer>
);

export default FormLabel;
