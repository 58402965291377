import { useCallback, useEffect, useState } from 'react';

import DroneReportContainer from 'components/DroneReportContainer';
import FlightReportList from 'components/FlightReportList';
import FlightReportsProvider from 'components/FlightReportsProvider';
import PilotsProvider from 'components/PilotsProvider';

import useData from './hooks/useData';
import useDroneReports from './hooks/useDroneReports';

import { DEFAULT_FLIGHT_REPORT_RESPONSE, TEST_ID } from './constants';
import type {
    DroneReportResponse,
    FlightReportApiResponse,
    FlightReportResponse,
    FunctionComponent,
    Props
} from './types';

const DroneReport: FunctionComponent<Props> = ({
    droneId,
    droneReportId,
    faaReportId,
    isEditable,
    orgMembers,
    testId = TEST_ID
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [flightReports, setFlightReports] = useState<FlightReportResponse[]>([]);

    const [flightReportsData, setFlightReportsData] = useState<FlightReportApiResponse>(
        DEFAULT_FLIGHT_REPORT_RESPONSE
    );

    const { fetch } = useData({
        droneReportId,
        faaReportId
    });

    const { droneReport, nickname, pilots } = useDroneReports({
        droneId,
        droneReportId,
        faaReportId,
        orgMembers
    });

    const fetchFlightReports = useCallback(
        async (hasNext: boolean, nextToken: string) => {
            try {
                const response = await fetch(hasNext, nextToken);

                if (!response) {
                    return;
                }

                const { hasNext: newHasNext, nextPageToken, objects: newFlightReports } = response;

                setFlightReportsData((currentState: FlightReportApiResponse) => {
                    const newReports = [...currentState.objects, ...newFlightReports];

                    return { hasNext: newHasNext, nextPageToken, objects: newReports };
                });

                setFlightReports(previousState => [...previousState, ...newFlightReports]);
            } catch (error) {
                console.log(error);
                alert(error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [faaReportId, droneReportId]
    );

    useEffect(() => {
        if (flightReports.length === 0) {
            fetchFlightReports(false, '');
        }
    }, [fetchFlightReports, flightReports.length]);

    const { hasNext, nextPageToken } = flightReportsData;

    const handleDelete = useCallback(({ ids = [] }: { ids: string[] }) => {
        setFlightReports(previousValue =>
            previousValue.reduce((flightReports, flightReport) => {
                const foundIndex = ids.indexOf(flightReport.id);

                if (foundIndex === -1) {
                    flightReports.push(flightReport);
                }

                return flightReports;
            }, [] as FlightReportResponse[])
        );
    }, []);

    const handleUpdate = useCallback(
        ({ changes = {}, ids = [] }: { ids: string[]; changes: object }) => {
            setFlightReports(previousValue =>
                previousValue.reduce((flightReports, flightReport) => {
                    const foundIndex = ids.indexOf(flightReport.id);

                    if (foundIndex !== -1) {
                        flightReports.push({
                            ...flightReport,
                            ...changes
                        });
                    } else {
                        flightReports.push(flightReport);
                    }

                    return flightReports;
                }, [] as FlightReportResponse[])
            );
        },
        []
    );

    const handleNextPage = useCallback(async () => {
        await fetchFlightReports(hasNext, nextPageToken);
    }, [fetchFlightReports, hasNext, nextPageToken]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <DroneReportContainer
            droneReport={{
                ...(droneReport as DroneReportResponse),
                nickname: nickname
            }}
            expandDisabled={!droneReportId}
            isOpen={isOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            pilots={pilots}
            testId={testId}
        >
            <PilotsProvider value={pilots as string[]}>
                <FlightReportsProvider value={flightReports}>
                    {droneReportId && (
                        <FlightReportList
                            droneReportId={droneReportId}
                            faaReportId={faaReportId}
                            hasMore={hasNext}
                            isEditable={isEditable}
                            nextToken={nextPageToken}
                            onDelete={handleDelete}
                            onNextPage={handleNextPage}
                            onUpdate={handleUpdate}
                        />
                    )}
                </FlightReportsProvider>
            </PilotsProvider>
        </DroneReportContainer>
    );
};

export default DroneReport;
