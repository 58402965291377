import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import BaseEquipmentCard from 'components/BaseEquipmentCard';

import usePageRoutes from 'hooks/usePageRoutes';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ControllerCard: FunctionComponent<Props> = ({
    className = '',
    controller,
    deleteController,
    testId = TEST_ID
}) => {
    const navigate = useNavigate();
    const { editControllerPage } = usePageRoutes();

    const { id } = controller;

    const onEdit = useCallback(() => {
        navigate(editControllerPage(id));
    }, [editControllerPage, id, navigate]);

    return (
        <BaseEquipmentCard
            className={className}
            equipment={controller}
            onDelete={deleteController}
            onEdit={onEdit}
            testId={testId}
        />
    );
};

export default ControllerCard;
