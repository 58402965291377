import implement from '@@utils/implement';

import ConnectionListItem from 'components/ConnectionListItem';
import type { ConnectionListItemType } from 'components/ConnectionListItem/types';
import ListPageLayout from 'components/ListPageLayout';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';
import PageContainer from 'components/PageContainer';
import SectionTitle from 'components/SectionTitle';
import JohnDeereOpsCenterIcon from 'components/icons/JohnDeereOpsCenterIcon';

import styles from './styles.module.scss';

const ManageConnectionsPage = () => {
    const connectionListItems: ConnectionListItemType[] = [
        {
            description:
                'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit Sed Do Eiusmod Tempor',
            icon: <JohnDeereOpsCenterIcon />,
            id: 'johnDeereOpsCenter',
            name: 'John Deere Operations Center',
            onClick: ({ id }) => implement(`updateConnection ${id}`)
        }
    ];

    return (
        <PageContainer>
            <ListPageLayout>
                <ListPageLayoutHeader pageTitle="Manage Connections" />

                <SectionTitle className={styles.sectionTitle} text="Available Connections" />

                {connectionListItems.map(({ description, icon, id, name, onClick }) => (
                    <ConnectionListItem
                        description={description}
                        icon={icon}
                        id={id}
                        key={id}
                        name={name}
                        onClick={onClick}
                    />
                ))}
            </ListPageLayout>
        </PageContainer>
    );
};

export default ManageConnectionsPage;
