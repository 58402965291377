import PageTitle from 'components/PageTitle';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SideBarContentHeader: FunctionComponent<Props> = ({ children, testId = TEST_ID, text }) => (
    <div className={styles.sideBarContentHeader} data-testid={testId}>
        <PageTitle className={styles.pageTitle} text={text} />

        {children}
    </div>
);

export default SideBarContentHeader;
