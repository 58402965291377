import { getIn } from 'formik';
import { type FunctionComponent } from 'react';

import { rem } from '@mantine/core';
import { TimeInput } from '@mantine/dates';

import type { Props } from './types';

import styles from './styles.module.scss';

const FormTime: FunctionComponent<Props> = ({
    className,
    disabled,
    field,
    form,
    isEditable = true,
    label,
    placeholder,
    rightSection
}) => {
    const { name } = field;

    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const placeholderText = isEditable ? placeholder : '';

    return (
        <TimeInput
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : '0'
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            className={`${className} ${styles.formTime} ${viewOnlyClass}`}
            disabled={!isEditable || disabled}
            error={visibleError ? error : undefined}
            form={form}
            label={label}
            placeholder={placeholderText}
            rightSection={rightSection}
            rightSectionWidth={rightSection ? rem('70px') : undefined}
            withSeconds={false}
            {...field}
        />
    );
};

export default FormTime;
