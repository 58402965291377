import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfWorkOrderCustomerGrower');

    return {
        ADDRESS: t('address'),
        COMPANY: t('company'),
        CONTACT: t('contact'),
        CUSTOMER_INFORMATION: t('customerInformation'),
        GROWER_INFORMATION: t('growerInformation'),
        PHONE: t('phone')
    };
};

export default useTranslation;
