import { useCallback, useMemo } from 'react';

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import ItemCard from 'components/ItemCard';
import SectionTitle from 'components/SectionTitle';
import SplitContainer from 'components/SplitContainer';
import VerticalContainer from 'components/VerticalContainer';

import useCurrentOrganization from 'hooks/useCurrentOrganization';

import useTranslation from './hooks/useTranslation';

import { LEARN_MORE_URL, TEST_ID, UPGRADE_URL } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OrganizationSubscriptionDetails: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const {
        ESSENTIALS_SUITE,
        FREE_ACCOUNT,
        LEARN_BUTTON,
        PREMIER_SUITE,
        SUBSCRIPTION,
        TRIAL,
        UPGRADE_BUTTON
    } = useTranslation();

    const { currentOrganization } = useCurrentOrganization();

    const subscription = currentOrganization?.subscription ?? 'FREE';

    const subscriptionTextMap: { [key: string]: string } = useMemo(
        () => ({
            ESSENTIALS: ESSENTIALS_SUITE,
            FREE: FREE_ACCOUNT,
            PREMIER: PREMIER_SUITE,
            TRIAL: TRIAL
        }),
        [TRIAL, FREE_ACCOUNT, ESSENTIALS_SUITE, PREMIER_SUITE]
    );

    const openLink = useCallback((link: string) => {
        window.open(link, '_blank');
    }, []);

    return (
        <VerticalContainer
            className={styles.organizationSubscriptionDetailsContainer}
            testId={testId}
        >
            <SectionTitle text={SUBSCRIPTION} />

            <ItemCard>{subscriptionTextMap[subscription] ?? ''}</ItemCard>

            <SplitContainer>
                <PrimaryButton onClick={() => openLink(UPGRADE_URL)} text={UPGRADE_BUTTON} />

                <PrimaryButton onClick={() => openLink(LEARN_MORE_URL)} text={LEARN_BUTTON} />
            </SplitContainer>
        </VerticalContainer>
    );
};

export default OrganizationSubscriptionDetails;
