import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { CONTROLLER_MODELS_API } from './constants';
import type { ControllerModelsApiResponse } from './types';

const useData = () => {
    const { authenticatedGet } = useFetch();
    const { addQuery } = useQuerystring({});

    const fetchModels = async (controllerMake?: string) => {
        let queryString = '';

        if (controllerMake) {
            const query = addQuery({ controllerMake });

            queryString = `?${query.toString()}`;
        }

        const uri = `${CONTROLLER_MODELS_API}${queryString}`;
        const response = await authenticatedGet(uri, null);

        const { data, error } = response;

        if (error) {
            throw new Error(JSON.stringify(error));
        }

        return data as ControllerModelsApiResponse;
    };

    return {
        fetchModels
    };
};

export default useData;
