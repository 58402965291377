import { EQUIPMENT_CHAR_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('controllerForm');

    return {
        BUTTON: t('button'),
        ERRORS: {
            MAKE_REQUIRED: t('errors.makeRequired'),
            MODEL_REQUIRED: t('errors.modelRequired'),
            NICKNAME_MAX: t('errors.nicknameMax', { charMax: EQUIPMENT_CHAR_MAX }),
            NICKNAME_REQUIRED: t('errors.nicknameRequired'),
            SERIAL_NUMBER_MAX: t('errors.serialNumberMax', { charMax: EQUIPMENT_CHAR_MAX }),
            SERIAL_NUMBER_REQUIRED: t('errors.serialNumberRequired')
        },
        PLACEHOLDERS: {
            MAKE: t('placeholders.make'),
            MODEL: t('placeholders.model'),
            NICKNAME: t('placeholders.nickname'),
            SERIAL_NUMBER: t('placeholders.serialNumber')
        }
    };
};

export default useTranslation;
