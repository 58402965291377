import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('applicationDetailsForm');

    return {
        APPLICATION_DATE: t('applicationDate'),
        APPLICATION_SITE: t('applicationSite'),
        APPLICATOR: t('applicator'),
        DATE_PLACEHOLDER: t('datePlaceholder'),
        DRONE_SERIAL_NUMBER: t('droneSerialNumber'),
        END_TIME: t('endTime'),
        ERRORS: {
            APPLICATION_DATE_REQUIRED: t('errors.applicationDateRequired'),
            END_TIME_GREATER_THAN_START_TIME: t('errors.endTimeGreaterThanStartTime'),
            END_TIME_REQUIRED: t('errors.endTimeRequired'),
            START_TIME_REQUIRED: t('errors.startTimeRequired'),
            TEMPERATURE_REQUIRED: t('errors.temperatureRequired'),
            WIND_DIRECTION_REQUIRED: t('errors.windDirectionRequired'),
            WIND_GUST_REQUIRED: t('errors.windGustRequired'),
            WIND_SPEED_NOT_NEGATIVE: t('errors.windSpeedNotNegative'),
            WIND_SPEED_REQUIRED: t('errors.windSpeedRequired')
        },
        MPH: t('mph'),
        NO_MEASURABLE_WIND: t('noMeasurableWind'),
        START_TIME: t('startTime'),
        TEMPERATURE: t('temperature'),
        TEMPERATURE_UNIT: t('temperatureUnit'),
        WEATHER: t('weather'),
        WIND_DIRECTION: t('windDirection'),
        WIND_GUST: t('windGust'),
        WIND_SPEED: t('windSpeed'),
        WORK_ORDER_NUMBER: t('workOrderNumber')
    };
};

export default useTranslation;
