import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('alert');

    return {
        SKIP_FOR_NOW: t('skipForNow'),
        TAKE_ME_THERE: t('takeMeThere')
    };
};

export default useTranslation;
