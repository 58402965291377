import rantizoLogo from '@@assets/Rantizo_Logo_PDF.png';
import { Image, View } from '@react-pdf/renderer';

import type { FunctionComponent } from './types';

import styles from './styles';

const Logo: FunctionComponent = () => (
    <View style={styles}>
        <Image src={rantizoLogo} />
    </View>
);

export default Logo;
