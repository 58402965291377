const translations = {
    apiError: 'Something went wrong. Please try again.',
    button: 'Save',
    errors: {
        emailInvalid: 'Email is invalid',
        emailRequired: 'Please provide an email',
        firstNameInvalid: 'First name contains invalid characters',
        firstNameMax: 'First name cannot exceed {{nameMax}} characters',
        firstNameRequired: 'Please provide a first name',
        lastNameInvalid: 'Last name contains invalid characters',
        lastNameMax: 'Last name cannot exceed {{nameMax}} characters',
        lastNameRequired: 'Please provide a last name',
        phoneInvalid: 'Phone number is invalid',
        phoneRequired: 'Please provide a phone number'
    },
    placeholders: {
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone Number'
    },
    title: 'Person of Contact'
};

export default translations;
