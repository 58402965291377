import { useCallback } from 'react';

import useDate from 'hooks/useDate';
import useFlights from 'hooks/useFlights';
import { FlightSummary } from 'hooks/useFlights/types';

import type { DateFormatter, FlightSummariesGroupedByDate, FlightSummaryQuery } from './types';

const groupByDate = (flights: FlightSummary[], dateFormatter: DateFormatter) =>
    flights.reduce((agg, item) => {
        const { droneSerialNumber, endTime, flightSource, flightType, id, nickname, startTime } =
            item;

        const formattedStartTime = dateFormatter(startTime);

        if (!(formattedStartTime in agg)) {
            agg[formattedStartTime] = [];
        }

        agg[formattedStartTime].push({
            droneSerialNumber,
            endTime,
            flightSource,
            flightType,
            id,
            nickname,
            startTime
        });

        return agg;
    }, {} as FlightSummariesGroupedByDate);

const useData = () => {
    const { findFlightSummaries } = useFlights();
    const { localizeDateString } = useDate();
    const fetch = useCallback(
        async (
            query: FlightSummaryQuery,
            sort: string[],
            pageToken: string | null,
            pageSize = 25
        ) => {
            const response = await findFlightSummaries(query, sort, pageSize, pageToken);

            return { ...response, objects: groupByDate(response.objects, localizeDateString) };
        },
        [findFlightSummaries, localizeDateString]
    );

    return {
        fetch
    };
};

export default useData;
