import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfProductUsageReportApplicationDetails');

    return {
        AIR_TEMPERATURE: t('airTemperature'),
        APPLICATION_DATE: t('applicationDate'),
        APPLICATION_SITE: t('applicationSite'),
        APPLICATOR: (name: string) => t('applicator', { name }),
        DRONE: (name: string) => t('drone', { name }),
        END_TIME: t('endTime'),
        FAHRENHEIGHT: t('fahrenheit'),
        MPH: t('mph'),
        START_TIME: t('startTime'),
        WIND_DIRECTION: t('windDirection'),
        WIND_GUST_SPEED: t('windGustSpeed'),
        WIND_SPEED: t('windSpeed'),
        WORK_ORDER_NUMBER: t('workOrderNumber')
    };
};

export default useTranslation;
