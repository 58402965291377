import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceDetailsColumn');

    return {
        CREATED_ON: (date: string) => t('createdOn', { date }),
        INVOICE_DETAILS: t('invoiceDetails')
    };
};

export default useTranslation;
