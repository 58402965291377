import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogListPageLayoutHeader');

    return {
        CREATE_FLIGHT_LOG: t('createFlightLog')
    };
};

export default useTranslation;
