import { useCallback } from 'react';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';
import { AsAppliedMap } from 'hooks/useAsAppliedMaps/types';
import useProductUsageReports from 'hooks/useProductUsageReports';

import type { Map } from './types';

// Port from AsAppliedMapsResultPage code written by walter
// only difference is that this uses our new hooks for as applied maps and does not
// rely on Atoms
const useData = () => {
    const { fetchAsAppliedMap } = useAsAppliedMaps();

    const { fetchProductUsageReport } = useProductUsageReports();

    const getChemicalsFromProductUsageReport = useCallback(
        async (productUsageReportId?: string) => {
            try {
                if (productUsageReportId) {
                    const { data, error } = await fetchProductUsageReport(productUsageReportId);

                    if (error) {
                        throw Error(JSON.stringify(error));
                    }

                    const chemicals = data.chemicalsApplied;
                    const chemicalNames: string[] = chemicals.map(
                        ({ chemical }) => chemical.labelName
                    );

                    return chemicalNames.join(',');
                }
            } catch (e) {
                console.error(e);
            }

            return null;
        },
        [fetchProductUsageReport]
    );

    const getSiteNameFromProductUsageReport = useCallback(
        async (productUsageReportId?: string) => {
            try {
                if (productUsageReportId) {
                    const { data, error } = await fetchProductUsageReport(productUsageReportId);

                    if (error) {
                        throw Error(JSON.stringify(error));
                    }

                    const sites = data.applicationSites;
                    const siteNames: string[] = sites.map(({ siteName }) => siteName);

                    return siteNames.join(',');
                }
            } catch (e) {
                console.error(e);
            }

            return null;
        },
        [fetchProductUsageReport]
    );

    const removeLayerIfExists = useCallback((map: Map, id: string) => {
        const layer = map.getLayer(id);

        if (layer) {
            map.removeLayer(id);
        }
    }, []);

    const removeSourceIfExists = useCallback((map: Map, id: string) => {
        const source = map.getSource(id);

        if (source) {
            map.removeSource(id);
        }
    }, []);

    const renderMap = useCallback(
        (map: Map, url: string, coordinates: number[][]) => {
            removeLayerIfExists(map, 'image');
            removeSourceIfExists(map, 'aam_data');

            map.addSource('aam_data', {
                coordinates,
                type: 'image',
                url
            });

            map.addLayer({
                id: 'image',
                paint: {
                    'raster-fade-duration': 0,
                    'raster-opacity': 1,
                    'raster-resampling': 'nearest'
                },
                source: 'aam_data',
                type: 'raster'
            });
        },
        [removeLayerIfExists, removeSourceIfExists]
    );

    const loadMap = useCallback((aam: AsAppliedMap) => {
        const { extent, legend, mapImage } = aam;

        const { maximumLatitude, maximumLongitude, minimumLatitude, minimumLongitude } = extent;

        const coords: number[][] = [
            [minimumLongitude, maximumLatitude],
            [maximumLongitude, maximumLatitude],
            [maximumLongitude, minimumLatitude],
            [minimumLongitude, minimumLatitude]
        ];

        return { coords, dataUrl: mapImage, legend: legend };
    }, []);

    const loadAamById = useCallback(
        async (id: string) => {
            try {
                const aam = await fetchAsAppliedMap(id);

                return aam;
            } catch (e) {
                console.error(e);
                // TODO have this come from translate
                alert('Unable to load aam try again later');
            }
        },
        [fetchAsAppliedMap]
    );

    return {
        getChemicalsFromProductUsageReport,
        getSiteNameFromProductUsageReport,
        loadAamById,
        loadMap,
        renderMap
    };
};

export default useData;
