import { AvailableFeatures } from './types';

export const FEATURE_MAP: Record<string, AvailableFeatures> = {
    'feature:as-applied-maps': 'asAppliedMaps',
    'feature:as-covered-maps': 'asCoveredMaps',
    'feature:faa-reports': 'faaReports',
    'feature:flight-logs': 'flightLogs',
    'feature:invoice-assistant': 'invoiceAssistant',
    'feature:john-deere': 'johnDeere',
    'feature:org-activity-reports': 'orgizationActivityReports',
    'feature:product-usage-reports': 'productUsageReports',
    'feature:pur-activity-reports': 'productUsageReportActivityReports',
    'feature:wo-activity-reports': 'workOrderActivityReports',
    'feature:work-orders': 'workOrders'
};
