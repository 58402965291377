import CloseButton from 'components/CloseButton';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const BackX: FunctionComponent<Props> = ({ className = '', onClick, testId = TEST_ID }) => (
    <CloseButton className={`${className} ${styles.backX}`} onClick={onClick} testId={testId} />
);

export default BackX;
