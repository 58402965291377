import { useMatch, useNavigate } from 'react-router-dom';

import Tab from 'components/Tab';
import Tabs from 'components/Tabs';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import {
    EQUIPMENT_TAB_TEST_ID,
    ORGANIZATION_DETAILS_TAB_TEST_ID,
    SUBSCRIPTION_TAB_TEST_ID,
    TEAM_MEMBER_TAB_TEST_ID,
    TEST_ID
} from './constants';
import type { FunctionComponent, Props } from './types';

const OrganizationTabs: FunctionComponent<Props> = ({ className, testId = TEST_ID }) => {
    const {
        organizationDetails,
        organizationEquipment,
        organizationSubscription,
        organizationTeamDetails
    } = usePageRoutes();
    const detailsMatch = useMatch(`${organizationDetails}/*`);
    const equipmentMatch = useMatch(`${organizationEquipment}/*`);
    const teamMatch = useMatch(`${organizationTeamDetails}/*`);
    const subscriptionMatch = useMatch(`${organizationSubscription}/*`);
    const navigate = useNavigate();
    const { EQUIPMENT, ORGANIZATION, SUBSCRIPTION, TEAM_MEMBERS } = useTranslation();

    return (
        <Tabs className={className} testId={testId}>
            <Tab
                isActive={Boolean(detailsMatch)}
                onClick={() => navigate(organizationDetails)}
                testId={ORGANIZATION_DETAILS_TAB_TEST_ID}
                text={ORGANIZATION}
            />

            <Tab
                isActive={Boolean(teamMatch)}
                onClick={() => navigate(organizationTeamDetails)}
                testId={TEAM_MEMBER_TAB_TEST_ID}
                text={TEAM_MEMBERS}
            />

            <Tab
                isActive={Boolean(equipmentMatch)}
                onClick={() => navigate(organizationEquipment)}
                testId={EQUIPMENT_TAB_TEST_ID}
                text={EQUIPMENT}
            />

            <Tab
                isActive={Boolean(subscriptionMatch)}
                onClick={() => navigate(organizationSubscription)}
                testId={SUBSCRIPTION_TAB_TEST_ID}
                text={SUBSCRIPTION}
            />
        </Tabs>
    );
};

export default OrganizationTabs;
