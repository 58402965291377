import TableColumn from 'components/pdf/TableColumn';
import TableRow from 'components/pdf/TableRow';
import InvoiceTableText from 'components/pdf/invoice/table/InvoiceTableText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceTableFieldRateRow: FunctionComponent<Props> = ({
    appliedAcres,
    fieldApplicationHours,
    fieldApplicationRate,
    fieldApplicationRateUnit
}) => {
    const { ACRES, FIELD_APPLICATION, HOURS, PER_ACRE, PER_HOUR } = useTranslation();

    const fieldApplicationText =
        fieldApplicationRateUnit === 'PER_ACRE'
            ? `${PER_ACRE}: ${appliedAcres} ${ACRES}`
            : `${PER_HOUR}: ${fieldApplicationHours} ${HOURS}`;
    const fieldApplicationValue =
        fieldApplicationRateUnit === 'PER_ACRE'
            ? (appliedAcres || 0) * (fieldApplicationRate || 0)
            : (fieldApplicationHours || 0) * (fieldApplicationRate || 0);

    if (!fieldApplicationRate) {
        return null;
    }

    return (
        <TableRow>
            <TableColumn style={styles.itemColumn} withDivider={false}>
                <InvoiceTableText text={FIELD_APPLICATION} />
            </TableColumn>

            <TableColumn style={styles.descriptionColumn} withDivider={false}>
                <InvoiceTableText text={fieldApplicationText} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableText text={`$ ${fieldApplicationValue.toFixed(2)}`} />
            </TableColumn>
        </TableRow>
    );
};

export default InvoiceTableFieldRateRow;
