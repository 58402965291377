import { useCallback, useEffect, useState } from 'react';

import DroneSelectCard from 'components/DroneSelectCard';
import MultiSelectCards from 'components/MultiSelectCards';

import useAircraft from 'hooks/useAircraft';

import { TEST_ID } from './constants';
import type { AircraftSelectedValue, FunctionComponent, Props } from './types';

const DroneMultiSelect: FunctionComponent<Props> = ({
    defaultSelectedIds,
    isEditable,
    onSelectionsChanged,
    testId = TEST_ID,
    title
}) => {
    const { fetchAllAircrafts } = useAircraft();

    const [drones, setDrones] = useState<AircraftSelectedValue[]>([]);

    const fetchDrones = useCallback(async () => {
        const { data, error } = await fetchAllAircrafts();

        if (error) {
            console.error(error);
        }
        if (data) {
            setDrones(
                data.map(drone => ({
                    ...drone,
                    isDisabled: false,
                    isSelected: defaultSelectedIds.includes(drone.id)
                }))
            );
        }
    }, [fetchAllAircrafts, setDrones, defaultSelectedIds]);

    const droneToCard = useCallback(
        (drone: AircraftSelectedValue, _index: number, selectedIndex: number) => {
            const { id, isDisabled, make, model, nickname, registrationNumber } = drone;

            return (
                <DroneSelectCard
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    key={id}
                    make={make}
                    model={model}
                    nickname={nickname}
                    registrationNumber={registrationNumber}
                    selectNumber={selectedIndex}
                />
            );
        },
        [isEditable]
    );

    useEffect(
        () => {
            fetchDrones();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <MultiSelectCards
            isEditable={isEditable}
            onSelectionChange={onSelectionsChanged}
            testId={testId}
            title={title}
            valueToComponentMap={droneToCard}
            values={drones}
        />
    );
};

export default DroneMultiSelect;
