import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ControllerForm from 'components/ControllerForm';
import PageContainer from 'components/PageContainer';

import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { ControllerFormType, FunctionComponent, Props } from './types';

const NewControllerPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { createController } = useData();
    const { organizationEquipmentController } = usePageRoutes();
    const [submitting, setSubmitting] = useState(false);
    const { API_ERROR, TITLE } = useTranslation();

    const initialValues: ControllerFormType = {
        make: '',
        model: '',
        nickname: '',
        serialNumber: ''
    };

    const onBack = useCallback(() => {
        navigate(organizationEquipmentController);
    }, [navigate, organizationEquipmentController]);

    const handleSubmit = useCallback(
        async (values: ControllerFormType) => {
            setSubmitting(true);
            try {
                const response = await createController(values);

                if (response) {
                    navigate(organizationEquipmentController);
                }
            } catch (error: unknown) {
                console.log(error);
                alert(API_ERROR);
            }

            setSubmitting(false);
        },
        [API_ERROR, createController, navigate, organizationEquipmentController]
    );

    return (
        <PageContainer testId={testId}>
            <ControllerForm
                initialValues={initialValues}
                isLoading={submitting}
                onBack={onBack}
                onSubmit={handleSubmit}
                title={TITLE}
            />
        </PageContainer>
    );
};

export default NewControllerPage;
