import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import PageLoader from 'components/PageLoader';
import AircraftForm from 'components/deprecating/AircraftForm';

import useBack from 'hooks/useBack';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { AircraftFormType, FunctionComponent, Props } from './types';

const EditAircraftPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { organizationEquipmentAircraft } = usePageRoutes();
    const back = useBack(organizationEquipmentAircraft);
    const { editAircraft, fetchAircraft } = useData();
    const { aircraftId } = useParams();
    const [aircraft, setAircraft] = useState<AircraftFormType>();
    const [submitting, setSubmitting] = useState(false);
    const { API_ERROR, TITLE } = useTranslation();

    const handleSubmit = useCallback(
        async (values: AircraftFormType) => {
            setSubmitting(true);
            try {
                const response = await editAircraft(values, aircraftId ?? '');

                if (response) {
                    back();
                }
            } catch (error: unknown) {
                console.log(error);
                alert(API_ERROR);
            }

            setSubmitting(false);
        },
        [API_ERROR, aircraftId, back, editAircraft]
    );

    useEffect(() => {
        const fetch = async () => {
            const data = await fetchAircraft(aircraftId ?? '');

            setAircraft(data);
        };

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!aircraftId) {
        back();
    }

    return (
        <PageContainer testId={testId}>
            {!aircraft && <PageLoader />}

            {aircraft && (
                <AircraftForm
                    initialValues={aircraft}
                    isExisting
                    isLoading={submitting}
                    onBack={back}
                    onSubmit={handleSubmit}
                    title={TITLE}
                />
            )}
        </PageContainer>
    );
};

export default EditAircraftPage;
