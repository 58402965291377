import { useEffect, useRef } from 'react';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Checkbox: FunctionComponent<Props> = ({
    className = '',
    isChecked,
    isDisabled = false,
    isIndeterminate = false,
    onChange,
    testId = TEST_ID
}) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = isIndeterminate;
        }
    }, [isIndeterminate, checkboxRef]);

    return (
        <label className={`${className} ${styles.checkbox}`} data-testid={testId}>
            <input
                onChange={event => {
                    onChange?.(event);
                }}
                checked={isChecked}
                className={styles.input}
                disabled={isDisabled}
                ref={checkboxRef}
                type="checkbox"
            />

            <span className={styles.checkmark} />
        </label>
    );
};

export default Checkbox;
