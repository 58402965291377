import { RESEND_EMAIL_API } from '../../constants';
import useTranslation from '../useTranslation';

import useAccessToken from 'hooks/useAccessToken';
import useFetch from 'hooks/useFetch';

const useResendEmail = () => {
    const { httpPost } = useFetch();
    const { fetchToken } = useAccessToken();
    const { API_ERROR, EMAIL_RESENT } = useTranslation();

    return async () => {
        const token = await fetchToken();

        if (!token) {
            alert(API_ERROR);

            return;
        }

        const response = await httpPost(
            RESEND_EMAIL_API,
            {},
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        if (response.error) {
            alert(API_ERROR);

            return;
        }

        // TODO: replace with notification component
        alert(EMAIL_RESENT);
    };
};

export default useResendEmail;
