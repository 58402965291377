import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('coordinateFields');

    return {
        LATITUDE: t('latitude'),
        LONGITUDE: t('longitude')
    };
};

export default useTranslation;
