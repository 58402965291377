import { Field, getIn } from 'formik';

import ErrorMessageText from 'components/ErrorMessageText';

import type { FieldProps, FunctionComponent, Props } from './types';

const ErrorMessage: FunctionComponent<Props> = ({ className, ignoreTouch = false, name }) => (
    <Field name={name}>
        {({ form }: FieldProps) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            const visibleError = (touch || ignoreTouch) && error && typeof error === 'string';

            return visibleError ? <ErrorMessageText className={className} text={error} /> : null;
        }}
    </Field>
);

export default ErrorMessage;
