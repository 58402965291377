import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const codeVerifierAtom = atom<string | null>({
    default: null,
    effects: [persistAtom],
    key: 'codeVerifier'
});

const stateAtom = atom({
    default: null,
    effects: [persistAtom],
    key: 'state'
});

const state = {
    atoms: {
        codeVerifierAtom,
        stateAtom
    }
};

export default state;
