import Column from 'components/pdf/Column';
import ColumnHeader from 'components/pdf/ColumnHeader';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const SectionColumn: FunctionComponent<Props> = ({ children, style, title }) => (
    <Column style={[styles.section, !title ? styles.sectionNoHeader : undefined, style]}>
        {title && <ColumnHeader text={title} />}

        {children}
    </Column>
);

export default SectionColumn;
