import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('certificatesAndExemptionsPage');

    return {
        COA: t('coa'),
        COA_TOOLTIP: t('coaTooltip'),
        ERROR_MESSAGE: t('errorMessage'),
        EXEMPTION_DOCKET_NUMBER: t('exemptionDocketNumber'),
        EXEMPTION_DOCKET_NUMBER_TOOLTIP: t('exemptionDocketNumberTooltip'),
        EXEMPTION_NUMBER: t('exemptionNumber'),
        EXEMPTION_NUMBER_TOOLTIP: t('exemptionNumberTooltip'),
        SAVE: t('save'),
        TITLE: t('title')
    };
};

export default useTranslation;
