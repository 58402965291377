import { INPUT_CHARS_MAX } from 'config';
import { format, parse, subYears } from 'date-fns';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { date, object, string } from 'yup';

import useTranslation from 'components/work-order/Details/hooks/useTranslation';

export const getDateMin = () => subYears(new Date(), 1);

export const constructUtcDateTime = (
    date: Date,
    time: string,
    timeZone: string,
    referenceDate: Date
) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const dateTimeStr = `${dateStr} ${time}`;
    const zonedDate = parse(dateTimeStr, 'yyyy-MM-dd HH:mm', referenceDate);

    return zonedTimeToUtc(zonedDate, timeZone);
};

const useSchema = () => {
    const { ERRORS } = useTranslation();

    return object({
        applicatorInformation: string().required(ERRORS.APPLICATOR_INFORMATION_REQUIRED),
        expirationDate: date()
            .required()
            .nullable()
            .test({
                message: ERRORS.EXPIRATION_DATE_MIN(getDateMin()),
                name: 'min',
                test: value => (value ? value >= getDateMin() : true)
            }),
        fileId: string().nullable(),
        proposedDate: date()
            .required(ERRORS.PROPOSED_DATE_REQUIRED)
            .nullable()
            .test({
                message: ERRORS.PROPOSED_DATE_REQUIRED,
                name: 'required',
                test: value => value !== null
            })
            .test({
                message: ERRORS.PROPOSED_DATE_MIN(getDateMin()),
                name: 'min',
                test: value => (value ? value >= getDateMin() : true)
            })
            .test({
                message: ERRORS.PROPOSED_DATE_INVALID,
                name: 'direction',
                test: (value, context) =>
                    value && context.parent.expirationDate
                        ? value <= context.parent.expirationDate
                        : true
            }),
        scheduledDate: date()
            .required()
            .nullable()
            .test({
                message: ERRORS.SCHEDULED_DATE_REQUIRED,
                name: 'required',
                test: (value, context) =>
                    context.parent.scheduledTime !== '' ? value !== null : true
            })
            .test({
                message: ERRORS.SCHEDULED_DATE_MIN(getDateMin()),
                name: 'min',
                test: value => (value ? value >= getDateMin() : true)
            })
            .test({
                message: ERRORS.SCHEDULED_DATE_INVALID,
                name: 'direction',
                test: (value, context) =>
                    value && context.parent.expirationDate
                        ? value <= context.parent.expirationDate
                        : true
            }),
        scheduledTime: string().ensure(),
        status: string().required(ERRORS.WORK_ORDER_STATUS_REQUIRED),
        workOrderNumber: string()
            .ensure()
            .max(INPUT_CHARS_MAX, ERRORS.WORK_ORDER_NUMBER_MAX(INPUT_CHARS_MAX))
    });
};

export default useSchema;
