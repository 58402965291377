import { API_PAGE_SIZE_PARAM_NAME, API_PAGE_TOKEN_PARAM_NAME } from 'config';

export const generatePagedQuery = (pageToken?: string | null, pageSize = 10) => {
    const query = new URLSearchParams();

    query.append(API_PAGE_SIZE_PARAM_NAME, pageSize.toString());

    if (pageToken) {
        query.append(API_PAGE_TOKEN_PARAM_NAME, pageToken);
    }

    return query;
};

export const formatPhoneNumber = (phoneNumber?: string | null) => {
    if (!phoneNumber) {
        return null;
    }

    const phoneSections = phoneNumber?.match(/^(\d{3})(\d{3})(\d{4})$/);

    const formattedPhoneNumber =
        phoneSections !== null
            ? `(${phoneSections[1]}) ${phoneSections[2]}-${phoneSections[3]}`
            : null;

    return formattedPhoneNumber;
};
