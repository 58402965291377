import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ListEmptyText: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text
}) => (
    <span className={`${styles.listEmptyText} ${className}`} data-testid={testId}>
        {children || text}
    </span>
);

export default ListEmptyText;
