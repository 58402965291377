import InvoiceTable from 'components/pdf/invoice/table/InvoiceTable';
import InvoiceTableChemicalCostRow from 'components/pdf/invoice/table/InvoiceTableChemicalCostRow';
import InvoiceTableFieldFlatFeeRow from 'components/pdf/invoice/table/InvoiceTableFieldFlatFeeRow';
import InvoiceTableFieldRateRow from 'components/pdf/invoice/table/InvoiceTableFieldRateRow';
import InvoiceTableFooter from 'components/pdf/invoice/table/InvoiceTableFooter';
import InvoiceTableHeader from 'components/pdf/invoice/table/InvoiceTableHeader';
import InvoiceTableOtherItemRows from 'components/pdf/invoice/table/InvoiceTableOtherItemRows';
import InvoiceTableTaxRow from 'components/pdf/invoice/table/InvoiceTableTaxRow';
import useCalculations from 'components/work-order/InvoiceAssistant/hooks/useCalculations';

import type { FunctionComponent, Props } from './types';

const InvoiceBreakdown: FunctionComponent<Props> = ({
    appliedAcres,
    chemicalCost,
    chemicalList = '',
    fieldApplicationFlatFee,
    fieldApplicationHours,
    fieldApplicationRate,
    fieldApplicationRateUnit = 'PER_ACRE',
    invoiceItems,
    taxAmount,
    taxAmountUnit = 'FLAT_RATE'
}) => {
    const { calculateEstimatedTotal } = useCalculations();

    const estimatedTotal = calculateEstimatedTotal(
        appliedAcres,
        chemicalCost,
        fieldApplicationFlatFee,
        fieldApplicationHours,
        fieldApplicationRate,
        fieldApplicationRateUnit,
        invoiceItems,
        undefined,
        undefined,
        taxAmount,
        taxAmountUnit
    );

    return (
        <InvoiceTable>
            <InvoiceTableHeader />

            <InvoiceTableFieldFlatFeeRow fieldApplicationFlatFee={fieldApplicationFlatFee} />

            <InvoiceTableFieldRateRow
                appliedAcres={appliedAcres}
                fieldApplicationHours={fieldApplicationHours}
                fieldApplicationRate={fieldApplicationRate}
                fieldApplicationRateUnit={fieldApplicationRateUnit}
            />

            <InvoiceTableChemicalCostRow chemicalCost={chemicalCost} chemicalList={chemicalList} />

            <InvoiceTableOtherItemRows invoiceItems={invoiceItems} />

            <InvoiceTableTaxRow
                estimatedTotal={estimatedTotal}
                taxAmount={taxAmount}
                taxAmountUnit={taxAmountUnit}
            />

            <InvoiceTableFooter estimatedTotal={estimatedTotal} />
        </InvoiceTable>
    );
};

export default InvoiceBreakdown;
