import Divider from 'components/Divider';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightRow: FunctionComponent<Props> = ({
    children,
    className = '',
    isHighlighted = false,
    testId = TEST_ID,
    withDivider = false
}) => {
    const highlightedClass = isHighlighted ? styles.highlighted : '';
    const withDividerClass = withDivider ? styles.withDivider : '';

    return (
        <>
            <div
                className={`${styles.flightRow} ${highlightedClass} ${withDividerClass} ${className}`}
                data-testid={testId}
            >
                {children}
            </div>

            {withDivider && <Divider className={styles.divider} />}
        </>
    );
};

export default FlightRow;
