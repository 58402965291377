import { INPUT_CHARS_MAX } from 'config';
import { boolean, object, string } from 'yup';
import 'yup-phone-lite';

import useTranslation from 'components/work-order/GrowerInformation/hooks/useTranslation';

const useSchema = () => {
    const { ERRORS } = useTranslation();

    return object({
        grower: object({
            companyName: string().when('sameAsCustomer', {
                is: false,
                otherwise: undefined,
                then: schema =>
                    schema.required(ERRORS.NAME_REQUIRED).max(INPUT_CHARS_MAX, ERRORS.NAME_MAX)
            }),
            contactName: string().notRequired().max(INPUT_CHARS_MAX, ERRORS.CONTACT_MAX),
            location: object({
                address1: string()
                    .requiredIfAnySiblingPresent(ERRORS.ADDRESS_REQUIRED)
                    .max(INPUT_CHARS_MAX, ERRORS.ADDRESS_MAX),
                address2: string().notRequired().max(INPUT_CHARS_MAX, ERRORS.ADDRESS2_MAX),
                city: string()
                    .requiredIfAnySiblingPresent(ERRORS.CITY_REQUIRED)
                    .max(INPUT_CHARS_MAX, ERRORS.CITY_MAX),
                state: string().requiredIfAnySiblingPresent(ERRORS.STATE_REQUIRED),
                zipCode: string().requiredIfAnySiblingPresent(ERRORS.ZIP_CODE_REQUIRED)
            }).notRequired(),
            phone: string().phone(null, ERRORS.PHONE_INVALID).notRequired(),
            sameAsCustomer: boolean().required()
        })
    });
};

export default useSchema;
