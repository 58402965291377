import { useCallback } from 'react';

import type { CalculatedEstimatedTotal, InvoiceItem } from './types';

const useCalculations = () => {
    const calculateEstimatedTotal = useCallback<CalculatedEstimatedTotal>(
        (
            appliedAcres,
            chemicalCost,
            fieldApplicationFlatFee,
            fieldApplicationHours,
            fieldApplicationRate,
            fieldApplicationRateUnit,
            invoiceItems,
            otherDescription,
            otherFee,
            taxAmount,
            taxAmountUnits
        ) => {
            const multiplier =
                fieldApplicationRateUnit === 'PER_ACRE' ? appliedAcres : fieldApplicationHours;

            const fieldApplicationTotal =
                (fieldApplicationFlatFee || 0) + (fieldApplicationRate || 0) * (multiplier || 0);

            const beginningTotal = otherDescription && otherFee ? otherFee : 0;

            const invoiceItemsTotal = invoiceItems.reduce(
                (accumulator: number, { amountDollars }: InvoiceItem) => {
                    accumulator += amountDollars;

                    return accumulator;
                },
                beginningTotal
            );

            const subTotal = fieldApplicationTotal + (chemicalCost || 0) + invoiceItemsTotal;

            const taxCost =
                taxAmountUnits === 'FLAT_RATE'
                    ? taxAmount || 0
                    : subTotal * 1 * ((taxAmount || 0) / 100);

            return subTotal + taxCost;
        },
        []
    );

    return { calculateEstimatedTotal };
};

export default useCalculations;
