import NavigationLink from 'components/NavigationLink';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CTANavigationLink: FunctionComponent<Props> = ({
    className,
    link,
    onClick,
    testId,
    text
}) => (
    <NavigationLink
        className={`${className} ${styles.ctaNavigationLink}`}
        isExactMatch={true}
        onClick={onClick}
        testId={testId}
        text={text}
        to={link}
    />
);

export default CTANavigationLink;
