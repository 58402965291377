import { useMemo } from 'react';

import { useDevice } from 'components/DeviceProvider';
import MobilePageNavigationDropdown from 'components/MobilePageNavigationDropdown';
import PageWithSidebarContainer from 'components/PageWithSidebarContainer';
import SideBar from 'components/SideBar';
import SideBarContentContainer from 'components/SideBarContentContainer';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SidebarOrDropdownLayout: FunctionComponent<Props> = ({
    children,
    currentSection,
    onSectionChange,
    sidebarItems
}) => {
    const { deviceType } = useDevice();

    const dropdownOptions = useMemo(() => {
        const dropdownData = Object.keys(sidebarItems).reduce(
            (accumulator, sidebarItemKey) => {
                const sidebarItem = sidebarItems[sidebarItemKey];
                const { index = 0 } = sidebarItem;

                accumulator.splice(index, 1, {
                    disabled: sidebarItem.isDisabled,
                    label: sidebarItem.title,
                    value: sidebarItemKey
                });

                return accumulator;
            },
            new Array(Object.keys(sidebarItems).length)
        );

        return dropdownData;
    }, [sidebarItems]);

    return (
        <>
            {deviceType === 'desktop' && (
                <PageWithSidebarContainer>
                    <SideBar
                        className={styles.sideBar}
                        currentSection={currentSection}
                        onSectionChange={onSectionChange}
                        sideBarItems={sidebarItems}
                    />

                    <SideBarContentContainer>{children}</SideBarContentContainer>
                </PageWithSidebarContainer>
            )}

            {deviceType === 'mobile' && (
                <>
                    <MobilePageNavigationDropdown
                        data={dropdownOptions}
                        onChange={onSectionChange}
                        value={currentSection}
                    />

                    {children}
                </>
            )}
        </>
    );
};

export default SidebarOrDropdownLayout;
