import type { Style } from './types';

const styles: Style = {
    backgroundColor: '#0000000F',
    borderBottom: '1pt solid #b9b9b9',
    flexDirection: 'row',
    width: '100%'
};

export default styles;
