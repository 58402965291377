import useJohnDeereBase from './hooks/useJohnDeereBase';
import useJohnDeereFields from './hooks/useJohnDeereFields';
import useJohnDeereMapLayers from './hooks/useJohnDeereMapLayers';
import useJohnDeereOrganizations from './hooks/useJohnDeereOrganizations';

const useJohnDeere = () => {
    const { authorizeOrganization, fetchJohnDeereOrganizations } = useJohnDeereOrganizations();
    const { exportMap } = useJohnDeereMapLayers();
    const { fetchJohnDeereFields } = useJohnDeereFields();
    const {
        fetchTokenOrRedirectAndLogIn,
        getPreviousPageFromUrl,
        logout,
        setTokenFromRedirect,
        token
    } = useJohnDeereBase();

    return {
        authorizeOrganization,
        exportMap,
        fetchJohnDeereFields,
        fetchJohnDeereOrganizations,
        fetchTokenOrRedirectAndLogIn,
        getPreviousPageFromUrl,
        logout,
        setTokenFromRedirect,
        token
    };
};

export default useJohnDeere;
