import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('faaReportListHeaders');

    return {
        TOTAL_HOURS_OF_FLIGHT: t('totalHoursOfFlight'),
        TOTAL_NUMBER_OF_DRONES: t('totalNumberOfDrones'),
        TOTAL_NUMBER_OF_FLIGHTS: t('totalNumberOfFlights'),
        TOTAL_NUMBER_OF_PILOTS: t('totalNumberOfPilots')
    };
};

export default useTranslation;
