import { useMemo } from 'react';

import { frontendToAreaRateUnitMap } from '@@types/units';
import { View } from '@react-pdf/renderer';

import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';

import useConvert from 'hooks/useConvert';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const WorkOrderChemicalInformation: FunctionComponent<Props> = ({ chemical }) => {
    const {
        ACTIVE_INGREDIENT,
        EPA_NUMBER,
        LABEL,
        NO_INGREDIENTS,
        PEST_CONDITION,
        RATE,
        SIGNAL_WORD,
        TITLE
    } = useTranslation();
    const { activeIngredients, epaNumber, labelName, pest, signalWord, sprayRate, sprayRateUnit } =
        chemical;

    const { convertPreferredRateToLabel } = useConvert();

    const rateText = `${sprayRate?.toFixed(2) ?? '-'} ${sprayRateUnit ? convertPreferredRateToLabel(frontendToAreaRateUnitMap[sprayRateUnit]) : ''}`;

    const activeIngredientText = useMemo(
        () =>
            activeIngredients.map((ingredient, ingredientIndex) => (
                <View key={ingredientIndex} wrap={false}>
                    <Text>
                        {ACTIVE_INGREDIENT((ingredientIndex + 1).toString())}

                        {ingredient}
                    </Text>
                </View>
            )),
        [ACTIVE_INGREDIENT, activeIngredients]
    );

    return (
        <Section>
            <SectionColumn title={TITLE}>
                <Text>
                    {LABEL}

                    {labelName}
                </Text>

                <Text>
                    {EPA_NUMBER}

                    {epaNumber ?? ''}
                </Text>

                <Text>
                    {SIGNAL_WORD}

                    {signalWord ?? ''}
                </Text>

                <Text>
                    {PEST_CONDITION}

                    {pest ?? ''}
                </Text>

                <Text>
                    {RATE}

                    {rateText}
                </Text>

                {activeIngredients.length === 0 && <Text>{NO_INGREDIENTS}</Text>}

                {activeIngredientText}
            </SectionColumn>
        </Section>
    );
};

export default WorkOrderChemicalInformation;
