import InterThin from '@@assets/inter/inter-latin-100-normal.ttf';
import InterUltralight from '@@assets/inter/inter-latin-200-normal.ttf';
import InterLight from '@@assets/inter/inter-latin-300-normal.ttf';
import InterNormal from '@@assets/inter/inter-latin-400-normal.ttf';
import InterMedium from '@@assets/inter/inter-latin-500-normal.ttf';
import InterSemibold from '@@assets/inter/inter-latin-600-normal.ttf';
import InterBold from '@@assets/inter/inter-latin-700-normal.ttf';
import InterUltraBold from '@@assets/inter/inter-latin-800-normal.ttf';
import InterHeavy from '@@assets/inter/inter-latin-900-normal.ttf';
import { Font, Document as PdfDocument } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

const Document: FunctionComponent<Props> = ({ children, title }) => {
    Font.register({
        family: 'Inter',
        fonts: [
            { fontWeight: 'thin', src: InterThin },
            { fontWeight: 'ultralight', src: InterUltralight },
            { fontWeight: 'light', src: InterLight },
            { fontWeight: 'normal', src: InterNormal },
            { fontWeight: 'medium', src: InterMedium },
            { fontWeight: 'semibold', src: InterSemibold },
            { fontWeight: 'bold', src: InterBold },
            { fontWeight: 'ultrabold', src: InterUltraBold },
            { fontWeight: 'heavy', src: InterHeavy }
        ]
    });

    return <PdfDocument title={title}>{children}</PdfDocument>;
};

export default Document;
