import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightOverview');

    return {
        FLIGHT_OVERVIEW: t('flightOverview'),
        MAP_OVERVIEW: t('mapOverview')
    };
};

export default useTranslation;
