import Loader from 'components/Loader';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapLoader: FunctionComponent<Props> = ({ testId = TEST_ID }) => (
    <div className={styles.mapLoader} data-testid={testId}>
        <Loader className={styles.icon} />
    </div>
);

export default MapLoader;
