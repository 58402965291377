import { useCallback } from 'react';

import { AddQuery, UseQueryString } from './types';

const useQuerystring: UseQueryString = (
    { querystring: defaultQuerystring = '' } = { querystring: '' }
) => {
    const addQuery: AddQuery = useCallback(
        (queryParameters = {}, currentQuery?: URLSearchParams) => {
            const query = Object.keys(queryParameters).reduce(
                (aggregatedQuery, queryKey) => {
                    const queryValues = ([] as (string | null)[])
                        .concat(queryParameters[queryKey])
                        .filter(item => item !== null);

                    queryValues?.forEach(queryValue => {
                        if (aggregatedQuery.has(queryKey)) {
                            aggregatedQuery.append(queryKey, queryValue!);
                        } else {
                            aggregatedQuery.set(queryKey, queryValue!);
                        }
                    });

                    return aggregatedQuery;
                },
                currentQuery || new URLSearchParams(defaultQuerystring)
            );

            return query;
        },
        [defaultQuerystring]
    );

    return {
        addQuery
    };
};

export default useQuerystring;
