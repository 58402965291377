import type { FunctionComponent } from 'react';

import AamButton from 'components/AamButton';
import ErrorPage from 'components/ErrorPage';

import { TEST_ID } from './constants';
import { Props } from './types';

const BadPathErrorPage: FunctionComponent<Props> = props => {
    const { testId = TEST_ID, title = 'error.headers.oops' } = props;

    const text = 'error.body.badPath';

    return (
        <ErrorPage testId={testId} text={text} title={title}>
            <AamButton />
        </ErrorPage>
    );
};

export default BadPathErrorPage;
