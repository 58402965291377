const translations = {
    autoFillError: 'Error auto filling work order',
    autoFillWorkOrder: 'Auto Fill Work Order From Pdf',
    autoFillWorkOrderDisclaimer:
        'Experimental Feature: Auto fills work order from agrain pdf. Will not work with any other type of pdf. Double check all fields after auto fill, results may vary.',
    errors: {
        applicatorInformationRequired: 'Please choose an applicator',
        expirationDateMin: 'Expiration Date must be later than {{dateMin}}',
        proposedDateInvalid: 'Proposed Date must be before Expiration Date',
        proposedDateMin: 'Proposed Date must be later than {{dateMin}}',
        proposedDateRequired: 'Please provide a Proposed Date',
        scheduledDateInvalid: 'Scheduled Date must be before Expiration Date',
        scheduledDateMin: 'Scheduled Date must be later than {{dateMin}}',
        scheduledDateRequired: 'Scheduled Date must be provided',
        workOrderNumberMax: 'Work Order Number must be less than {{charMax}} characters',
        workOrderStatusRequired: 'Please choose a status'
    },
    labels: {
        applicatorInformation: 'Applicator Information',
        expirationDate: 'Expiration Date',
        proposedDate: 'Proposed Date',
        scheduledDate: 'Scheduled Date',
        scheduledTime: 'Time',
        status: 'Work Order Status',
        workOrderNumber: 'Work Order Number (Third Party)'
    },
    placeholders: {
        applicatorInformation: 'Team Members',
        expirationDate: 'MM/DD/YYYY',
        proposedDate: 'MM/DD/YYYY',
        scheduledDate: 'MM/DD/YYYY',
        scheduledTime: '00:00:00 --'
    },
    statuses: {
        canceled: 'Canceled',
        complete: 'Complete',
        pending: 'Pending',
        scheduled: 'Scheduled',
        started: 'Started'
    },
    workOrderButton: 'Upload Work Order'
};

export default translations;
