import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogDeleteConfirmationModal');

    return {
        BUTTON_TEXT: t('buttonText'),
        CONFIRM_DELETE_FLIGHT_LOG: t('confirmDeleteFlightLog'),
        CONFIRM_DELETE_FLIGHT_LOG_MESSAGE: t('confirmDeleteFlightLogMessage'),
        CONFIRM_DELETE_FLIGHT_LOG_SUBTEXT_MESSAGE: t('confirmDeleteFlightLogSubtextMessage'),
        CONFIRM_DELETE_FLIGHT_LOGS: t('confirmDeleteFlightLogs'),
        CONFIRM_DELETE_FLIGHT_LOGS_MESSAGE: t('confirmDeleteFlightLogsMessage')
    };
};

export default useTranslation;
