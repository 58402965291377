import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('coveredLabel');

    return {
        COVERED: t('covered')
    };
};

export default useTranslation;
