import type { Style } from './types';

const styles: Record<string, Style> = {
    estimatedTotalColumn: {
        flex: 3.5
    },
    totalColumn: {
        flex: 1
    }
};

export default styles;
