import useTranslation from '../useTranslation';
import { clone } from 'lodash';
import { useNavigate } from 'react-router-dom';

import useAccessToken from 'hooks/useAccessToken';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useOrganizations from 'hooks/useOrganizations';
import usePageRoutes from 'hooks/usePageRoutes';

import type { Organization, OrganizationRequest } from './types';

const useCreateOrganization = () => {
    const { fetchToken } = useAccessToken();
    const { setCurrentOrganization } = useCurrentOrganization();
    const navigate = useNavigate();
    const { createOrganization } = useOrganizations();
    const { homePage } = usePageRoutes();
    const { API_ERROR } = useTranslation();

    return async (values: OrganizationRequest) => {
        try {
            const newValues = clone(values);

            if (newValues.phone === '') {
                newValues.phone = null;
            }

            const response = await createOrganization(newValues);

            if (response?.data) {
                const data: Organization = response.data;

                await setCurrentOrganization(data);

                const orgToken = await fetchToken(data.auth0Id, false);

                if (orgToken) {
                    navigate(homePage);
                } else {
                    alert(API_ERROR);
                    setCurrentOrganization(undefined);
                }
            } else {
                alert(API_ERROR);
            }
        } catch (error: unknown) {
            console.error(error);
            alert(API_ERROR);

            return;
        }
    };
};

export default useCreateOrganization;
