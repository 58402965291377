import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfWorkOrderChemicalInformation');

    return {
        ACTIVE_INGREDIENT: (number: string) => t('activeIngredient', { number }),
        EPA_NUMBER: t('epaNumber'),
        GPA: t('gpa'),
        LABEL: t('label'),
        NO_INGREDIENTS: t('noIngredients'),
        PEST_CONDITION: t('pestCondition'),
        RATE: t('rate'),
        SIGNAL_WORD: t('signalWord'),
        TITLE: t('title')
    };
};

export default useTranslation;
