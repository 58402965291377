import { Children } from 'react';

import VerticalHighlight from 'components/VerticalHighlight';
import VerticalTabs from 'components/VerticalTabs';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const VerticalHighlightTabs: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID
}) => {
    const selectedIndex = Children.toArray(children).findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        child => (child as any).props.isActive
    );

    const gap = parseInt(styles.gap, 10);

    const height = 15;
    const top = selectedIndex ? selectedIndex * 14.5 + selectedIndex * gap : 0;

    const style = {
        height: `${height}px`,
        top: `${top}px`
    };

    return (
        <VerticalTabs className={`${className} ${styles.verticalHighlightTabs}`} testId={testId}>
            {children}

            <VerticalHighlight css={style} />
        </VerticalTabs>
    );
};

export default VerticalHighlightTabs;
