import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogListHeader');

    return {
        APPLICATION_TYPE: t('applicationType'),
        DRONE: t('drone'),
        FILE_TYPE: t('fileType'),
        FLIGHT_DURATION: t('flightDuration'),
        FLIGHT_TIME: t('flightTime'),
        LOCATION: t('location'),
        SELECT_ALL: t('selectAll'),
        SELECT_NONE: t('selectNone')
    };
};

export default useTranslation;
