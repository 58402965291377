import { useMemo } from 'react';

import flattenAddress from '@@utils/flattenAddress';
import { combineFeaturesToMultiPolygon, convertGeometryToGeoJSON } from '@@utils/mapUtils';
import { Image } from '@react-pdf/renderer';

import Column from 'components/pdf/Column';
import ColumnHeader from 'components/pdf/ColumnHeader';
import Section from 'components/pdf/Section';

import useMapboxStaticImages from 'hooks/useMapboxStaticImages';

import useTranslation from './hooks/useTranslation';

import { ZOOM_LEVEL } from './constants';
import styles from './style';
import type { FunctionComponent, Geometry, Point, Props } from './types';

const WorkOrderApplicationSiteBoundary: FunctionComponent<Props> = ({ index, site }) => {
    const { APPLICATION_SITE } = useTranslation();
    const { generateStaticImageUrl } = useMapboxStaticImages();

    const { boundary, coordinates, location, siteName } = site;

    let sectionText = '';

    if (!location) {
        sectionText = siteName;
    } else {
        const { address1, address2, city, state, zipCode } = location;

        sectionText = flattenAddress({
            address1,
            address2,
            city,
            state,
            zipCode
        });
    }

    const multipolygon = useMemo(() => combineFeaturesToMultiPolygon(boundary), [boundary]);

    const point = useMemo(() => {
        if (!coordinates?.latitude && !coordinates?.longitude) {
            return null;
        }

        return {
            coordinates: [coordinates?.longitude, coordinates?.latitude],
            type: 'Point'
        } as Point;
    }, [coordinates?.latitude, coordinates?.longitude]);

    const imageUrl = useMemo(() => {
        if (multipolygon === null && point === null) {
            return '';
        }

        const geojson =
            multipolygon !== null
                ? convertGeometryToGeoJSON([{ geometry: multipolygon, properties: {} }])
                : convertGeometryToGeoJSON([{ geometry: point as Geometry, properties: {} }]);

        return generateStaticImageUrl({
            geometry: geojson,
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
            zoom: ZOOM_LEVEL
        });
    }, [
        multipolygon,
        point,
        coordinates?.longitude,
        coordinates?.latitude,
        generateStaticImageUrl
    ]);

    if (
        (boundary === null || multipolygon === null) &&
        !coordinates?.latitude &&
        !coordinates?.longitude
    ) {
        return null;
    }

    return (
        <Section style={styles.container}>
            <Column>
                <ColumnHeader text={`${APPLICATION_SITE(index + 1)}: ${sectionText}`} />

                <Image src={imageUrl} style={styles.image} />
            </Column>
        </Section>
    );
};

export default WorkOrderApplicationSiteBoundary;
