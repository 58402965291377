import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import usePageRoutes from 'hooks/usePageRoutes';

const useBack = (backRoute?: string) => {
    const navigate = useNavigate();
    const { homePage } = usePageRoutes();

    const back = useCallback(
        (goHome = true) => {
            if (backRoute) {
                navigate(backRoute);
            } else if (goHome) {
                navigate(homePage);
            } else {
                navigate(-1);
            }
        },
        [backRoute, homePage, navigate]
    );

    return back;
};

export default useBack;
