import { useCallback } from 'react';

import { generatePagedQuery } from '@@utils/string';

import useFetch from 'hooks/useFetch';

import {
    DJI_DRONE_REPORT_IMPORT_REQUEST,
    DRONE_REPORT_API,
    XAG_DRONE_REPORT_UPLOAD_ENDPOINT
} from './constants';
import {
    DjiDroneReportImportRequest,
    DroneReportFilterParameters,
    DroneReportResponse,
    PagedResponseDroneReportResponse,
    XagDroneImportRequest
} from './types';

const useDroneReport = () => {
    const { authenticatedGet, authenticatedPost } = useFetch();

    const fetchDroneReports = useCallback(
        async (
            faaReportId: string,
            parameters: DroneReportFilterParameters,
            pageToken?: string,
            pageSize = 10
        ) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const { droneIds } = parameters;

            queryParams.append('droneIds', droneIds.join(','));

            const uri = `${DRONE_REPORT_API(faaReportId)}?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data as PagedResponseDroneReportResponse;
        },
        [authenticatedGet]
    );

    const fetchDroneReport = useCallback(
        async (faaReportId: string, droneId: string) => {
            const uri = `${DRONE_REPORT_API(faaReportId)}/${droneId}`;

            const { data, error } = await authenticatedGet(uri);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data as DroneReportResponse;
        },
        [authenticatedGet]
    );

    const importDjiDroneReport = useCallback(
        async (faaReportId: string, request: DjiDroneReportImportRequest) => {
            const { data, error } = await authenticatedPost(
                DJI_DRONE_REPORT_IMPORT_REQUEST(faaReportId),
                JSON.stringify(request)
            );

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPost]
    );

    const importXagDroneReport = useCallback(
        async (faaReportId: string, request: XagDroneImportRequest) => {
            const { data, error } = await authenticatedPost(
                XAG_DRONE_REPORT_UPLOAD_ENDPOINT(faaReportId),
                JSON.stringify(request)
            );

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPost]
    );

    return { fetchDroneReport, fetchDroneReports, importDjiDroneReport, importXagDroneReport };
};

export default useDroneReport;
