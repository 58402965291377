import type { Style } from './types';

const styles: Style = {
    alignSelf: 'flex-start',
    fontFamily: 'Inter',
    fontSize: '9pt',
    padding: '9pt'
};

export default styles;
