import LabelPrimaryText from 'components/LabelPrimaryText';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const LabelSecondaryText: FunctionComponent<Props> = ({
    className = '',
    testId = TEST_ID,
    text
}) => (
    <LabelPrimaryText className={`${styles.labelSecondaryText} ${className}`} testId={testId}>
        {text}
    </LabelPrimaryText>
);

export default LabelSecondaryText;
