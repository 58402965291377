import { Field } from 'formik';

import FormSectionContainer from 'components/FormSectionContainer';
import AddressFields from 'components/deprecating/AddressFields';
import FormInput from 'components/deprecating/FormInput/index';
import FormPhoneInput from 'components/deprecating/FormPhoneInput';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

/**
 * @deprecated No new version exists yet. We need to create a new version of this.
 */
const CompanyContactFields: FunctionComponent<Props> = ({
    className = '',
    isDisabled = false,
    isEditable = true,
    namePrefix = '',
    testId = TEST_ID
}) => {
    const { COMPANY, CONTACT, PHONE_NUMBER } = useTranslation();

    return (
        <FormSectionContainer
            className={`${className} ${styles.companyContactFields}`}
            data-testid={testId}
        >
            <Field
                component={FormInput}
                disabled={isDisabled}
                isEditable={isEditable}
                label={COMPANY}
                name={`${namePrefix}companyName`}
                testId={`${namePrefix}companyName`}
                type="text"
            />

            <Field
                component={FormInput}
                disabled={isDisabled}
                isEditable={isEditable}
                label={CONTACT}
                name={`${namePrefix}contactName`}
                testId={`${namePrefix}contactName`}
                type="text"
            />

            <AddressFields
                isDisabled={isDisabled}
                isEditable={isEditable}
                namePrefix={namePrefix}
            />

            <Field
                component={FormPhoneInput}
                disabled={isDisabled}
                isEditable={isEditable}
                label={PHONE_NUMBER}
                name={`${namePrefix}phone`}
                testId={`${namePrefix}phone`}
                type="text"
            />
        </FormSectionContainer>
    );
};

export default CompanyContactFields;
