import { useState } from 'react';

import { Popover } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DesktopNavigationDropdown: FunctionComponent<Props> = ({
    children,
    className = '',
    dropdownElement,
    testId = TEST_ID
}) => {
    const [open, setOpen] = useState(false);

    return (
        <Popover opened={open} portalProps={{ target: document.body }} withinPortal={true}>
            <Popover.Target>
                <span
                    className={`${styles.desktopNavigationDropdown} ${className}`}
                    data-testid={testId}
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                >
                    {dropdownElement}
                </span>
            </Popover.Target>

            <Popover.Dropdown
                className={styles.dropdownContent}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                {children}
            </Popover.Dropdown>
        </Popover>
    );
};

export default DesktopNavigationDropdown;
