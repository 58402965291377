import { useCallback, useState } from 'react';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SelectableCard: FunctionComponent<Props> = ({
    children,
    isDisabled,
    isEditable,
    isSelected,
    onDeselect,
    onSelect,
    testId = TEST_ID,
    value
}) => {
    const [isCurrentlySelected, setIsCurrentlySelected] = useState(isSelected);

    const handleClick = useCallback(() => {
        if (isDisabled) {
            return;
        }

        if (isCurrentlySelected) {
            onDeselect(value);
        } else {
            onSelect?.(value);
        }

        setIsCurrentlySelected(!isCurrentlySelected);
    }, [isCurrentlySelected, isDisabled, onDeselect, onSelect, value]);

    return (
        <div
            className={
                isCurrentlySelected && isEditable
                    ? styles.selectContainer
                    : styles.unselectedContainer
            }
            data-testid={testId}
            onClick={isEditable ? handleClick : undefined}
        >
            {children}
        </div>
    );
};

export default SelectableCard;
