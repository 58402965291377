import { md5 } from 'js-md5';
import { isArray } from 'lodash';
import { useCallback, useState } from 'react';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFetch from 'hooks/useFetch';

import { CONFLICT } from './constants';
import { DocumentUploadRequest, DocumentUploadResponse, FileUpload, UploadedFile } from './types';

const useFileUploadHandler = ({
    validFileExtension
}: {
    validFileExtension: string | string[];
}) => {
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

    const { currentOrganization } = useCurrentOrganization();

    const { authenticatedPost } = useFetch();

    const addUploadedFile = useCallback((uploadedFile: UploadedFile) => {
        setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, uploadedFile]);
    }, []);

    const getPresignedUrlFromEndpoint = useCallback(
        async (
            fileUpload: FileUpload,
            endpoint: string,
            additionalHashingStrings: string[] = []
        ) => {
            const hash = md5.create();

            if (!currentOrganization) {
                throw new Error('User is not currently logged in');
            }

            const hashingStrings = [currentOrganization?.id, ...additionalHashingStrings];

            const hashingStringBytes = hashingStrings.map(str => new TextEncoder().encode(str));

            const totalHashingStringLength = hashingStringBytes.reduce(
                (totalLength: number, bytes: Uint8Array) => totalLength + bytes.length,
                0
            );

            const fileArrayBuffer = await fileUpload.file.arrayBuffer();

            const combined = new Uint8Array(totalHashingStringLength + fileArrayBuffer.byteLength);
            let prevLength = 0;

            hashingStringBytes.forEach((bytes: Uint8Array) => {
                combined.set(bytes, prevLength);
                prevLength += bytes.length;
            });

            combined.set(new Uint8Array(fileArrayBuffer), totalHashingStringLength);

            hash.update(combined);

            const request = {
                contentType: fileUpload.fileRequest.contentType,
                filesize: fileUpload.fileRequest.filesize,
                hash: hash.hex()
            } as DocumentUploadRequest;

            const { data, error } = await authenticatedPost(endpoint, JSON.stringify(request));

            if (error) {
                console.log('ERROR', error);

                if (error.message === CONFLICT) {
                    throw new Error('CONFLICT');
                }

                throw new Error('UPLOAD_ERROR');
            }

            return data as DocumentUploadResponse;
        },
        [authenticatedPost, currentOrganization]
    );

    const getUploadedFiles = useCallback(() => uploadedFiles, [uploadedFiles]);

    const isValidFile = useCallback(
        async (file: File) => {
            const fileExtension = file.name.split('.').pop()?.toUpperCase();

            if (
                fileExtension &&
                isArray(validFileExtension) &&
                validFileExtension.includes(fileExtension)
            ) {
                return true;
            }

            return fileExtension === validFileExtension;
        },
        [validFileExtension]
    );

    const modifyFileRequest = useCallback(async (file: FileUpload) => file, []);

    return {
        addUploadedFile,
        getPresignedUrlFromEndpoint,
        getUploadedFiles,
        isValidFile,
        modifyFileRequest,
        setUploadedFiles
    };
};

export default useFileUploadHandler;
