const translations = {
    coverageMapWarning: 'Selected flights are only able to produce an As Covered Map',
    flightAreaValidationError: 'Flight area too large. Please select a smaller area.',
    flightOverview: 'Flight Overview',
    flightSprayValidationError: 'Selected flights do not contain any spray data',
    flightsFound: 'Flights Found',
    mapError: 'Error Generating Map Please Try Again Later',
    maxNumberOfFlightsError:
        'You have more than {{maxNumberOfFlights}} flights selected. Please select fewer flights.',
    multipleFlightTypesSelected:
        'Only one spray type is allowed. Please select either all liquid or all dry flights',
    selectFlights: 'Select Flights for Map'
};

export default translations;
