import { memo, useEffect } from 'react';

import WithErrorMessage from 'components/WithErrorMessage';
import AddressTwoInput from 'components/form/AddressTwoInput';

import { FormFieldProps } from 'hooks/useFormState/types';
import useValidation from 'hooks/useValidation';
import maxLengthValidator from 'hooks/useValidation/maxLengthValidator';
import requiredValidator from 'hooks/useValidation/requiredValidator';

import useTranslation from './hooks/useTranslation';

import { MAX_CHAR_COUNT } from './constants';

const AddressTwoField = <T extends object>(props: FormFieldProps<T>) => {
    const { form, isDisabled = false, isEditable = true, isRequired = false, name } = props;
    const { getValues, handleChange, handleError, handleValid, register } = form;

    const { ref } = register<HTMLInputElement>(name);

    const { MAX_EXCEEDED_ERROR_MESSAGE, REQUIRED_ERROR_MESSAGE } = useTranslation();

    const { validateValue } = useValidation({
        validators: [
            requiredValidator(REQUIRED_ERROR_MESSAGE, isRequired),
            maxLengthValidator(MAX_EXCEEDED_ERROR_MESSAGE, MAX_CHAR_COUNT)
        ]
    });

    const handleFieldChange = (value: string) => {
        if (!name) {
            return;
        }
        const validationErrorMessage = validateValue(value);

        handleChange(name, value);
        if (validationErrorMessage) {
            handleError(name, { message: validationErrorMessage });
        } else {
            handleValid(name);
        }
    };

    useEffect(() => {
        const validationErrorMessage = validateValue(ref.current.value);

        if (validationErrorMessage) {
            handleError(name, { message: validationErrorMessage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WithErrorMessage>
            <AddressTwoInput
                isDisabled={isDisabled}
                isEditable={isEditable}
                name={name as string}
                onChange={handleFieldChange}
                onError={(message: string) => handleError(name, { message })}
                ref={ref}
                value={getValues(name) as string}
            />
        </WithErrorMessage>
    );
};

AddressTwoField.displayName = 'AddressTwoField';

export default memo(
    AddressTwoField,
    (prevProps, nextProps) =>
        prevProps.name === nextProps.name &&
        prevProps.form.formErrors === nextProps.form.formErrors &&
        prevProps.isEditable === nextProps.isEditable
) as typeof AddressTwoField;
