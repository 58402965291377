import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('uploadFlightLogPage');

    return {
        APPLICATION_DETAILS: t('applicationDetails'),
        FILE_UPLOAD: t('fileUpload'),
        FLIGHT_OVERVIEW: t('flightOverview'),
        UPLOAD_FLIGHT_LOG: t('uploadAFlightLog')
    };
};

export default useTranslation;
