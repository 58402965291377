import DataLabel from 'components/pdf/DataLabel';
import DataRow from 'components/pdf/DataRow';
import DataValue from 'components/pdf/DataValue';

import type { FunctionComponent, Props } from './types';

const DataMeasurementValue: FunctionComponent<Props> = ({ measurement, text }) => (
    <DataRow>
        <DataLabel text={text} />

        <DataValue measurement={measurement} />
    </DataRow>
);

export default DataMeasurementValue;
