import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogDeleteErrorModal');

    return {
        DELETE_FLIGHT_LOG_ERROR_MESSAGE: t('deleteFlightLogErrorMessage'),
        DELETE_FLIGHT_LOG_ERROR_TITLE: t('deleteFlightLogErrorTitle')
    };
};

export default useTranslation;
