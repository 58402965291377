import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import FlightDetails from 'components/FlightDetails';
import SideBarContentContainer from 'components/SideBarContentContainer';
import VerticalContainer from 'components/VerticalContainer';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslate';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightOverviewTab: FunctionComponent<Props> = ({ flights, testId = TEST_ID }) => {
    const { CLOSE } = useTranslation();

    const navigate = useNavigate();
    const { flightLogPage } = usePageRoutes();

    return (
        <SideBarContentContainer testId={testId}>
            <VerticalContainer className={styles.flightTabContainer}>
                <FlightDetails flights={flights} />

                <PrimaryButton onClick={() => navigate(flightLogPage)} text={CLOSE} />
            </VerticalContainer>
        </SideBarContentContainer>
    );
};

export default FlightOverviewTab;
