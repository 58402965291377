const translations = {
    errors: {
        firstNameInvalid: 'First name contains invalid characters',
        firstNameMax: 'First name cannot exceed {{nameMax}} characters',
        firstNameRequired: 'Please provide your first name',
        lastNameInvalid: 'Last name contains invalid characters',
        lastNameMax: 'Last name cannot exceed {{nameMax}} characters',
        lastNameRequired: 'Please provide your last name',
        phoneInvalid: 'Phone number is invalid'
    },
    placeholders: {
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone Number (optional)'
    }
};

export default translations;
