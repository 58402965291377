import { atom } from 'recoil';

import type { OAuth2Token } from 'hooks/useJohnDeere/types';

const johnDeereTokenAtom = atom<OAuth2Token | null>({
    default: null,
    key: 'johnDeereToken'
});

const state = {
    atoms: {
        johnDeereTokenAtom
    }
};

export default state;
