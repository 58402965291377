import PageContainer from 'components/PageContainer';
import VerticalContainer from 'components/VerticalContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ProductUsageReportContentContainer: FunctionComponent<Props> = ({
    children,
    testId = TEST_ID
}) => (
    <PageContainer testId={testId}>
        <VerticalContainer className={styles.productUsageReportContentContainer}>
            {children}
        </VerticalContainer>
    </PageContainer>
);

export default ProductUsageReportContentContainer;
