const translations = {
    activeIngredients: 'Active Ingredients',
    chemicalToApply: 'Chemical to be Applied',
    epaNumber: 'EPA Number',
    pestCondition: 'Pest/Condition',
    rate: 'Rate',
    rateUnit: 'Ga/Ac',
    signalWord: 'Signal Word'
};

export default translations;
