import { Image, View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const MapImage: FunctionComponent<Props> = ({ mapImageUrl }) => (
    <View style={styles.mapContainer}>
        <Image src={mapImageUrl} style={styles.map} />
    </View>
);

export default MapImage;
