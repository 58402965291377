const translations = {
    acres: 'Acres',
    applicationSite: 'Application Site',
    appliedAcres: 'Applied Acres',
    appliedAcresTooltip: 'Data will autofill when Product Usage Report has been completed',
    chemicalCost: 'Chemical Cost',
    errors: {
        fieldApplicationHoursMax: 'Hours cannot exceed {{hoursMax}}',
        fieldApplicationHoursRequired: 'Hours are required',
        otherDescriptionMax: 'Description cannot exceed {{descriptionMax}}',
        otherDescriptionRequired: 'Description is required',
        otherFeeRequired: 'Fee is required'
    },
    estimatedTotal: 'Estimated Total',
    fieldApplicationFlatFee: 'Field Application Flat Fee',
    fieldApplicationRate: 'Field Application Rate',
    hours: 'Hours',
    hoursWorked: 'Hours Worked',
    notProvided: 'Not Provided',
    optional: 'Optional',
    other: 'Other',
    proposedAcres: 'Proposed Acres',
    taxAmount: 'Tax Amount',
    taxTooltip:
        'Users are solely responsible for the interpretation and application of the generated information in compliance with relevant laws and regulations.',
    usd: 'USD'
};

export default translations;
