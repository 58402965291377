import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('multiFileUpload');

    return {
        AIRCRAFT_MISMATCH: t('aircraftMismatch'),
        CONFLICT_ERROR: t('conflictError'),
        CSV_MULTI_AIRCRAFT_ERROR: t('csvMultiAircraftError'),
        FILE_UPLOADS: t('fileUploads'),
        INVALID_DATA_ERROR: t('invalidDataError'),
        INVALID_EXTENSION_ERROR: t('invalidExtensionError'),
        INVALID_FLIGHT_MODE_ERROR: t('invalidFlightModeError'),
        SELECT_FILES: t('selectFiles'),
        UNKNOWN_ERROR: t('unknownError'),
        UPLOAD_ERROR: t('uploadError')
    };
};

export default useTranslation;
