import { useMemo } from 'react';

import AvatarInitials from 'components/AvatarInitials';
import AvatarOverflow from 'components/AvatarOverflow';
import HorizontalContainer from 'components/HorizontalContainer';

import { MAX_INITIALS, TEST_ID } from './constants';
import type { FunctionComponent, Props, ReactNode } from './types';

import styles from './styles.module.scss';

const AvatarList: FunctionComponent<Props> = ({
    maxInitials = MAX_INITIALS,
    names,
    testId = TEST_ID
}) => {
    const avatarList = useMemo(() => {
        const numberOfInitials = names.length < maxInitials ? names.length : maxInitials;

        const avatars: ReactNode[] = [];

        for (let i = 0; i < numberOfInitials; i++) {
            avatars.push(<AvatarInitials key={`${i}-{${names[i]}}`} name={names[i]} />);
        }

        if (names.length > maxInitials) {
            avatars.push(<AvatarOverflow key="more" number={names.length - maxInitials} />);
        }

        return avatars;
    }, [names, maxInitials]);

    return (
        <HorizontalContainer className={styles.avatarList} testId={testId}>
            {avatarList}
        </HorizontalContainer>
    );
};

export default AvatarList;
