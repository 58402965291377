import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useAccessToken from 'hooks/useAccessToken';

import {
    MANAGE_EQUIPMENT_ROLE,
    MANAGE_FAA_REPORTS_ROLE,
    MANAGE_FLIGHTS_ROLE,
    MANAGE_ORGANIZATIONS_ROLE,
    MANAGE_USERS_ROLE,
    MANAGE_WORK_ORDERS_ROLE
} from './constants';

const useRoles = () => {
    const { isAuthenticated } = useAuth0();
    const { decodedToken, fetchToken } = useAccessToken();

    useEffect(() => {
        if (isAuthenticated) {
            fetchToken();
        }
    }, [isAuthenticated, fetchToken]);

    const userRoles = decodedToken?.permissions
        ? (decodedToken?.permissions as Array<string>)
        : null;

    const canManageEquipment = userRoles ? userRoles.includes(MANAGE_EQUIPMENT_ROLE) : null;
    const canManageFaaReports = userRoles ? userRoles?.includes(MANAGE_FAA_REPORTS_ROLE) : null;
    const canManageFlights = userRoles ? userRoles?.includes(MANAGE_FLIGHTS_ROLE) : null;
    const canManageOrganizations = userRoles ? userRoles.includes(MANAGE_ORGANIZATIONS_ROLE) : null;
    const canManageUsers = userRoles ? userRoles?.includes(MANAGE_USERS_ROLE) : null;
    const canManageWorkOrders = userRoles ? userRoles?.includes(MANAGE_WORK_ORDERS_ROLE) : null;

    return {
        canManageEquipment,
        canManageFaaReports,
        canManageFlights,
        canManageOrganizations,
        canManageUsers,
        canManageWorkOrders
    };
};

export default useRoles;
