import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('asAppliedMapDetails');

    return {
        APPLIED_RATE: t('appliedRate'),
        APPLIED_TOTAL: t('appliedTotal'),
        AREA_APPLIED: t('areaApplied'),
        CHEMICALS_APPLIED: t('chemicalsApplied'),
        END_TIME: t('endTime'),
        LOCATION: t('location'),
        NA: t('na'),
        START_TIME: t('startTime'),
        UNKNOWN: t('unknown')
    };
};

export default useTranslation;
