import VerticalContainer from 'components/VerticalContainer';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ItemCard: FunctionComponent<Props> = ({ children, className, onClick, testId = TEST_ID }) => (
    <VerticalContainer
        className={`${className} ${styles.itemCard}`}
        onClick={onClick}
        testId={testId}
    >
        {children}
    </VerticalContainer>
);

export default ItemCard;
