const translations = {
    acceptedFileTypes: 'KML, CSV',
    associatedApplicationDate: 'Associated Application Date',
    back: 'Back',
    fileType: 'File Types',
    fileUploads: 'File Uploads',
    saveToFlightLog: 'Save to Flight Log',
    startDateError: 'You must provide a start date and time for the kml file',
    swathWidth: 'Swath Width',
    unknownError: 'Unknown error processing your flight logs try again later.',
    zeroSwathWidth: 'Swath width must be greater than 0.'
};

export default translations;
