import { Button } from '@rantizo-software/rantizo-ui';

import Link from 'components/Link';

import { TEST_ID } from './constants';
import { Props } from './types';

import styles from './styles.module.scss';

const LinkButton = ({ className, onClick, testId = TEST_ID, text }: Props) => (
    <Button className={`${styles.linkButton}  ${className}`} onClick={onClick} testId={testId}>
        <Link>{text}</Link>
    </Button>
);

export default LinkButton;
