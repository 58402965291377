import { useCallback, useMemo } from 'react';

import { round } from '@@utils/math';

import {
    CELSIUS_TO_FAHRENHEIT_MODIFIER,
    CONVERSION_TABLE_TO_AC,
    CONVERSION_TABLE_TO_GA_PER_AC,
    CONVERSION_TABLE_TO_L_PER_M2,
    CONVERSION_TABLE_TO_M2,
    DECIMAL_MINUTES_TO_SECONDS,
    DECIMAL_TO_MINUTES,
    DEGREES_C_ABOVE_K,
    DEGREES_F_ABOVE_C,
    FAHRENHEIT_TO_CELSIUS_MODIFIER,
    FLUID_OUNCES_PER_LITER,
    FLUID_OZ_PER_GALLON,
    LITERS_PER_FLUID_OUNCE,
    LITERS_PER_GALLON,
    LITERS_PER_PINT,
    LITERS_PER_QUART,
    LITERS_PER_SQUARE_METER_TO_LITERS_PER_HECTARE,
    METERS_PER_SECOND_PER_MILES_PER_HOUR,
    METER_PER_FOOT,
    MILLISECONDS_PER_DAY,
    MILLISECONDS_PER_HOUR,
    OUNCES_PER_ACRE_PER_LITER_PER_SQUARE_METER,
    OUNCES_PER_KILO,
    OUNCES_PER_POUND,
    OUNCE_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER,
    POUNDS_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER,
    POUNDS_PER_KILO,
    QUARTS_PER_LITER,
    SQUARE_METERS_PER_ACRE
} from './constants';
import type {
    ConversionMap,
    DegreesDecimalMinutes,
    DegreesMinutesSeconds,
    MassRateUnit,
    MassUnit,
    VolumeRateUnit,
    VolumeUnit,
    WorkOrderAcreageUnit,
    WorkOrderTargetRateUnit
} from './types';

const useConvert = () => {
    const convertAreaToAcres = useCallback(
        (value: number, unit: WorkOrderAcreageUnit) => value * CONVERSION_TABLE_TO_AC[unit],
        []
    );

    const convertDaysAndHoursToMilliseconds = useCallback(
        (days: number | null, hours: number | null) => {
            if (days === null && hours === null) {
                return null;
            }

            if (days === 0 && hours === 0) {
                return 0;
            }

            const daysPortion = days === null ? 0 : days * MILLISECONDS_PER_DAY;
            const hoursPortion = hours === null ? 0 : hours * MILLISECONDS_PER_HOUR;

            return daysPortion + hoursPortion;
        },
        []
    );

    const convertLitersPerSquareMeterToFluidOuncesPerAcre = useCallback(
        (litersPerSquareMeter: number): number =>
            litersPerSquareMeter * (SQUARE_METERS_PER_ACRE / LITERS_PER_FLUID_OUNCE),
        []
    );

    const convertFluidOuncesPerAcreToLitersPerSquareMeter = useCallback(
        (fluidOuncesPerAcre: number): number =>
            fluidOuncesPerAcre * (LITERS_PER_FLUID_OUNCE / SQUARE_METERS_PER_ACRE),
        []
    );

    const convertLitersPerSquareMeterToGallonsPerAcre = useCallback(
        (litersPerSquareMeter: number) =>
            litersPerSquareMeter * (SQUARE_METERS_PER_ACRE / LITERS_PER_GALLON),
        []
    );

    const convertGallonsPerAcreToLiterPerSquareMeter = useCallback(
        (gallonsPerAcre: number) => gallonsPerAcre * (LITERS_PER_GALLON / SQUARE_METERS_PER_ACRE),
        []
    );

    const convertLitersPerSquareMeterToQuartsPerAcre = useCallback(
        (litersPerSquareMeter: number) =>
            litersPerSquareMeter * (SQUARE_METERS_PER_ACRE / LITERS_PER_QUART),
        []
    );

    const convertQuartsPerAcreToLitersPerSquareMeter = useCallback(
        (quartsPerAcre: number) => quartsPerAcre * (LITERS_PER_QUART / SQUARE_METERS_PER_ACRE),
        []
    );

    const convertLitersPerSquareMeterToPintsPerAcre = useCallback(
        (litersPerSquareMeter: number) =>
            litersPerSquareMeter * (SQUARE_METERS_PER_ACRE / LITERS_PER_PINT),
        []
    );

    const convertPintsPerAcreToLitersPerSquareMeter = useCallback(
        (pintPerAcre: number) => pintPerAcre * (LITERS_PER_PINT / SQUARE_METERS_PER_ACRE),
        []
    );

    const convertMetersPerSecondToMilesPerHour = useCallback(
        (value: number) => value * METERS_PER_SECOND_PER_MILES_PER_HOUR,
        []
    );

    const convertMilesPerHourToMetersPerSecond = useCallback(
        (value: number) => value / METERS_PER_SECOND_PER_MILES_PER_HOUR,
        []
    );

    const convertFahrenheitToKelvin = useCallback(
        (value: number) =>
            (value - DEGREES_F_ABOVE_C) * FAHRENHEIT_TO_CELSIUS_MODIFIER + DEGREES_C_ABOVE_K,
        []
    );

    const convertKelvinToFahrenheit = useCallback(
        (value: number) =>
            (value - DEGREES_C_ABOVE_K) * CELSIUS_TO_FAHRENHEIT_MODIFIER + DEGREES_F_ABOVE_C,
        []
    );

    const convertGallonsPerAcreToFlozPerAcre = useCallback(
        (value: number) => value / CONVERSION_TABLE_TO_GA_PER_AC.flozPerAc,
        []
    );

    const convertFlozPerAcreToGallonsPerAcre = useCallback(
        (value: number) => value * CONVERSION_TABLE_TO_GA_PER_AC.flozPerAc,
        []
    );

    const convertLitersPerSquareMeterToLitersPerHectare = useCallback(
        (value: number) => value * LITERS_PER_SQUARE_METER_TO_LITERS_PER_HECTARE,
        []
    );

    const convertMillisecondsToDaysAndHours = useCallback((milliseconds: number) => {
        const days = Math.floor(milliseconds / MILLISECONDS_PER_DAY);
        const millisecondsLeft = milliseconds - days * MILLISECONDS_PER_DAY;
        const hours = millisecondsLeft / MILLISECONDS_PER_HOUR;

        return {
            days: days,
            hours: hours
        };
    }, []);

    const convertMillisecondsToHours = useCallback((milliseconds: number) => {
        const hours = milliseconds / MILLISECONDS_PER_HOUR;

        return hours;
    }, []);

    const convertSquareMetersToAcres = useCallback(
        (value: number) => value * CONVERSION_TABLE_TO_AC['m2'],
        []
    );

    const convertTargetRateToAcresPerGallon = useCallback(
        (value: number, unit: WorkOrderTargetRateUnit) =>
            value * CONVERSION_TABLE_TO_GA_PER_AC[unit],
        []
    );

    const convertLitersPerSquareMeterToOuncesPerAcre = useCallback(
        (value: number) => value * OUNCES_PER_ACRE_PER_LITER_PER_SQUARE_METER,
        []
    );

    const convertToLitersPerSquareMeter = useCallback(
        (value: number, unit: WorkOrderTargetRateUnit) =>
            value * CONVERSION_TABLE_TO_L_PER_M2[unit],
        []
    );

    const convertToSquareMeters = useCallback(
        (value: number, unit: WorkOrderAcreageUnit) => value * CONVERSION_TABLE_TO_M2[unit],
        []
    );

    const convertGallonsToLiters = useCallback((value: number) => value * LITERS_PER_GALLON, []);

    const convertPintsToLiters = useCallback((value: number) => value * LITERS_PER_PINT, []);

    const convertLitersToPints = useCallback((value: number) => value / LITERS_PER_PINT, []);

    const convertFluidOuncesToLiters = useCallback(
        (value: number) => value * FLUID_OUNCES_PER_LITER,
        []
    );

    const convertLitersToFluidOunces = useCallback(
        (value: number) => value / FLUID_OUNCES_PER_LITER,
        []
    );

    const convertQuartsToLiters = useCallback((value: number) => value * QUARTS_PER_LITER, []);
    const convertLitersToQuarts = useCallback((value: number) => value / QUARTS_PER_LITER, []);

    const convertFluidOuncesToGallons = useCallback(
        (value: number) => value / FLUID_OZ_PER_GALLON,
        []
    );
    const convertGallonsToFluidOunces = useCallback(
        (value: number) => value * FLUID_OZ_PER_GALLON,
        []
    );

    const convertLitersToGallons = useCallback((value: number) => value / LITERS_PER_GALLON, []);

    const convertKilogramsToPounds = useCallback((value: number) => value * POUNDS_PER_KILO, []);
    const convertPoundsToKilograms = useCallback((value: number) => value / POUNDS_PER_KILO, []);

    const convertOuncesToKilograms = useCallback((value: number) => value / OUNCES_PER_KILO, []);
    const convertKilogramsToOunces = useCallback((value: number) => value * OUNCES_PER_KILO, []);

    const convertPoundsToOunces = useCallback((value: number) => value * OUNCES_PER_POUND, []);
    const convertOuncesToPounds = useCallback((value: number) => value / OUNCES_PER_POUND, []);

    const convertKilogramsToPreferredUnits = useCallback(
        (kilograms: number, preferredMassUnits: MassUnit) => {
            const conversionMap: Record<string, (mass: number) => number> = {
                KILOGRAMS: (mass: number) => round(mass, 2),
                OUNCES: (mass: number) => round(convertKilogramsToOunces(mass), 2),
                POUNDS: (mass: number) => round(convertKilogramsToPounds(mass), 2)
            };

            const convertFunction = conversionMap[preferredMassUnits];

            if (convertFunction) {
                return convertFunction(kilograms);
            } else {
                return round(kilograms, 2);
            }
        },
        [convertKilogramsToOunces, convertKilogramsToPounds]
    );

    const convertKilogramsPerSquareMeterToPoundsPerAcre = useCallback(
        (value: number) => value * POUNDS_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER,
        []
    );

    const convertKilogramsPerSquareMeterToOuncePerAcre = useCallback(
        (value: number) => value * OUNCE_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER,
        []
    );

    const convertPoundsPerAcreToKilogramsPerSquareMeter = useCallback(
        (value: number) => value / POUNDS_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER,
        []
    );

    const convertOuncePerAcreToKilogramPerSquareMeter = useCallback(
        (value: number) => value / OUNCE_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER,
        []
    );

    const convertKilogramsPerSquareMeterToPreferredUnits = useCallback(
        (kilogramsPerSquareMeter: number, preferredRateUnits: MassRateUnit) => {
            const conversionMap = {
                KILOGRAMS_PER_SQUARE_METER: (rate: number) => round(rate, 2),
                OUNCES_PER_ACRE: (rate: number) =>
                    round(convertKilogramsPerSquareMeterToOuncePerAcre(rate), 2),
                POUNDS_PER_ACRE: (rate: number) =>
                    round(convertKilogramsPerSquareMeterToPoundsPerAcre(rate), 2)
            };

            const convertFunction = conversionMap[preferredRateUnits];

            if (convertFunction) {
                return convertFunction(kilogramsPerSquareMeter);
            } else {
                return round(kilogramsPerSquareMeter, 2);
            }
        },
        [
            convertKilogramsPerSquareMeterToOuncePerAcre,
            convertKilogramsPerSquareMeterToPoundsPerAcre
        ]
    );

    const rateLabels = useMemo(
        () => ({
            FLUID_OUNCES_PER_ACRE: 'Fl. Oz/Ac',
            GALLONS_PER_ACRE: 'Ga/Ac',
            KILOGRAMS_PER_SQUARE_METER: 'Kg/m\u00b2 (Dry)',
            LITERS_PER_HECTARE: 'L/Ha',
            LITERS_PER_SQUARE_METER: 'L/m\u00b2',
            OUNCES_PER_ACRE: 'Oz/Ac (Dry)',
            PINTS_PER_ACRE: 'Pt/Ac',
            POUNDS_PER_ACRE: 'Lb/Ac (Dry)',
            QUARTS_PER_ACRE: 'Qt/Ac'
        }),
        []
    );

    const massLabels = useMemo(
        () => ({
            KILOGRAMS: 'Kg',
            OUNCES: 'Oz',
            POUNDS: 'Lb'
        }),
        []
    );

    const convertPreferredRateToLabel = useCallback(
        (preferredRateUnits: string) => {
            const rateLabelMap: Record<string, string> = {
                FLUID_OUNCES_PER_ACRE: rateLabels.FLUID_OUNCES_PER_ACRE,
                GALLONS_PER_ACRE: rateLabels.GALLONS_PER_ACRE,
                KILOGRAMS_PER_SQUARE_METER: rateLabels.KILOGRAMS_PER_SQUARE_METER,
                LITERS_PER_HECTARE: rateLabels.LITERS_PER_HECTARE,
                LITERS_PER_SQUARE_METER: rateLabels.LITERS_PER_SQUARE_METER,
                OUNCES_PER_ACRE: rateLabels.OUNCES_PER_ACRE,
                PINTS_PER_ACRE: rateLabels.PINTS_PER_ACRE,
                POUNDS_PER_ACRE: rateLabels.POUNDS_PER_ACRE,
                QUARTS_PER_ACRE: rateLabels.QUARTS_PER_ACRE
            };

            return rateLabelMap[preferredRateUnits] || rateLabels.LITERS_PER_SQUARE_METER;
        },
        [
            rateLabels.FLUID_OUNCES_PER_ACRE,
            rateLabels.GALLONS_PER_ACRE,
            rateLabels.KILOGRAMS_PER_SQUARE_METER,
            rateLabels.LITERS_PER_HECTARE,
            rateLabels.LITERS_PER_SQUARE_METER,
            rateLabels.OUNCES_PER_ACRE,
            rateLabels.PINTS_PER_ACRE,
            rateLabels.POUNDS_PER_ACRE,
            rateLabels.QUARTS_PER_ACRE
        ]
    );

    const convertLitersPerSquareMeterToPreferredRate = useCallback(
        (litersPerSquareMeter: number, preferredRateUnits: string) => {
            const conversionMap: Record<string, (rate: number) => number> = {
                FLUID_OUNCES_PER_ACRE: (rate: number) =>
                    round(convertLitersPerSquareMeterToFluidOuncesPerAcre(rate), 2),
                GALLONS_PER_ACRE: (rate: number) =>
                    round(convertLitersPerSquareMeterToGallonsPerAcre(rate), 2),
                LITERS_PER_HECTARE: (rate: number) =>
                    round(convertLitersPerSquareMeterToLitersPerHectare(rate), 2),
                LITERS_PER_SQUARE_METER: (rate: number) => round(rate, 2),
                PINTS_PER_ACRE: (rate: number) =>
                    round(convertLitersPerSquareMeterToPintsPerAcre(rate), 2),
                QUARTS_PER_ACRE: (rate: number) =>
                    round(convertLitersPerSquareMeterToQuartsPerAcre(rate), 2)
            };

            const convertFunction = conversionMap[preferredRateUnits];

            if (convertFunction) {
                return convertFunction(litersPerSquareMeter);
            } else {
                return round(litersPerSquareMeter, 2);
            }
        },
        [
            convertLitersPerSquareMeterToGallonsPerAcre,
            convertLitersPerSquareMeterToLitersPerHectare,
            convertLitersPerSquareMeterToFluidOuncesPerAcre,
            convertLitersPerSquareMeterToPintsPerAcre,
            convertLitersPerSquareMeterToQuartsPerAcre
        ]
    );

    const convertAreaToPreferredUnits = useCallback(
        (areaAcres: number, preferredUnit: string) =>
            ['LITERS_PER_SQUARE_METER', 'KILOGRAMS_PER_SQUARE_METER'].includes(preferredUnit)
                ? convertToSquareMeters(areaAcres ?? 0.0, 'ac')
                : (areaAcres ?? 0.0),
        [convertToSquareMeters]
    );

    const calculateAmountApplied = useCallback(
        (rateApplied: number, rateUnits: string, areaAcres: number) => {
            const totalArea = convertAreaToPreferredUnits(areaAcres, rateUnits);

            return rateApplied * totalArea;
        },
        [convertAreaToPreferredUnits]
    );

    const calculateRateApplied = useCallback(
        (amountApplied: number, rateUnits: string, areaAcres: number) => {
            const totalArea = convertAreaToPreferredUnits(areaAcres, rateUnits);

            return amountApplied / totalArea;
        },
        [convertAreaToPreferredUnits]
    );

    const convertFeetToMeters = useCallback((value: number) => value * METER_PER_FOOT, []);
    const convertMetersToFeet = useCallback((value: number) => value / METER_PER_FOOT, []);

    const convertDecimalDegreesToDegreesDecimalMinutes = useCallback((value: number) => {
        const absoluteValue = Math.abs(value);
        const degrees = Math.floor(absoluteValue);
        const minutes = (absoluteValue - degrees) * DECIMAL_TO_MINUTES;

        return {
            degrees,
            minutes
        } as DegreesDecimalMinutes;
    }, []);

    const convertDecimalDegreesToDegreesMinutesSeconds = useCallback((value: number) => {
        const absoluteValue = Math.abs(value);
        const degrees = Math.floor(absoluteValue);
        const decimalMinutes = (absoluteValue - degrees) * DECIMAL_TO_MINUTES;
        const minutes = Math.floor(decimalMinutes);
        const seconds = (decimalMinutes - minutes) * DECIMAL_MINUTES_TO_SECONDS;

        return {
            degrees,
            minutes,
            seconds
        } as DegreesMinutesSeconds;
    }, []);

    const convertDegreesMinutesSecondsToDecimalDegrees = useCallback(
        (degrees: number, minutes: number, seconds: number) => {
            const decimalMinutes = minutes + seconds / DECIMAL_MINUTES_TO_SECONDS;
            const decimalDegrees = degrees + decimalMinutes / DECIMAL_TO_MINUTES;

            return decimalDegrees;
        },
        []
    );

    const convertDegreesMinutesSecondsToDegreesDecimalMinutes = useCallback(
        (degrees: number, minutes: number, seconds: number) =>
            ({
                degrees,
                minutes: minutes + seconds / DECIMAL_MINUTES_TO_SECONDS
            }) as DegreesDecimalMinutes,
        []
    );

    const convertDegreesDecimalMinutesToDecimalDegrees = useCallback(
        (degrees: number, minutes: number) => degrees + minutes / DECIMAL_TO_MINUTES,
        []
    );

    const convertDegreesDecimalMinutesToDegreesMinutesSeconds = useCallback(
        (degrees: number, minutes: number) => {
            const intMinutes = Math.floor(minutes);
            const seconds = (minutes - intMinutes) * DECIMAL_MINUTES_TO_SECONDS;

            return { degrees, minutes: intMinutes, seconds };
        },
        []
    );

    // DRY MASS RATE CONVERSIONS

    const massRateUnitToKilogramsPerSquareMeterMap: ConversionMap<MassRateUnit> = useMemo(
        () => ({
            KILOGRAMS_PER_SQUARE_METER: (value: number) => value,
            OUNCES_PER_ACRE: (value: number) => convertOuncePerAcreToKilogramPerSquareMeter(value),
            POUNDS_PER_ACRE: (value: number) => convertPoundsPerAcreToKilogramsPerSquareMeter(value)
        }),
        [convertOuncePerAcreToKilogramPerSquareMeter, convertPoundsPerAcreToKilogramsPerSquareMeter]
    );

    const massRateKilogramsPerSquareMeterToRateUnitMap: ConversionMap<MassRateUnit> = useMemo(
        () => ({
            KILOGRAMS_PER_SQUARE_METER: (value: number) => value,
            OUNCES_PER_ACRE: (value: number) => convertKilogramsPerSquareMeterToOuncePerAcre(value),
            POUNDS_PER_ACRE: (value: number) => convertKilogramsPerSquareMeterToPoundsPerAcre(value)
        }),
        [
            convertKilogramsPerSquareMeterToOuncePerAcre,
            convertKilogramsPerSquareMeterToPoundsPerAcre
        ]
    );

    const convertDryMassRate = useCallback(
        (value: number, fromUnit: MassRateUnit, toUnit: MassRateUnit) => {
            const unitToKilogramsPerSquareMeter =
                massRateUnitToKilogramsPerSquareMeterMap[fromUnit];
            const kilogramsPerSquareMeterToUnit =
                massRateKilogramsPerSquareMeterToRateUnitMap[toUnit];

            if (!unitToKilogramsPerSquareMeter || !kilogramsPerSquareMeterToUnit) {
                throw new Error(`Conversion from '${fromUnit}' to '${toUnit}' is not supported`);
            }

            const valueInLiters = unitToKilogramsPerSquareMeter(value);

            return kilogramsPerSquareMeterToUnit(valueInLiters);
        },
        [massRateKilogramsPerSquareMeterToRateUnitMap, massRateUnitToKilogramsPerSquareMeterMap]
    );

    // DRY MASS CONVERSIONS
    const massUnitToKilogramsMap: ConversionMap<MassUnit> = useMemo(
        () => ({
            KILOGRAMS: (value: number) => value,
            OUNCES: (value: number) => convertOuncesToKilograms(value),
            POUNDS: (value: number) => convertPoundsToKilograms(value)
        }),
        [convertOuncesToKilograms, convertPoundsToKilograms]
    );

    const massKilogramsToUnitMap: ConversionMap<MassUnit> = useMemo(
        () => ({
            KILOGRAMS: (value: number) => value,
            OUNCES: (value: number) => convertKilogramsToOunces(value),
            POUNDS: (value: number) => convertKilogramsToPounds(value)
        }),
        [convertKilogramsToOunces, convertKilogramsToPounds]
    );

    const convertDryMass = useCallback(
        (value: number, fromUnit: MassUnit, toUnit: MassUnit) => {
            const unitToKilograms = massUnitToKilogramsMap[fromUnit];
            const kilogramsToUnit = massKilogramsToUnitMap[toUnit];

            if (!unitToKilograms || !kilogramsToUnit) {
                throw new Error(`Conversion from '${fromUnit}' to '${toUnit}' is not supported`);
            }

            const valueInLiters = unitToKilograms(value);

            return kilogramsToUnit(valueInLiters);
        },
        [massKilogramsToUnitMap, massUnitToKilogramsMap]
    );

    // VOLUME RATE CONVERSIONS

    const volumeAreaUnitToLitersPerSquareMeterMap: ConversionMap<VolumeRateUnit> = useMemo(
        () => ({
            FLUID_OUNCES_PER_ACRE: (value: number) =>
                convertFluidOuncesPerAcreToLitersPerSquareMeter(value),
            GALLONS_PER_ACRE: (value: number) => convertGallonsPerAcreToLiterPerSquareMeter(value),
            LITERS_PER_SQUARE_METER: (value: number) => value,
            PINTS_PER_ACRE: (value: number) => convertPintsPerAcreToLitersPerSquareMeter(value),
            QUARTS_PER_ACRE: (value: number) => convertQuartsPerAcreToLitersPerSquareMeter(value)
        }),
        [
            convertFluidOuncesPerAcreToLitersPerSquareMeter,
            convertGallonsPerAcreToLiterPerSquareMeter,
            convertPintsPerAcreToLitersPerSquareMeter,
            convertQuartsPerAcreToLitersPerSquareMeter
        ]
    );

    const volumeAreaLitersPerSquareMeterToUnitMap: ConversionMap<VolumeRateUnit> = useMemo(
        () => ({
            FLUID_OUNCES_PER_ACRE: (value: number) =>
                convertLitersPerSquareMeterToFluidOuncesPerAcre(value),
            GALLONS_PER_ACRE: (value: number) => convertLitersPerSquareMeterToGallonsPerAcre(value),
            LITERS_PER_SQUARE_METER: (value: number) => value,
            PINTS_PER_ACRE: (value: number) => convertLitersPerSquareMeterToPintsPerAcre(value),
            QUARTS_PER_ACRE: (value: number) => convertLitersPerSquareMeterToQuartsPerAcre(value)
        }),
        [
            convertLitersPerSquareMeterToFluidOuncesPerAcre,
            convertLitersPerSquareMeterToPintsPerAcre,
            convertLitersPerSquareMeterToGallonsPerAcre,
            convertLitersPerSquareMeterToQuartsPerAcre
        ]
    );

    const convertLiquidVolumeArea = useCallback(
        (value: number, fromUnit: VolumeRateUnit, toUnit: VolumeRateUnit) => {
            const unitToLitersPerSquareMeter = volumeAreaUnitToLitersPerSquareMeterMap[fromUnit];
            const litersPerSquareMeterToUnit = volumeAreaLitersPerSquareMeterToUnitMap[toUnit];

            if (!unitToLitersPerSquareMeter || !litersPerSquareMeterToUnit) {
                throw new Error(`Conversion from '${fromUnit}' to '${toUnit}' is not supported`);
            }

            const valueInLiters = unitToLitersPerSquareMeter(value);

            return litersPerSquareMeterToUnit(valueInLiters);
        },
        [volumeAreaLitersPerSquareMeterToUnitMap, volumeAreaUnitToLitersPerSquareMeterMap]
    );

    // VOLUME CONVERSIONS
    const volumeUnitToLitersMap: ConversionMap<VolumeUnit> = useMemo(
        () => ({
            FLUID_OUNCES: (value: number) => convertFluidOuncesToLiters(value),
            GALLONS: (value: number) => convertGallonsToLiters(value),
            LITERS: (value: number) => value,
            PINTS: (value: number) => convertPintsToLiters(value),
            QUARTS: (value: number) => convertQuartsToLiters(value)
        }),
        [
            convertFluidOuncesToLiters,
            convertGallonsToLiters,
            convertPintsToLiters,
            convertQuartsToLiters
        ]
    );

    const volumeLitersToUnitMap: ConversionMap<VolumeUnit> = useMemo(
        () => ({
            FLUID_OUNCES: (value: number) => convertLitersToFluidOunces(value),
            GALLONS: (value: number) => convertLitersToGallons(value),
            LITERS: (value: number) => value,
            PINTS: (value: number) => convertLitersToPints(value),
            QUARTS: (value: number) => convertLitersToQuarts(value)
        }),
        [
            convertLitersToFluidOunces,
            convertLitersToGallons,
            convertLitersToPints,
            convertLitersToQuarts
        ]
    );

    const convertLiquidVolume = useCallback(
        (value: number, fromUnit: VolumeUnit, toUnit: VolumeUnit) => {
            const unitToLiters = volumeUnitToLitersMap[fromUnit];
            const litersToUnit = volumeLitersToUnitMap[toUnit];

            if (!unitToLiters || !litersToUnit) {
                throw new Error(`Conversion from '${fromUnit}' to '${toUnit}' is not supported`);
            }

            const valueInLiters = unitToLiters(value);

            return litersToUnit(valueInLiters);
        },
        [volumeLitersToUnitMap, volumeUnitToLitersMap]
    );

    return {
        calculateAmountApplied,
        calculateRateApplied,
        convertAreaToAcres,
        convertAreaToPreferredUnits,
        convertDaysAndHoursToMilliseconds,
        convertDecimalDegreesToDegreesDecimalMinutes,
        convertDecimalDegreesToDegreesMinutesSeconds,
        convertDegreesDecimalMinutesToDecimalDegrees,
        convertDegreesDecimalMinutesToDegreesMinutesSeconds,
        convertDegreesMinutesSecondsToDecimalDegrees,
        convertDegreesMinutesSecondsToDegreesDecimalMinutes,
        convertDryMass,
        convertDryMassRate,
        convertFahrenheitToKelvin,
        convertFeetToMeters,
        convertFlozPerAcreToGallonsPerAcre,
        convertFluidOuncesToGallons,
        convertGallonsPerAcreToFlozPerAcre,
        convertGallonsPerAcreToLiterPerSquareMeter,
        convertGallonsToFluidOunces,
        convertGallonsToLiters,
        convertKelvinToFahrenheit,
        convertKilogramsPerSquareMeterToOuncePerAcre,
        convertKilogramsPerSquareMeterToPoundsPerAcre,
        convertKilogramsPerSquareMeterToPreferredUnits,
        convertKilogramsToOunces,
        convertKilogramsToPounds,
        convertKilogramsToPreferredUnits,
        convertLiquidVolume,
        convertLiquidVolumeArea,
        convertLitersPerSquareMeterToGallonsPerAcre,
        convertLitersPerSquareMeterToLitersPerHectare,
        convertLitersPerSquareMeterToOuncesPerAcre,
        convertLitersPerSquareMeterToPintsPerAcre,
        convertLitersPerSquareMeterToPreferredRate,
        convertLitersPerSquareMeterToQuartsPerAcre,
        convertLitersToGallons,
        convertMetersPerSecondToMilesPerHour,
        convertMetersToFeet,
        convertMilesPerHourToMetersPerSecond,
        convertMillisecondsToDaysAndHours,
        convertMillisecondsToHours,
        convertOuncePerAcreToKilogramPerSquareMeter,
        convertOuncesToKilograms,
        convertOuncesToPounds,
        convertPoundsPerAcreToKilogramsPerSquareMeter,
        convertPoundsToKilograms,
        convertPoundsToOunces,
        convertPreferredRateToLabel,
        convertSquareMetersToAcres,
        convertTargetRateToAcresPerGallon,
        convertToLitersPerSquareMeter,
        convertToSquareMeters,
        massLabels
    };
};

export default useConvert;
