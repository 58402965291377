import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('organizationList');

    return {
        ERROR: t('error')
    };
};

export default useTranslation;
