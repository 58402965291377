import { Field, Formik } from 'formik';
import type { InferType } from 'yup';

import FormContainer from 'components/FormContainer';
import FormSelectControllerMakes from 'components/FormSelectControllerMakes';
import FormSelectControllerModels from 'components/FormSelectControllerModels';
import OneColumnContainer from 'components/OneColumnContainer';
import PageContainer from 'components/PageContainer';
import PageTitle from 'components/PageTitle';
import SubmitButton from 'components/SubmitButton';
import VerticalContainer from 'components/VerticalContainer';
import WithBackArrow from 'components/WithBackArrow';
import FormInput from 'components/deprecating/FormInput';

import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import {
    MAKE_FIELD_TEST_ID,
    MODEL_FIELD_TEST_ID,
    NICKNAME_FIELD_TEST_ID,
    SERIAL_NUMBER_FIELD_TEST_ID,
    TEST_ID
} from './constants';
import type { FormikProps, FunctionComponent, Props } from './types';

const ControllerForm: FunctionComponent<Props> = ({
    initialValues,
    isExisting = false,
    isLoading,
    onBack,
    onSubmit,
    testId = TEST_ID,
    title
}) => {
    const controllerSchema = useSchema();
    const { BUTTON, PLACEHOLDERS } = useTranslation();

    type ControllerSchemaType = InferType<typeof controllerSchema>;

    return (
        <Formik
            data-testid={testId}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={controllerSchema}
        >
            {({ isSubmitting, values }: FormikProps<ControllerSchemaType>) => (
                <FormContainer>
                    <PageContainer testId={testId}>
                        <VerticalContainer>
                            {title && (
                                <WithBackArrow onClick={onBack}>
                                    <PageTitle text={title} />
                                </WithBackArrow>
                            )}

                            <OneColumnContainer>
                                <Field
                                    component={FormInput}
                                    name="nickname"
                                    placeholder={PLACEHOLDERS.NICKNAME}
                                    testId={NICKNAME_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormSelectControllerMakes}
                                    disabled={isExisting}
                                    name="make"
                                    placeholder={PLACEHOLDERS.MAKE}
                                    testId={MAKE_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormSelectControllerModels}
                                    controllerMake={values.make}
                                    disabled={isExisting || values.make === ''}
                                    name="model"
                                    placeholder={PLACEHOLDERS.MODEL}
                                    testId={MODEL_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormInput}
                                    disabled={isExisting}
                                    name="serialNumber"
                                    placeholder={PLACEHOLDERS.SERIAL_NUMBER}
                                    testId={SERIAL_NUMBER_FIELD_TEST_ID}
                                    type="text"
                                />
                            </OneColumnContainer>
                        </VerticalContainer>

                        <SubmitButton
                            isDisabled={isSubmitting || isLoading}
                            isLoading={isSubmitting || isLoading}
                            text={BUTTON}
                        />
                    </PageContainer>
                </FormContainer>
            )}
        </Formik>
    );
};

export default ControllerForm;
