import copyrightSymbol from '@@assets/copyright-logo.png';
import { Image, View } from '@react-pdf/renderer';

import SubtleText from 'components/pdf/SubtleText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

import styles from './styles';

const Copyright: FunctionComponent = () => {
    const { COPYRIGHT_LEFT, COPYRIGHT_RIGHT } = useTranslation();

    /* 
    React PDF has many known bugs regarding fonts, one of which being
    broken unicode characters, such as described in the GitHub issue below.
    In lieu of better unicode support and support for woff2 font sources, 
    we are "hacking" this to use a copyright symbol png
    
    https://github.com/diegomura/react-pdf/issues/2277

    Update this to unicode when React PDF has better font support
  */
    return (
        <View style={styles.copyright}>
            <SubtleText text={COPYRIGHT_LEFT} />

            <Image src={copyrightSymbol} style={styles.copyrightSymbol} />

            <SubtleText text={COPYRIGHT_RIGHT} />
        </View>
    );
};

export default Copyright;
