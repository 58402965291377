import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const TableColumn: FunctionComponent<Props> = ({ children, style, withDivider = true }) => (
    <View style={[styles.tableColumn, style, withDivider ? styles.columnDivider : {}]}>
        {children}
    </View>
);

export default TableColumn;
