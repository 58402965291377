import { AAM_MIN_START } from 'config';
import { format } from 'date-fns';
import { useCallback } from 'react';

import { constructUtcDateTime } from '@@utils/dates';
import { round } from '@@utils/math';
import { generatePagedQuery } from '@@utils/string';

import useConvert from 'hooks/useConvert';
import useCurrentTime from 'hooks/useCurrentTime';
import useFetch from 'hooks/useFetch';

import { PRODUCT_USAGE_REPORT_DRAFT_API, WORK_ORDER_API } from './constants';
import {
    ApplicationSite,
    ChemicalApplied,
    ChemicalAppliedForm,
    ChemicalAppliedPayload,
    ConversionFunction,
    ConversionMap,
    DryChemicalApplied,
    DryChemicalAppliedPayload,
    ExpandedVolumeRateUnit,
    MassRateUnit,
    MassUnit,
    ProductUsageReportDraft,
    ProductUsageReportDraftApiResponse,
    ProductUsageReportDraftResponse,
    ProductUsageReportDraftWithId,
    ProductUsageReportForm,
    VolumeRateUnit,
    VolumeUnit
} from './types';

const useProductUsageReportDrafts = () => {
    const {
        convertAreaToAcres,
        convertFahrenheitToKelvin,
        convertGallonsToLiters,
        convertKelvinToFahrenheit,
        convertKilogramsPerSquareMeterToOuncePerAcre,
        convertKilogramsPerSquareMeterToPoundsPerAcre,
        convertKilogramsToOunces,
        convertKilogramsToPounds,
        convertLiquidVolume,
        convertLiquidVolumeArea,
        convertLitersPerSquareMeterToGallonsPerAcre,
        convertLitersPerSquareMeterToLitersPerHectare,
        convertLitersPerSquareMeterToOuncesPerAcre,
        convertLitersToGallons,
        convertMetersPerSecondToMilesPerHour,
        convertMilesPerHourToMetersPerSecond,
        convertOuncePerAcreToKilogramPerSquareMeter,
        convertOuncesToKilograms,
        convertPoundsPerAcreToKilogramsPerSquareMeter,
        convertPoundsToKilograms,
        convertToLitersPerSquareMeter,
        convertToSquareMeters
    } = useConvert();

    const { authenticatedDelete, authenticatedGet, authenticatedPost, authenticatedPut } =
        useFetch();

    const { getCurrentTime, getCurrentTimeZone } = useCurrentTime();

    const createDryChemicalAppliedPayload = useCallback(
        (chemicalsApplied: ChemicalAppliedForm[]): DryChemicalAppliedPayload[] =>
            chemicalsApplied.reduce((acc, chem) => {
                const amountApplied = chem.amountApplied ?? 0;
                const rateApplied = chem.rateApplied ?? 0;

                const conversionMap: ConversionMap<MassUnit> = {
                    KILOGRAMS: amount => amount,
                    OUNCES: convertOuncesToKilograms,
                    POUNDS: convertPoundsToKilograms
                };

                const convertFunction: ConversionFunction =
                    conversionMap[chem.preferredAmountUnits as MassUnit];

                if (!convertFunction) {
                    return acc;
                }

                const massAppliedKilograms: number = convertFunction(amountApplied);

                const rateConversionMap: ConversionMap<MassRateUnit> = {
                    KILOGRAMS_PER_SQUARE_METER: (rate: number) => rate,
                    OUNCES_PER_ACRE: convertOuncePerAcreToKilogramPerSquareMeter,
                    POUNDS_PER_ACRE: convertPoundsPerAcreToKilogramsPerSquareMeter
                };

                const rateConvertFunction: ConversionFunction =
                    rateConversionMap[chem.preferredRateUnits as MassRateUnit];

                if (!rateConvertFunction) {
                    return acc;
                }

                const rateAppliedKilogramPerSquareMeter: number = rateConvertFunction(rateApplied);

                acc.push({
                    chemicalId: chem.chemicalId,
                    massAppliedKilograms: massAppliedKilograms,
                    preferredMassUnits: chem.preferredAmountUnits as MassUnit,
                    preferredRateUnits: chem.preferredRateUnits as MassRateUnit,
                    rateAppliedKilogramPerSquareMeter: rateAppliedKilogramPerSquareMeter
                });

                return acc;
            }, [] as DryChemicalAppliedPayload[]),
        [
            convertOuncePerAcreToKilogramPerSquareMeter,
            convertOuncesToKilograms,
            convertPoundsPerAcreToKilogramsPerSquareMeter,
            convertPoundsToKilograms
        ]
    );

    const createChemicalAppliedPayload = useCallback(
        (chemicalsApplied: ChemicalAppliedForm[]): ChemicalAppliedPayload[] =>
            chemicalsApplied.reduce((acc, chem) => {
                const amountApplied = chem.amountApplied ?? 0;
                const rateApplied = chem.rateApplied ?? 0;
                const amountConversionMap: ConversionMap<VolumeUnit> = {
                    FLUID_OUNCES: (amount: number) =>
                        convertLiquidVolume(amount, 'FLUID_OUNCES', 'LITERS'),
                    GALLONS: convertGallonsToLiters,
                    LITERS: (amount: number) => amount,
                    PINTS: (amount: number) => convertLiquidVolume(amount, 'PINTS', 'LITERS'),
                    QUARTS: (amount: number) => convertLiquidVolume(amount, 'QUARTS', 'LITERS')
                };

                const amountConvertFunction =
                    amountConversionMap[chem.preferredAmountUnits as VolumeUnit];

                if (!amountConvertFunction) {
                    return acc;
                }

                const volumeAppliedLiters = amountConvertFunction(amountApplied);

                const rateConversionMap: ConversionMap<ExpandedVolumeRateUnit> = {
                    FLUID_OUNCES_PER_ACRE: (rate: number) =>
                        convertToLitersPerSquareMeter(rate, 'flozPerAc'),
                    GALLONS_PER_ACRE: (rate: number) =>
                        convertToLitersPerSquareMeter(rate, 'gaPerAc'),
                    LITERS_PER_ACRE: (rate: number) => rate,
                    LITERS_PER_HECTARE: (rate: number) =>
                        convertToLitersPerSquareMeter(rate, 'lPerHa'),
                    LITERS_PER_SQUARE_METER: (rate: number) => rate,
                    PINTS_PER_ACRE: (rate: number) =>
                        convertLiquidVolumeArea(rate, 'PINTS_PER_ACRE', 'LITERS_PER_SQUARE_METER'),
                    QUARTS_PER_ACRE: (rate: number) =>
                        convertLiquidVolumeArea(rate, 'QUARTS_PER_ACRE', 'LITERS_PER_SQUARE_METER')
                };

                const rateConvertFunction =
                    rateConversionMap[chem.preferredRateUnits as ExpandedVolumeRateUnit];

                if (!rateConvertFunction) {
                    return acc;
                }

                const rateAppliedLitersPerSquareMeter = rateConvertFunction(rateApplied);

                acc.push({
                    chemicalId: chem.chemicalId,
                    preferredRateUnits: chem.preferredRateUnits as VolumeRateUnit,
                    preferredVolumeUnits: chem.preferredAmountUnits as VolumeUnit,
                    rateAppliedLitersPerSquareMeter: rateAppliedLitersPerSquareMeter,
                    volumeAppliedLiters: volumeAppliedLiters
                });

                return acc;
            }, [] as ChemicalAppliedPayload[]),
        [
            convertGallonsToLiters,
            convertLiquidVolume,
            convertLiquidVolumeArea,
            convertToLitersPerSquareMeter
        ]
    );

    const createPayloadFromForm = useCallback(
        (form: ProductUsageReportForm) => {
            const {
                applicationDate,
                applicationSites,
                carrier,
                chemicalsApplied,
                droneId,
                endTime,
                endTimeTimeZone,
                notes,
                startTime,
                startTimeTimeZone,
                temperature,
                totalAreaAppliedAcres,
                windDirection,
                windGustSpeed,
                windSpeed
            } = form;

            const totalAreaAppliedSquareMeters = totalAreaAppliedAcres
                ? convertToSquareMeters(totalAreaAppliedAcres, 'ac')
                : null;

            const chemicalsAppliedPayload = createChemicalAppliedPayload(chemicalsApplied);
            const dryChemicalsAppliedPayload = createDryChemicalAppliedPayload(chemicalsApplied);

            const applicationStartDate = applicationDate
                ? constructUtcDateTime(
                      applicationDate as Date,
                      startTime,
                      startTimeTimeZone,
                      new Date(AAM_MIN_START)
                  )
                : null;

            const applicationEndDate =
                applicationDate && endTime
                    ? constructUtcDateTime(
                          applicationDate as Date,
                          endTime as string,
                          endTimeTimeZone,
                          new Date(AAM_MIN_START)
                      )
                    : null;

            const windSpeedMetersPerSecond =
                windSpeed !== null
                    ? convertMilesPerHourToMetersPerSecond(windSpeed as number)
                    : null;

            const windGustSpeedMetersPerSecond =
                windGustSpeed !== null
                    ? convertMilesPerHourToMetersPerSecond(windGustSpeed as number)
                    : null;

            const airTemperatureKelvin =
                temperature !== null ? convertFahrenheitToKelvin(temperature as number) : null;

            const draft: ProductUsageReportDraft = {
                airTemperatureKelvin,
                applicationEndDate,
                applicationSites,
                applicationStartDate,
                carrier,
                chemicalsApplied: chemicalsAppliedPayload,
                droneId,
                dryChemicalsApplied: dryChemicalsAppliedPayload,
                notes,
                totalAreaAppliedSquareMeters,
                windDirection,
                windGustSpeedMetersPerSecond,
                windSpeedMetersPerSecond
            };

            return draft;
        },
        [
            convertToSquareMeters,
            createChemicalAppliedPayload,
            createDryChemicalAppliedPayload,
            convertMilesPerHourToMetersPerSecond,
            convertFahrenheitToKelvin
        ]
    );

    const updateProductUsageReportDraftFromForm = useCallback(
        async (form: ProductUsageReportForm, draftId: string) => {
            const draft = createPayloadFromForm(form);

            const { data, error } = await authenticatedPut(
                `${PRODUCT_USAGE_REPORT_DRAFT_API}/${draftId}`,
                JSON.stringify(draft)
            );

            if (error) {
                console.log(error);
                throw Error(JSON.stringify(error));
            }

            return data as ProductUsageReportDraftWithId;
        },
        [authenticatedPut, createPayloadFromForm]
    );

    const createProductUsageReportDraftFromForm = useCallback(
        async (form: ProductUsageReportForm, workOrderId: string) => {
            const draft = createPayloadFromForm(form);

            const { data, error } = await authenticatedPost(
                `${WORK_ORDER_API}/${workOrderId}/product-usage-report-drafts`,
                JSON.stringify(draft)
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data as ProductUsageReportDraftWithId;
        },
        [authenticatedPost, createPayloadFromForm]
    );

    const fetchProductUsageReportDraft = useCallback(
        async (id: string) => {
            const { data, error } = await authenticatedGet(
                `${PRODUCT_USAGE_REPORT_DRAFT_API}/${id}`
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedGet]
    );

    const getTimeFromDate = useCallback((date: Date) => format(date, 'HH:mm') + ':00', []);

    const deleteProductUsageReportDraft = useCallback(
        async (id: string) => {
            const { data, error } = await authenticatedDelete(
                `${PRODUCT_USAGE_REPORT_DRAFT_API}/${id}`
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedDelete]
    );

    const chemicalAppliedFormValuesFromDryChemicals = useCallback(
        (chemicalsApplied: DryChemicalApplied[]) =>
            chemicalsApplied.map((chem: DryChemicalApplied) => {
                const massConversionMap: Record<string, (mass: number) => number> = {
                    KILOGRAMS: (mass: number) => mass,
                    OUNCES: (mass: number) => round(convertKilogramsToOunces(mass), 2),
                    POUNDS: (mass: number) => round(convertKilogramsToPounds(mass), 2)
                };

                const massConvertFunction = massConversionMap[chem.preferredMassUnits];

                const massApplied = massConvertFunction?.(chem.massAppliedKilograms) ?? 0.0;

                const rateConversionMap: Record<string, (rate: number) => number> = {
                    KILOGRAMS_PER_SQUARE_METER: (rate: number) => rate,
                    OUNCES_PER_ACRE: (rate: number) =>
                        round(convertKilogramsPerSquareMeterToOuncePerAcre(rate), 2),
                    POUNDS_PER_ACRE: (rate: number) =>
                        round(convertKilogramsPerSquareMeterToPoundsPerAcre(rate), 2)
                };

                const rateConvertFunction = rateConversionMap[chem.preferredRateUnits];

                const rateApplied =
                    rateConvertFunction?.(chem.rateAppliedKilogramPerSquareMeter) ?? 0.0;

                return {
                    amountApplied: massApplied,
                    chemicalId: chem.chemical.id,
                    label: chem.chemical.labelName,
                    preferredAmountUnits: chem.preferredMassUnits,
                    preferredRateUnits: chem.preferredRateUnits,
                    rateApplied: rateApplied
                } as ChemicalAppliedForm;
            }),
        [
            convertKilogramsPerSquareMeterToOuncePerAcre,
            convertKilogramsPerSquareMeterToPoundsPerAcre,
            convertKilogramsToOunces,
            convertKilogramsToPounds
        ]
    );

    const chemicalAppliedFormValuesFromChemicals = useCallback(
        (chemicalsApplied: ChemicalApplied[]) =>
            chemicalsApplied.map((chem: ChemicalApplied) => {
                const volumeAppliedConversionMap: Record<string, (volume: number) => number> = {
                    GALLONS: volume => round(convertLitersToGallons(volume), 2),
                    LITERS: volume => volume
                };

                const volumeAppliedConversionFunction =
                    volumeAppliedConversionMap[chem.preferredVolumeUnits];

                const volumeApplied =
                    volumeAppliedConversionFunction?.(chem.volumeAppliedLiters) ?? 0;

                const rateAppliedConversionMap: Record<string, (rate: number) => number> = {
                    FLUID_OUNCES_PER_ACRE: rate =>
                        round(convertLitersPerSquareMeterToOuncesPerAcre(rate), 2),

                    GALLONS_PER_ACRE: rate =>
                        round(convertLitersPerSquareMeterToGallonsPerAcre(rate), 2),

                    LITERS_PER_HECTARE: rate =>
                        round(convertLitersPerSquareMeterToLitersPerHectare(rate), 2),
                    LITERS_PER_SQUARE_METER: rate => rate
                };

                const rateAppliedConversionFunction =
                    rateAppliedConversionMap[chem.preferredRateUnits];

                const rateApplied =
                    rateAppliedConversionFunction?.(chem.rateAppliedLitersPerSquareMeter) ?? 0;

                return {
                    amountApplied: volumeApplied,
                    chemicalId: chem.chemical.id,
                    label: chem.chemical.labelName,
                    preferredAmountUnits: chem.preferredVolumeUnits,
                    preferredRateUnits: chem.preferredRateUnits,
                    rateApplied: rateApplied
                } as ChemicalAppliedForm;
            }),
        [
            convertLitersPerSquareMeterToOuncesPerAcre,
            convertLitersPerSquareMeterToGallonsPerAcre,
            convertLitersPerSquareMeterToLitersPerHectare,
            convertLitersToGallons
        ]
    );

    const fetchProductUsageReportForm = useCallback(
        (productUsageReport: ProductUsageReportDraftResponse) => {
            const {
                airTemperatureKelvin,
                applicationEndDate,
                applicationSites,
                applicationStartDate,
                carrier,
                chemicalsApplied,
                droneId,
                dryChemicalsApplied,
                notes,
                totalAreaAppliedSquareMeters,
                windDirection,
                windGustSpeedMetersPerSecond,
                windSpeedMetersPerSecond
            } = productUsageReport;

            const totalAreaAppliedAcres = totalAreaAppliedSquareMeters
                ? round(convertAreaToAcres(totalAreaAppliedSquareMeters, 'm2'), 2)
                : null;

            const appliedChemicals = chemicalAppliedFormValuesFromChemicals(chemicalsApplied);
            const dryChemicals = chemicalAppliedFormValuesFromDryChemicals(dryChemicalsApplied);

            const allChemicals = [...appliedChemicals, ...dryChemicals];

            const startTime = applicationStartDate
                ? getTimeFromDate(new Date(applicationStartDate))
                : getCurrentTime();
            const endTime = applicationEndDate
                ? getTimeFromDate(new Date(applicationEndDate))
                : null;

            const windSpeed =
                windSpeedMetersPerSecond !== null
                    ? convertMetersPerSecondToMilesPerHour(windSpeedMetersPerSecond)
                    : null;

            const windGustSpeed =
                windGustSpeedMetersPerSecond !== null
                    ? convertMetersPerSecondToMilesPerHour(windGustSpeedMetersPerSecond)
                    : null;

            const airTemperature =
                airTemperatureKelvin !== null
                    ? convertKelvinToFahrenheit(airTemperatureKelvin)
                    : null;

            const applicationSiteIds = applicationSites.map((site: ApplicationSite) => site.id);

            return {
                applicationDate: applicationStartDate ? new Date(applicationStartDate) : null,
                applicationSites: applicationSiteIds,
                carrier: carrier,
                chemicalsApplied: allChemicals,
                droneId: droneId,
                endTime: endTime,
                endTimeTimeZone: getCurrentTimeZone,
                notes: notes,
                startTime: startTime,
                startTimeTimeZone: getCurrentTimeZone,
                temperature: airTemperature,
                temperatureUnit: '\u00b0F',
                totalAreaAppliedAcres: totalAreaAppliedAcres,
                windDirection: windDirection,
                windGustSpeed: windGustSpeed,
                windGustSpeedUnit: 'MPH',
                windSpeed: windSpeed,
                windSpeedUnit: 'MPH'
            } as ProductUsageReportForm;
        },
        [
            chemicalAppliedFormValuesFromChemicals,
            chemicalAppliedFormValuesFromDryChemicals,
            convertAreaToAcres,
            convertKelvinToFahrenheit,
            convertMetersPerSecondToMilesPerHour,
            getCurrentTime,
            getCurrentTimeZone,
            getTimeFromDate
        ]
    );

    const fetchProductUsageReportDraftForm = useCallback(
        async (id: string) => {
            const draft = await fetchProductUsageReportDraft(id);

            return fetchProductUsageReportForm(draft);
        },
        [fetchProductUsageReportDraft, fetchProductUsageReportForm]
    );

    const fetchProductUsageReportDrafts = useCallback(
        async (pageToken?: string, pageSize = 10) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const uri = `${PRODUCT_USAGE_REPORT_DRAFT_API}?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri, null);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as ProductUsageReportDraftApiResponse;
        },
        [authenticatedGet]
    );

    return {
        createPayloadFromForm,
        createProductUsageReportDraftFromForm,
        deleteProductUsageReportDraft,
        fetchProductUsageReportDraftForm,
        fetchProductUsageReportDrafts,
        fetchProductUsageReportForm,
        updateProductUsageReportDraftFromForm
    };
};

export default useProductUsageReportDrafts;
