import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import VerticalContainer from 'components/VerticalContainer';
import SelectableCard from 'components/form/SelectableCard';

import useTranslate from './hooks/useTranslation';

import MultiSelectCardsHeader from './components/MultiSelectCardsHeader';
import { TEST_ID } from './constants';
import type { FunctionComponent, Props, SelectableValue } from './types';

import styles from './styles.module.scss';

const MultiSelectCards: FunctionComponent<Props> = ({
    isEditable,
    onSelectionChange,
    testId = TEST_ID,
    title,
    valueToComponentMap,
    values
}) => {
    const selectedValuesRef = useRef<SelectableValue[]>(
        values.filter(({ isSelected }) => isSelected)
    );

    const [numSelected, setNumSelected] = useState<number>(
        values.filter(({ isSelected }) => isSelected).length
    );

    useEffect(() => {
        setNumSelected(values.filter(({ isSelected }) => isSelected).length);
        selectedValuesRef.current = values.filter(({ isSelected }) => isSelected);
    }, [values]);

    const { SELECTED } = useTranslate();

    const updateSelections = useCallback(() => {
        onSelectionChange(selectedValuesRef.current);
        setNumSelected(selectedValuesRef.current.length);
    }, [onSelectionChange]);

    const onDeselect = useCallback(
        (deselectValue: unknown) => {
            selectedValuesRef.current = selectedValuesRef.current.filter(
                selectedValue => selectedValue !== deselectValue
            );
            updateSelections();
        },
        [updateSelections]
    );

    const onSelect = useCallback(
        (selectValue: unknown) => {
            selectedValuesRef.current = [...selectedValuesRef.current, selectValue];
            updateSelections();
        },
        [updateSelections]
    );

    const selectableCards = useMemo(() => {
        let selectedIndex = 0;

        return values.map((value, index) => {
            const displayCard = valueToComponentMap(value, index, selectedIndex);

            if (value.isSelected) {
                selectedIndex++;
            }

            if (!isEditable && !value.isSelected) {
                return null;
            }

            return (
                <SelectableCard
                    isDisabled={value.isDisabled}
                    isEditable={isEditable}
                    isSelected={value.isSelected}
                    key={JSON.stringify(value)}
                    onDeselect={onDeselect}
                    onSelect={value.isDisabled ? undefined : onSelect}
                    value={value}
                >
                    {displayCard}
                </SelectableCard>
            );
        });
    }, [values, valueToComponentMap, onDeselect, onSelect, isEditable]);

    return (
        <VerticalContainer className={styles.multiSelectContainer} testId={testId}>
            {isEditable && (
                <MultiSelectCardsHeader
                    selectedText={SELECTED(numSelected, values.length)}
                    title={title}
                />
            )}

            {selectableCards}
        </VerticalContainer>
    );
};

export default MultiSelectCards;
