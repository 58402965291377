import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useAccount from 'hooks/useAccount';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useGlobalStore from 'hooks/useGlobalStore';

const useLogout = () => {
    const { logout: logoutAuth0 } = useAuth0();
    const { setCurrentOrganization } = useCurrentOrganization();
    const { saveMyUserToStore } = useAccount();
    const { getValue, setValue } = useGlobalStore({ namespace: 'useLogout' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveIsLoggingOutToStore = useCallback(setValue('isLoggingOut'), []);

    const logout = useCallback(() => {
        setCurrentOrganization(undefined);
        saveMyUserToStore({ data: undefined, loading: false });
        saveIsLoggingOutToStore(true);
        logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
    }, [logoutAuth0, saveIsLoggingOutToStore, saveMyUserToStore, setCurrentOrganization]);

    return {
        isLoggingOut: getValue('isLoggingOut') as boolean,
        logout
    };
};

export default useLogout;
