import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import HorizontalContainer from 'components/HorizontalContainer';
import ItemContainer from 'components/ItemContainer';
import ListText from 'components/ListText';
import SmallText from 'components/SmallText';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ConnectionListItem: FunctionComponent<Props> = ({
    description,
    icon,
    id,
    name,
    onClick,
    testId = TEST_ID
}) => {
    const { CONNECT } = useTranslation();

    return (
        <ItemContainer className={styles.flightLogListItem} testId={testId}>
            <HorizontalContainer className={styles.horizontalContainer}>
                {icon}

                <HorizontalContainer className={styles.nestedText}>
                    <ListText className={styles.listItemText} text={name} />

                    <SmallText text={description} />
                </HorizontalContainer>

                <PrimaryButton
                    className={styles.primaryButton}
                    onClick={() => onClick({ id })}
                    text={CONNECT}
                />
            </HorizontalContainer>
        </ItemContainer>
    );
};

export default ConnectionListItem;
