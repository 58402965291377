import { Position } from 'iconoir-react';

import MapButton from 'components/Mapbox/components/MapButton';

import { FunctionComponent, Props } from './types';

const RecenterControl: FunctionComponent<Props> = ({ onClick }) => (
    <MapButton onClick={onClick}>
        {
            // @ts-expect-error implementation will later be changed}
            <Position strokeWidth={2} />
        }
    </MapButton>
);

export default RecenterControl;
