import VerticalContainer from 'components/VerticalContainer';
import WorkOrderReadOnlyLabel from 'components/WorkOrderReadOnlyLabel';
import WorkOrderReadOnlyText from 'components/WorkOrderReadOnlyText';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WorkOrderLabelValuePair: FunctionComponent<Props> = ({
    children,
    label,
    testId = TEST_ID,
    value
}) => (
    <VerticalContainer className={styles.workOrderLabelValuePairContainer} testId={testId}>
        <WorkOrderReadOnlyLabel text={label} />

        <WorkOrderReadOnlyText text={value} />

        {children}
    </VerticalContainer>
);

export default WorkOrderLabelValuePair;
