import type { Style } from './types';

const styles: Record<string, Style> = {
    copyright: {
        alignSelf: 'center',
        flexDirection: 'row'
    },
    copyrightSymbol: {
        alignSelf: 'center',
        height: '8pt',
        margin: '0 2pt',
        width: '8pt'
    }
};

export default styles;
