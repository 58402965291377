import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogListTabs');

    return {
        FLIGHTS: t('flights'),
        GROUPS: t('groups')
    };
};

export default useTranslation;
