import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('editUserDetailsPage');

    return {
        BUTTON: t('button'),
        TITLE: t('title')
    };
};

export default useTranslation;
