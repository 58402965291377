import { useCallback } from 'react';

import useFlightReport from 'hooks/useFlightReport';

import useTranslation from './hooks/useTranslation';

import { UseFlightReport } from './types';

const useFlightReportDelete: UseFlightReport = ({ droneReportId, faaReportId }) => {
    const { DELETE_FLIGHT_REPORT_ERROR } = useTranslation();

    const { deleteFlightReport: deleteFlightReportFromApi } = useFlightReport();

    const deleteFlightReport = useCallback(
        async (flightReportId: string) => {
            try {
                return await deleteFlightReportFromApi(faaReportId, droneReportId, flightReportId);
            } catch (error) {
                console.error(
                    DELETE_FLIGHT_REPORT_ERROR({ flightReportIds: flightReportId }),
                    error
                );
            }
        },

        [DELETE_FLIGHT_REPORT_ERROR, deleteFlightReportFromApi, droneReportId, faaReportId]
    );

    const deleteFlightReports = useCallback(
        async ({ flightReportIds }: { flightReportIds: string[] }) => {
            const successfullyDeletedIds: string[] = [];
            const failedDeletionIds: string[] = [];

            for (const flightReportId of flightReportIds) {
                if (await deleteFlightReport(flightReportId)) {
                    successfullyDeletedIds.push(flightReportId);
                } else {
                    failedDeletionIds.push(flightReportId);
                }
            }

            if (failedDeletionIds.length > 0) {
                alert(
                    DELETE_FLIGHT_REPORT_ERROR({ flightReportIds: failedDeletionIds.join(', ') })
                );
            }

            return successfullyDeletedIds;
        },
        [deleteFlightReport, DELETE_FLIGHT_REPORT_ERROR]
    );

    return {
        deleteFlightReports
    };
};

export default useFlightReportDelete;
