import { format } from 'date-fns';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderDetails');

    return {
        AUTO_FILL_ERROR: t('autoFillError'),
        AUTO_FILL_WORK_ORDER: t('autoFillWorkOrder'),
        AUTO_FILL_WORK_ORDER_DISCLAIMER: t('autoFillWorkOrderDisclaimer'),
        ERRORS: {
            APPLICATOR_INFORMATION_REQUIRED: t('errors.applicatorInformationRequired'),
            EXPIRATION_DATE_MIN: (minDate: Date) =>
                t('errors.expirationDateMin', { dateMin: format(minDate, 'MM/dd/yyyy') }),
            PROPOSED_DATE_INVALID: t('errors.proposedDateInvalid'),
            PROPOSED_DATE_MIN: (minDate: Date) =>
                t('errors.proposedDateMin', { dateMin: format(minDate, 'MM/dd/yyyy') }),
            PROPOSED_DATE_REQUIRED: t('errors.proposedDateRequired'),
            SCHEDULED_DATE_INVALID: t('errors.scheduledDateInvalid'),
            SCHEDULED_DATE_MIN: (minDate: Date) =>
                t('errors.scheduledDateMin', { dateMin: format(minDate, 'MM/dd/yyyy') }),
            SCHEDULED_DATE_REQUIRED: t('errors.scheduledDateRequired'),
            WORK_ORDER_NUMBER_MAX: (charMax: number) => t('errors.workOrderNumberMax', { charMax }),
            WORK_ORDER_STATUS_REQUIRED: t('errors.workOrderStatusRequired')
        },
        LABELS: {
            APPLICATOR_INFORMATION: t('labels.applicatorInformation'),
            EXPIRATION_DATE: t('labels.expirationDate'),
            PROPOSED_DATE: t('labels.proposedDate'),
            SCHEDULED_DATE: t('labels.scheduledDate'),
            SCHEDULED_TIME: t('labels.scheduledTime'),
            STATUS: t('labels.status'),
            WORK_ORDER_NUMBER: t('labels.workOrderNumber')
        },
        PLACEHOLDERS: {
            APPLICATOR_INFORMATION: t('placeholders.applicatorInformation'),
            EXPIRATION_DATE: t('placeholders.expirationDate'),
            PROPOSED_DATE: t('placeholders.proposedDate'),
            SCHEDULED_DATE: t('placeholders.scheduledDate'),
            SCHEDULED_TIME: t('placeholders.scheduledTime')
        },
        STATUSES: {
            CANCELED: t('statuses.canceled'),
            COMPLETE: t('statuses.complete'),
            PENDING: t('statuses.pending'),
            SCHEDULED: t('statuses.scheduled'),
            STARTED: t('statuses.started')
        },
        WORK_ORDER_BUTTON: t('workOrderButton')
    };
};

export default useTranslation;
