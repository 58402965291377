import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

const SignupPage = () => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup'
            }
        });
    }, [loginWithRedirect]);

    return <div>SignupPage</div>;
};

export default SignupPage;
