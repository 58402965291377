import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ItemCardTitle: FunctionComponent<Props> = ({ className = '', testId = TEST_ID, text }) => (
    <h6 className={`${styles.itemCardTitle} ${className}`} data-testid={testId}>
        {text}
    </h6>
);

export default ItemCardTitle;
