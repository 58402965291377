import WorkOrderReadOnlyLabel from 'components/WorkOrderReadOnlyLabel';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ItemLabel: FunctionComponent<Props> = ({ testId = TEST_ID, text }) => (
    <WorkOrderReadOnlyLabel className={styles.itemLabel} testId={testId} text={text} />
);

export default ItemLabel;
