import { useCallback } from 'react';

import { MAP_API } from 'hooks/useJohnDeere/constants';
import useJohnDeereBase from 'hooks/useJohnDeere/hooks/useJohnDeereBase';
import { JohnDeereMapRequest } from 'hooks/useJohnDeere/types';

const useJohnDeereMapLayers = () => {
    const { authenticatedPost, printAndThrowErrorIfExists } = useJohnDeereBase();

    const exportMap = useCallback(
        async (mapRequest: JohnDeereMapRequest): Promise<string> => {
            const { data, error } = await authenticatedPost(MAP_API, JSON.stringify(mapRequest));

            printAndThrowErrorIfExists(error);

            return data.redirectLink;
        },
        [authenticatedPost, printAndThrowErrorIfExists]
    );

    return {
        exportMap
    };
};

export default useJohnDeereMapLayers;
