import FormSectionContainer from 'components/FormSectionContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SideBarContentContainer: FunctionComponent<Props> = ({ children, testId = TEST_ID }) => (
    <FormSectionContainer className={styles.sideBarContentContainer} testId={testId}>
        {children}
    </FormSectionContainer>
);

export default SideBarContentContainer;
