import PageTitle from 'components/PageTitle';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const TabContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    onClick,
    testId = TEST_ID,
    title
}) => (
    <div className={`${className} ${styles.tabContainer}`} data-testid={testId} onClick={onClick}>
        <PageTitle className={styles.title} text={title} />

        {children}
    </div>
);

export default TabContainer;
