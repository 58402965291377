import { useCallback, useRef, useState } from 'react';

import ItemCardWithBorder from 'components/ItemCardWithBorder';

import { TEST_ID } from './constants';
import type { FunctionComponent, HandleCheckboxChange, Props } from './types';

import styles from './styles.module.scss';

const CheckboxList: FunctionComponent<Props> = ({
    children,
    className = '',
    isChecked = false,
    onCheckAll,
    testId = TEST_ID
}) => {
    const [isAllChecked, setIsAllChecked] = useState(isChecked);

    const items = useRef<boolean[]>([]);

    const handleCheckAll = useCallback(() => {
        const isChecked = !isAllChecked;

        setIsAllChecked(isChecked);

        onCheckAll?.({ isChecked, isClicked: true });
    }, [isAllChecked, onCheckAll]);

    const handleCheckboxChange: HandleCheckboxChange = useCallback(
        ({ index, isChecked = false, onCheckboxChange }) => {
            items.current[index] = isChecked;

            if (isChecked && !isAllChecked) {
                const allItemsChecked = items.current.every(item => item);

                setIsAllChecked(allItemsChecked);
                onCheckAll?.({ isChecked: allItemsChecked });
            } else if (!isChecked && isAllChecked) {
                setIsAllChecked(false);
                onCheckAll?.({ isChecked: false });
            }

            onCheckboxChange?.({ index, isChecked });
        },
        [isAllChecked, onCheckAll]
    );

    return (
        <ItemCardWithBorder className={`${className} ${styles.checkboxList}`} testId={testId}>
            {children({ handleCheckAll, handleCheckboxChange })}
        </ItemCardWithBorder>
    );
};

export default CheckboxList;
