import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';

import en from '@@i18n/en.json';
import { MantineProvider } from '@mantine/core';

import { DeviceProvider } from 'components/DeviceProvider';
import FeatureProvider from 'components/FeatureProvider';

import useServiceWorker from './hooks/useServiceWorker';

import AppRoutes from './AppRoutes';
import { AppTheme } from './AppTheme';
import { FunctionComponent } from './types';

export const App: FunctionComponent = () => {
    useServiceWorker();

    return (
        <IntlProvider defaultLocale="en" locale="en" messages={en}>
            <DeviceProvider deskWidthPx="769px">
                <MantineProvider
                    theme={AppTheme}
                    withCSSVariables
                    withGlobalStyles
                    withNormalizeCSS
                >
                    <FeatureProvider>
                        <Router>
                            <AppRoutes />
                        </Router>
                    </FeatureProvider>
                </MantineProvider>
            </DeviceProvider>
        </IntlProvider>
    );
};
