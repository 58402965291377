import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogListItem');

    return {
        MINUTES: t('minutes')
    };
};

export default useTranslation;
