const translations = {
    address: 'Address: ',
    company: 'Company: ',
    contact: 'Contact: ',
    customerInformation: 'Customer Information',
    growerInformation: 'Grower Information',
    phone: 'Phone Number: '
};

export default translations;
