import { PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('notesForm');

    return {
        ERROR_NOTES_MAX: t('errorNotesMax', { charMax: PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX }),
        NOTES: t('notes')
    };
};

export default useTranslation;
