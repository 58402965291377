import { atom } from 'recoil';

import type { WorkOrder } from './types';

const workOrderAtom = atom<WorkOrder | null>({
    default: null,
    key: 'workOrder'
});

const state = {
    atoms: {
        workOrderAtom
    }
};

export default state;
