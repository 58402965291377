import ResponsiveText from 'components/ResponsiveText';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ContentHeaderText: FunctionComponent<Props> = ({ mobileText, testId = TEST_ID, text }) => (
    <h4 className={styles.contentHeaderText} data-testid={testId}>
        <ResponsiveText mobileText={mobileText} text={text} />
    </h4>
);

export default ContentHeaderText;
