import ColoredLabel from 'components/colored-labels/ColoredLabel';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const AppliedLabel: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const { APPLIED } = useTranslation();

    return (
        <ColoredLabel
            className={`${styles.appliedLabel} ${className}`}
            testId={testId}
            text={APPLIED}
        />
    );
};

export default AppliedLabel;
