import { INPUT_CHARS_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('editPersonOfContactPage');

    return {
        API_ERROR: t('apiError'),
        BUTTON: t('button'),
        ERRORS: {
            EMAIL_INVALID: t('errors.emailInvalid'),
            EMAIL_REQUIRED: t('errors.emailRequired'),
            FIRST_NAME_INVALID: t('errors.firstNameInvalid'),
            FIRST_NAME_MAX: t('errors.firstNameMax', { nameMax: INPUT_CHARS_MAX }),
            FIRST_NAME_REQUIRED: t('errors.firstNameRequired'),
            LAST_NAME_INVALID: t('errors.lastNameInvalid'),
            LAST_NAME_MAX: t('errors.lastNameMax', { nameMax: INPUT_CHARS_MAX }),
            LAST_NAME_REQUIRED: t('errors.lastNameRequired'),
            PHONE_INVALID: t('errors.phoneInvalid'),
            PHONE_REQUIRED: t('errors.phoneRequired')
        },
        PLACEHOLDERS: {
            EMAIL: t('placeholders.email'),
            FIRST_NAME: t('placeholders.firstName'),
            LAST_NAME: t('placeholders.lastName'),
            PHONE: t('placeholders.phone')
        },
        TITLE: t('title')
    };
};

export default useTranslation;
