import type { Style } from './types';

const styles: Record<string, Style> = {
    map: {
        height: '4.083in',
        width: '100%'
    },
    mapContainer: {
        borderBottomLeftRadius: '6pt',
        borderBottomRightRadius: '6pt',
        borderTopLeftRadius: '6pt',
        borderTopRightRadius: '6pt',
        overflow: 'hidden'
    }
};

export default styles;
