import { Select } from '@mantine/core';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MobilePageNavigationDropdown: FunctionComponent<Props> = ({
    className = '',
    data,
    onChange,
    value
}) => (
    <Select
        className={`${styles.mobilePageNavigationDropdown} ${className}`}
        data={data}
        onChange={onChange}
        value={value}
    />
);

export default MobilePageNavigationDropdown;
