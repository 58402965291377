import MenuButton from 'components/MenuButton';

import useTranslation from './hooks/useTranslation';

import { FEATURE, TEST_ID } from './constants';
import { FunctionComponent, Items, Props } from './types';

import styles from './styles.module.scss';

const WorkOrderMenu: FunctionComponent<Props> = ({
    className = '',
    includeEdit,
    includeView,
    onDelete,
    onDuplicate,
    onEdit,
    onView,
    testId = TEST_ID
}) => {
    const { DELETE, DUPLICATE, EDIT } = useTranslation();

    const items: Items = [];

    if (includeView) {
        items.push({
            onClick: onView,
            text: 'View'
        });
    }

    if (includeEdit) {
        items.push({
            feature: FEATURE,
            onClick: onEdit,
            text: EDIT
        });
    }

    if (includeEdit) {
        items.push({
            feature: FEATURE,
            onClick: onDuplicate,
            text: DUPLICATE
        });
    }

    items.push({
        isDelete: true,
        onClick: onDelete,
        text: DELETE
    });

    return (
        <MenuButton
            className={`${className} ${styles.workOrderMenu}`}
            items={items}
            testId={testId}
        />
    );
};

export default WorkOrderMenu;
