import { AlertIcon } from '@rantizo-software/rantizo-ui';

import BaseNotification from 'components/BaseNotification';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WarningNotification: FunctionComponent<Props> = ({ testId = TEST_ID, text }) => (
    <BaseNotification
        className={styles.warningNotification}
        icon={<AlertIcon testId={TEST_ID} />}
        testId={testId}
        text={text}
    />
);

export default WarningNotification;
