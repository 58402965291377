import useTranslation from '../useTranslation';
import { EQUIPMENT_CHAR_MAX } from 'config';
import { object, string } from 'yup';

const useSchema = () => {
    const { ERRORS } = useTranslation();

    return object({
        make: string().required(ERRORS.MAKE_REQUIRED),
        model: string().required(ERRORS.MODEL_REQUIRED),
        nickname: string()
            .required(ERRORS.NICKNAME_REQUIRED)
            .max(EQUIPMENT_CHAR_MAX, ERRORS.NICKNAME_MAX),
        serialNumber: string()
            .required(ERRORS.SERIAL_NUMBER_REQUIRED)
            .max(EQUIPMENT_CHAR_MAX, ERRORS.SERIAL_NUMBER_MAX)
    });
};

export default useSchema;
