import { useCallback } from 'react';

import { ORGANIZATIONS_API } from 'hooks/useJohnDeere/constants';
import useJohnDeereBase from 'hooks/useJohnDeere/hooks/useJohnDeereBase';
import { JohnDeereValue } from 'hooks/useJohnDeere/types';

const useJohnDeereOrganizations = () => {
    const { authenticatedGet, printAndThrowErrorIfExists, tokenQueryString } = useJohnDeereBase();

    const fetchJohnDeereOrganizations = useCallback(async (): Promise<JohnDeereValue[]> => {
        const { data, error } = await authenticatedGet(`${ORGANIZATIONS_API}?${tokenQueryString}`);

        printAndThrowErrorIfExists(error);

        return data as JohnDeereValue[];
    }, [authenticatedGet, printAndThrowErrorIfExists, tokenQueryString]);

    const authorizeContributions = useCallback(
        async (organizationId: string) => {
            const { error } = await authenticatedGet(
                `${ORGANIZATIONS_API}/${organizationId}/contributions/authorize?${tokenQueryString}`
            );

            printAndThrowErrorIfExists(error);
        },
        [authenticatedGet, printAndThrowErrorIfExists, tokenQueryString]
    );

    const authorizeOrganization = useCallback(
        async (organization: JohnDeereValue, redirectUri: string) => {
            const connectionLinks = organization.links.filter(link => link.rel === 'connections');

            if (connectionLinks.length === 0) {
                authorizeContributions(organization.id);

                return;
            }

            const connectionLink = connectionLinks[0];

            document.location = `${connectionLink.uri}?redirect_uri=${redirectUri}`;
        },
        [authorizeContributions]
    );

    return {
        authorizeOrganization,
        fetchJohnDeereOrganizations
    };
};

export default useJohnDeereOrganizations;
