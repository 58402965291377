import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';

import VerticalContainer from 'components/VerticalContainer';
import WorkOrderInvoicePDF from 'components/WorkOrderInvoicePDF';
import WorkOrderReportPDF from 'components/WorkOrderReportPDF';

import useTranslation from './hooks/useTranslation';

import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WorkOrderFooter: FunctionComponent<Props> = ({ workOrder }) => {
    const { CREATE_PRODUCT_USAGE_REPORT, DOWNLOAD_INVOICE_PDF, DOWNLOAD_WORK_ORDER_PDF } =
        useTranslation();

    return (
        <VerticalContainer className={styles.workOrderFooter}>
            {workOrder && (
                <PDFDownloadLink
                    className={styles.downloadLink}
                    document={<WorkOrderReportPDF workOrder={workOrder} />}
                    fileName={`WorkOrder_${workOrder?.id.slice(0, 9)}.pdf`}
                >
                    {
                        // @ts-expect-error implementation will later be changed
                        ({ loading }) => <>{!loading && DOWNLOAD_WORK_ORDER_PDF}</>
                    }
                </PDFDownloadLink>
            )}

            {workOrder && (
                <PDFDownloadLink
                    className={styles.downloadLink}
                    document={<WorkOrderInvoicePDF workOrder={workOrder} />}
                    fileName={`WorkOrderInvoice_${workOrder?.id.slice(0, 9)}.pdf`}
                >
                    {
                        // @ts-expect-error implementation will later be changed
                        ({ loading }) => <>{!loading && DOWNLOAD_INVOICE_PDF}</>
                    }
                </PDFDownloadLink>
            )}

            <Link
                className={styles.createProductUsageReport}
                to={`/work-order/${workOrder?.id}/product-usage-report`}
            >
                {CREATE_PRODUCT_USAGE_REPORT}
            </Link>
        </VerticalContainer>
    );
};

export default WorkOrderFooter;
