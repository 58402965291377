import SectionColumn from 'components/pdf/SectionColumn';
import InvoiceText from 'components/pdf/invoice/InvoiceText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const InvoiceThirdPartyWorkOrderColumn: FunctionComponent<Props> = ({
    thirdPartyWorkOrderNumber
}) => {
    const { THIRD_PARTY_WORK_ORDER_NUMBER } = useTranslation();

    return (
        <SectionColumn title={THIRD_PARTY_WORK_ORDER_NUMBER}>
            <InvoiceText
                text={thirdPartyWorkOrderNumber ? thirdPartyWorkOrderNumber.toString() : ''}
            />
        </SectionColumn>
    );
};

export default InvoiceThirdPartyWorkOrderColumn;
