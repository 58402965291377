import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfDataValue');

    return {
        NA: t('na')
    };
};

export default useTranslation;
