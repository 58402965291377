const translations = {
    address: 'Address',
    billTo: 'Bill to',
    city: 'City',
    country: 'Country: United States',
    name: 'Name',
    state: 'State',
    zipcode: 'Zipcode'
};

export default translations;
