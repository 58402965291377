const translations = {
    companyDetails: 'Company Details',
    errorFetchingFaaReport: 'Error Fetching the Faa Report',
    errorUpdatingFaaReport: 'Error Updating the Faa Report',
    flightDetails: 'Flight Details',
    formErrorMessage: 'Please fix all errors in the form before submitting',
    operationDetails: 'Operation Details',
    saveReport: 'Save Report',
    title: 'Create an FAA Report',
    uasTypeAndModel: 'UAS Type and Model'
};

export default translations;
