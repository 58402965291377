import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { useCallback, useEffect, useState } from 'react';

import { DragCircleMode } from '@@utils/mapUtils';
import MapboxDraw, { DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import {
    CircleIcon,
    MarkerIcon,
    PolygonIcon,
    RectangleIcon,
    SelectIcon,
    TrashIcon
} from '@rantizo-software/rantizo-ui';
import { bbox } from '@turf/turf';

import MapButton from 'components/Mapbox/components/MapButton';
import VerticalContainer from 'components/VerticalContainer';

import drawStyles from './drawStyles';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const viewOnlyTool: DrawCustomMode = {
    onSetup: options => ({
        count: options.count || 0
    }),

    toDisplayFeatures: (_state, geojson, display) => {
        display(geojson);
    }
};
const DrawToolsControl: FunctionComponent<Props> = ({
    initialOptions,
    initialState,
    isEditable,
    map
}) => {
    const [draw, setDraw] = useState<MapboxDraw>();

    const modes = {
        ...MapboxDraw.modes,
        draw_circle: DragCircleMode,
        draw_rectangle: DrawRectangle,
        view_only_tool: viewOnlyTool
    };

    const onChangeMode = useCallback(
        (mode: string) => {
            draw?.changeMode(mode);
        },
        [draw]
    );

    const onResize = useCallback(() => {
        if (draw) {
            const shapes = draw.getAll();
            const box = bbox(shapes);

            map.fitBounds(box, { linear: true, padding: 20 });
        }
    }, [draw, map]);

    const onTrash = useCallback(() => {
        draw?.trash();
    }, [draw]);

    const onPinDropClicked = useCallback(() => {
        onChangeMode('draw_point');
    }, [onChangeMode]);

    useEffect(() => {
        if (draw) {
            map.on('resize', onResize);
        }

        return () => {
            map.off('resize', onResize);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draw]);

    useEffect(() => {
        const drawObject: MapboxDraw = new MapboxDraw({
            defaultMode: isEditable ? 'draw_polygon' : 'view_only_tool',
            displayControlsDefault: isEditable,
            modes: modes,
            styles: drawStyles,
            userProperties: true,
            ...initialOptions
        });

        // @ts-expect-error implementation will later be changed
        drawObject.onAdd(map);

        if (initialState && initialState?.length > 0) {
            initialState.forEach(feature => drawObject.add(feature));
        }

        setDraw(drawObject);

        return () => {
            try {
                // @ts-expect-error implementation will later be changed
                drawObject.onRemove(map);
            } catch (error: unknown) {
                console.log(error);

                return;
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditable]);

    return (
        <VerticalContainer className={styles.drawToolsControl}>
            <MapButton onClick={() => onChangeMode('simple_select')}>
                <SelectIcon />
            </MapButton>

            <MapButton onClick={() => onChangeMode('draw_polygon')}>
                <PolygonIcon />
            </MapButton>

            <MapButton onClick={() => onChangeMode('draw_rectangle')}>
                <RectangleIcon />
            </MapButton>

            <MapButton onClick={() => onChangeMode('draw_circle')}>
                <CircleIcon />
            </MapButton>

            <MapButton onClick={onPinDropClicked}>
                <MarkerIcon />
            </MapButton>

            <MapButton onClick={onTrash}>
                <TrashIcon />
            </MapButton>
        </VerticalContainer>
    );
};

export default DrawToolsControl;
