import useFetch from 'hooks/useFetch';

import { CONTROLLER_MAKES_API } from './constants';
import type { ControllerMakesApiResponse } from './types';

const useData = () => {
    const { authenticatedGet } = useFetch();

    const fetchMakes = async () => {
        const response = await authenticatedGet(CONTROLLER_MAKES_API, null);

        const { data, error } = response;

        if (error) {
            throw new Error(JSON.stringify(error));
        }

        return data as ControllerMakesApiResponse;
    };

    return {
        fetchMakes
    };
};

export default useData;
