import ContentText from 'components/ContentText';
import GridContainer from 'components/GridContainer';
import HorizontalContainer from 'components/HorizontalContainer';
import InverseLink from 'components/InverseLink';
import UpgradePlanTip from 'components/UpgradePlanTip';

import useFeatureAccess from 'hooks/useFeatureAccess';

import useTranslation from './hooks/useTranslation';

import { LEARN_MORE_URL, TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithFeatureGate: FunctionComponent<Props> = ({
    children,
    className = '',
    feature,
    testId = TEST_ID
}) => {
    const { LEARN_MORE, PREMIER_FEATURE } = useTranslation();

    const features = useFeatureAccess();

    const isFeatureAccessible = features[feature] || false;

    return (
        <GridContainer className={`${className} ${styles.withFeatureGate}`} testId={testId}>
            {isFeatureAccessible ? (
                children
            ) : (
                <UpgradePlanTip>
                    <HorizontalContainer>
                        <ContentText className={styles.contentText} text={PREMIER_FEATURE} />

                        <InverseLink
                            className={styles.inverseLink}
                            target="_blank"
                            text={LEARN_MORE}
                            to={LEARN_MORE_URL}
                        />
                    </HorizontalContainer>
                </UpgradePlanTip>
            )}
        </GridContainer>
    );
};

export default WithFeatureGate;
