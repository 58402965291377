import { Button } from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DeleteButton: FunctionComponent<Props> = props => {
    const { DEFAULT_BUTTON_TEXT } = useTranslation();
    const {
        className = '',
        isDisabled = false,
        isLoading = false,
        onClick,
        testId = TEST_ID,
        text = DEFAULT_BUTTON_TEXT,
        tooltip = ''
    } = props;

    return (
        <Button
            className={`${className} ${styles.deleteButton}`}
            isDisabled={isLoading || isDisabled}
            isLoading={isLoading}
            onClick={onClick}
            testId={testId}
            tooltip={tooltip}
        >
            {text}
        </Button>
    );
};

export default DeleteButton;
