import { useCallback } from 'react';
import isFunction from 'utils/isFunction';

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { ClickEvent, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const BackButton: FunctionComponent<Props> = ({
    className = '',
    isDisabled = false,
    onClick,
    testId = TEST_ID
}) => {
    const { BACK } = useTranslation();

    const handleClick = useCallback(
        (clickEvent: ClickEvent) => {
            if (isFunction(onClick)) {
                onClick?.(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <PrimaryButton
            className={`${className} ${styles.backButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            testId={testId}
            text={BACK}
        />
    );
};

export default BackButton;
