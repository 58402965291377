import { getIn } from 'formik';
import { merge } from 'lodash';

import { NumberInput, rem } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

/**
 * @deprecated Use `src/components/form/NumberInput` instead.
 */
const FormNumberInput: FunctionComponent<Props> = ({
    className,
    customOnChange,
    disabled,
    field,
    form,
    inputMode,
    isEditable = true,
    label,
    max,
    placeholder,
    precision,
    rightSection,
    rightSectionWidth,
    step,
    testId = TEST_ID,
    type,
    withAsterisk
}) => {
    const { name, value } = field;

    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    const onChange = async (value: number | '') => {
        const trueValue = value === '' ? null : value;

        await form.setFieldValue(name, trueValue);
        const touched = merge(form.touched, { [name]: true });

        await form.setTouched(touched);
        if (customOnChange) {
            customOnChange(typeof value === 'number' ? value : null);
        }
    };

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const placeholderText = isEditable ? placeholder : '';
    const hideClass = !isEditable && (value === null || value === undefined) ? styles.hide : '';

    return (
        <NumberInput
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : ''
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                rightSection: {
                    display: 'flex!important'
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            className={`${className} ${styles.formNumberInput} ${hideClass} ${viewOnlyClass}`}
            data-testid={testId}
            disabled={!isEditable || disabled}
            error={visibleError ? error : undefined}
            label={label}
            max={max}
            placeholder={placeholderText}
            precision={precision || 6}
            removeTrailingZeros={true}
            rightSection={rightSection}
            rightSectionWidth={rightSectionWidth}
            type={type}
            withAsterisk={withAsterisk}
            {...field}
            hideControls
            inputMode={inputMode}
            onChange={onChange}
            step={step ?? 0.01}
            value={value ?? ''}
        />
    );
};

export default FormNumberInput;
