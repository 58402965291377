import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportMenu');

    return {
        DELETE: t('delete'),
        EDIT: t('edit'),
        VIEW: t('view')
    };
};

export default useTranslation;
