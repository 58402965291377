import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('baseEquipmentCard');

    return {
        DELETE: t('delete'),
        DRONE_NAME: t('droneName'),
        EDIT: t('edit'),
        MAKE: t('make'),
        MODEL: t('model'),
        SERIAL_NUMBER: t('serialNumber')
    };
};

export default useTranslation;
