const translations = {
    applicationDate: 'Application Date',
    contact: 'Contact',
    grower: 'Grower',
    mapType: 'Map Type',
    productUsageReport: 'Product Usage Report',
    siteName: 'Site Name'
};

export default translations;
