import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('mapShareButton');

    return {
        SHARE_TO: t('shareTo')
    };
};

export default useTranslation;
