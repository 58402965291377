const translations = {
    customerCheckBoxLabel: 'Same as Customer Information',
    errors: {
        address2Max: 'Address Line 2 cannot exceed {{charMax}} characters',
        addressMax: 'Address cannot exceed {{charMax}} characters',
        addressRequired: 'Address is required',
        cityMax: 'City cannot exceed {{charMax}} characters',
        cityRequired: 'City is required',
        contactMax: 'Contact cannot exceed {{charMax}} characters',
        locationInvalid: 'The given address cannot be verified',
        nameMax: 'Company Name cannot exceed {{charMax}} characters',
        nameRequired: 'Company Name is required',
        phoneInvalid: 'Phone number is invalid',
        stateRequired: 'State is required',
        zipCodeRequired: 'Zip is required'
    },
    title: 'Grower Information'
};

export default translations;
