import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addressOneField');

    return {
        INVALID_ADDRESS_ERROR_MESSAGE: t('invalidAddressErrorMessage'),
        MAX_EXCEEDED_ERROR_MESSAGE: t('maxExceededErrorMessage'),
        REQUIRED_ERROR_MESSAGE: t('requiredErrorMessage')
    };
};

export default useTranslation;
