import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatPhoneNumber } from '@@utils/string';

import { useDevice } from 'components/DeviceProvider';
import HorizontalContainer from 'components/HorizontalContainer';
import ItemContainer from 'components/ItemContainer';
import ListText from 'components/ListText';
import Time from 'components/Time';
import WorkOrderMenu from 'components/WorkOrderMenu';
import CanceledLabel from 'components/colored-labels/CanceledLabel';
import CompleteLabel from 'components/colored-labels/CompleteLabel';
import PendingLabel from 'components/colored-labels/PendingLabel';
import ScheduledLabel from 'components/colored-labels/ScheduledLabel';
import StartedLabel from 'components/colored-labels/StartedLabel';

import usePageRoutes from 'hooks/usePageRoutes';
import useProductUsageReports from 'hooks/useProductUsageReports';
import useWorkOrder from 'hooks/useWorkOrder';

import useTranslate from './hooks/useTranslate';

import { FunctionComponent, LabelMap, Props } from './types';

import styles from './styles.module.scss';

const Job: FunctionComponent<Props> = ({
    customerName,
    date,
    growerName,
    id,
    onDelete,
    phoneNumber,
    siteName,
    status,
    time
}) => {
    const { deviceType } = useDevice();
    const { workOrderDuplicatePage, workOrderEditPage, workOrderPageWithId } = usePageRoutes();
    const { deleteWorkOrder } = useWorkOrder();

    const { doesWorkOrderHaveProductUsageReport } = useProductUsageReports();

    const { PRODUCT_USAGE_REPORT_DELETE_WARNING } = useTranslate();

    const handleDelete = useCallback(async () => {
        try {
            const hasProductUsageReport = await doesWorkOrderHaveProductUsageReport(id);

            if (hasProductUsageReport) {
                alert(PRODUCT_USAGE_REPORT_DELETE_WARNING);

                return;
            }
            await deleteWorkOrder(id);
            onDelete?.(date, id);
        } catch (error) {
            console.error(error);
            alert('Error deleting work order');
        }
    }, [
        PRODUCT_USAGE_REPORT_DELETE_WARNING,
        date,
        deleteWorkOrder,
        doesWorkOrderHaveProductUsageReport,
        id,
        onDelete
    ]);

    const navigate = useNavigate();

    const viewLink = workOrderPageWithId(id);
    const editLink = workOrderEditPage(id);
    const duplicateLink = workOrderDuplicatePage(id);

    const onView = useCallback(() => {
        navigate(viewLink);
    }, [viewLink, navigate]);

    const onEdit = useCallback(() => {
        navigate(editLink);
    }, [editLink, navigate]);

    const onDuplicate = useCallback(() => {
        navigate(duplicateLink);
    }, [duplicateLink, navigate]);

    const labelMap: LabelMap = useMemo(
        () => ({
            CANCELED: <CanceledLabel />,
            COMPLETE: <CompleteLabel />,
            PENDING: <PendingLabel />,
            SCHEDULED: <ScheduledLabel />,
            STARTED: <StartedLabel />
        }),
        []
    );

    return (
        <ItemContainer className={styles.itemContainer}>
            <HorizontalContainer className={styles.horizontalContainer} onClick={onView}>
                <Time className={styles.time} text={time ?? ''} />

                <ListText text={customerName ?? ''} />

                {deviceType === 'desktop' && (
                    <>
                        <ListText text={growerName ?? ''} />

                        <ListText text={formatPhoneNumber(phoneNumber) ?? ''} />

                        <ListText text={siteName ?? ''} />
                    </>
                )}

                {labelMap[status]}
            </HorizontalContainer>

            <WorkOrderMenu
                includeEdit={true}
                includeView={true}
                onDelete={handleDelete}
                onDuplicate={onDuplicate}
                onEdit={onEdit}
                onView={onView}
            />
        </ItemContainer>
    );
};

export default Job;
