import { EMPTY_APPLICATION_SITE } from 'components/work-order/ApplicationSite/defaults';
import { EMPTY_CHEMICAL } from 'components/work-order/ChemicalInformation/defaults';

import type { WorkOrderForm, WorkOrderValidationMap } from './types';

export const defaultForm: WorkOrderForm = {
    applicationSites: [EMPTY_APPLICATION_SITE],
    applicatorInformation: '',
    appliedAcres: null,
    chemicalCost: null,
    chemicals: [EMPTY_CHEMICAL],
    commodity: '',
    customer: {
        companyName: '',
        contactName: '',
        location: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: ''
        },
        phone: ''
    },
    estimatedTotal: 0,
    expirationDate: null,
    fieldApplicationFlatFee: null,
    fieldApplicationHours: 0,
    fieldApplicationRate: null,
    fieldApplicationRateUnit: 'PER_ACRE',
    fileId: null,
    grower: {
        companyName: '',
        contactName: '',
        location: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: ''
        },
        phone: '',
        sameAsCustomer: false
    },
    invoiceItems: [],
    invoicedApplicationSites: [],
    notes: '',
    otherDescription: '',
    otherFee: undefined,
    preHarvestInterval: null,
    proposedAcres: null,
    proposedAcresUnit: 'ac',
    proposedDate: null,
    reEntryIntervalDays: null,
    reEntryIntervalHours: null,
    scheduledDate: null,
    scheduledTime: '',
    status: 'PENDING',
    targetSprayRate: null,
    targetSprayRateUnit: 'gaPerAc',
    taxAmount: null,
    taxAmountUnit: 'FLAT_RATE',
    workOrderNumber: ''
};

export const defaultValidationMap: WorkOrderValidationMap = {
    chemical: true,
    commodity: true,
    customer: true,
    details: true,
    grower: true,
    invoice: true,
    notes: true,
    site: true
};
