import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderInvoiceAssistant');

    return {
        ACRES: t('acres'),
        APPLICATION_SITE: t('applicationSite'),
        APPLIED_ACRES: t('appliedAcres'),
        APPLIED_ACRES_TOOLTIP: t('appliedAcresTooltip'),
        CHEMICAL_COST: t('chemicalCost'),
        ERRORS: {
            FIELD_APPLICATION_HOURS_MAX: t('errors.fieldApplicationHoursMax'),
            FIELD_APPLICATION_HOURS_REQUIRED: t('errors.fieldApplicationHoursRequired'),
            OTHER_DESCRIPTION_MAX: t('errors.otherDescriptionMax'),
            OTHER_DESCRIPTION_REQUIRED: t('errors.otherDescriptionRequired'),
            OTHER_FEE_REQUIRED: t('errors.otherFeeRequired')
        },
        ESTIMATED_TOTAL: t('estimatedTotal'),
        FIELD_APPLICATION_FLAT_FEE: t('fieldApplicationFlatFee'),
        FIELD_APPLICATION_RATE: t('fieldApplicationRate'),
        HOURS: t('hours'),
        HOURS_WORKED: t('hoursWorked'),
        NOT_PROVIDED: t('notProvided'),
        OPTIONAL: t('optional'),
        OTHER: t('other'),
        PROPOSED_ACRES: t('proposedAcres'),
        TAX_AMOUNT: t('taxAmount'),
        TAX_TOOLTIP: t('taxTooltip'),
        USD: t('usd')
    };
};

export default useTranslation;
