import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import type { MyOrganization, PersonOfContact } from './types';

const { persistAtom } = recoilPersist();

const currentOrganizationAtom = atom<MyOrganization>({
    default: undefined,
    effects: [persistAtom],
    key: 'currentOrganization'
});

const isNewOrganizationAtom = atom<boolean>({
    default: false,
    key: 'isNewOrganization'
});

const orgPersonOfContactAtom = atom<PersonOfContact>({
    default: undefined,
    key: 'orgPersonOfContact'
});

const state = {
    atoms: {
        currentOrganizationAtom,
        isNewOrganizationAtom,
        orgPersonOfContactAtom
    }
};

export default state;
