export const defaultProductUsageReport = {
    airTemperatureKelvin: 0.0,
    applicationEndDate: '',
    applicationSites: [],
    applicationStartDate: '',
    carrier: '',
    chemicalsApplied: [],
    drone: {
        defaultSwathWidthMeters: 0.0,
        flightControllerSerial: '',
        id: '',
        make: '',
        model: '',
        nickname: '',
        registrationNumber: '',
        serialNumber: ''
    },
    droneId: '',
    dryChemicalsApplied: [],
    id: '',
    notes: '',
    totalAreaAppliedSquareMeters: 0.0,
    windDirection: '',
    windGustSpeedMetersPerSecond: 0.0,
    windSpeedMetersPerSecond: 0.0,
    workOrderId: ''
};
