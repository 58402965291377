import TableColumn from 'components/pdf/TableColumn';
import TableRow from 'components/pdf/TableRow';
import InvoiceTableText from 'components/pdf/invoice/table/InvoiceTableText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceTableTaxRow: FunctionComponent<Props> = ({
    estimatedTotal,
    taxAmount,
    taxAmountUnit
}) => {
    const { FLAT_RATE, TAX_AMOUNT } = useTranslation();

    const taxText = taxAmountUnit === 'FLAT_RATE' ? FLAT_RATE : `${taxAmount}%`;
    const taxValue =
        taxAmountUnit === 'PERCENTAGE' && taxAmount
            ? estimatedTotal - estimatedTotal / (1 + taxAmount / 100)
            : taxAmount;

    if (!taxAmount) {
        return null;
    }

    return (
        <TableRow>
            <TableColumn style={styles.itemColumn} withDivider={false}>
                <InvoiceTableText text={TAX_AMOUNT} />
            </TableColumn>

            <TableColumn style={styles.descriptionColumn} withDivider={false}>
                <InvoiceTableText text={taxText} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableText text={`$ ${taxValue?.toFixed(2)}`} />
            </TableColumn>
        </TableRow>
    );
};

export default InvoiceTableTaxRow;
