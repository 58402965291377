import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfWorkOrderApplicationSites');

    return {
        ADDRESS: t('address'),
        BOUNDARY: t('boundary'),
        COORDINATES: t('coordinates'),
        NAME: t('name')
    };
};

export default useTranslation;
