import type { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import OrganizationTabs from 'components/OrganizationTabs';

const ManageOrganizationPage: FunctionComponent = () => (
    <>
        <OrganizationTabs />

        <Outlet />
    </>
);

export default ManageOrganizationPage;
