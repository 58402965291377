import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportTabs');

    return {
        DRAFTS: t('drafts'),
        REPORTS: t('reports')
    };
};

export default useTranslation;
