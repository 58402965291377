import { forwardRef } from 'react';

import TextInput from 'components/form/TextInput';

import { Props } from './types';

const CityInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        hasError = false,
        isDisabled = false,
        isEditable = false,
        isRequired = false,
        name = '',
        onChange,
        onError,
        onKeyUp,
        onSubmit,
        onValid,
        placeholder = '',
        value
    } = props;

    return (
        <TextInput
            className={className}
            hasError={hasError}
            isDisabled={isDisabled}
            isEditable={isEditable}
            isRequired={isRequired}
            name={name}
            onChange={onChange}
            onError={onError}
            onKeyUp={onKeyUp}
            onSubmit={onSubmit}
            onValid={onValid}
            placeholder={placeholder}
            ref={ref}
            value={value}
        />
    );
});

CityInput.displayName = 'CityInput';

export default CityInput;
