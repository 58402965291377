import { TEST_ID } from './constants';

import styles from './styles.module.scss';

const PageTitle = ({ className = '', testId = TEST_ID, text = '' }) => (
    <h2 className={`${className} ${styles.pageTitle}`} data-testid={testId}>
        {text}
    </h2>
);

export default PageTitle;
