import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('chemicalAppliedForm');

    return {
        AMOUNT_APPLIED: t('amountApplied'),
        RATE: t('rate'),
        RATE_APPLIED: t('rateApplied'),
        TOTAL_PRODUCT_USED: t('totalProductUsed'),
        UNITS: t('units')
    };
};

export default useTranslation;
