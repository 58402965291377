import { Text } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceHeaderText: FunctionComponent<Props> = ({ text }) => (
    <Text style={styles}>{text}</Text>
);

export default InvoiceHeaderText;
