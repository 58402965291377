import { ChevronDownIcon } from '@rantizo-software/rantizo-ui';

import BodyText from 'components/BodyText';
import FilesMenu from 'components/FilesMenu';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FileListHeader: FunctionComponent<Props> = ({
    isOpen,
    onClick,
    onDeleteAll,
    testId = TEST_ID,
    text
}) => {
    const openClass = isOpen ? styles.fileListHeaderOpen : '';
    const openIcon = isOpen ? styles.chevronRotated : '';

    return (
        <div className={`${styles.fileListHeader} ${openClass}`} data-testid={testId}>
            <BodyText className={styles.fileListText} text={text} />

            <ChevronDownIcon className={`${styles.chevron} ${openIcon}`} onClick={onClick} />

            <FilesMenu onDelete={onDeleteAll} />
        </div>
    );
};

export default FileListHeader;
