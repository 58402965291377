const translations = {
    activeIngredient: 'Active Ingredient ({{number}}): ',
    epaNumber: 'EPA Number: ',
    gpa: 'Ga/Ac',
    label: 'Label Name: ',
    noIngredients: 'No Active Ingredients',
    pestCondition: 'Pest/Condition: ',
    rate: 'Rate: ',
    signalWord: 'Signal Word: ',
    title: 'Chemical to Be Applied'
};

export default translations;
