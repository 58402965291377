import { memo } from 'react';

import Label from 'components/Label';
import WithCheckbox from 'components/WithCheckbox';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CheckboxWithLabel: FunctionComponent<Props> = ({
    className = '',
    isChecked,
    isDisabled = false,
    onChange,
    testId = TEST_ID,
    text = ''
}) => (
    <div className={`${className} ${styles.checkboxWithLabel}`} data-testid={testId}>
        <WithCheckbox isChecked={isChecked} isDisabled={isDisabled} onChange={onChange}>
            <Label className={styles.label} text={text} />
        </WithCheckbox>
    </div>
);

export default memo(CheckboxWithLabel);
