import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ItemCardText: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text
}) => (
    <p className={`${styles.itemCardText} ${className}`} data-testid={testId}>
        {children || text}
    </p>
);

export default ItemCardText;
