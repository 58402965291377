import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('generateAsAppliedMapPage');

    return {
        APPLICATION_DETAILS: t('applicationDetails'),
        AS_APPLIED_MAP: t('asAppliedMap'),
        AS_COVERED: t('asCovered'),
        AS_FLOWN: t('asFlown'),
        FLIGHT_LOG: t('flightLog'),
        PRODUCT_USAGE_REPORT: (createdDate: string, customerName: string, sites: string) =>
            t('productUsageReport', { createdDate, customerName, sites }),
        TITLE: t('title'),
        VIEW_AS_APPLIED_MAP: t('viewAsAppliedMap'),
        VIEW_COVERED_MAP: t('viewAsCoveredMap')
    };
};

export default useTranslation;
