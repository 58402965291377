import {
    type FunctionComponent,
    type ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import type { DeviceContext } from '@@types/device';

const deviceContextDefault: DeviceContext = {
    deviceOS: 'other',
    deviceType: 'mobile',
    networkStatus: 'online'
};

/**
 * For some reason, not sure whether that's vite, rollup or preact, if
 * we name two different contexts the same, e.g. ctx, it is impossible
 * to consume both simultaneously.
 */
const deviceCtx = createContext(deviceContextDefault);

export type DeviceProviderProps = {
    deskWidthPx?: string;
    children?: ReactNode;
};

export const DeviceProvider: FunctionComponent<DeviceProviderProps> = ({
    children,
    deskWidthPx = '0px'
}) => {
    const sizeMQ = useMemo(() => window.matchMedia(`(min-width: ${deskWidthPx})`), [deskWidthPx]);

    // TODO: come back to this to see if there is a better alternative, since navigator.platform seems to be deprecated
    const isIOS = useMemo(
        () =>
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
        []
    );

    const [state, setState] = useState<DeviceContext>({
        ...deviceContextDefault,
        deviceOS: isIOS ? 'ios' : 'other',
        deviceType: sizeMQ.matches ? 'desktop' : 'mobile',
        networkStatus: navigator.onLine ? 'online' : 'offline'
    });

    useEffect(() => {
        const sizeCb = ({ matches }: MediaQueryListEvent) =>
            setState(s => ({
                ...s,
                deviceType: matches ? 'desktop' : 'mobile'
            }));

        const onlineCb = () =>
            setState(s => ({
                ...s,
                networkStatus: 'online'
            }));

        const offlineCb = () =>
            setState(s => ({
                ...s,
                networkStatus: 'offline'
            }));

        sizeMQ.addEventListener('change', sizeCb);
        window.addEventListener('online', onlineCb);
        window.addEventListener('offline', offlineCb);

        return () => {
            sizeMQ.removeEventListener('change', sizeCb);
            window.removeEventListener('online', onlineCb);
            window.removeEventListener('offline', offlineCb);
        };
    }, [sizeMQ, setState]);

    return <deviceCtx.Provider value={state}>{children}</deviceCtx.Provider>;
};

export const useDevice = () => {
    const c = useContext(deviceCtx);

    if (typeof c === 'undefined') {
        throw new Error('No DeviceProvider found');
    }

    return c;
};
