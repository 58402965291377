import { useCallback, useEffect, useMemo } from 'react';

import Loader from 'components/Loader';
import VerticalContainer from 'components/VerticalContainer';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { AsAppliedMapJob, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapProcessorLoader: FunctionComponent<Props> = ({
    mapRequest,
    onLoadComplete,
    onLoadFail,
    testId = TEST_ID
}) => {
    const { fetchAsAppliedMapJobStatus, requestAsAppliedMap } = useAsAppliedMaps();

    const { LOADING_MESSAGE, WAITING_MESSAGE } = useTranslation();

    const jobStatusHandler = useMemo(
        () => ({
            onError: () => onLoadFail(),
            onJobUpdate: (job: AsAppliedMapJob) => {
                switch (job.status) {
                    case 'COMPLETE':
                        onLoadComplete(job);
                        break;
                    case 'PENDING':
                        break;
                    case 'ERROR':
                        console.error('sse error');
                        console.log(job);
                        onLoadFail();
                        break;
                    case 'EXPIRED':
                        console.error('sse expired');
                        onLoadFail();
                        break;
                }
            }
        }),
        [onLoadComplete, onLoadFail]
    );

    const requestMap = useCallback(async () => {
        try {
            const job = await requestAsAppliedMap(mapRequest);

            await fetchAsAppliedMapJobStatus(job.id, jobStatusHandler);
        } catch (e) {
            console.error(e);
            onLoadFail();
        }
    }, [requestAsAppliedMap, mapRequest, fetchAsAppliedMapJobStatus, jobStatusHandler, onLoadFail]);

    useEffect(() => {
        requestMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VerticalContainer testId={testId}>
            <p className={styles.loadingMessage}>{LOADING_MESSAGE}</p>

            <Loader />

            <p className={styles.waitingMessage}>{WAITING_MESSAGE}</p>
        </VerticalContainer>
    );
};

export default MapProcessorLoader;
