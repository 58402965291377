import Column from 'components/pdf//Column';
import ColumnHeader from 'components/pdf/ColumnHeader';
import Section from 'components/pdf/Section';
import Text from 'components/pdf/Text';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const WorkOrderNotes: FunctionComponent<Props> = ({ notes }) => {
    const { TITLE } = useTranslation();

    return (
        <Section>
            <Column>
                <ColumnHeader text={TITLE} />

                <Text text={notes ?? ''} />
            </Column>
        </Section>
    );
};

export default WorkOrderNotes;
