import { INPUT_CHARS_MAX } from 'config';
import { object, string } from 'yup';
import 'yup-phone-lite';

import useTranslation from 'components/work-order/CustomerInformation/hooks/useTranslation';

import useMapboxGeocoding from 'hooks/useMapboxGeocoding';

const useSchema = () => {
    const { validateAddress } = useMapboxGeocoding();
    const { ERRORS } = useTranslation();

    return object({
        customer: object({
            companyName: string()
                .required(ERRORS.NAME_REQUIRED)
                .max(INPUT_CHARS_MAX, ERRORS.NAME_MAX),
            contactName: string().notRequired().max(INPUT_CHARS_MAX, ERRORS.CONTACT_MAX),
            location: object({
                address1: string()
                    .requiredIfAnySiblingPresent(ERRORS.ADDRESS_REQUIRED)
                    .max(INPUT_CHARS_MAX, ERRORS.ADDRESS_MAX),
                address2: string().notRequired().max(INPUT_CHARS_MAX, ERRORS.ADDRESS2_MAX),
                city: string()
                    .requiredIfAnySiblingPresent(ERRORS.CITY_REQUIRED)
                    .max(INPUT_CHARS_MAX, ERRORS.CITY_MAX),
                state: string().requiredIfAnySiblingPresent(ERRORS.STATE_REQUIRED),
                zipCode: string().requiredIfAnySiblingPresent(ERRORS.ZIP_CODE_REQUIRED)
            })
                .test({
                    message: ERRORS.LOCATION_INVALID,
                    name: 'validAddress',
                    test: async value =>
                        // disable this validator when testing, to prevent network calls to Mapbox
                        // which fails when running in a GitHub Action
                        import.meta.env.MODE !== 'test' &&
                        value !== null &&
                        value?.address1 &&
                        value?.city &&
                        value?.state &&
                        value?.zipCode
                            ? await validateAddress(value)
                            : true
                })
                .notRequired(),
            phone: string().phone(null, ERRORS.PHONE_INVALID).notRequired()
        })
    });
};

export default useSchema;
