import BaseNoEquipmentCard from 'components/BaseNoEquipmentCard';

import useRoles from 'hooks/useRoles';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import styles from './style.module.scss';
import type { FunctionComponent, Props } from './types';

const NoControllerCard: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { canManageEquipment } = useRoles();
    const {
        MESSAGE_ADMIN_1: MESSAGE_ADMIN1,
        MESSAGE_ADMIN_2: MESSAGE_ADMIN2,
        MESSAGE_USER,
        RANTIZO,
        TITLE
    } = useTranslation();

    const message = canManageEquipment ? (
        <>
            {MESSAGE_ADMIN1}

            <span className={styles.rantizoHighlight}>{RANTIZO}</span>

            {MESSAGE_ADMIN2}
        </>
    ) : (
        MESSAGE_USER
    );

    return <BaseNoEquipmentCard message={message} testId={testId} title={TITLE} />;
};

export default NoControllerCard;
