import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deleteConfirmationModal');

    return {
        BUTTON_TEXT: t('buttonText')
    };
};

export default useTranslation;
