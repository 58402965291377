import { Navigate } from 'react-router-dom';

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import CompanyDetailsEditCard from 'components/CompanyDetailsEditCard';
import OrganizationCertificationEditCard from 'components/OrganizationCertificationEditCard';
import PageTitle from 'components/PageTitle';
import PersonOfContactEditCard from 'components/PersonOfContactEditCard';
import VerticalContainer from 'components/VerticalContainer';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import usePageRoutes from 'hooks/usePageRoutes';
import useRoles from 'hooks/useRoles';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

import styles from './styles.module.scss';

const OrganizationDetails: FunctionComponent = () => {
    const { canManageOrganizations } = useRoles();
    const { currentOrganization } = useCurrentOrganization();
    const { accountLoginPage } = usePageRoutes();
    const { BUTTON, TITLE } = useTranslation();

    if (currentOrganization === undefined) {
        return <Navigate replace to={accountLoginPage} />;
    }

    const canEditDetails = canManageOrganizations;

    return (
        <VerticalContainer className={styles.pageContainer}>
            <PageTitle className={styles.header} text={TITLE} />

            <div className={styles.cardContainer}>
                <CompanyDetailsEditCard editable={canEditDetails ? canEditDetails : false} />

                <PersonOfContactEditCard editable={canEditDetails ? canEditDetails : false} />

                <OrganizationCertificationEditCard
                    editable={canEditDetails ? canEditDetails : false}
                    organization={currentOrganization}
                />
            </div>

            <PrimaryButton className={styles.transferOrgButton} isDisabled text={BUTTON} />
        </VerticalContainer>
    );
};

export default OrganizationDetails;
