import { Text } from '@react-pdf/renderer';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

import styles from './styles';

const PageNumberOfN: FunctionComponent = () => {
    const { PAGE_NUMBER } = useTranslation();

    return (
        <Text
            render={({ pageNumber, totalPages }) => PAGE_NUMBER(pageNumber, totalPages)}
            style={styles}
        />
    );
};

export default PageNumberOfN;
