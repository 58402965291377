const translations = {
    essentialsSuite: 'Essentials Suite',
    freeAccount: 'Free Account',
    learnButton: 'Learn More About Subscription Tiers',
    premierSuite: 'Premier Suite',
    subscription: 'Subscription',
    trial: 'Essentials Suite (Trial)',
    upgradeButton: 'Upgrade your Subscription'
};

export default translations;
