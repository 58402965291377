import PageContainer from 'components/PageContainer';
import PageLoader from 'components/PageLoader';
import PageWithSidebarHeader from 'components/PageWithSidebarHeader';
import WithBackArrow from 'components/WithBackArrow';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const Page: FunctionComponent<Props> = ({
    children,
    headerActions,
    isLoading = false,
    onBack,
    testId = TEST_ID,
    title
}) => {
    const pageTitle =
        typeof title === 'string' ? (
            <PageWithSidebarHeader onBack={onBack} text={title}>
                {headerActions}
            </PageWithSidebarHeader>
        ) : (
            <WithBackArrow onClick={onBack}>{title}</WithBackArrow>
        );

    return (
        <PageContainer testId={testId}>
            {pageTitle}

            {isLoading && <PageLoader />}

            {!isLoading && children}
        </PageContainer>
    );
};

export default Page;
