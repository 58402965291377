import { AlertButton } from '@rantizo-software/rantizo-ui';

import HorizontalContainer from 'components/HorizontalContainer';
import Link from 'components/Link';
import VerticalContainer from 'components/VerticalContainer';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Alert: FunctionComponent<Props> = ({
    children,
    className = '',
    onClose,
    testId = TEST_ID,
    text = ''
}) => {
    const { SKIP_FOR_NOW, TAKE_ME_THERE } = useTranslation();

    return (
        <VerticalContainer className={`${className} ${styles.alert}`} testId={testId}>
            <VerticalContainer className={styles.verticalContainer}>
                {children || text}
            </VerticalContainer>

            <HorizontalContainer className={styles.horizontalContainer}>
                <AlertButton className={styles.alertButton} text={TAKE_ME_THERE} />

                <Link className={styles.link} onClick={onClose} text={SKIP_FOR_NOW} />
            </HorizontalContainer>
        </VerticalContainer>
    );
};

export default Alert;
