import ListAddButton from 'components/ListAddButton';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';

import useFeatureAccess from 'hooks/useFeatureAccess';

import useTranslation from './hooks/useTranslation';

import { FEATURE, TEST_ID } from './constants';
import { Props } from './types';

const MapsListPageLayoutHeader = ({ onAddClick, pageTitle, testId = TEST_ID }: Props) => {
    const { CREATE_AAM } = useTranslation();

    const features = useFeatureAccess();

    const isFeatureAccessible = features[FEATURE];

    return (
        <ListPageLayoutHeader pageTitle={pageTitle} testId={testId}>
            <ListAddButton
                isDisabled={!isFeatureAccessible}
                onClick={onAddClick}
                text={CREATE_AAM}
            />
        </ListPageLayoutHeader>
    );
};

export default MapsListPageLayoutHeader;
