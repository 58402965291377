const translations = {
    airTemperature: 'Air Temperature: ',
    applicationDate: 'Application Date: ',
    applicationSite: 'Application Site: ',
    applicator: 'Applicator: {{name}}',
    drone: 'Drone: {{name}}',
    endTime: 'End Time: ',
    fahrenheit: '\u00b0F',
    mph: 'MPH',
    startTime: 'Start Time: ',
    windDirection: 'Wind Direction: ',
    windGustSpeed: 'Wind Gust Speed: ',
    windSpeed: 'Wind Speed: ',
    workOrderNumber: 'Work Order Number (Rantizo): '
};

export default translations;
