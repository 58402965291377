import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('generateAsAppliedMapApplicationDetailsForm');

    return {
        APPLICATION_DATE: t('applicationDate'),
        APPLIED_WITH: t('appliedWith'),
        ASSOCIATED_WITH: t('associatedWith'),
        BACK: t('back'),
        CONTINUE: t('continue'),
        FLIGHTS_NOT_FOUND: t('flightsNotFound'),
        INCOMPLETE_FORM: t('incompleteForm'),
        PRODUCT_USAGE_REPORT_PLACEHOLDER: t('productUsageReportPlaceholder'),
        TO: t('to')
    };
};

export default useTranslation;
