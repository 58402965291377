import { object, string } from 'yup';

import useTranslate from 'components/NewProductUsageReportModal/hooks/useTranslation';

const useSchema = () => {
    const { WORK_ORDER_REQUIRED_ERROR } = useTranslate();

    return object({
        workOrderId: string().ensure().required(WORK_ORDER_REQUIRED_ERROR)
    });
};

export default useSchema;
