const translations = {
    aircraftMismatch: "The aircraft in this file doesn't match the applied with aircraft",
    conflictError: 'This file conflicts with a flight log that already exists',
    csvMultiAircraftError: 'The file contains multiple aircraft serial numbers',
    fileUploads: 'File Uploads',
    invalidDataError: 'The file is missing critical data necessary to create your flight',
    invalidExtensionError: 'Invalid file type',
    invalidFlightModeError:
        'We can only process automatic flights from DJI. Manual flights lack critical data.',
    selectFiles: 'Select Files',
    unknownError: 'An unknown error occurred with this file',
    uploadError: 'An error occurred while uploading this file'
};

export default translations;
