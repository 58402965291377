import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightDetails');

    return {
        FLIGHT_DETAILS: t('flightDetails'),
        SAVE_REPORT: t('saveReport')
    };
};

export default useTranslation;
