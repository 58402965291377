import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfProductUsageReportProductDetails');

    return {
        AMOUNT_APPLIED: t('amountApplied'),
        CARRIER: t('carrier'),
        CHEMICAL: t('chemical'),
        GALLONS: t('gallons'),
        GALLONS_PER_ACRE: t('gallonsPerAcre'),
        RATE: t('rate'),
        TOTAL_ACRES: t('totalAcres'),
        TOTAL_PRODUCT: t('totalProduct'),
        VOLUME: t('volume')
    };
};

export default useTranslation;
