import { useMemo } from 'react';

import { View } from '@react-pdf/renderer';

import Label from 'components/pdf/Label';
import LegendColor from 'components/pdf/LegendColor';
import LegendRow from 'components/pdf/LegendRow';
import LegendValue from 'components/pdf/LegendValue';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const Legend: FunctionComponent<Props> = ({ legend, title }) => {
    const { getDisplayLegendItems } = useAsAppliedMaps();

    const displayLegend = useMemo(
        () => getDisplayLegendItems(legend),
        [getDisplayLegendItems, legend]
    );

    return (
        <View style={styles.legendContainer}>
            <Label text={title} />

            <View style={styles.legend}>
                {displayLegend.map(({ color, text }, index) => (
                    <LegendRow key={index}>
                        <LegendColor color={color} />

                        <LegendValue value={text} />
                    </LegendRow>
                ))}
            </View>
        </View>
    );
};

export default Legend;
