import { CloseIcon } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CloseButton: FunctionComponent<Props> = ({ className = '', onClick, testId = TEST_ID }) => (
    <button
        className={`${className} ${styles.closeButton}`}
        data-testid={testId}
        onClick={onClick}
        type="button"
    >
        <CloseIcon />
    </button>
);

export default CloseButton;
