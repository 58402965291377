import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoicePageFooter');

    return {
        DISCLAIMER_1: t('disclaimer1'),
        DISCLAIMER_2: t('disclaimer2'),
        GENERATED_BY: t('generatedBy')
    };
};

export default useTranslation;
