import type { ReplaceTextToComponents } from './types';

const replaceTextToComponents: ReplaceTextToComponents = (map, text) =>
    Object.keys(map).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (jsx: any, key: unknown) => {
            const regex = new RegExp(key as string);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return jsx.reduce((acc: any, part: any) => {
                if (typeof part !== 'string') {
                    acc.push(part);

                    return acc;
                }

                const parts = part
                    .split(regex)
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .map((item: any) => [item, map[key as string](key as string)]);
                const jsxParts = acc.concat(parts).flat();

                jsxParts.pop();

                return jsxParts;
            }, []);
        },
        [text]
    );

export default replaceTextToComponents;
