import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDevice } from 'components/DeviceProvider';
import FlightLogMenu from 'components/FlightLogMenu';
import HorizontalContainer from 'components/HorizontalContainer';
import ItemContainer from 'components/ItemContainer';
import ListText from 'components/ListText';
import Time from 'components/Time';
import CsvLabel from 'components/colored-labels/CsvLabel';
import DryLabel from 'components/colored-labels/DryLabel';
import KmlLabel from 'components/colored-labels/KmlLabel';
import SyncedLabel from 'components/colored-labels/SyncedLabel';
import WetLabel from 'components/colored-labels/WetLabel';
import Checkbox from 'components/form/Checkbox';

import useDate from 'hooks/useDate';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { DJI_API, TEST_ID, XAG_API, XAG_KML_API } from './constants';
import type { ApplicationTypeMap, FileTypeMap, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightLogListItem: FunctionComponent<Props> = ({
    applicationType,
    droneNickname,
    endTime,
    fileType,
    id,
    location,
    onDelete: propagateDelete,
    selectedFlightIds,
    setSelectedFlightIds,
    startTime,
    testId = TEST_ID
}) => {
    const { MINUTES } = useTranslation();
    const { formatTimeString, getDurationInMinutes } = useDate();
    const navigate = useNavigate();
    const { flightDetailsPage } = usePageRoutes();
    const { deviceType } = useDevice();

    const flightStartTimeDisplayString = formatTimeString(startTime);

    const flightDuration = getDurationInMinutes({
        endDateString: endTime,
        startDateString: startTime
    });

    const flightSourceMap: FileTypeMap = useMemo(
        () => ({
            DJI_SYNCED: { component: SyncedLabel },
            DJI_UPLOADED: {
                component: KmlLabel,
                url: DJI_API
            },
            XAG_KML_UPLOADED: {
                component: KmlLabel,
                url: XAG_KML_API
            },
            XAG_UPLOADED: {
                component: CsvLabel,
                url: XAG_API
            }
        }),
        []
    );

    const selected = useMemo(() => selectedFlightIds.includes(id), [id, selectedFlightIds]);
    const flightTypeMap: ApplicationTypeMap = useMemo(
        () => ({
            DRY: DryLabel,
            LIQUID: WetLabel
        }),
        []
    );

    const onView = useCallback(() => {
        navigate(flightDetailsPage(id));
    }, [flightDetailsPage, id, navigate]);

    const ApplicationTypeComponent = flightTypeMap[applicationType];
    const FileTypeComponent = flightSourceMap[fileType].component;
    const filetypeUrl = flightSourceMap[fileType].url;

    const onDelete = useCallback(async () => {
        if (propagateDelete && filetypeUrl) {
            propagateDelete?.({ id, url: filetypeUrl });
        }
    }, [filetypeUrl, id, propagateDelete]);

    const updateSelectedFlights = useCallback(() => {
        if (selected) {
            setSelectedFlightIds((prev: string[]) => prev.filter(selectedId => selectedId !== id));
        } else {
            setSelectedFlightIds((prev: string[]) => [...prev, id]);
        }
    }, [id, selected, setSelectedFlightIds]);

    return (
        <ItemContainer className={styles.flightLogListItem} testId={testId}>
            <Checkbox
                className={styles.checkbox}
                isChecked={selected}
                onChange={updateSelectedFlights}
            />

            <HorizontalContainer className={styles.horizontalContainer} onClick={onView}>
                <Time text={flightStartTimeDisplayString} />

                <ListText className={styles.listItemText} text={droneNickname ?? ''} />

                {deviceType === 'desktop' && (
                    <>
                        <ListText
                            className={styles.listItemText}
                            text={`${flightDuration} ${MINUTES}`}
                        />

                        <ListText className={styles.listItemText} text={location ?? ''} />
                    </>
                )}

                <ApplicationTypeComponent />

                <FileTypeComponent />
            </HorizontalContainer>

            {deviceType === 'desktop' && (
                <FlightLogMenu flightSource={fileType} onDelete={onDelete} onView={onView} />
            )}
        </ItemContainer>
    );
};

export default FlightLogListItem;
