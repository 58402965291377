import { DroneIcon } from '@rantizo-software/rantizo-ui';

import ItemCard from 'components/ItemCard';
import ItemCardText from 'components/ItemCardText';
import ItemCardTitle from 'components/ItemCardTitle';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const BaseNoEquipmentCard: FunctionComponent<Props> = ({
    className = '',
    message,
    testId = TEST_ID,
    title
}) => (
    <ItemCard className={`${styles.baseNoEquipmentCard} ${className}`} testId={testId}>
        <DroneIcon className={styles.droneIcon} />

        <ItemCardTitle text={title} />

        <ItemCardText>{message}</ItemCardText>
    </ItemCard>
);

export default BaseNoEquipmentCard;
