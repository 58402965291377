import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('personOfContactEditCard');

    return {
        LOADING: t('loading'),
        NO_CONTACT: t('noContact'),
        TITLE: t('title')
    };
};

export default useTranslation;
