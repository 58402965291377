import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('organizationCertificationEditCard');

    return {
        CERTIFICATE_OF_AUTHORIZATION_NUMBER: t('certificateOfAuthorizationNumber'),
        CERTIFICATIONS_AND_EXEMPTIONS: t('certificationsAndExemptions'),
        FAA_DOCKET_NUMBER: t('faaDocketNumber'),
        FAA_EXEMPTION_NUMBER: t('faaExemptionNumber'),
        NO_CERTIFICATIONS: t('noCertifications')
    };
};

export default useTranslation;
