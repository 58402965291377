import { View } from '@react-pdf/renderer';

import Header from 'components/pdf/Header';
import Logo from 'components/pdf/Logo';
import SubtleText from 'components/pdf/SubtleText';

import type { FunctionComponent, Props } from './types';

const PageHeader: FunctionComponent<Props> = ({ headerText = '' }) => (
    <Header>
        <Logo />

        <View>
            <SubtleText text={headerText} />
        </View>
    </Header>
);

export default PageHeader;
