import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('listFooter');

    return {
        ALL: t('all'),
        DELETE_ERROR: t('deleteError'),
        NONE: t('none'),
        NUMBER_SELECTED: (selected: number, total: number) =>
            t('numberSelected', { selected, total })
    };
};

export default useTranslation;
