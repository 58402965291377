import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('applicationDetailsTab');

    return {
        APPLIED_WITH: t('appliedWith'),
        CONTINUE: t('continue')
    };
};

export default useTranslation;
