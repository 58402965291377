import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import BodyText from 'components/BodyText';
import CheckboxList from 'components/CheckboxList';
import ContentHeaderText from 'components/ContentHeaderText';
import FlightRow from 'components/FlightRow';
import VerticalContainer from 'components/VerticalContainer';
import DryLabel from 'components/colored-labels/DryLabel';
import SyncedLabel from 'components/colored-labels/SyncedLabel';
import UploadedLabel from 'components/colored-labels/UploadedLabel';
import WetLabel from 'components/colored-labels/WetLabel';
import Checkbox from 'components/form/Checkbox';

import useTranslation from './hooks/useTranslation';

import { DATE_FORMAT, TEST_ID, TIME_FORMAT } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const dataTypeMap: Record<string, FunctionComponent> = {
    DJI_SYNCED: SyncedLabel,
    DJI_UPLOADED: UploadedLabel,
    XAG_KML_UPLOADED: UploadedLabel,
    XAG_UPLOADED: UploadedLabel
};

const sprayTypeMap: Record<string, FunctionComponent> = {
    DRY: DryLabel,
    LIQUID: WetLabel
};

const FlightList: FunctionComponent<Props> = ({
    className = '',
    items = [],
    onCheckAll,
    onCheckboxChange,
    testId = TEST_ID
}) => {
    const { DATA_TYPE, DRONES, SPRAY_TYPE, TIMESTAMP, TYPE } = useTranslation();

    const [isAllChecked, setIsAllChecked] = useState(items.every(item => item));

    const handleChange = useCallback(
        ({ isChecked, isClicked }: { isChecked: boolean; isClicked?: boolean }) => {
            setIsAllChecked(isChecked);

            onCheckAll?.({ isChecked, isClicked });
        },
        [onCheckAll]
    );

    const flightCount = useMemo(() => items.length, [items]);

    return (
        <CheckboxList
            className={`${styles.flightList} ${className}`}
            isChecked={isAllChecked}
            onCheckAll={handleChange}
            testId={testId}
        >
            {({ handleCheckAll, handleCheckboxChange }) => (
                <>
                    <FlightRow className={styles.header} withDivider>
                        <Checkbox isChecked={isAllChecked} onChange={handleCheckAll} />

                        <ContentHeaderText text={DRONES} />

                        <ContentHeaderText text={TIMESTAMP} />

                        <ContentHeaderText mobileText={TYPE} text={SPRAY_TYPE} />

                        <ContentHeaderText mobileText={''} text={DATA_TYPE} />
                    </FlightRow>

                    <VerticalContainer className={styles.content}>
                        {items.map(
                            (
                                {
                                    checked: isDefaultChecked,
                                    droneSerialNumber,
                                    endTime: endTimestamp,
                                    flightSource,
                                    flightType,
                                    startTime: startTimestamp
                                },
                                index
                            ) => {
                                const startDateObject = new Date(startTimestamp);
                                const endDateObject = new Date(endTimestamp);

                                const startDate = format(startDateObject, DATE_FORMAT);
                                const startTime = format(startDateObject, TIME_FORMAT);
                                const endTime = format(endDateObject, TIME_FORMAT);

                                const DataTypeLabelComponent: FunctionComponent<{
                                    className?: string;
                                }> = dataTypeMap[flightSource];
                                const SprayTypeLabelComponent = sprayTypeMap[flightType];

                                return (
                                    <FlightRow
                                        isHighlighted={isDefaultChecked}
                                        key={index}
                                        withDivider={index !== flightCount - 1}
                                    >
                                        <Checkbox
                                            onChange={event => {
                                                const checkedStatus = event?.target?.checked;
                                                const isChecked = checkedStatus ?? isDefaultChecked;

                                                handleCheckboxChange({
                                                    index,
                                                    isChecked,
                                                    onCheckboxChange
                                                });
                                            }}
                                            isChecked={isDefaultChecked}
                                        />

                                        <BodyText
                                            className={styles.flightText}
                                            text={droneSerialNumber}
                                        />

                                        <BodyText
                                            className={styles.flightText}
                                            text={`${startDate}: ${startTime} - ${endTime}`}
                                        />

                                        <SprayTypeLabelComponent />

                                        <DataTypeLabelComponent className={styles.dataType} />
                                    </FlightRow>
                                );
                            }
                        )}
                    </VerticalContainer>
                </>
            )}
        </CheckboxList>
    );
};

export default FlightList;
