import { useCallback, useMemo } from 'react';

import useApplicationSitesSchema from 'components/work-order/ApplicationSite/hooks/useSchema';
import useChemicalInformationSchema from 'components/work-order/ChemicalInformation/hooks/useSchema';
import useCustomerInformationSchema from 'components/work-order/CustomerInformation/hooks/useSchema';
import useDetailsSchema from 'components/work-order/Details/hooks/useSchema';
import useGrowerInformationSchema from 'components/work-order/GrowerInformation/hooks/useSchema';
import useInvoiceSchema from 'components/work-order/InvoiceAssistant/hooks/useSchema';
import useNotesSchema from 'components/work-order/Notes/hooks/useSchema';
import useSiteCommoditySchema from 'components/work-order/SiteCommodity/hooks/useSchema';

import type { WorkOrderForm, WorkOrderSchemaMap, WorkOrderSection } from './types';

const useSchema = () => {
    const applicationSiteSchema = useApplicationSitesSchema();
    const chemicalInformationSchema = useChemicalInformationSchema();
    const customerInformationSchema = useCustomerInformationSchema();
    const detailsSchema = useDetailsSchema();
    const growerInformationSchema = useGrowerInformationSchema();
    const invoiceSchema = useInvoiceSchema();
    const notesSchema = useNotesSchema();
    const siteCommoditySchema = useSiteCommoditySchema();

    const schemaMap: WorkOrderSchemaMap = useMemo(
        () => ({
            chemical: chemicalInformationSchema,
            commodity: siteCommoditySchema,
            customer: customerInformationSchema,
            details: detailsSchema,
            grower: growerInformationSchema,
            invoice: invoiceSchema,
            notes: notesSchema,
            site: applicationSiteSchema
        }),
        [
            applicationSiteSchema,
            chemicalInformationSchema,
            customerInformationSchema,
            detailsSchema,
            growerInformationSchema,
            invoiceSchema,
            notesSchema,
            siteCommoditySchema
        ]
    );

    const validateSchema = useCallback(
        async (values: WorkOrderForm, schema: WorkOrderSection) => {
            try {
                await schemaMap[schema].validate(values);

                return true;
            } catch (error: unknown) {
                console.log(error);

                return false;
            }
        },
        [schemaMap]
    );

    const validateAllSchema = useCallback(
        async (values: WorkOrderForm) => {
            try {
                await Promise.all([
                    validateSchema(values, 'chemical'),
                    validateSchema(values, 'commodity'),
                    validateSchema(values, 'customer'),
                    validateSchema(values, 'details'),
                    validateSchema(values, 'grower'),
                    validateSchema(values, 'invoice'),
                    validateSchema(values, 'notes'),
                    validateSchema(values, 'site')
                ]);

                return true;
            } catch (error: unknown) {
                console.log(error);

                return false;
            }
        },
        [validateSchema]
    );

    return {
        schemaMap,
        validateAllSchema,
        validateSchema
    };
};

export default useSchema;
