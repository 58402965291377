import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('zipCodeField');

    return {
        MAX_EXCEEDED_ERROR_MESSAGE: t('maxExceededErrorMessage'),
        REQUIRED_ERROR_MESSAGE: t('requiredErrorMessage'),
        ZIP_CODE_ERROR_MESSAGE: t('zipCodeErrorMessage')
    };
};

export default useTranslation;
