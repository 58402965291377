import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportPdf');

    return {
        APPLICATION_DETAILS: t('applicationDetails'),
        PRODUCT_DETAILS: t('productDetails'),
        PRODUCT_USAGE_REPORT_HEADER: (workOrderNumber: string) =>
            t('productUsageReportHeader', { workOrderNumber })
    };
};

export default useTranslation;
