import type { Style } from './types';

const styles: Style = {
    alignSelf: 'center',
    fontFamily: 'Inter',
    fontSize: '11pt',
    padding: '9pt'
};

export default styles;
