import { format } from 'date-fns';

import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const dateFormat = 'MM/dd/yyyy';
const timeFormat = 'hh:mm:ss a';

const WorkOrderDetails: FunctionComponent<Props> = ({ workOrder }) => {
    const {
        APPLICATOR,
        ATTACHED_WORK_ORDER,
        EXPIRATION_DATE,
        NO,
        PROPOSED_DATE,
        SCHEDULED_DATE,
        STATUS,
        TIME,
        TITLE,
        WORK_ORDER_NUMBER,
        YES
    } = useTranslation();

    const {
        applicator,
        expirationDate,
        file,
        proposedDate,
        scheduledDate,
        status,
        workOrderNumber
    } = workOrder;

    return (
        <Section>
            <SectionColumn title={TITLE}>
                <Text>
                    {WORK_ORDER_NUMBER}

                    {workOrderNumber ?? ''}
                </Text>

                <Text>
                    {ATTACHED_WORK_ORDER}

                    {file ? YES : NO}
                </Text>

                <Text>
                    {STATUS}

                    {status ?? ''}
                </Text>

                <Text>
                    {PROPOSED_DATE}

                    {proposedDate ? format(new Date(proposedDate), dateFormat) : 'MM/DD/YYYY'}
                </Text>
            </SectionColumn>

            <SectionColumn>
                <Text>
                    {EXPIRATION_DATE}

                    {expirationDate ? format(new Date(expirationDate), dateFormat) : 'MM/DD/YYYY'}
                </Text>

                <Text>
                    {SCHEDULED_DATE}

                    {scheduledDate ? format(new Date(scheduledDate), dateFormat) : 'MM/DD/YYYY'}
                </Text>

                <Text>
                    {TIME}

                    {scheduledDate ? format(new Date(scheduledDate), timeFormat) : '00:00:00 --'}
                </Text>

                <Text>
                    {APPLICATOR}

                    {applicator !== null ? `${applicator?.firstName} ${applicator?.lastName}` : ''}
                </Text>
            </SectionColumn>
        </Section>
    );
};

export default WorkOrderDetails;
