import type { Style } from './types';

const styles: Style = {
    flex: 1,
    flexDirection: 'row',
    marginBottom: '2pt',
    maxHeight: '18pt'
};

export default styles;
