import { Loader as MantineLoader } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const Loader: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => (
    <MantineLoader className={className} data-testid={testId} />
);

export default Loader;
