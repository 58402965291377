const translations = {
    appliedRate: 'Estimated Applied Rate',
    appliedTotal: 'Estimated Applied Total',
    areaApplied: 'Estimated Area Applied',
    chemicalsApplied: 'Chemicals Applied',
    endTime: 'End Time',
    location: 'Location',
    na: 'N/A',
    startTime: 'Start Time',
    unknown: 'Unknown'
};

export default translations;
