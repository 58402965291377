import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ImageContainer: FunctionComponent<Props> = ({ className = '', src, testId = TEST_ID }) => (
    <img className={`${styles.image} ${className}`} data-testid={testId} src={src} />
);

export default ImageContainer;
