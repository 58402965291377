import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const HorizontalContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    onClick,
    testId = TEST_ID
}) => (
    <div
        className={`${className} ${styles.horizontalContainer}`}
        data-testid={testId}
        onClick={onClick}
    >
        {children}
    </div>
);

export default HorizontalContainer;
