import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const TableRow: FunctionComponent<Props> = ({ children, withDivider = true }) => (
    <View style={[styles.tableRow, withDivider ? styles.rowDivider : {}]}>{children}</View>
);

export default TableRow;
