import { INPUT_CHARS_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderCustomerInformation');

    return {
        ERRORS: {
            ADDRESS_MAX: t('errors.addressMax', { charMax: INPUT_CHARS_MAX }),
            ADDRESS_REQUIRED: t('errors.addressRequired'),
            ADDRESS2_MAX: t('errors.address2Max', { charMax: INPUT_CHARS_MAX }),
            CITY_MAX: t('errors.cityMax', { charMax: INPUT_CHARS_MAX }),
            CITY_REQUIRED: t('errors.cityRequired'),
            CONTACT_MAX: t('errors.contactMax', { charMax: INPUT_CHARS_MAX }),
            LOCATION_INVALID: t('errors.locationInvalid'),
            NAME_MAX: t('errors.nameMax', { charMax: INPUT_CHARS_MAX }),
            NAME_REQUIRED: t('errors.nameRequired'),
            PHONE_INVALID: t('errors.phoneInvalid'),
            STATE_REQUIRED: t('errors.stateRequired'),
            ZIP_CODE_REQUIRED: t('errors.zipCodeRequired')
        },
        TITLE: t('title')
    };
};

export default useTranslation;
