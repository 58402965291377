const translations = {
    contact: 'Contact',
    grower: 'Grower',
    noJobs: 'No Jobs Scheduled',
    phoneNumber: 'Phone Number',
    siteName: 'Site Name',
    status: 'Status',
    time: 'Time'
};

export default translations;
