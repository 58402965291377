import { Container, Footer, rem } from '@mantine/core';

import FooterNavigationLink from 'components/FooterNavigationLink';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

import styles from './styles.module.scss';

const SiteFooter: FunctionComponent = () => {
    const { COPYRIGHT, TERMS_OF_SERVICE } = useTranslation();
    const { termsOfServicePage } = usePageRoutes();

    const currentYear = new Date().getFullYear().toString();

    return (
        <Footer className={styles.navbarBottomContainer} data-testid="site-footer" height={rem(60)}>
            <Container className={styles.navbarBottom}>
                <FooterNavigationLink label={TERMS_OF_SERVICE} link={termsOfServicePage} />
            </Container>

            <p className={styles.copyright}>{COPYRIGHT(currentYear)}</p>
        </Footer>
    );
};

export default SiteFooter;
