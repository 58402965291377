import type { Style } from './types';

const styles: Record<string, Style> = {
    dataLabel: {
        flexBasis: '45%',
        flexGrow: 0,
        flexShrink: 0
    },
    dataLabelText: {
        fontFamily: 'Inter',
        fontSize: '11pt',
        fontWeight: 'medium'
    }
};

export default styles;
