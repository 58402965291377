import { FullscreenControl, Map, NavigationControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { bbox } from '@turf/turf';

import { CustomMapboxControl } from './classes';
import DrawToolsControl from './components/DrawToolsControl';
import RecenterControl from './components/RecenterControl';
import { TEST_ID } from './constants';
import type {
    AnySourceData,
    DrawHandler,
    FunctionComponent,
    GeoJSON,
    GeoJSONSourceRaw,
    MapType,
    MapboxEvent,
    Props
} from './types';

import styles from './styles.module.scss';

const Mapbox: FunctionComponent<Props> = ({
    additionalButtons = [],
    center = [-74.5, 40],
    className = '',
    initialDrawState,
    initialOptions,
    interactive = true,
    isEditable = false,
    mapSource,
    onDrawCreate,
    onDrawDelete,
    onDrawUpdate,
    onMapLoaded,
    onMapRemoved,
    onMapResized,
    testId = TEST_ID
}) => {
    const [map, setMap] = useState<MapType>();
    const mapNode = useRef(null);
    const drawControl = useMemo(() => new CustomMapboxControl(), []);
    const recenterControl = useMemo(() => new CustomMapboxControl(), []);
    const additionalControls = useMemo(() => {
        const arr: CustomMapboxControl[] = Array.from({ length: additionalButtons.length }).map(
            _ => new CustomMapboxControl()
        );

        return arr;
    }, [additionalButtons.length]);

    const fitToData = useCallback((source: AnySourceData, map: MapType) => {
        if (source.type === 'geojson') {
            const box = bbox(source.data);

            map.fitBounds(box, { linear: true, padding: 20 });
        } else if (source.type === 'image' && source.coordinates !== undefined) {
            // Reverse coordinates back to min-x, min-y, max-x, max-y
            const box: [number, number, number, number] = [
                source.coordinates[3][0],
                source.coordinates[3][1],
                source.coordinates[1][0],
                source.coordinates[1][1]
            ];

            map.fitBounds(box, { linear: true, padding: 20 });
        }
    }, []);

    const onMapResize = useCallback(
        (event: MapboxEvent) => {
            const mapboxMap = event.target;

            if (mapSource && !initialDrawState) {
                fitToData(mapSource, mapboxMap);
            }

            if (initialDrawState && initialDrawState?.length > 0) {
                const featureCollection: GeoJSON = {
                    features: initialDrawState,
                    type: 'FeatureCollection'
                };

                const geojson: GeoJSONSourceRaw = { data: featureCollection, type: 'geojson' };

                fitToData(geojson, mapboxMap);
            }

            onMapResized?.(event);
        },
        [fitToData, initialDrawState, mapSource, onMapResized]
    );

    const maybeAddEvent = useCallback(
        (event: MapboxEvent, eventName: string, eventHandler: DrawHandler | undefined) => {
            if (eventHandler !== undefined) {
                event.target.on(eventName, eventHandler);
            }
        },
        []
    );

    const maybeRemoveEvent = useCallback(
        (eventName: string, eventHandler: DrawHandler | undefined) => {
            if (map && eventHandler !== undefined) {
                map.off(eventName, eventHandler);
            }
        },
        [map]
    );

    const handleMapLoaded = useCallback(
        (event: MapboxEvent) => {
            if (isEditable) {
                maybeAddEvent(event, 'draw.create', onDrawCreate);
                maybeAddEvent(event, 'draw.delete', onDrawDelete);
                maybeAddEvent(event, 'draw.update', onDrawUpdate);
            }

            onMapLoaded?.(event);
        },
        [isEditable, maybeAddEvent, onDrawCreate, onDrawDelete, onDrawUpdate, onMapLoaded]
    );

    const handleMapRemoved = useCallback(() => {
        if (map) {
            map.off('resize', onMapResize);

            if (isEditable) {
                maybeRemoveEvent('draw.create', onDrawCreate);
                maybeRemoveEvent('draw.delete', onDrawDelete);
                maybeRemoveEvent('draw.update', onDrawUpdate);
            }

            map.remove();
            onMapRemoved?.();
        }
    }, [
        isEditable,
        map,
        maybeRemoveEvent,
        onDrawCreate,
        onDrawDelete,
        onDrawUpdate,
        onMapRemoved,
        onMapResize
    ]);

    useEffect(() => {
        const node = mapNode.current;

        if (typeof window === 'undefined' || node === null) {
            return;
        }

        const mapboxMap = new Map({
            accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
            center: center,
            container: node,
            interactive: interactive || isEditable,
            maxZoom: 19,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            touchZoomRotate: false,
            zoom: 16,
            ...initialOptions
        });

        mapboxMap.on('resize', onMapResize);

        if (interactive || isEditable) {
            mapboxMap.addControl(new FullscreenControl(), 'top-right');
            mapboxMap.addControl(new NavigationControl(), 'bottom-right');
        }

        if (isEditable) {
            mapboxMap.addControl(drawControl, 'top-left');
        }

        if (mapSource && interactive) {
            mapboxMap.addControl(recenterControl, 'top-left');
        }

        setMap(mapboxMap);

        mapboxMap.once('load', handleMapLoaded);

        return () => {
            handleMapRemoved();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interactive, isEditable]);

    useEffect(() => {
        if (map) {
            if (additionalControls.length > 0) {
                additionalControls.forEach((control, index) => {
                    if (map.hasControl(control)) {
                        map.removeControl(control);
                    }
                    map.addControl(control, additionalButtons[index].position);
                });
            }
        }
    }, [map, additionalButtons, additionalControls]);

    return (
        <>
            <div className={`${styles.mapbox} ${className}`} data-testid={testId} ref={mapNode} />

            {drawControl &&
                map &&
                createPortal(
                    <DrawToolsControl
                        initialState={initialDrawState}
                        isEditable={isEditable}
                        map={map}
                    />,
                    drawControl._container,
                    Math.random().toString()
                )}

            {recenterControl &&
                mapSource &&
                map &&
                createPortal(
                    <RecenterControl onClick={() => fitToData(mapSource, map)} />,
                    recenterControl._container,
                    Math.random().toString()
                )}

            {additionalButtons.map(({ component, portalKey }, index) => {
                const { _container } = additionalControls[index];

                return createPortal(component, _container, portalKey);
            })}
        </>
    );
};

export default Mapbox;
