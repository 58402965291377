const translations = {
    errors: {
        commodityMax: 'Commodity must be less than {{charMax}} characters',
        proposedAcresMax: 'Proposed acres must be less than {{acreMax}} acres',
        proposedAcresMin: 'Proposed acres must be greater than 0',
        targetSprayRateMax: 'Target Spray Rate must be less than {{sprayMax}} Ga/Ac',
        targetSprayRateMin: 'Target Spray Rate must be greater than 0'
    },
    labels: {
        commodity: 'Site Commodity',
        preHarvestInterval: 'Pre-Harvest Interval',
        proposedAcres: 'Proposed Acres',
        reEntryIntervalDays: 'Re-Entry Interval',
        targetSprayRate: 'Target Spray Rate'
    },
    units: {
        acres: 'Ac',
        days: 'Days',
        fluidOzPerAcre: 'Fl. Oz/ac',
        gallonsPerAcre: 'Ga/Ac',
        hectares: 'ha',
        hours: 'Hours',
        litersPerHectare: 'L/ha'
    }
};

export default translations;
