const translations = {
    buttonText: 'Delete',
    confirmDeleteFlightLog: 'Delete Flight Log?',
    confirmDeleteFlightLogMessage: 'Are you sure you want to delete this flight log?',
    confirmDeleteFlightLogSubtextMessage: 'This action cannot be undone.',
    confirmDeleteFlightLogs: 'Delete Flight Logs?',
    confirmDeleteFlightLogsMessage: 'Are you sure you want to delete the selected flight logs?'
};

export default translations;
