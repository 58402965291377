import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createFaaReportOperationDetails');

    return {
        ERROR_ADDING_FLIGHT_REPORT: t('flightReportError'),
        FILE_UPLOADS: t('fileUploads'),
        FLIGHT_REPORT_UPLOAD: t('flightReportUpload'),
        REPORTING_PERIOD: t('reportingPeriod')
    };
};

export default useTranslation;
