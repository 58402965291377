import { useCallback, useState } from 'react';

import HighlightTab from 'components/HighlightTab';
import HighlightTabs from 'components/HighlightTabs';

import useTranslation from './hooks/useTranslation';

import { INITIAL_TAB, TEST_ID } from './constants';
import { FunctionComponent, Props, Tab } from './types';

import styles from './styles.module.scss';

const WorkOrderTabs: FunctionComponent<Props> = ({
    className = '',
    onChange,
    testId = TEST_ID
}) => {
    const [tab, setTab] = useState(INITIAL_TAB);

    const { PAST_JOBS, UPCOMING_JOBS } = useTranslation();

    const handleTabClick = useCallback(
        (value: Tab) => {
            setTab(value);
            onChange?.(value);
        },
        [onChange]
    );

    return (
        <HighlightTabs className={className} selectedClass={styles[tab] || ''} testId={testId}>
            <HighlightTab
                isActive={tab === 'upcoming'}
                onClick={() => handleTabClick('upcoming')}
                text={UPCOMING_JOBS}
            />

            <HighlightTab
                isActive={tab === 'past'}
                onClick={() => handleTabClick('past')}
                text={PAST_JOBS}
            />
        </HighlightTabs>
    );
};

export default WorkOrderTabs;
