import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import WithErrorMessage from 'components/WithErrorMessage';
import Dropdown from 'components/form/Dropdown';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import { OnError, Props } from './types';

const AircraftDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            ignoreList = [],
            isDisabled = false,
            isEditable = true,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            selectedId,
            testId = TEST_ID
        },
        ref
    ) => {
        const { aircraft, fetchAircraft } = useData();

        useEffect(() => {
            fetchAircraft();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const aircraftList = useMemo(
            () => aircraft.filter(({ value }) => !ignoreList.includes(value)),
            [aircraft, ignoreList]
        );

        const [errorMessage, setErrorMessage] = useState('');

        const handleError = useCallback(
            (_dropdownValue: unknown, error: string) => {
                onError?.(error);
                setErrorMessage(error);
            },
            [onError]
        );

        const handleChange = useCallback(
            (_selectedValue: unknown, _handleError: OnError, index: number) => {
                onChange?.(JSON.stringify(aircraftList[index]));
            },
            [aircraftList, onChange]
        );

        const hasAnError = Boolean(hasError || errorMessage);

        const defaultSelectedIndex = useMemo(() => {
            if (selectedId && aircraftList.length > 0) {
                const selectedIndex = aircraftList.findIndex(
                    ({ value }) => JSON.parse(value).id === selectedId
                );

                return selectedIndex >= 0 ? selectedIndex : undefined;
            }

            return undefined;
        }, [aircraftList, selectedId]);

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasAnError}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    isRequired={isRequired}
                    items={aircraftList}
                    label={label}
                    onChange={handleChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    ref={ref}
                    selected={defaultSelectedIndex}
                    testId={testId}
                />
            </WithErrorMessage>
        );
    }
);

AircraftDropdown.displayName = 'AircraftDropdown';

export default AircraftDropdown;
