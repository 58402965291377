import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogForm');

    return {
        COVERAGE_MAP_WARNING: t('coverageMapWarning'),
        FLIGHT_AREA_VALIDATION_ERROR: t('flightAreaValidationError'),
        FLIGHT_OVERVIEW: t('flightOverview'),
        FLIGHT_SPRAY_VALIDATION_ERROR: t('flightSprayValidationError'),
        FLIGHTS_FOUND: t('flightsFound'),
        MAP_ERROR: t('mapError'),
        MAX_NUMBER_OF_FLIGHTS_ERROR: (maxNumberOfFlights: number) =>
            t('maxNumberOfFlightsError', { maxNumberOfFlights }),
        MULTIPLE_FLIGHT_TYPES_SELECTED: t('multipleFlightTypesSelected'),
        SELECT_FLIGHTS: t('selectFlights')
    };
};

export default useTranslation;
