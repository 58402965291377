import { useCallback } from 'react';

import useFlightReport from 'hooks/useFlightReport';

import { UseData } from './types';

const useData: UseData = ({ droneReportId, faaReportId }) => {
    const { fetchFlightReports } = useFlightReport();

    const fetch = useCallback(
        async (hasNext: boolean, nextToken: string) => {
            try {
                const response = await fetchFlightReports(
                    faaReportId,
                    droneReportId,
                    hasNext ? nextToken : undefined
                );

                return response;
            } catch (error) {
                console.log(error);
                alert(error);
            }
        },
        [fetchFlightReports, faaReportId, droneReportId]
    );

    return { fetch };
};

export default useData;
