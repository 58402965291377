import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('organizationSubscriptionDetails');

    return {
        ESSENTIALS_SUITE: t('essentialsSuite'),
        FREE_ACCOUNT: t('freeAccount'),
        LEARN_BUTTON: t('learnButton'),
        PREMIER_SUITE: t('premierSuite'),
        SUBSCRIPTION: t('subscription'),
        TRIAL: t('trial'),
        UPGRADE_BUTTON: t('upgradeButton')
    };
};

export default useTranslation;
