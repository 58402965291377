import ColoredLabel from 'components/colored-labels/ColoredLabel';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CsvLabel: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const { CSV } = useTranslation();

    return (
        <ColoredLabel className={`${styles.csvLabel} ${className}`} testId={testId} text={CSV} />
    );
};

export default CsvLabel;
