export const workOrderStatusOptions = [
    {
        label: 'Canceled',
        value: 'CANCELED'
    },
    {
        label: 'Complete',
        value: 'COMPLETE'
    },
    {
        label: 'Pending',
        value: 'PENDING'
    },
    {
        label: 'Scheduled',
        value: 'SCHEDULED'
    },
    {
        label: 'Started',
        value: 'STARTED'
    }
];

export const acreageUnitOptions = [
    { label: 'Ac', value: 'ac' },
    { label: 'Ha', value: 'ha' }
];

export const targetSprayRateUnitOptions = [
    { label: 'Ga/Ac', value: 'gaPerAc' },
    { label: 'L/Ha', value: 'lPerHa' },
    { label: 'Fl. Oz/Ac', value: 'flozPerAc' }
];
