import { EDIT_CONTACT_API } from '../../constants';
import { PersonOfContactForm } from '../../types';
import { useCallback } from 'react';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFetch from 'hooks/useFetch';

const useVerifyAccount = () => {
    const { fetchPersonOfContact } = useCurrentOrganization();
    const { authenticatedPut } = useFetch();

    const submitForm = useCallback(
        async (data: PersonOfContactForm) => {
            const { email, firstName, lastName, phone } = data;

            const requestData = {
                email,
                firstName,
                lastName,
                phoneNumber: phone.replaceAll(/[-() ]/g, '')
            };
            const response =
                (await authenticatedPut(EDIT_CONTACT_API, JSON.stringify(requestData))) || {};

            return response;
        },
        [authenticatedPut]
    );

    const getInitialValues = useCallback(async () => {
        const contact = await fetchPersonOfContact(false);

        if (contact === null || contact === undefined) {
            return { email: '', firstName: '', lastName: '', phone: '' } as PersonOfContactForm;
        }

        return {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phoneNumber
        } as PersonOfContactForm;
    }, [fetchPersonOfContact]);

    return {
        getInitialValues,
        submitForm
    };
};

export default useVerifyAccount;
