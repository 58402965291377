export type BackendUnit =
    | 'ac'
    | '°C'
    | '°F'
    | 'fl.oz/acre'
    | 'gal/acre'
    | 'ha'
    | 'l/acre'
    | 'l/ha'
    | 'l/m²'
    | 'mph'
    | 'pt/acre'
    | 'qt/acre'
    | 'kg/m²'
    | 'oz/acre'
    | 'lb/acre';

export type Unit = { label: string; value: string };

export const FLUID_OUNCES_PER_ACRE: Unit = { label: 'Fl. Oz/Ac', value: 'flozPerAc' };
export const GALLONS_PER_ACRE: Unit = { label: 'Ga/Ac', value: 'gaPerAc' };
export const PINTS_PER_ACRE: Unit = { label: 'Pt/Ac', value: 'pintPerAc' };
export const QUARTS_PER_ACRE: Unit = { label: 'Qt/Ac', value: 'quartPerAc' };
export const LITERS_PER_HECTARE: Unit = { label: 'L/Ha', value: 'lPerHa' };
export const KILOGRAMS_PER_SQUARE_METER: Unit = { label: 'Kg/m² (Dry)', value: 'kgPerM2' };
export const POUNDS_PER_ACRE: Unit = { label: 'Lb/Ac (Dry)', value: 'lbPerAc' };
export const OUNCES_PER_ACRE: Unit = { label: 'Oz/Ac (Dry)', value: 'ozPerAc' };

export const ACRES: Unit = { label: 'Ac', value: 'ac' };
export const HECTARES: Unit = { label: 'Ha', value: 'ha' };
export const FAHRENHEIT: Unit = { label: '\u00b0F', value: 'fahrenheit' };
export const CELSIUS: Unit = { label: '\u00b0C', value: 'celsius' };
export const MILES_PER_HOUR: Unit = { label: 'MPH', value: 'mph' };

export const massRates = ['kgPerM2', 'lbPerAc', 'ozPerAc'];

export const backendUnitMap: Record<string, BackendUnit> = {
    flozPerAc: 'fl.oz/acre',
    gaPerAc: 'gal/acre',
    kgPerM2: 'kg/m²',
    lbPerAc: 'lb/acre',
    ozPerAc: 'oz/acre',
    pintPerAc: 'pt/acre',
    quartPerAc: 'qt/acre'
};

export const frontendUnitMap: Record<string, Unit> = {
    'fl.oz/acre': FLUID_OUNCES_PER_ACRE,
    'gal/acre': GALLONS_PER_ACRE,
    'kg/m²': KILOGRAMS_PER_SQUARE_METER,
    'lb/acre': POUNDS_PER_ACRE,
    'oz/acre': OUNCES_PER_ACRE,
    'pt/acre': PINTS_PER_ACRE,
    'qt/acre': QUARTS_PER_ACRE
};

// TODO we really have to standardize these units at some point

export const frontendToAreaRateUnitMap: Record<string, string> = {
    flozPerAc: 'FLUID_OUNCES_PER_ACRE',
    gaPerAc: 'GALLONS_PER_ACRE',
    kgPerM2: 'KILOGRAMS_PER_SQUARE_METER',
    lbPerAc: 'POUNDS_PER_ACRE',
    ozPerAc: 'OUNCES_PER_ACRE',
    pintPerAc: 'PINTS_PER_ACRE',
    quartPerAc: 'QUARTS_PER_ACRE'
};

export const rateUnitMap: Record<string, string> = {
    FLUID_OUNCES_PER_ACRE: 'FLUID_OUNCES',
    GALLONS_PER_ACRE: 'GALLONS',
    KILOGRAMS_PER_SQUARE_METER: 'KILOGRAMS',
    LITERS_PER_SQUARE_METER: 'LITERS',
    OUNCES_PER_ACRE: 'OUNCES',
    PINTS_PER_ACRE: 'PINTS',
    POUNDS_PER_ACRE: 'POUNDS',
    QUARTS_PER_ACRE: 'QUARTS'
};
