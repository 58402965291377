'use client';

import useFlightReports from 'hooks/useFlightReports';

import { FunctionComponent, Props } from './types';

const FlightReportsProvider: FunctionComponent<Props> = ({ children, value }) => {
    const { context } = useFlightReports();

    return <context.Provider value={value}>{children}</context.Provider>;
};

FlightReportsProvider.displayName = 'FlightReportsProvider';

export default FlightReportsProvider;
