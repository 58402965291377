import { Form as FormikForm } from 'formik';

import { FormContainerProps, FunctionComponent } from './types';

import styles from './styles.module.scss';

const FormContainer: FunctionComponent<FormContainerProps> = ({ children, className = '' }) => (
    <FormikForm className={`${className} ${styles.container}`} noValidate>
        {children}
    </FormikForm>
);

export default FormContainer;
