import { useCallback } from 'react';

import useFileUploadHandler from 'hooks/useFileUploadHandler';
import useKmlValidation from 'hooks/useKmlValidation';

import { XAG_FILE_CSV_UPLOAD, XAG_FILE_KML_UPLOAD } from './constants';
import { FileUpload, FileUploadHandlerHook } from './types';

const useXagFileUpload: FileUploadHandlerHook = ({ selectedAircraft }) => {
    const {
        addUploadedFile,
        getPresignedUrlFromEndpoint,
        getUploadedFiles,
        isValidFile,
        setUploadedFiles
    } = useFileUploadHandler({
        validFileExtension: ['CSV', 'KML']
    });

    const { sanitizeFile, validateKmlFileAttribute } = useKmlValidation();

    const getPresignedUrl = useCallback(
        async (fileUpload: FileUpload) => {
            const fileExtension = fileUpload.file.name.split('.').pop()?.toUpperCase();
            const endpoint = fileExtension === 'KML' ? XAG_FILE_KML_UPLOAD : XAG_FILE_CSV_UPLOAD;

            return await getPresignedUrlFromEndpoint(fileUpload, endpoint);
        },
        [getPresignedUrlFromEndpoint]
    );

    const modifyFileRequest = useCallback(
        async (fileUpload: FileUpload): Promise<FileUpload> => await sanitizeFile(fileUpload),
        [sanitizeFile]
    );

    const parseCsvLine = useCallback((line: string) => {
        const parsedValues = line.replace(/[\t"]/g, '').trim().split(',');

        return parsedValues;
    }, []);

    const validateSelectedAircraftMatchesFile = useCallback(
        (uploadedSerial: string) => {
            if (selectedAircraft?.flightControllerSerial !== uploadedSerial) {
                throw new Error('AIRCRAFT_MISMATCH');
            }
        },
        [selectedAircraft]
    );

    const validateSingleAircraftInFile = useCallback(
        ({ lines, uploadedSerial }: { lines: string[]; uploadedSerial: string }) => {
            lines.map((line, lineIndex) => {
                const serialPerLine = parseCsvLine(line)[0];

                if (lineIndex <= 1 || serialPerLine.length < 1) {
                    return;
                }

                if (serialPerLine !== uploadedSerial) {
                    throw new Error('CSV_MULTI_AIRCRAFT_ERROR');
                }
            });
        },
        [parseCsvLine]
    );

    const validateCsvFile = useCallback(
        async (file: File) => {
            const fileContent = await file.text();
            const lines = fileContent.split('\n');
            const headers = parseCsvLine(lines[0]);

            if (
                !headers.includes('aircraft serial number') ||
                !headers.includes('battery serial number') ||
                !headers.includes('flight UTC time') ||
                !headers.includes('work type') ||
                !headers.includes('speed(m/s)') ||
                !headers.includes('longitude') ||
                !headers.includes('latitude') ||
                !headers.includes('height(m)') ||
                !headers.includes('total dosage(ml)') ||
                !headers.includes('discharge rate(ml/min)')
            ) {
                throw new Error('INVALID_DATA');
            }
            const line2Values = parseCsvLine(lines[1]);
            const uploadedAircraftSerial = line2Values[0];

            validateSingleAircraftInFile({ lines, uploadedSerial: uploadedAircraftSerial });

            validateSelectedAircraftMatchesFile(uploadedAircraftSerial);
        },
        [parseCsvLine, validateSelectedAircraftMatchesFile, validateSingleAircraftInFile]
    );

    const validateVersionOneKmlFile = useCallback(
        (parsedFile: Document) => {
            validateKmlFileAttribute(parsedFile, 'serial number');
            validateKmlFileAttribute(parsedFile, 'work time');
            validateKmlFileAttribute(parsedFile, 'work duration');
            validateKmlFileAttribute(parsedFile, 'work area');
            validateKmlFileAttribute(parsedFile, 'work height');
            validateKmlFileAttribute(parsedFile, 'spray width');
            validateKmlFileAttribute(parsedFile, 'speed');
            validateKmlFileAttribute(parsedFile, 'total dosage');
        },
        [validateKmlFileAttribute]
    );

    const validateVersionTwoKmlFile = useCallback(
        (parsedFile: Document) => {
            validateKmlFileAttribute(parsedFile, 'Device name');
            validateKmlFileAttribute(parsedFile, 'Serial No.');
            validateKmlFileAttribute(parsedFile, 'Operation duration');
            validateKmlFileAttribute(parsedFile, 'Height');
            validateKmlFileAttribute(parsedFile, 'Driving distance');
            validateKmlFileAttribute(parsedFile, 'Width');
            validateKmlFileAttribute(parsedFile, 'Speed');
            validateKmlFileAttribute(parsedFile, 'Total use');
        },
        [validateKmlFileAttribute]
    );

    const validateKmlFile = useCallback(
        async (file: File) => {
            const parser = new DOMParser();

            const parsedFile = parser.parseFromString(await file.text(), 'application/xml');

            try {
                validateVersionOneKmlFile(parsedFile);
            } catch (error) {
                console.log(error);

                validateVersionTwoKmlFile(parsedFile);
            }
        },
        [validateVersionOneKmlFile, validateVersionTwoKmlFile]
    );

    const validateFile = useCallback(
        async (fileUpload: FileUpload) => {
            const file = fileUpload.file;

            if (!isValidFile(file)) {
                throw new Error('INVALID_EXTENSION');
            }
            if (file.name.split('.').pop()?.toUpperCase() === 'KML') {
                await validateKmlFile(file);
            } else {
                await validateCsvFile(file);
            }

            return true;
        },
        [isValidFile, validateKmlFile, validateCsvFile]
    );

    return {
        addUploadedFile,
        getPresignedUrl,
        getUploadedFiles,
        modifyFileRequest,
        setUploadedFiles,
        validateFile
    };
};

export default useXagFileUpload;
