import { format } from 'date-fns';

import { View } from '@react-pdf/renderer';

import DataContainer from 'components/pdf/DataContainer';
import DataCustomValue from 'components/pdf/DataCustomValue';
import DataMeasurementValue from 'components/pdf/DataMeasurementValue';
import Document from 'components/pdf/Document';
import Label from 'components/pdf/Label';
import Legend from 'components/pdf/Legend';
import MapImage from 'components/pdf/MapImage';
import PageWithHeaderAndFooter from 'components/pdf/PageWithHeaderAndFooter';
import Row from 'components/pdf/Row';
import VerticalFlexDivider from 'components/pdf/VerticalFlexDivider';

import useTranslation from './hooks/useTranslation';

import { DATE_FORMAT } from './constants';
import type { FunctionComponent, Props } from './types';

const dateFormat = 'EEE, MMMM do yyyy h:mm a';

const AsAppliedMapPDF: FunctionComponent<Props> = ({
    chemicalNames,
    data,
    legend,
    mapLink,
    mapType,
    siteName
}) => {
    const {
        APPLIED_RATE,
        APPLIED_TOTAL,
        AREA_APPLIED,
        AS_APPLIED_MAP_TITLE,
        AS_COVERED_MAP_TITLE,
        CHEMICALS_APPLIED,
        DATA,
        END_TIME,
        GENERATED_DATE,
        LEGEND,
        LOCATION,
        START_TIME,
        TBD
    } = useTranslation();

    if (!mapLink || !legend) {
        return <Document />;
    }

    const { appliedRate, appliedTotal, areaApplied, centerPoint, endTime, startTime } = data;

    const mapTitle = mapType === 'AAM' ? AS_APPLIED_MAP_TITLE : AS_COVERED_MAP_TITLE;

    return (
        <Document title={`AAM_${format(new Date(), DATE_FORMAT)}.pdf`}>
            <PageWithHeaderAndFooter headerText={GENERATED_DATE}>
                <View>
                    <Label text={mapTitle} />
                </View>

                <MapImage mapImageUrl={mapLink} />

                <Row>
                    <DataContainer>
                        <Label text={DATA} />

                        <View>
                            <DataCustomValue text={CHEMICALS_APPLIED} value={chemicalNames} />

                            <DataCustomValue text={LOCATION} value={siteName ? siteName : TBD} />

                            <DataCustomValue
                                text={''}
                                value={`(${centerPoint.coordinates[0]}, ${centerPoint.coordinates[1]})`}
                            />

                            <DataCustomValue
                                text={START_TIME}
                                value={format(new Date(startTime), dateFormat)}
                            />

                            {mapType === 'AAM' && (
                                <>
                                    <DataCustomValue
                                        text={END_TIME}
                                        value={format(new Date(endTime), dateFormat)}
                                    />

                                    <DataMeasurementValue
                                        measurement={areaApplied}
                                        text={AREA_APPLIED}
                                    />

                                    <DataMeasurementValue
                                        measurement={appliedRate}
                                        text={APPLIED_RATE}
                                    />

                                    <DataMeasurementValue
                                        measurement={appliedTotal}
                                        text={APPLIED_TOTAL}
                                    />
                                </>
                            )}
                        </View>
                    </DataContainer>

                    <VerticalFlexDivider />

                    {mapType === 'AAM' && <Legend legend={legend} title={LEGEND} />}
                </Row>
            </PageWithHeaderAndFooter>
        </Document>
    );
};

export default AsAppliedMapPDF;
