import TableColumn from 'components/pdf/TableColumn';
import InvoiceTableHeaderText from 'components/pdf/invoice/table/InvoiceTableHeaderText';
import InvoiceTableHighlightedRow from 'components/pdf/invoice/table/InvoiceTableHighlightedRow';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

import styles from './styles';

const InvoiceTableHeader: FunctionComponent = () => {
    const { DESCRIPTION, ITEM, TOTAL } = useTranslation();

    return (
        <InvoiceTableHighlightedRow>
            <TableColumn style={styles.itemColumn} withDivider={false}>
                <InvoiceTableHeaderText text={ITEM} />
            </TableColumn>

            <TableColumn style={styles.descriptionColumn} withDivider={false}>
                <InvoiceTableHeaderText text={DESCRIPTION} />
            </TableColumn>

            <TableColumn style={styles.totalColumn} withDivider={false}>
                <InvoiceTableHeaderText text={TOTAL} />
            </TableColumn>
        </InvoiceTableHighlightedRow>
    );
};

export default InvoiceTableHeader;
