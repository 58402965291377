import SectionColumn from 'components/pdf/SectionColumn';
import InvoiceText from 'components/pdf/invoice/InvoiceText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const InvoiceWorkOrderColumn: FunctionComponent<Props> = ({ workOrderNumber }) => {
    const { WORK_ORDER_NUMBER } = useTranslation();

    return (
        <SectionColumn title={WORK_ORDER_NUMBER}>
            <InvoiceText text={workOrderNumber.toString()} />
        </SectionColumn>
    );
};

export default InvoiceWorkOrderColumn;
