import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import NotFoundErrorPage from 'components/NotFoundErrorPage';

import useBack from 'hooks/useBack';
import useWorkOrder from 'hooks/useWorkOrder';

import WorkOrderPage from 'pages/WorkOrderPage';

import useTranslation from './hooks/useTranslation';

import { defaultWorkOrder } from './defaults';
import { FunctionComponent, Props, WorkOrder } from './types';

const WorkOrderReportPage: FunctionComponent<Props> = ({
    isDuplicate = false,
    startEditable = false
}) => {
    const back = useBack();
    const { workOrderId } = useParams();
    const { TITLE } = useTranslation();
    const { fetchWorkOrder } = useWorkOrder();

    const [workOrder, setWorkOrder] = useState<WorkOrder>(defaultWorkOrder);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loading, setLoading] = useState(true);

    const workOrderTitle = useMemo(() => `${TITLE} (${workOrder.key ?? ''})`, [workOrder, TITLE]);

    useEffect(
        () => {
            const fetch = async () => {
                if (typeof workOrderId === 'string') {
                    const response = await fetchWorkOrder(workOrderId);

                    setLoading(false);

                    if (response.error) {
                        // Redirect to not found page
                        setIsNotFound(true);
                    } else {
                        const workOrderResult = response.data;

                        setWorkOrder(workOrderResult);
                        setIsNotFound(false);
                    }
                } else {
                    setIsNotFound(true);
                }
            };

            fetch();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [workOrderId]
    );

    const backHome = useCallback(() => {
        back();
    }, [back]);

    return isNotFound ? (
        <NotFoundErrorPage onBack={backHome} resourceName={workOrderTitle} />
    ) : (
        <WorkOrderPage
            isDuplicate={isDuplicate}
            isLoading={loading}
            startEditable={startEditable}
            title={workOrderTitle}
            workOrder={workOrder}
        />
    );
};

export default WorkOrderReportPage;
