import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('asAppliedMapResultsPage');

    return {
        AS_APPLIED_MAP: t('asAppliedMap'),
        AS_COVERED: t('asCovered'),
        RESOURCE_NAME: t('resourceName')
    };
};

export default useTranslation;
