import { Text, View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const LegendValue: FunctionComponent<Props> = ({ value }) => (
    <View style={styles}>
        <Text>{value}</Text>
    </View>
);

export default LegendValue;
