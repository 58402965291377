import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderApplicationSiteFields');

    return {
        APPLICATION_SITE: t('applicationSite'),
        HIDE_MAP: t('hideMap'),
        LATITUDE: t('latitude'),
        LONGITUDE: t('longitude'),
        SHOW_MAP: t('showMap')
    };
};

export default useTranslation;
