import Loader from 'components/Loader';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const PageLoader: FunctionComponent<Props> = ({ testId = TEST_ID }) => (
    <Loader className={styles.pageLoader} testId={testId} />
);

export default PageLoader;
