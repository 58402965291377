import { useCallback } from 'react';

import useConvert from 'hooks/useConvert';
import useRoundFormat from 'hooks/useRoundFormat';

import { DEGREE_SYMBOL } from './constants';
import type { CoordinateType } from './types';

const useCoordinateFormat = () => {
    const {
        convertDecimalDegreesToDegreesDecimalMinutes,
        convertDecimalDegreesToDegreesMinutesSeconds
    } = useConvert();
    const round = useRoundFormat();

    const getCardinality = useCallback((value: number, type: CoordinateType) => {
        if (value >= 0) {
            if (type === 'latitude') {
                return 'N';
            }

            return 'E';
        } else {
            if (type === 'latitude') {
                return 'S';
            }

            return 'W';
        }
    }, []);

    const formatCoordinateDegreesDecimalMinutes = useCallback(
        (value: number, type: CoordinateType) => {
            const suffix = getCardinality(value, type);
            const { degrees, minutes } = convertDecimalDegreesToDegreesDecimalMinutes(value);

            return `${Math.abs(degrees)}${DEGREE_SYMBOL} ${round(minutes)}' ${suffix}`;
        },
        [convertDecimalDegreesToDegreesDecimalMinutes, getCardinality, round]
    );

    const formatCoordinateDegreesMinutesSeconds = useCallback(
        (value: number, type: CoordinateType) => {
            const suffix = getCardinality(value, type);
            const { degrees, minutes, seconds } =
                convertDecimalDegreesToDegreesMinutesSeconds(value);

            return `${Math.abs(degrees)}${DEGREE_SYMBOL} ${minutes}' ${round(seconds)}" ${suffix}`;
        },
        [convertDecimalDegreesToDegreesMinutesSeconds, getCardinality, round]
    );

    return {
        formatCoordinateDegreesDecimalMinutes,
        formatCoordinateDegreesMinutesSeconds
    };
};

export default useCoordinateFormat;
