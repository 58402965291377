import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightReportList');

    return {
        DATE: t('date'),
        FLIGHT_TIME: t('flightTime'),
        FLIGHTS: t('flights'),
        LOCATION: t('location'),
        PILOT: t('pilot')
    };
};

export default useTranslation;
