import DataLabel from 'components/pdf/DataLabel';
import DataRow from 'components/pdf/DataRow';
import DataValue from 'components/pdf/DataValue';

import type { FunctionComponent, Props } from './types';

const DataCustomValue: FunctionComponent<Props> = ({ text, value }) => (
    <DataRow>
        <DataLabel text={text} />

        <DataValue value={value} />
    </DataRow>
);

export default DataCustomValue;
