import ListHeader from 'components/ListHeader';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FaaReportListHeaders: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const {
        TOTAL_HOURS_OF_FLIGHT,
        TOTAL_NUMBER_OF_DRONES,
        TOTAL_NUMBER_OF_FLIGHTS,
        TOTAL_NUMBER_OF_PILOTS
    } = useTranslation();

    return (
        <div className={`${styles.labelContainer} ${className}`} data-testid={testId}>
            <ListHeader text={TOTAL_HOURS_OF_FLIGHT} />

            <ListHeader text={TOTAL_NUMBER_OF_FLIGHTS} />

            <ListHeader text={TOTAL_NUMBER_OF_DRONES} />

            <ListHeader text={TOTAL_NUMBER_OF_PILOTS} />
        </div>
    );
};

export default FaaReportListHeaders;
