import { INPUT_CHARS_MAX, WORK_ORDER_ACRE_MAX, WORK_ORDER_TARGET_SPRAY_GA_AC_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderSiteCommodity');

    return {
        ERRORS: {
            COMMODITY_MAX: t('errors.commodityMax', { charMax: INPUT_CHARS_MAX }),
            PROPOSED_ACRES_MAX: t('errors.proposedAcresMax', { acreMax: WORK_ORDER_ACRE_MAX }),
            PROPOSED_ACRES_MIN: t('errors.proposedAcresMin'),
            TARGET_SPRAY_RATE_MAX: t('errors.targetSprayRateMax', {
                sprayMax: WORK_ORDER_TARGET_SPRAY_GA_AC_MAX
            }),
            TARGET_SPRAY_RATE_MIN: t('errors.targetSprayRateMin')
        },
        LABELS: {
            COMMODITY: t('labels.commodity'),
            PRE_HARVEST_INTERVAL: t('labels.preHarvestInterval'),
            PROPOSED_ACRES: t('labels.proposedAcres'),
            RE_ENTRY_INTERVAL_DAYS: t('labels.reEntryIntervalDays'),
            TARGET_SPRAY_RATE: t('labels.targetSprayRate')
        },
        UNITS: {
            ACRES: t('units.acres'),
            DAYS: t('units.days'),
            FLUID_OUNCES_PER_ACRES: t('units.fluidOzPerAcre'),
            GALLONS_PER_ACRE: t('units.gallonsPerAcre'),
            HECTARES: t('units.hectares'),
            HOURS: t('units.hours'),
            LITERS_PER_HECTARE: t('units.litersPerHectare')
        }
    };
};

export default useTranslation;
