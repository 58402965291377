import { round } from '@@utils/math';
import { View } from '@react-pdf/renderer';

import Text from 'components/pdf/Text';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const DataValue: FunctionComponent<Props> = ({ measurement, value }) => {
    const { NA } = useTranslation();

    const { getUnitLabel } = useAsAppliedMaps();

    if (measurement !== undefined) {
        const text =
            measurement === null
                ? NA
                : `${round(measurement.value, 2)} ${getUnitLabel(measurement.unitOfMeasurement)}`;

        return (
            <View style={styles}>
                <Text text={text} />
            </View>
        );
    } else if (value !== undefined) {
        return (
            <View style={styles}>
                <Text text={value} />
            </View>
        );
    }

    return null;
};

export default DataValue;
