import { forwardRef } from 'react';

import Input from 'components/form/Input';

import type { Props } from './types';

import styles from './styles.module.scss';

const InputWithIcons = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        children,
        className = '',
        hasError = false,
        isDisabled = false,
        isEditable = true,
        isReadOnly = false,
        isRequired = false,
        name = '',
        onBlur,
        onChange,
        onClick,
        onSubmit,
        placeholder = '',
        type = 'text',
        value
    } = props;

    const disabledClass = isDisabled ? styles.viewOnly : '';
    const errorClassName = hasError ? styles.error : '';

    return (
        <div
            className={`${className} ${styles.inputWithIcons} ${disabledClass} ${errorClassName}`}
            onClick={onClick}
        >
            <Input
                className={styles.input}
                hasError={hasError}
                isDisabled={isDisabled}
                isEditable={isEditable}
                isReadOnly={isReadOnly}
                isRequired={isRequired}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onSubmit={onSubmit}
                placeholder={placeholder}
                ref={ref}
                type={type}
                value={value}
            />

            {!isDisabled && isEditable && <div className={styles.icons}>{children}</div>}
        </div>
    );
});

InputWithIcons.displayName = 'InputWithIcons';

export default InputWithIcons;
