import { EQUIPMENT_CHAR_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('aircraftForm');

    return {
        BUTTON: t('button'),
        ERRORS: {
            FLIGHT_CONTROLLER_SERIAL_MAX: t('errors.flightControllerSerialMax'),
            MAKE_REQUIRED: t('errors.makeRequired'),
            MODEL_REQUIRED: t('errors.modelRequired'),
            NICKNAME_MAX: t('errors.nicknameMax', { charMax: EQUIPMENT_CHAR_MAX }),
            NICKNAME_REQUIRED: t('errors.nicknameRequired'),
            REGISTRATION_NUMBER_MAX: t('errors.registrationNumberMax', {
                charMax: EQUIPMENT_CHAR_MAX
            }),
            REGISTRATION_NUMBER_REQUIRED: t('errors.registrationNumberRequired'),
            SERIAL_NUMBER_MAX: t('errors.serialNumberMax', { charMax: EQUIPMENT_CHAR_MAX }),
            SERIAL_NUMBER_REQUIRED: t('errors.serialNumberRequired')
        },
        PLACEHOLDERS: {
            FLIGHT_CONTROLLER_SERIAL: t('placeholders.flightControllerSerial'),
            MAKE: t('placeholders.make'),
            MODEL: t('placeholders.model'),
            NICKNAME: t('placeholders.nickname'),
            REGISTRATION_NUMBER: t('placeholders.registrationNumber'),
            SERIAL_NUMBER: t('placeholders.serialNumber')
        },
        SWATH_WIDTH: t('swathWidth')
    };
};

export default useTranslation;
