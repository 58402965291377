import ContentText from 'components/ContentText';
import DeleteButton from 'components/DeleteButton';
import ModalOverlay from 'components/ModalOverlay';
import ModalTitle from 'components/ModalTitle';
import VerticalContainer from 'components/VerticalContainer';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ProductUsageReportDeleteConfirmationModal: FunctionComponent<Props> = ({
    onClose,
    onConfirm,
    testId = TEST_ID
}) => {
    const {
        BUTTON_TEXT,
        CONFIRM_DELETE_PRODUCT_USAGE_REPORT,
        CONFIRM_DELETE_PRODUCT_USAGE_REPORT_MESSAGE,
        CONFIRM_DELETE_PRODUCT_USAGE_REPORT_SUBTEXT_MESSAGE
    } = useTranslation();

    const confirmDelete = () => {
        onConfirm();
        onClose();
    };

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer
                className={styles.productUsageReportDeleteConfirmationModalContainer}
            >
                <ModalTitle text={CONFIRM_DELETE_PRODUCT_USAGE_REPORT} />

                <ContentText text={CONFIRM_DELETE_PRODUCT_USAGE_REPORT_MESSAGE} />

                <ContentText text={CONFIRM_DELETE_PRODUCT_USAGE_REPORT_SUBTEXT_MESSAGE} />

                <DeleteButton onClick={confirmDelete} text={BUTTON_TEXT} />
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default ProductUsageReportDeleteConfirmationModal;
