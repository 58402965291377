import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportDropdown');

    return {
        LABEL: (createdOn: string, contact: string, siteName: string) =>
            t('label', { contact, createdOn, siteName }),
        TBD: t('tbd')
    };
};

export default useTranslation;
