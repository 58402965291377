import { forwardRef } from 'react';

import TextInput from 'components/form/TextInput';

import { TEST_ID } from './constants';
import type { Props } from './types';

const CoaInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        hasError = false,
        isDisabled,
        isEditable,
        isRequired = false,
        onChange,
        onError,
        onValid,
        testId = TEST_ID,
        value
    } = props;

    return (
        <TextInput
            hasError={hasError}
            isDisabled={isDisabled}
            isEditable={isEditable}
            isRequired={isRequired}
            onChange={onChange}
            onError={onError}
            onValid={onValid}
            ref={ref}
            testId={testId}
            value={value}
        />
    );
});

CoaInput.displayName = 'CoaInput';

export default CoaInput;
