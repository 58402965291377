import useUsers from 'hooks/useUsers';

import type { UserApiResponse } from './types';

const useData = () => {
    const { fetchTeamMembers } = useUsers();

    return async () => {
        const response = await fetchTeamMembers(undefined, 25);

        const { data, error } = response;

        if (error) {
            throw new Error(JSON.stringify(error));
        }

        return data as UserApiResponse;
    };
};

export default useData;
