import Link from 'components/Link';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const InverseLink: FunctionComponent<Props> = ({
    children,
    className = '',
    onClick,
    target,
    testId = TEST_ID,
    text = '',
    to = ''
}) => (
    <Link
        className={`${className} ${styles.inverseLink}`}
        onClick={onClick}
        target={target}
        testId={testId}
        to={to}
    >
        {children || text}
    </Link>
);

export default InverseLink;
