import type { Style } from './types';

const styles: Style = {
    fontFamily: 'Inter',
    fontSize: '12pt',
    fontWeight: 'semibold',
    padding: '22pt 0'
};

export default styles;
