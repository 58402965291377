import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productUsageReportListPage');

    return {
        CONTACT: t('contact'),
        CREATE_PRODUCT_USAGE_REPORT: t('createProductUsageReport'),
        GROWER: t('grower'),
        PHONE_NUMBER: t('phoneNumber'),
        PRODUCT_USAGE_REPORT_ERROR: t('productUsageReportError'),
        PRODUCT_USAGE_REPORTS: t('productUsageReports'),
        SITE_NAME: t('siteName'),
        STATUS: t('status'),
        TIME: t('time')
    };
};

export default useTranslation;
