import { UnitOfMeasurement } from './types';

export const AAM_API = '/api/as-applied-maps';
export const AAM_JOB_API = '/api/as-applied-map-jobs';
export const AAM_JOB_SUMMARIES = '/api/as-applied-map-job-summaries';

export const measurementMap: Record<UnitOfMeasurement, string> = {
    ACRES: 'ac',
    FEET: 'ft',
    GALLON: 'gal',
    GALLONS_PER_ACRE: 'gal/ac',
    LITER: 'l',
    LITERS_PER_SQUARE_METER: 'l/m^2',
    METERS: 'm',
    METERS_PER_SECOND: 'm/s',
    MILES_PER_HOUR: 'mi/hr',
    SQUARE_METERS: 'm^2'
};
