import { MutableRefObject, createRef, useCallback, useReducer, useRef } from 'react';

import {
    FormElement,
    FormErrorObject,
    FormErrors,
    FormFieldProps,
    GetFieldError,
    GetValues,
    HandleChange,
    HandleError,
    HandleValid,
    HasErrors,
    NestedKeys,
    Path,
    RefsMap,
    Register,
    UseFormState,
    UseFormStateProps
} from './types';

const useFormState = <T extends object>({
    debug = false,
    initialSchema,
    onFormChange,
    onSubmit
}: UseFormStateProps<T>): UseFormState<T> => {
    const setDeepErrorValue = useCallback(
        <T>(
            obj: FormErrors<T>,
            path: string[],
            error: FormErrorObject[] | undefined
        ): FormErrors<T> => {
            const [key, ...rest] = path;

            if (!rest.length) {
                return { ...obj, [key]: error };
            }

            return {
                ...obj,
                [key as keyof FormErrors<T>]: setDeepErrorValue(
                    obj[key as keyof FormErrors<T>] as FormErrors<T>,
                    rest,
                    error
                )
            };
        },
        []
    );

    const errorReducer = useCallback(
        (
            state: FormErrors<T>,
            action: { fieldName: FormFieldProps<T>['name']; error: FormErrorObject[] | undefined }
        ) => {
            const { error, fieldName } = action;

            if (typeof fieldName !== 'string') {
                return state;
            }
            const fieldPath = fieldName.split('.');

            // Compare deep value of state and return early if no change
            const currentError = getDeepValue(state, fieldPath);

            if (currentError === error) {
                return state;
            }

            // Update the state at the nested field path
            return setDeepErrorValue(state, fieldPath, error);
        },
        [setDeepErrorValue]
    );

    // Form errors managed with a reducer (to avoid triggering re-renders for all fields and align better with the nested structure of the schema)
    const [formErrors, dispatch] = useReducer(errorReducer, {} as FormErrors<T>);

    // Custom logger that only logs when debug is true
    const logger = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (...messages: any[]) => {
            if (debug) {
                console.log(...messages);
            }
        },
        [debug]
    );

    // Helper function to get a deep value from a nested object using a string path
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getNestedValue = (obj: any, path: string): any =>
        path.split('.').reduce((acc, key) => acc && acc[key], obj);

    const getFieldError: GetFieldError<T> = useCallback(
        fieldName => getNestedValue(formErrors, fieldName as string),
        [formErrors]
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setDeepValue = useCallback(<T>(obj: T, path: string[], value: any): T => {
        const [key, ...rest] = path as [keyof T, ...string[]]; // Ensure type safety for the first key

        if (!rest.length) {
            return { ...obj, [key]: value };
        }

        return {
            ...obj,
            [key]: setDeepValue(obj[key], rest, value) // Recursively update the nested field
        };
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getDeepValue = <T>(obj: T, path: string[]): any =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        path.reduce((acc: any, key) => acc && acc[key], obj);

    const setDeepRef = useCallback(
        <T>(obj: RefsMap<T>, path: Path<T>, ref: MutableRefObject<FormElement>): RefsMap<T> => {
            const [key, ...rest] = path;

            if (!rest.length) {
                return { ...obj, [key]: ref };
            }

            return {
                ...obj,
                [key]: setDeepRef(obj[key] as RefsMap<T>, rest, ref)
            };
        },
        []
    );

    // Hold the form data in a ref
    logger('initialSchema:', initialSchema);
    const formDataRef = useRef<T>(initialSchema || ({} as T));

    // Hold refs for each field element in the form
    const refsMap = useRef<RefsMap<T>>({});

    // Helper function to determine if the form has any errors
    const hasErrors: HasErrors<T> = useCallback(
        (errors = formErrors) => {
            const checkErrors = (obj: FormErrors<T> | FormErrorObject[] | undefined): boolean => {
                if (!obj) {
                    return false;
                }
                if (Array.isArray(obj)) {
                    return obj.length > 0;
                }
                if (typeof obj === 'object') {
                    return Object.values(obj).some(value =>
                        checkErrors(value as FormErrors<T> | FormErrorObject[])
                    );
                }

                return false;
            };

            return checkErrors(errors);
        },
        [formErrors]
    );

    const handleChange: HandleChange<T> = useCallback(
        (fieldName, value) => {
            const isEmptyString = value === '';

            logger('handleChange:', { fieldName, isEmptyString, value });

            const fieldPath = (fieldName as string).split('.');

            formDataRef.current = setDeepValue(
                formDataRef.current,
                fieldPath,
                isEmptyString ? null : value
            );

            logger('Updated formData:', formDataRef.current);
            logger('Current formErrors:', formErrors);

            onFormChange?.({
                formData: formDataRef.current,
                formErrors,
                hasErrors: hasErrors()
            });
        },
        [logger, formErrors, onFormChange, setDeepValue, hasErrors]
    );

    // Handle valid field by clearing the errors
    const handleValid: HandleValid<T> = useCallback(
        fieldName => {
            logger('handleValid:', fieldName);
            logger('formErrors before updating:', formErrors);
            dispatch({ error: undefined, fieldName });

            const fieldPath = (fieldName as string).split('.');
            const updatedFormErrors = setDeepErrorValue(formErrors, fieldPath, undefined);

            onFormChange?.({
                formData: formDataRef.current,
                formErrors: updatedFormErrors,
                hasErrors: hasErrors(updatedFormErrors)
            });
        },
        [formErrors, logger, onFormChange, setDeepErrorValue, hasErrors]
    );

    // Update formErrors state
    const handleError: HandleError<T> = useCallback(
        (fieldName, error) => {
            logger('handleError:', fieldName, error);

            dispatch({ error: [error], fieldName });
            const fieldPath = (fieldName as string).split('.');
            const updatedFormErrors = setDeepErrorValue(formErrors, fieldPath, [error]);

            onFormChange?.({
                formData: formDataRef.current,
                formErrors: updatedFormErrors,
                hasErrors: hasErrors(updatedFormErrors)
            });
        },
        [formErrors, logger, onFormChange, setDeepErrorValue, hasErrors]
    );

    // Register function to create refs and return field-specific handlers
    const register: Register<T> = useCallback(
        <ELType extends FormElement>(fieldName: FormFieldProps<T>['name']) => {
            if (fieldName !== 'string') {
                return {
                    fieldName,
                    ref: createRef<ELType>() as MutableRefObject<FormElement>
                };
            }
            const fieldPath: string[] = fieldName.split('.');

            logger('Registering:', fieldName);

            const ref = createRef<ELType>();
            const mutableRef: MutableRefObject<ELType> = {
                current: ref.current as ELType
            };

            // Update the refsMap with the nested path
            refsMap.current = setDeepRef(
                refsMap.current,
                fieldPath as Path<T>,
                mutableRef as MutableRefObject<FormElement>
            );

            logger('RefsMap after registering:', fieldName, refsMap.current);

            return {
                fieldName,
                ref: mutableRef
            };
        },
        [logger, setDeepRef]
    );

    const handleSubmit = useCallback(() => {
        // Here you would check for errors and handle form submission
        logger('Submitting form:', formDataRef.current);

        const errorKeys = Object.keys(formErrors as FormErrors<T>) as (keyof T)[];

        // Check if there are any errors
        const hasErrors = errorKeys.some(FieldErrorKey =>
            getFieldError(FieldErrorKey as NestedKeys<T>)
        );

        // If there are no errors, submit the form
        if (!hasErrors) {
            onSubmit?.(formDataRef.current);

            logger('Form submitted successfully:', formDataRef.current);
        }

        logger('Form has errors:', formErrors);
    }, [formErrors, getFieldError, logger, onSubmit]);

    // Get the field value for a specific field
    const getValues: GetValues<T> = useCallback(fieldName => {
        if (!fieldName) {
            return formDataRef.current;
        }
        if (typeof fieldName === 'string') {
            const fieldPath = fieldName.split('.');

            return getDeepValue(formDataRef.current, fieldPath);
        }
    }, []);

    return {
        formErrors,
        getFieldError,
        getValues,
        handleChange,
        handleError,
        handleSubmit,
        handleValid,
        refsMap: refsMap.current,
        register
    };
};

export default useFormState;
