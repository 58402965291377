import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pilotsDropdown');

    return {
        CHANGE_PILOT: t('changePilot')
    };
};

export default useTranslation;
