import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { AIRCRAFT_API } from './constants';
import type { AircraftFormType } from './types';

const useData = () => {
    const { authenticatedPost } = useFetch();

    const createAircraft = useCallback(
        async (values: AircraftFormType) => {
            const { data, error } = await authenticatedPost(AIRCRAFT_API, JSON.stringify(values));

            if (error) {
                throw new Error((error as { message: string }).message);
            }

            return data;
        },
        [authenticatedPost]
    );

    return {
        createAircraft
    };
};

export default useData;
