import { WORK_ORDER_APPLICATION_SITE_MAX } from 'config';
import { FieldArray, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import ErrorMessage from 'components/ErrorMessage';
import FormSectionContainer from 'components/FormSectionContainer';
import SecondaryButton from 'components/SecondaryButton';

import useTranslation from './hooks/useTranslation';

import ApplicationSiteFields from './components/ApplicationSiteFields';
import { TEST_ID } from './constants';
import { EMPTY_APPLICATION_SITE } from './defaults';
import type { FunctionComponent, Props, WorkOrderForm } from './types';

const ApplicationSite: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { ADD_APPLICATION_SITE } = useTranslation();
    const {
        setFieldValue,
        values: { applicationSites, invoicedApplicationSites }
    } = useFormikContext<WorkOrderForm>();

    const onRemove = useCallback(
        (id: string) => {
            if (invoicedApplicationSites.find(invoicedSiteId => invoicedSiteId === id)) {
                setFieldValue(
                    'invoicedApplicationSites',
                    invoicedApplicationSites.filter(siteId => siteId !== id)
                );
            }
        },
        [invoicedApplicationSites, setFieldValue]
    );

    return (
        <FormSectionContainer>
            <FormSectionContainer testId={testId}>
                <FieldArray name="applicationSites">
                    {({ push, remove }) => (
                        <>
                            {applicationSites.map(({ id }, index) => (
                                <ApplicationSiteFields
                                    onClose={() => {
                                        remove(index);
                                        onRemove(id);
                                    }}
                                    canClose={applicationSites?.length > 1}
                                    hasProductUsageReport={hasProductUsageReport}
                                    index={index}
                                    isEditable={isEditable}
                                    key={id}
                                />
                            ))}

                            {isEditable &&
                                applicationSites?.length < WORK_ORDER_APPLICATION_SITE_MAX && (
                                    <SecondaryButton
                                        onClick={() =>
                                            push({
                                                ...EMPTY_APPLICATION_SITE,
                                                id: uuid()
                                            })
                                        }
                                        isDisabled={hasProductUsageReport}
                                        text={ADD_APPLICATION_SITE}
                                    />
                                )}
                        </>
                    )}
                </FieldArray>

                <ErrorMessage name="applicationSites" />
            </FormSectionContainer>
        </FormSectionContainer>
    );
};

export default ApplicationSite;
