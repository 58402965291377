import { CheckmarkIcon } from '@rantizo-software/rantizo-ui';

import BodyText from 'components/BodyText';
import DropdownItem from 'components/form/DropdownItem';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MultiDropdownItem: FunctionComponent<Props> = ({
    className = '',
    isSelected = false,
    onClick,
    testId = TEST_ID,
    text = ''
}) => (
    <DropdownItem
        className={`${className} ${styles.multiDropdownItem}`}
        onClick={onClick}
        testId={testId}
    >
        {isSelected && <CheckmarkIcon className={styles.checkmarkIcon} />}

        <BodyText className={styles.bodyText} text={text} />
    </DropdownItem>
);

export default MultiDropdownItem;
