import { useCallback } from 'react';

import useAccessToken from 'hooks/useAccessToken';

import { UseFetchParams } from './types';

const API_HOST = import.meta.env.VITE_RANTIZO_API_BASE_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFetch = (params: UseFetchParams | undefined = undefined): any => {
    const { apiHost, headers, retrieveToken } = params ?? {
        apiHost: API_HOST,
        headers: { 'Content-Type': 'application/json' },
        retrieveToken: undefined
    };

    const { fetchToken } = useAccessToken();

    const fetchAccessToken: () => Promise<string | undefined> = retrieveToken ?? fetchToken;

    const resolveHost = useCallback(
        (url: string) => (url.startsWith('/') ? `${apiHost}${url}` : url),
        [apiHost]
    );

    const httpRequest = useCallback(
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        async (
            url = '',
            requestData: string | Uint8Array,
            options: any = {},
            isBinary: boolean = false
        ) => {
            const requestOptions = {
                body: requestData,
                cache: 'no-cache',
                redirect: 'follow',
                ...options,
                headers: new Headers({
                    ...headers,
                    ...options.headers
                })
            };

            const endpoint = resolveHost(url);

            let error;
            let data: any = {};

            try {
                const response = await fetch(endpoint, requestOptions);

                if (!response.ok) {
                    throw new Error(response.status.toString());
                }

                if (isBinary) {
                    const body = await response.arrayBuffer();

                    if (body) {
                        data = new Uint8Array(body);
                    }
                } else {
                    const body = await response.text();

                    if (body) {
                        data = JSON.parse(body);

                        if (response.headers.get('location')) {
                            data.location = response.headers.get('location');
                        }
                    }
                }
            } catch (e) {
                error = e;
            }

            return { data, error };
        },
        [headers, resolveHost]
    );

    const httpGet = useCallback(
        async (url = '', data?: any, options = {}) => {
            const requestOptions = {
                ...options,
                method: 'GET'
            };

            return await httpRequest(url, data, requestOptions);
        },
        [httpRequest]
    );

    const httpDelete = useCallback(
        async (url = '', data?: any, options = {}) => {
            const requestOptions = {
                ...options,
                method: 'DELETE'
            };

            return await httpRequest(url, data, requestOptions);
        },
        [httpRequest]
    );

    const httpPost = useCallback(
        async (url = '', data: any, options = {}, isBinary = false) => {
            const requestOptions = {
                headers: headers,

                ...options,
                method: 'POST'
            };

            return await httpRequest(url, data, requestOptions, isBinary);
        },
        [headers, httpRequest]
    );

    const httpPut = useCallback(
        async (url = '', data: any, options = {}) => {
            const requestOptions = {
                headers: headers,
                ...options,
                method: 'PUT'
            };

            return await httpRequest(url, data, requestOptions);
        },
        [headers, httpRequest]
    );

    const httpPutBinary = useCallback(
        async (url = '', data: Uint8Array, options = {}) => {
            const requestOptions = {
                ...options,
                method: 'PUT'
            };

            return await httpRequest(url, data, requestOptions);
        },
        [httpRequest]
    );

    const httpGetBinary = useCallback(async (url = '') => {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        return uint8Array;
    }, []);

    const authenticatedRequest = useCallback(
        async (
            httpMethod: typeof httpRequest,
            url = '',
            data: any,
            options = {},
            isBinary = false
        ) => {
            const token = await fetchAccessToken();

            if (!token) {
                return {
                    data: null,
                    error: 'User not authenticated'
                };
            }

            const response = await httpMethod(
                url,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    ...options
                },
                isBinary
            );

            if (response?.error) {
                return {
                    data: null,
                    error: response.error
                };
            }

            return response;
        },
        [fetchAccessToken]
    );

    const authenticatedGet = useCallback(
        async (url = '', data: any = {}, options = {}) => {
            data = undefined;

            return await authenticatedRequest(httpGet, url, data, options);
        },
        [authenticatedRequest, httpGet]
    );

    const authenticatedDelete = useCallback(
        async (url = '', data: any = {}, options = {}) => {
            data = undefined;

            return await authenticatedRequest(httpDelete, url, data, options);
        },
        [authenticatedRequest, httpDelete]
    );

    const authenticatedPost = useCallback(
        async (url = '', data: any, options = {}) =>
            await authenticatedRequest(httpPost, url, data, options),
        [authenticatedRequest, httpPost]
    );

    const authenticatedPut = useCallback(
        async (url = '', data: any, options = {}) =>
            await authenticatedRequest(httpPut, url, data, options),
        [authenticatedRequest, httpPut]
    );

    const authenticatedBinaryPost = useCallback(
        async (url = '', data: any, options = {}) =>
            await authenticatedRequest(httpPost, url, data, options, true),
        [authenticatedRequest, httpPost]
    );

    return {
        authenticatedBinaryPost,
        authenticatedDelete,
        authenticatedGet,
        authenticatedPost,
        authenticatedPut,
        httpGet,
        httpGetBinary,
        httpPost,
        httpPut,
        httpPutBinary,
        resolveHost
    };
};

export default useFetch;
