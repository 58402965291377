import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('xagFileUploadTab');

    return {
        ACCEPTED_FILE_TYPES: t('acceptedFileTypes'),
        ASSOCIATED_APPLICATION_DATE: t('associatedApplicationDate'),
        BACK: t('back'),
        FILE_TYPE: t('fileType'),
        FILE_UPLOADS: t('fileUploads'),
        SAVE_TO_FLIGHT_LOG: t('saveToFlightLog'),
        START_DATE_ERROR: t('startDateError'),
        SWATH_WIDTH: t('swathWidth'),
        UNKNOWN_ERROR: t('unknownError'),
        UPLOAD: t('upload'),
        ZERO_SWATH_WIDTH: t('zeroSwathWidth')
    };
};

export default useTranslation;
