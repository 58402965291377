import { useTranslation } from 'react-i18next';

const useTranslate = (component: string) => {
    const { t } = useTranslation('translation', {
        keyPrefix: component
    });

    return (key: string, params = {}) => t(key, params);
};

export default useTranslate;
