import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const LegendRow: FunctionComponent<Props> = ({ children }) => (
    <View style={styles}>{children}</View>
);

export default LegendRow;
