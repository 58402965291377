import { useCallback, useMemo } from 'react';
import { ObjectSchema } from 'yup';

import useApplicationDetailsSchema from 'components/NewProductUsageReport/ApplicationDetailsForm/hooks/useSchema';
import useNotesSchema from 'components/NewProductUsageReport/NotesForm/hooks/useSchema';
import useProductDetailsSchema from 'components/NewProductUsageReport/ProductDetailsForm/hooks/useSchema';

import {
    ProductUsageReportForm,
    ProductUsageReportSection
} from 'pages/NewProductUsageReportPage/types';

const useSchema = () => {
    const applicationDetailsSchema = useApplicationDetailsSchema();
    const productDetailsSchema = useProductDetailsSchema();
    const notesSchema = useNotesSchema();

    // eslint-disable-next-line
    const schemaMap: Record<ProductUsageReportSection, ObjectSchema<any>> = useMemo(
        () => ({
            applicationDetails: applicationDetailsSchema,
            notes: notesSchema,
            productDetails: productDetailsSchema
        }),
        [applicationDetailsSchema, productDetailsSchema, notesSchema]
    );

    const validateSchema = useCallback(
        // TODO add type

        async (values: ProductUsageReportForm, schema: ProductUsageReportSection) => {
            try {
                await schemaMap[schema].validate(values);

                return true;
            } catch (error: unknown) {
                console.log(error);

                return false;
            }
        },
        [schemaMap]
    );

    const validateAllSchema = useCallback(
        // eslint-disable-next-line
        async (values: any) => {
            try {
                await Promise.all([
                    validateSchema(values, 'applicationDetails'),
                    validateSchema(values, 'productDetails'),
                    validateSchema(values, 'notes')
                ]);

                return true;
            } catch (error: unknown) {
                console.log(error);

                return false;
            }
        },
        [validateSchema]
    );

    return {
        schemaMap,
        validateAllSchema,
        validateSchema
    };
};

export default useSchema;
