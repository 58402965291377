import { BackArrowButton } from '@rantizo-software/rantizo-ui';

import BackX from 'components/BackX';
import PageWithSidebarContainer from 'components/PageWithSidebarContainer';
import SideBarContentContainer from 'components/SideBarContentContainer';
import SideBarContentHeader from 'components/SideBarContentHeader';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const backMap: Record<string, any> = {
    false: BackArrowButton,
    true: BackX
};

const PageWithSidebarHeader: FunctionComponent<Props> = ({
    children,
    onBack,
    testId = TEST_ID,
    text,
    useX = false
}) => {
    const BackComponent = backMap[String(useX) as keyof typeof backMap];

    return (
        <PageWithSidebarContainer className={styles.pageWithSidebarHeader} testId={testId}>
            <BackComponent className={styles.backArrow} onClick={onBack} />

            <SideBarContentContainer>
                <SideBarContentHeader text={text}>{children}</SideBarContentHeader>
            </SideBarContentContainer>
        </PageWithSidebarContainer>
    );
};

export default PageWithSidebarHeader;
