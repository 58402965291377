const translations = {
    applicationType: 'Application Type',
    drone: 'Drone',
    fileType: 'File Type',
    flightDuration: 'Flight Duration',
    flightTime: 'Flight Time',
    location: 'Location',
    selectAll: 'All',
    selectNone: 'None'
};

export default translations;
