import { useEffect, useState } from 'react';

import useFlightMap from 'hooks/useFlightMap';

import FlightOverview from 'pages/GenerateAsAppliedMapPage/components/FlightOverview';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const FlightDetails: FunctionComponent<Props> = ({
    alternateFlights = [],
    flights,
    testId = TEST_ID
}) => {
    const {
        centroidLocation,
        handleMapLoaded,
        handleToggleAlternateFlights,
        handleToggleFlightPath,
        loadFlights,
        mapData
    } = useFlightMap(flights, alternateFlights);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadData = async () => {
            await loadFlights();
            setIsLoading(false);
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlightOverview
            centroidLocation={centroidLocation}
            data={mapData}
            isLoading={isLoading}
            onAlternatePathClick={handleToggleAlternateFlights}
            onLoad={handleMapLoaded}
            onPathClick={handleToggleFlightPath}
            testId={testId}
        />
    );
};

export default FlightDetails;
