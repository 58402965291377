import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const Header: FunctionComponent<Props> = ({ children }) => (
    <View fixed style={styles}>
        {children}
    </View>
);

export default Header;
