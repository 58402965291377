const translations = {
    createProductUsageReport: 'Create Product Usage Report',
    loading: 'loading...',
    newProductUsageReport: 'New Product Usage Report',
    productUsageReportSubtextMessage:
        'Select the Work Order you would like to make the Product Usage Report for',
    selectWorkOrder: 'Select Work Order',
    workOrderRequiredError: 'Work Order Is Required'
};

export default translations;
