const translations = {
    applicationDetails: 'Application Details',
    fetchDraftError: 'Error fetching your draft. Using default values instead',
    finalSubmitError: 'There was an error saving the product usage report',
    notes: 'Notes',
    productDetails: 'Product Details',
    saveAsDraft: 'Save as Draft',
    submitFormError: 'There was an error creating your draft',
    submitReport: 'Submit Product Usage Report',
    title: 'New Product Usage Report'
};

export default translations;
