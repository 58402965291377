import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceTableFooter');

    return {
        ESTIMATED_TOTAL: t('estimatedTotal')
    };
};

export default useTranslation;
