import { useCallback } from 'react';

import { generatePagedQuery } from '@@utils/string';

import useFetch from 'hooks/useFetch';

import { PUR_DRAFT_SUMMARY_API, PUR_SUMMARY_API } from './constants';
import { ProductUsageReportSummaryApiResponse } from './types';

const useProductUsageReportSummaries = () => {
    const { authenticatedGet } = useFetch();

    const fetchSummaries = useCallback(
        async (isDraft: boolean, pageToken?: string, pageSize = 10) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const endpoint = isDraft ? PUR_DRAFT_SUMMARY_API : PUR_SUMMARY_API;
            const uri = `${endpoint}?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri, null);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as ProductUsageReportSummaryApiResponse;
        },
        [authenticatedGet]
    );

    const fetchProductUsageReportSummaries = useCallback(
        async (pageToken?: string, pageSize = 10) =>
            (await fetchSummaries(
                false,
                pageToken,
                pageSize
            )) as ProductUsageReportSummaryApiResponse,
        [fetchSummaries]
    );

    const fetchAllProductUsageReportSummaries = useCallback(async () => {
        try {
            let productUsageReportResponse = await fetchProductUsageReportSummaries();

            let productUsageReports = productUsageReportResponse.objects;

            while (productUsageReportResponse.hasNext) {
                productUsageReportResponse = await fetchProductUsageReportSummaries(
                    productUsageReportResponse.nextPageToken
                );
                productUsageReports = productUsageReports.concat(
                    productUsageReportResponse.objects
                );
            }

            return {
                data: productUsageReports,
                error: undefined
            };
        } catch (e) {
            return {
                data: undefined,
                error: e
            };
        }
    }, [fetchProductUsageReportSummaries]);

    const fetchProductUsageReportDraftSummaries = useCallback(
        async (pageToken?: string, pageSize = 10) =>
            (await fetchSummaries(
                true,
                pageToken,
                pageSize
            )) as ProductUsageReportSummaryApiResponse,
        [fetchSummaries]
    );

    return {
        fetchAllProductUsageReportSummaries,
        fetchProductUsageReportDraftSummaries,
        fetchProductUsageReportSummaries
    };
};

export default useProductUsageReportSummaries;
