import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { FAA_REPORT_API, FAA_REPORT_EXCEL_EXPORT } from './constants';
import { Aircraft, FaaReportRequest, FaaReportResponse } from './types';

const changeDateToCustomStringFormat = (date: Date) =>
    date ? date.toISOString().split('T')[0] : '';

const changeCustomStringFormatToDate = (date: string) =>
    date ? new Date(`${date}T00:00:00`) : new Date();
const useFaaReports = () => {
    const {
        authenticatedBinaryPost,
        authenticatedDelete,
        authenticatedGet,
        authenticatedPost,
        authenticatedPut
    } = useFetch();

    const createFaaReport = useCallback(
        async (faaReportRequest: FaaReportRequest) => {
            const faaReportRequestWithCustomStringDate: Omit<
                FaaReportRequest,
                'reportingPeriod'
            > & { reportingPeriod: string } = {
                ...faaReportRequest,
                reportingPeriod: changeDateToCustomStringFormat(faaReportRequest.reportingPeriod!)
            };

            const { data, error } = await authenticatedPost(
                FAA_REPORT_API,
                JSON.stringify(faaReportRequestWithCustomStringDate)
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPost]
    );

    const updateFaaReport = useCallback(
        async (faaReportId: string, faaReportRequest: FaaReportRequest) => {
            const faaReportRequestWithCustomStringDate: Omit<
                FaaReportRequest,
                'reportingPeriod'
            > & { reportingPeriod: string } = {
                ...faaReportRequest,
                reportingPeriod: changeDateToCustomStringFormat(faaReportRequest.reportingPeriod!)
            };

            const { data, error } = await authenticatedPut(
                `${FAA_REPORT_API}/${faaReportId}`,
                JSON.stringify(faaReportRequestWithCustomStringDate)
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPut]
    );

    const fetchFaaReport = useCallback(
        async (faaReportId: string) => {
            const { data, error } = await authenticatedGet(`${FAA_REPORT_API}/${faaReportId}`);

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }
            const faaReport: FaaReportResponse = {
                ...data,
                reportingPeriod: changeCustomStringFormatToDate(data.reportingPeriod)
            };

            return { ...faaReport, droneIds: data.drones.map((drone: Aircraft) => drone.id) };
        },
        [authenticatedGet]
    );

    const getFaaReportExcelFile = useCallback(
        async (faaReportId: string) => {
            const { data, error } = await authenticatedBinaryPost(
                FAA_REPORT_EXCEL_EXPORT(faaReportId),
                {}
            );

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedBinaryPost]
    );

    const deleteFaaReport = useCallback(
        async (faaReportId: string) => {
            const { error } = await authenticatedDelete(`${FAA_REPORT_API}/${faaReportId}`);

            if (error) {
                console.log(error);
                throw new Error(JSON.stringify(error));
            }

            return true;
        },
        [authenticatedDelete]
    );

    return {
        createFaaReport,
        deleteFaaReport,
        fetchFaaReport,
        getFaaReportExcelFile,
        updateFaaReport
    };
};

export default useFaaReports;
