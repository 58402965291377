import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderChemicalInformationFields');

    return {
        ACTIVE_INGREDIENTS: t('activeIngredients'),
        CHEMICAL_TO_APPLY: t('chemicalToApply'),
        EPA_NUMBER: t('epaNumber'),
        PEST_CONDITION: t('pestCondition'),
        RATE: t('rate'),
        RATE_UNIT: t('rateUnit'),
        SIGNAL_WORD: t('signalWord')
    };
};

export default useTranslation;
