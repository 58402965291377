import type { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import EquipmentTabs from 'components/EquipmentTabs';
import TabContainer from 'components/TabContainer';

import useTranslation from './hooks/useTranslation';

const EquipmentDetails: FunctionComponent = () => {
    const { TITLE } = useTranslation();

    return (
        <TabContainer title={TITLE}>
            <EquipmentTabs />

            <Outlet />
        </TabContainer>
    );
};

export default EquipmentDetails;
