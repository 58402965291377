import type { Style } from './types';

const styles: Style = {
    color: '#9e9e9e',
    fontFamily: 'Inter',
    fontSize: '10pt',
    fontWeight: 'medium'
};

export default styles;
