import ItemCardWithBorder from 'components/ItemCardWithBorder';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapContainer: FunctionComponent<Props> = ({ children, className = '', testId = TEST_ID }) => (
    <ItemCardWithBorder className={`${styles.mapContainer} ${className}`} testId={testId}>
        {children}
    </ItemCardWithBorder>
);

export default MapContainer;
