import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('droneSelectCard');

    return {
        MAKE: t('make'),
        MODEL: t('model'),
        NICKNAME: t('nickname'),
        REGISTRATION_NUMBER: t('registrationNumber'),
        UAS: (selectNumber: number) => t('uas', { selectNumber })
    };
};

export default useTranslation;
