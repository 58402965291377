const translations = {
    applicationDate: 'Application Date',
    appliedWith: 'Applied With',
    associatedWith: 'Associated With',
    back: 'Back',
    continue: 'Continue',
    flightsNotFound: 'No flights found for query',
    incompleteForm: 'You must either fill out all of the form fields or upload kml files',
    productUsageReportPlaceholder: 'Select Product Usage Report (Optional)',
    to: 'to'
};

export default translations;
