import { View } from '@react-pdf/renderer';

import Section from 'components/pdf/Section';
import InvoiceApplicationDateColumn from 'components/pdf/invoice/InvoiceApplicationDateColumn';
import InvoiceBillingColumn from 'components/pdf/invoice/InvoiceBillingColumn';
import InvoiceCompanyColumn from 'components/pdf/invoice/InvoiceCompanyColumn';
import InvoiceDetailsColumn from 'components/pdf/invoice/InvoiceDetailsColumn';
import InvoiceThirdPartyWorkOrderColumn from 'components/pdf/invoice/InvoiceThirdPartyWorkOrderColumn';
import InvoiceWorkOrderColumn from 'components/pdf/invoice/InvoiceWorkOrderColumn';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceDetails: FunctionComponent<Props> = ({
    applicationDate,
    customer,
    grower,
    thirdPartyWorkOrderNumber,
    workOrderNumber
}) => (
    <View style={styles.invoiceDetails}>
        <Section style={styles.section}>
            <InvoiceCompanyColumn customer={customer} />

            <InvoiceBillingColumn grower={grower} />

            <InvoiceDetailsColumn />
        </Section>

        <Section style={styles.section}>
            <InvoiceWorkOrderColumn workOrderNumber={workOrderNumber.toString()} />

            <InvoiceThirdPartyWorkOrderColumn
                thirdPartyWorkOrderNumber={thirdPartyWorkOrderNumber}
            />

            <InvoiceApplicationDateColumn applicationDate={applicationDate} />
        </Section>
    </View>
);

export default InvoiceDetails;
