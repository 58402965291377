import { useCallback } from 'react';

import useQuerystring from 'hooks/useQuerystring';

import { BASE_MAPBOX_URL, DEFAULT_MAPBOX_BASEMAP } from './constants';
import type { GenerateStaticImageUrlArgs } from './types';

import styles from './styles.module.scss';

const useMapboxStaticImages = () => {
    const { addQuery } = useQuerystring({});

    const generateStaticImageUrl = useCallback(
        ({
            basemap = DEFAULT_MAPBOX_BASEMAP,
            geometry,
            height = 500,
            latitude,
            longitude,
            markerColor = styles.markerColor,
            strokeColor = styles.strokeColor,
            strokeWidth = 10,
            viewBox,
            width = 700,
            zoom
        }: GenerateStaticImageUrlArgs) => {
            const overlay = geometry;

            overlay.features.forEach(feature => {
                feature.properties = {
                    'marker-color': markerColor,
                    stroke: strokeColor,
                    'stroke-width': strokeWidth
                };
            });

            const encodedOverlay = encodeURIComponent(JSON.stringify(overlay));

            const query = addQuery({ access_token: import.meta.env.VITE_MAPBOX_TOKEN });

            let viewPort = viewBox;

            if (!viewBox) {
                viewPort = `${longitude},${latitude},${zoom},0,0`;
            }

            return `${BASE_MAPBOX_URL(
                basemap
            )}/geojson(${encodedOverlay})/${viewPort}/${width}x${height}?${query.toString()}`;
        },
        [addQuery]
    );

    return {
        generateStaticImageUrl
    };
};

export default useMapboxStaticImages;
