import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('faaReportModal');

    return {
        BUTTON_TEXT: t('buttonText'),
        CREATE_FAA_REPORT_ERROR: t('createFaaReportError'),
        SUBTEXT: t('subtext'),
        TITLE: t('title')
    };
};

export default useTranslation;
