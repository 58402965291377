import { TEST_ID } from './constants';
import styles from './style.module.scss';
import { FunctionComponent, Props } from './types';

const DownloadLink: FunctionComponent<Props> = ({
    className = '',
    href,
    testId = TEST_ID,
    text
}) => (
    <a className={`${className} ${styles.downloadLink}`} data-testid={testId} download href={href}>
        {text}
    </a>
);

export default DownloadLink;
