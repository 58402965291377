import { DroneReportResponse } from 'generatedTypes/faaReport/models';
import { useCallback, useEffect, useState } from 'react';

import useAircraft from 'hooks/useAircraft';
import useDeduplicate from 'hooks/useDeDuplicate';
import useDroneReport from 'hooks/useDroneReport';
import { FlightReportResponse } from 'hooks/useFlightReport/types';

const useDroneReports = ({
    droneId,
    droneReportId,
    faaReportId
}: {
    orgMembers: string[];
    faaReportId: string;
    droneId: string;
    droneReportId?: string;
}) => {
    const { fetchAircraft } = useAircraft();
    const { fetchDroneReport } = useDroneReport();

    const { deduplicate } = useDeduplicate();

    const [droneReport, setDroneReport] = useState<DroneReportResponse>();
    const [nickname, setNickname] = useState('');
    const [pilots, setPilots] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchDroneNickname = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await fetchAircraft(droneId);

            setNickname(data.nickname);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            setIsLoading(false);
        }
    }, [droneId, fetchAircraft]);

    const getDroneReport = useCallback(async () => {
        if (!droneReportId) {
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetchDroneReport(faaReportId, droneReportId);

            setDroneReport(response);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            setIsLoading(false);
        }
    }, [droneReportId, faaReportId, fetchDroneReport]);

    useEffect(() => {
        if (!droneReport) {
            return;
        }

        setPilots(droneReport.pilots);
    }, [droneReport]);

    useEffect(() => {
        setIsLoading(true);
        fetchDroneNickname();
        getDroneReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePilotChange = useCallback(
        (updatedPilotList: string[]) => {
            setPilots(deduplicate(updatedPilotList) as string[]);
        },
        [deduplicate]
    );

    const handleFlightReportDelete = useCallback(
        (deletedFlightReports: FlightReportResponse[]) => {
            if (!droneReport) {
                return;
            }

            const updatedTotalFlightTimeMilliseconds = deletedFlightReports.reduce(
                (acc, flightReport) => acc + flightReport.durationMilliseconds,
                0
            );

            const updatedDroneReport: DroneReportResponse = {
                ...droneReport,
                totalFlightTimeMilliseconds:
                    droneReport?.totalFlightTimeMilliseconds - updatedTotalFlightTimeMilliseconds,
                totalFlights: droneReport?.totalFlights - deletedFlightReports.length
            };

            setDroneReport(updatedDroneReport);
            const updatedPilots = updatedDroneReport.pilots.map(pilot => pilot);

            setPilots(deduplicate(updatedPilots) as string[]);
        },
        [deduplicate, droneReport]
    );

    return {
        droneReport,
        handleFlightReportDelete,
        handlePilotChange,
        isLoading,
        nickname,
        pilots
    };
};

export default useDroneReports;
