import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('noAircraftCard');

    return {
        MESSAGE_ADMIN_1: t('messageAdmin1'),
        MESSAGE_ADMIN_2: t('messageAdmin2'),
        MESSAGE_USER: t('messageUser'),
        RANTIZO: t('rantizo'),
        TITLE: t('title')
    };
};

export default useTranslation;
