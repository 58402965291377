import { useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import HighlightTab from 'components/HighlightTab';
import HighlightTabs from 'components/HighlightTabs';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const EquipmentTabs: FunctionComponent<Props> = ({ className, testId = TEST_ID }) => {
    const { organizationEquipmentAircraft, organizationEquipmentController } = usePageRoutes();
    const aircraftMatch = useMatch(organizationEquipmentAircraft);
    const controllerMatch = useMatch(organizationEquipmentController);

    const navigate = useNavigate();

    const { AIRCRAFT, CONTROLLER } = useTranslation();

    const initialTab = (aircraftMatch && 'aircraft') || (controllerMatch && 'controller') || '';

    const [tab, setTab] = useState(initialTab);

    const handleTabClick = useCallback(
        ({ tab, to }: { tab: string; to: string }) => {
            setTab(tab);
            navigate(to);
        },
        [navigate]
    );

    useEffect(() => {
        setTab(initialTab);
    }, [initialTab]);

    const selectedTab = tab || initialTab || 'aircraft';

    return (
        <HighlightTabs
            className={className}
            selectedClass={styles[selectedTab] || ''}
            testId={testId}
        >
            <HighlightTab
                onClick={() =>
                    handleTabClick({ tab: 'aircraft', to: organizationEquipmentAircraft })
                }
                isActive={selectedTab === 'aircraft'}
                text={AIRCRAFT}
            />

            <HighlightTab
                onClick={() =>
                    handleTabClick({ tab: 'controller', to: organizationEquipmentController })
                }
                isActive={selectedTab === 'controller'}
                text={CONTROLLER}
            />
        </HighlightTabs>
    );
};

export default EquipmentTabs;
