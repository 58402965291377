import { Text as PdfText } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const InvoiceText: FunctionComponent<Props> = ({ children, text }) => (
    <PdfText style={styles}>{children || text}</PdfText>
);

export default InvoiceText;
