import type { ApplicationSite } from './types';

export const EMPTY_APPLICATION_SITE: ApplicationSite = {
    boundary: [],
    coordinates: {
        latitude: null,
        longitude: null
    },
    id: '',
    imageObjectKey: null,
    imageUrl: undefined,
    location: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: ''
    },
    siteName: ''
};
