import { INPUT_CHARS_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('userDetailsForm');

    return {
        ERRORS: {
            FIRST_NAME_INVALID: t('errors.firstNameInvalid'),
            FIRST_NAME_MAX: t('errors.firstNameMax', { nameMax: INPUT_CHARS_MAX }),
            FIRST_NAME_REQUIRED: t('errors.firstNameRequired'),
            LAST_NAME_INVALID: t('errors.lastNameInvalid'),
            LAST_NAME_MAX: t('errors.lastNameMax', { nameMax: INPUT_CHARS_MAX }),
            LAST_NAME_REQUIRED: t('errors.lastNameRequired'),
            PHONE_INVALID: t('errors.phoneInvalid')
        },
        PLACEHOLDERS: {
            FIRST_NAME: t('placeholders.firstName'),
            LAST_NAME: t('placeholders.lastName'),
            PHONE: t('placeholders.phone')
        }
    };
};

export default useTranslation;
