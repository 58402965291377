import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('faaReportsPage');

    return {
        CREATE_FAA_REPORT_BUTTON: t('createFaaReportButton'),
        CREATE_FAA_REPORT_ERROR: t('createFaaReportError'),
        DELETE_FAA_REPORT_ERROR: t('deleteFaaReportError'),
        ERROR: t('error'),
        NO_DATA: t('noData'),
        TITLE: t('title')
    };
};

export default useTranslation;
