import NavigationLink from 'components/NavigationLink';

import type { FunctionComponent, Props } from './types';

const MainNavigationLink: FunctionComponent<Props> = ({
    className = '',
    link,
    onClick,
    testId,
    text
}) => (
    <NavigationLink className={className} onClick={onClick} testId={testId} text={text} to={link} />
);

export default MainNavigationLink;
