import { Svg3DBridge } from 'iconoir-react';

import MapButton from 'components/Mapbox/components/MapButton';

import type { FunctionComponent, Props } from './types';

const FlightPathControl: FunctionComponent<Props> = ({ onClick }) => (
    <MapButton onClick={onClick}>
        {
            // @ts-expect-error implementation will later be changed
            <Svg3DBridge strokeWidth={3} />
        }
    </MapButton>
);

export default FlightPathControl;
