import OptionsButtonTextWithArrow from 'components/OptionsButtonTextWithArrow';
import ShareToJohnDeere from 'components/ShareToJohnDeere';

import useFeatureFlags from 'hooks/useFeatureFlags';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const MapShareButton: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    jobId,
    mapType,
    productUsageReportId,
    testId = TEST_ID
}) => {
    const { JOHN_DEERE } = useFeatureFlags();
    const { SHARE_TO } = useTranslation();

    return (
        <OptionsButtonTextWithArrow
            feature={'johnDeere'}
            isDisabled={!JOHN_DEERE}
            testId={testId}
            text={SHARE_TO}
        >
            {JOHN_DEERE && (
                <ShareToJohnDeere
                    aam={aam}
                    chemicalNames={chemicalNames}
                    jobId={jobId}
                    mapType={mapType}
                    productUsageReportId={productUsageReportId}
                />
            )}
        </OptionsButtonTextWithArrow>
    );
};

export default MapShareButton;
