import { useEffect, useState } from 'react';

import { HIDE_UPLOAD_BAR_DELAY_MS, START_UPLOAD_BAR_DELAY_MS, TEST_ID } from './constants';
import type { CSSProperties, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const UploadBar: FunctionComponent<Props> = ({ isComplete, startAnimation, testId = TEST_ID }) => {
    const [progress, setProgress] = useState<string>('0%');
    const [height, setHeight] = useState('0.375rem');

    useEffect(() => {
        if (isComplete) {
            setProgress('100%');
        }

        setTimeout(() => setHeight('0'), HIDE_UPLOAD_BAR_DELAY_MS);
    }, [isComplete]);

    setTimeout(() => {
        if (startAnimation) {
            setProgress('90%');
            setHeight('0.375rem');
        } else if (!isComplete) {
            setProgress('0%');
        }
    }, START_UPLOAD_BAR_DELAY_MS);

    return (
        <div
            style={
                {
                    '--upload-bar-height': height,
                    '--upload-bar-progress': progress
                } as CSSProperties
            }
            className={styles.uploadBar}
            data-testid={testId}
        />
    );
};

export default UploadBar;
