/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * AcreConnect API
 * AcreConnect API documentation
 * OpenAPI spec version: 1.0
 */

export type AsAppliedMapRequestFlightModification =
    (typeof AsAppliedMapRequestFlightModification)[keyof typeof AsAppliedMapRequestFlightModification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsAppliedMapRequestFlightModification = {
    REMOVE_FERRYING_LINES: 'REMOVE_FERRYING_LINES'
} as const;
