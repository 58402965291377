import grayscaleRantizoLogo from '@@assets/RantizoLogoGrayscalePDF.png';
import { Image } from '@react-pdf/renderer';

import type { FunctionComponent } from './types';

import styles from './styles';

const GrayscaleLogo: FunctionComponent = () => <Image src={grayscaleRantizoLogo} style={styles} />;

export default GrayscaleLogo;
