import { useCallback, useState } from 'react';

import { Button } from '@rantizo-software/rantizo-ui';
import { ArrowRightIcon } from '@rantizo-software/rantizo-ui';

import VerticalContainer from 'components/VerticalContainer';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const HamburgerMenuDropdown: FunctionComponent<Props> = ({
    children,
    className = '',
    dropdownElement,
    testId = TEST_ID
}) => {
    const [open, setOpen] = useState(false);

    const onClick = useCallback(() => {
        setOpen(open => !open);
    }, []);

    return (
        <div className={className} data-testid={testId}>
            <div className={styles.dropdownTitle}>
                {dropdownElement}

                <Button className={styles.chevronButton} onClick={onClick}>
                    <ArrowRightIcon
                        className={`${styles.chevron} ${open && styles.chevronRotated}`}
                    />
                </Button>
            </div>

            <VerticalContainer
                className={`${styles.dropdownContainer} ${open && styles.dropdownOpen}`}
            >
                {children}
            </VerticalContainer>
        </div>
    );
};

export default HamburgerMenuDropdown;
