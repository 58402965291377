import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const RowSpaceBetween: FunctionComponent<Props> = ({ children, className, testId = TEST_ID }) => (
    <div className={`${className} ${styles.rowSpaceBetween}`} data-testid={testId}>
        {children}
    </div>
);

export default RowSpaceBetween;
