import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceApplicationDateColumn');

    return {
        APPLICATION_DATE: t('applicationDate'),
        SCHEDULED_FOR: (date: string) => t('scheduledFor', { date })
    };
};

export default useTranslation;
