import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('mapsListPageLayoutHeader');

    return {
        CREATE_AAM: t('createAam')
    };
};

export default useTranslation;
