import type { Style } from './types';

const styles: Style = {
    flexDirection: 'column',
    height: '100%',
    minHeight: '11in',
    padding: '0.5in 0.625in',
    width: '100%'
};

export default styles;
