import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('faaReportsListPageLayoutHeader');

    return {
        CREATE_FAA_REPORT: t('createFaaReport')
    };
};

export default useTranslation;
