import { useMemo } from 'react';

import useTranslation from 'pages/GenerateAsAppliedMapPage/hooks/useTranslation';

import { AS_APPLIED_MAP_SECTION, AS_COVERED_SECTION, AS_FLOWN_SECTION } from './constants';
import type { AsAppliedMapResultsSection, SideBarItem } from './types';

const useSidebarItems = (
    hasAsAppliedMap: boolean
): { sidebarItems: Record<AsAppliedMapResultsSection, SideBarItem> } => {
    const { AS_APPLIED_MAP, AS_COVERED, AS_FLOWN } = useTranslation();

    const sidebarItems: Record<AsAppliedMapResultsSection, SideBarItem> = useMemo(() => {
        const items = {
            asCovered: {
                index: Number(hasAsAppliedMap) + 1,
                isDisabled: false,
                isInvalid: false,
                isViewOnly: true,
                nextSection: AS_APPLIED_MAP_SECTION,
                previousSection: AS_FLOWN_SECTION,
                title: AS_COVERED
            },
            asFlown: {
                index: 0,
                isDisabled: false,
                isInvalid: false,
                isViewOnly: true,
                nextSection: AS_COVERED_SECTION,
                title: AS_FLOWN
            }
        } as Record<AsAppliedMapResultsSection, SideBarItem>;

        if (hasAsAppliedMap) {
            return {
                ...items,
                asAppliedMap: {
                    index: 1,
                    isDisabled: false,
                    isInvalid: false,
                    isViewOnly: true,
                    nextSection: AS_COVERED_SECTION,
                    previousSection: AS_FLOWN_SECTION,
                    title: AS_APPLIED_MAP
                }
            };
        }

        return items;
    }, [AS_APPLIED_MAP, AS_COVERED, AS_FLOWN, hasAsAppliedMap]);

    return {
        sidebarItems
    };
};

export default useSidebarItems;
