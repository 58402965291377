const translations = {
    button: 'Save Aircraft',
    errors: {
        flightControllerSerialMax: 'Flight controller serial cannot exceed 20 characters',
        makeRequired: 'Please provide a make',
        modelRequired: 'Please provide a model',
        nicknameMax: 'Drone Name cannot exceed {{charMax}} characters',
        nicknameRequired: 'Please provide a Drone Name',
        registrationNumberMax: 'Registration number cannot exceed {{charMax}} characters',
        registrationNumberRequired: 'Please provide a registration number',
        serialNumberMax: 'Serial number cannot exceed {{charMax}} characters',
        serialNumberRequired: 'Please provide a serial number'
    },
    placeholders: {
        flightControllerSerial: 'Flight Controller Serial',
        make: 'Drone Make',
        model: 'Drone Model',
        nickname: 'Drone Name',
        registrationNumber: 'Registration Number',
        serialNumber: 'Serial Number'
    },
    swathWidth: 'Swath Width Meters'
};

export default translations;
