import { atom, useRecoilState } from 'recoil';

import type { VerifyAccountForm } from './types';

export const VerifyAccountAtom = atom<VerifyAccountForm>({
    default: {
        firstName: '',
        lastName: '',
        phone: ''
    },
    key: 'VerifyAccountAtom'
});

export const useVerifyAccountForm = () => useRecoilState(VerifyAccountAtom);
