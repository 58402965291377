import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceTableFieldFlatFeeRow');

    return {
        FIELD_APPLICATION: t('fieldApplication'),
        FLAT_FEE: t('flatFee')
    };
};

export default useTranslation;
