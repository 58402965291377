import { useCallback } from 'react';

import useAircraft from 'hooks/useAircraft';
import useWorkOrder from 'hooks/useWorkOrder';

import { Aircraft, FetchProductUsageReportOptions } from 'pages/NewProductUsageReportPage/types';

const useProductUsageReportOptions = () => {
    const { fetchWorkOrder } = useWorkOrder();
    const { fetchAllAircrafts } = useAircraft();

    const fetchProductUsageReportOptions: FetchProductUsageReportOptions = useCallback(
        async (id: string) => {
            const aircrafts = await fetchAllAircrafts();

            if (aircrafts.error) {
                return {
                    data: null,
                    error: aircrafts.error
                };
            }
            const workOrder = await fetchWorkOrder(id);

            if (workOrder.error) {
                return {
                    data: null,
                    error: workOrder.error
                };
            }

            const {
                applicationSites,
                applicator,
                chemicals,
                id: workOrderId,
                key,
                proposedAcres
            } = workOrder.data;

            return {
                data: {
                    applicationSites,
                    applicatorName: `${applicator?.firstName ?? ''} ${applicator?.lastName ?? ''}`,
                    chemicals,
                    drones: aircrafts.data as Aircraft[],
                    proposedAcres,
                    workOrderId,
                    workOrderKey: key
                },
                error: undefined
            };
        },
        [fetchAllAircrafts, fetchWorkOrder]
    );

    return {
        fetchProductUsageReportOptions
    };
};

export default useProductUsageReportOptions;
