import { useCallback } from 'react';

import BackNextButtons from 'components/BackNextButtons';
import DroneMultiSelect from 'components/DroneMultiSelect';
import FormSectionContainer from 'components/FormSectionContainer';
import SideBarContentContainer from 'components/SideBarContentContainer';

import useForm from 'hooks/useForm';

import useTranslate from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, SelectableValue } from './types';

const UasTypeAndModel: FunctionComponent<Props> = ({
    formData,
    isEditable,
    isSaveDisabled,
    onBack,
    onChange,
    onNext,
    onSave,
    testId = TEST_ID
}) => {
    const { SELECT_UAS } = useTranslate();

    const form = useForm(formData);

    const { form: currentFormData, handleChange, handleValid } = form;

    const handleBack = useCallback(async () => {
        onBack?.({ form });
    }, [form, onBack]);

    const handleNext = useCallback(async () => {
        if (isEditable) {
            onSave();
        }
        onNext?.({ form });
    }, [form, isEditable, onNext, onSave]);

    const handleSelectionsChange = useCallback(
        (selectedAircrafts: SelectableValue[]) => {
            const selectedIds = selectedAircrafts.map(({ id }) => id);

            handleChange('droneIds')(selectedIds);
            handleValid('droneIds')(selectedIds);
            onChange(form);
        },
        [form, handleChange, handleValid, onChange]
    );

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer>
                <DroneMultiSelect
                    defaultSelectedIds={currentFormData.current.droneIds}
                    isEditable={isEditable}
                    onSelectionsChanged={handleSelectionsChange}
                    title={SELECT_UAS}
                />

                <BackNextButtons
                    isNextDisabled={isSaveDisabled}
                    isNextLoading={false}
                    onBack={handleBack}
                    onNext={handleNext}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default UasTypeAndModel;
