import { Button } from '@rantizo-software/rantizo-ui';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapButton: FunctionComponent<Props> = ({ children, className = '', onClick, text }) => (
    <Button className={`${className} ${styles.mapButton}`} onClick={onClick}>
        {children || text}
    </Button>
);

export default MapButton;
