import type { Style } from './types';

const styles: Record<string, Style> = {
    section: {
        alignSelf: 'flex-start',
        gap: '20pt'
    },
    sectionNoHeader: {
        paddingTop: '30pt'
    }
};

export default styles;
