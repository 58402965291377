import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('exitWorkOrderModal');

    return {
        CONFIRM: t('confirm'),
        WARNING: t('warning')
    };
};

export default useTranslation;
