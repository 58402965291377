import { useEffect, useState } from 'react';

import FormSelect from 'components/deprecating/FormSelect';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, SelectData } from './types';

const FormSelectTeamMembers: FunctionComponent<Props> = ({
    className,
    disabled = false,
    field,
    form,
    isEditable = true,
    label,
    meta,
    placeholder,
    required,
    searchable,
    selectOnBlur,
    testId = TEST_ID,
    type
}) => {
    const fetchTeamMembers = useData();
    const [initializing, setInitializing] = useState(true);
    const [models, setModels] = useState<SelectData>([]);

    const initializeTeamMembers = async () => {
        const response = await fetchTeamMembers();
        const options = response?.objects.map(tm => ({
            label: `${tm.firstName} ${tm.lastName}`,
            value: tm.id
        }));

        setModels(options);
    };

    useEffect(() => {
        if (initializing) {
            initializeTeamMembers();
            setInitializing(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializing]);

    return (
        <FormSelect
            className={className}
            data={models}
            disabled={disabled}
            field={field}
            form={form}
            isEditable={isEditable}
            isLoading={initializing || models.length === 0}
            label={label}
            meta={meta}
            placeholder={placeholder}
            required={required}
            searchable={searchable}
            selectOnBlur={selectOnBlur}
            testId={testId}
            type={type}
        />
    );
};

export default FormSelectTeamMembers;
