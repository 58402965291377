import { useCallback, useMemo, useRef, useState } from 'react';

import { round } from '@@utils/math';

import NumberInputWithUnit from 'components/form/NumberInputWithUnit';

import useConvert from 'hooks/useConvert';

import { MAX_FEET, TEST_ID, UNIT_OPTIONS } from './constants';
import type { FunctionComponent, NumberWithUnit, OnError, Props } from './types';

const DistanceInput: FunctionComponent<Props> = ({
    hasError = false,
    isRequired = false,
    onChange,
    onError,
    onSubmit,
    onValid,
    testId = TEST_ID,
    value
}) => {
    const distanceRef = useRef<HTMLInputElement>(null);

    const { convertFeetToMeters, convertMetersToFeet } = useConvert();

    const [currentValue, setCurrentValue] = useState<NumberWithUnit>(value);

    const MAX_METERS = useMemo(() => convertFeetToMeters(MAX_FEET), [convertFeetToMeters]);

    const onChangeHandler = useCallback(
        (newValue: NumberWithUnit, onError: OnError) => {
            const newNumberValue = newValue.value ?? 0.0;

            if (newNumberValue < 0) {
                newValue.value = 0;
            }

            if (newValue.unit === 'FEET' && currentValue.unit === 'METERS') {
                newValue.value = round(convertMetersToFeet(newValue.value ?? 0.0), 2);
            } else if (newValue.unit === 'METERS' && currentValue.unit === 'FEET') {
                newValue.value = round(convertFeetToMeters(newValue.value ?? 0.0), 2);
            }

            if (newValue.unit === 'FEET' && (newValue.value ?? 0.0) > MAX_FEET) {
                newValue.value = MAX_FEET;
            } else if (newValue.unit === 'METERS' && (newValue.value ?? 0.0) > MAX_METERS) {
                newValue.value = MAX_METERS;
            }

            setCurrentValue(newValue);
            onChange?.(newValue, onError);
        },
        [MAX_METERS, convertFeetToMeters, convertMetersToFeet, currentValue.unit, onChange]
    );

    return (
        <NumberInputWithUnit
            hasError={hasError}
            isRequired={isRequired}
            items={UNIT_OPTIONS}
            onChange={onChangeHandler}
            onError={onError}
            onSubmit={onSubmit}
            onValid={onValid}
            ref={distanceRef}
            testId={testId}
            value={currentValue}
        />
    );
};

export default DistanceInput;
