import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderFooter');

    return {
        CREATE_PRODUCT_USAGE_REPORT: t('createProductUsageReport'),
        DOWNLOAD_INVOICE_PDF: t('downloadInvoicePdf'),
        DOWNLOAD_WORK_ORDER_PDF: t('downloadWorkOrderPdf')
    };
};

export default useTranslation;
