import { useCallback } from 'react';

import { formatPhoneNumberForApi, trimAndNullIfEmptyOrUndefined } from '@@utils/data';

import useFetch from 'hooks/useFetch';

import { CUSTOMER_API } from './constants';
import type { Customer } from './types';

const useCustomer = () => {
    const { authenticatedPost, authenticatedPut } = useFetch();

    const preparePayload = useCallback((company: Customer) => {
        const { companyName, contactName, location, phone } = company;

        const payload = {
            address1: trimAndNullIfEmptyOrUndefined(location?.address1),
            address2: trimAndNullIfEmptyOrUndefined(location?.address2),
            city: trimAndNullIfEmptyOrUndefined(location?.city),
            company: companyName?.trim(),
            contact: trimAndNullIfEmptyOrUndefined(contactName),
            phoneNumber: formatPhoneNumberForApi(phone),
            state: trimAndNullIfEmptyOrUndefined(location?.state),
            zipCode: trimAndNullIfEmptyOrUndefined(location?.zipCode)
        };

        return payload;
    }, []);

    const handleCustomer = useCallback(
        async (company: Customer, isNew: boolean) => {
            const payload = preparePayload(company);

            const { id } = company;

            const createOrUpdateCustomerUrl = id && !isNew ? `${CUSTOMER_API}/${id}` : CUSTOMER_API;

            const { data, error } = await (id && !isNew ? authenticatedPut : authenticatedPost)(
                createOrUpdateCustomerUrl,
                JSON.stringify(payload)
            );

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data.id;
        },
        [authenticatedPost, authenticatedPut, preparePayload]
    );

    return {
        handleCustomer
    };
};

export default useCustomer;
