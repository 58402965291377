import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfInvoiceThirdPartyWorkOrderColumn');

    return {
        THIRD_PARTY_WORK_ORDER_NUMBER: t('thirdPartyWorkOrderNumber')
    };
};

export default useTranslation;
