import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { WORK_ORDER_API } from './constants';
import { WorkOrderSummary } from './types';

const useWorkOrderSummaries = () => {
    const { authenticatedGet } = useFetch();
    const { addQuery } = useQuerystring({});

    const fetchAllWorkOrderSummaries = useCallback(async () => {
        const query = addQuery({
            sort: `proposedDate,desc`
        });
        let workOrders: WorkOrderSummary[] = [];
        let keepFetching = true;
        let pageToken = null;

        while (keepFetching) {
            if (pageToken) {
                query.set('pageToken', pageToken);
            }

            const { data, error } = await authenticatedGet(`${WORK_ORDER_API}?${query.toString()}`);

            if (error) {
                console.log(error);
                break;
            }

            const { hasNext, nextPageToken, objects } = data;

            pageToken = nextPageToken;
            keepFetching = hasNext;

            workOrders = workOrders.concat(objects);
        }

        return workOrders;
    }, [addQuery, authenticatedGet]);

    return { fetchAllWorkOrderSummaries };
};

export default useWorkOrderSummaries;
