import { useMemo } from 'react';

import useTranslation from 'pages/GenerateAsAppliedMapPage/hooks/useTranslation';

import type { GenerateAsAppliedMapSection, SideBarItem, UseSidebarItemsInterface } from './types';

const useSidebarItems: UseSidebarItemsInterface = ({ aamMap, coverageMap, flights }) => {
    const { APPLICATION_DETAILS, AS_APPLIED_MAP, AS_COVERED, FLIGHT_LOG } = useTranslation();

    const sidebarItems: Record<GenerateAsAppliedMapSection, SideBarItem> = useMemo(
        () => ({
            applicationDetails: {
                index: 0,
                isDisabled: false,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'flightLog',
                title: APPLICATION_DETAILS
            },
            asAppliedMap: {
                index: 2,
                isDisabled: !aamMap,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'asCovered',
                previousSection: 'flightLog',
                title: AS_APPLIED_MAP
            },
            asCovered: {
                index: 3,
                isDisabled: !coverageMap,
                isInvalid: false,
                isViewOnly: true,
                previousSection: 'asAppliedMap',
                title: AS_COVERED
            },
            flightLog: {
                index: 1,
                isDisabled: flights.length === 0,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'asAppliedMap',
                previousSection: 'applicationDetails',
                title: FLIGHT_LOG
            }
        }),
        [
            APPLICATION_DETAILS,
            AS_APPLIED_MAP,
            AS_COVERED,
            FLIGHT_LOG,
            aamMap,
            coverageMap,
            flights.length
        ]
    );

    return {
        sidebarItems
    };
};

export default useSidebarItems;
