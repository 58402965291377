import flattenAddress from '@@utils/flattenAddress';
import { formatPhoneNumber } from '@@utils/string';

import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const WorkOrderCustomerGrower: FunctionComponent<Props> = ({ customer, grower }) => {
    const { ADDRESS, COMPANY, CONTACT, CUSTOMER_INFORMATION, GROWER_INFORMATION, PHONE } =
        useTranslation();

    return (
        <Section>
            {customer && (
                <SectionColumn title={CUSTOMER_INFORMATION}>
                    <Text>
                        {COMPANY}

                        {customer.companyName ?? ''}
                    </Text>

                    <Text>
                        {CONTACT}

                        {customer.contactName ?? ''}
                    </Text>

                    <Text>
                        {ADDRESS}

                        {flattenAddress({
                            address1: customer?.location?.address1 ?? '',
                            address2: customer?.location?.address2 ?? '',
                            city: customer?.location?.city,
                            state: customer?.location?.state,
                            zipCode: customer?.location?.zipCode
                        })}
                    </Text>

                    <Text>
                        {PHONE}

                        {formatPhoneNumber(customer.phone) ?? ''}
                    </Text>
                </SectionColumn>
            )}

            {grower && (
                <SectionColumn title={GROWER_INFORMATION}>
                    <Text>
                        {COMPANY}

                        {grower.companyName ?? ''}
                    </Text>

                    <Text>
                        {CONTACT}

                        {grower.contactName ?? ''}
                    </Text>

                    <Text>
                        {ADDRESS}

                        {flattenAddress({
                            address1: grower?.location?.address1,
                            address2: grower?.location?.address2,
                            city: grower?.location?.city,
                            state: grower?.location?.state,
                            zipCode: grower?.location?.zipCode
                        })}
                    </Text>

                    <Text>
                        {PHONE}

                        {formatPhoneNumber(grower.phone) ?? ''}
                    </Text>
                </SectionColumn>
            )}
        </Section>
    );
};

export default WorkOrderCustomerGrower;
