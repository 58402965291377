import { forwardRef, memo } from 'react';

import DateRangePicker from 'components/DateRangePicker';
import FlightLogBulkDeleteButton from 'components/FlightLogBulkDeleteButton';
import FlightLogFilterButton from 'components/FlightLogFilterButton';

import { TEST_ID } from './constants';
import { DateRangeRefs, Props } from './types';

import styles from './styles.module.scss';

const FlightLogListFilters = memo(
    forwardRef<DateRangeRefs, Props>(
        (
            {
                allowedDateRange,
                dateRangeValue,
                isDeleteDisabled,
                onAircraftFilterChange,
                onBulkDelete,
                onDateRangeChange,
                testId = TEST_ID
            },
            ref
        ) => (
            <div className={styles.flightLogListFilters} data-testid={testId}>
                <DateRangePicker
                    className={styles.dateRangePicker}
                    onChange={onDateRangeChange}
                    rangeInterval={allowedDateRange}
                    ref={ref}
                    value={dateRangeValue}
                />

                <FlightLogFilterButton
                    className={styles.flightLogFilterButton}
                    onClick={onAircraftFilterChange}
                />

                <FlightLogBulkDeleteButton
                    className={styles.flightLogBulkDeleteButton}
                    isDisabled={isDeleteDisabled}
                    onClick={onBulkDelete}
                />
            </div>
        )
    )
);

FlightLogListFilters.displayName = 'FlightLogListFilters';

export default FlightLogListFilters;
