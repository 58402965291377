import type { Equipment } from 'components/BaseEquipmentCard/types';

import type { User } from 'hooks/useUsers/types';

export const userSortFirstName = (a: User, b: User) => {
    const aFirstLowercase = a.firstName.toLowerCase();
    const bFirstLowercase = b.firstName.toLowerCase();

    if (aFirstLowercase < bFirstLowercase) {
        return -1;
    }
    if (aFirstLowercase > bFirstLowercase) {
        return 1;
    }

    return 0;
};

export const userSortLastName = (a: User, b: User) => {
    const aLastLowercase = a.lastName.toLowerCase();
    const bLastLowercase = b.lastName.toLowerCase();

    if (aLastLowercase < bLastLowercase) {
        return -1;
    }
    if (aLastLowercase > bLastLowercase) {
        return 1;
    }

    return 0;
};

export const equipmentSortNickname = (a: Equipment, b: Equipment) => {
    const aNicknameLowercase = a.nickname.toLowerCase();
    const bNicknameLowercase = b.nickname.toLowerCase();

    if (aNicknameLowercase < bNicknameLowercase) {
        return -1;
    }
    if (aNicknameLowercase > bNicknameLowercase) {
        return 1;
    }

    return 0;
};
