import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const LegendColor: FunctionComponent<Props> = ({ color }) => (
    <View style={[styles, { backgroundColor: color }]} />
);

export default LegendColor;
