import { Text as PdfText } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const TableText: FunctionComponent<Props> = ({ children, text }) => (
    <PdfText style={styles} wrap>
        {children || text}
    </PdfText>
);

export default TableText;
