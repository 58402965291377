import { useCallback } from 'react';

const useDeduplicate = () => {
    const deduplicate = useCallback((items: unknown[]) => Array.from(new Set(items)), []);

    return {
        deduplicate
    };
};

export default useDeduplicate;
