const translations = {
    ac: 'Ac',
    addChemical: 'Add Chemical',
    carrier: 'Carrier',
    errors: {
        carrierRequired: 'Carrier Required',
        rateAppliedGreaterThanZero: 'Rate Must Be Greater Than Or Equal To 0',
        rateAppliedRequired: 'Rate Applied Required',
        rateAppliedTooLarge: 'Rate Must Be Less Than {{rate}} {{unit}}',
        rateAppliedUnitsRequired: 'Rate Applied Units Required',
        totalAcresAppliedRequired: 'Total Acres Applied Required',
        totalAcresGreaterThanZero: 'Total Acres Applied Must Be Greater Than Or Equal To 0',
        volumeAppliedGreaterThanZero: 'Amount Applied Must Be Greater Than Or Equal to 0',
        volumeAppliedRequired: 'Amount Applied Required',
        volumeAppliedTooLarge: 'Amount Must Be Less Than {{maxVolumeApplied}} {{unit}}',
        volumeUnitsRequired: 'Amount Units Required'
    },
    fluidOuncesPerAcreUnit: 'Fl. Oz/Ac',
    gallons: 'Gallons',
    gallonsPerAcreUnit: 'Ga/Ac',
    liters: 'Liters',
    litersPerHectareUnit: 'L/Ha',
    litersPerMeterSquaredUnit: 'L/m\u00b2',
    rateApplied: 'Rate Applied',
    totalAcresApplied: 'Total Acres Applied',
    totalProductUsed: 'Total Product Used'
};

export default translations;
