import { format } from 'date-fns';
import { useCallback } from 'react';

import { round } from '@@utils/math';

import DataItem from 'components/DataItem';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import useTranslation from './hooks/useTranslation';

import { DATE_FORMAT, TEST_ID } from './constants';
import type { FunctionComponent, Measurement, Props } from './types';

import styles from './styles.module.scss';

const AsAppliedMapsDetails: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    mapType,
    siteName = '',
    testId = TEST_ID
}) => {
    const { appliedRate, appliedTotal, areaApplied, centerPoint, endTime, startTime } = aam;

    const {
        APPLIED_RATE,
        APPLIED_TOTAL,
        AREA_APPLIED,
        CHEMICALS_APPLIED,
        END_TIME,
        LOCATION,
        NA,
        START_TIME
    } = useTranslation();

    const { getUnitLabel } = useAsAppliedMaps();

    const measurementElement = useCallback(
        (measurement: Measurement | null) =>
            measurement === null ? (
                <>{NA}</>
            ) : (
                <>
                    {round(measurement.value, 2)}

                    {getUnitLabel(measurement.unitOfMeasurement)}
                </>
            ),
        [NA, getUnitLabel]
    );

    return (
        <div className={styles.dataContainer} data-testid={testId}>
            <DataItem text={CHEMICALS_APPLIED}>{chemicalNames || NA}</DataItem>

            {siteName && <DataItem text={LOCATION}>{siteName}</DataItem>}

            <DataItem text={siteName ? '' : LOCATION}>
                {`(${centerPoint.coordinates[0]}, ${centerPoint.coordinates[1]})`}
            </DataItem>

            <DataItem text={START_TIME}>{format(new Date(startTime), DATE_FORMAT)}</DataItem>

            {mapType === 'AAM' && (
                <DataItem text={END_TIME}>{format(new Date(endTime), DATE_FORMAT)}</DataItem>
            )}

            {mapType === 'AAM' && (
                <DataItem text={AREA_APPLIED}>{measurementElement(areaApplied)}</DataItem>
            )}

            {mapType === 'AAM' && (
                <DataItem text={APPLIED_RATE}>{measurementElement(appliedRate)}</DataItem>
            )}

            {mapType === 'AAM' && (
                <DataItem text={APPLIED_TOTAL}>{measurementElement(appliedTotal)}</DataItem>
            )}
        </div>
    );
};

export default AsAppliedMapsDetails;
