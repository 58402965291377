import { PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX } from 'config';
import { object, string } from 'yup';

import useTranslation from 'components/NewProductUsageReport/NotesForm/hooks/useTranslation';

const useSchema = () => {
    const { ERROR_NOTES_MAX } = useTranslation();

    return object({
        notes: string().ensure().max(PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX, ERROR_NOTES_MAX)
    });
};

export default useSchema;
