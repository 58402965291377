import type { Style } from './types';

const styles: Style = {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
};

export default styles;
