import { View } from '@react-pdf/renderer';

import SectionColumn from 'components/pdf/SectionColumn';
import InvoiceText from 'components/pdf/invoice/InvoiceText';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const InvoiceBillingColumn: FunctionComponent<Props> = ({
    grower: { address1, address2, city, companyName, state, zipCode }
}) => {
    const { ADDRESS, BILL_TO, CITY, COUNTRY, NAME, STATE, ZIPCODE } = useTranslation();

    return (
        <SectionColumn title={BILL_TO}>
            <View>
                <InvoiceText text={`${NAME}: ${companyName}`} />

                <InvoiceText text={`${ADDRESS}: ${address1 ?? ''} ${address2 ?? ''}`} />

                <InvoiceText text={`${CITY}: ${city ?? ''}`} />

                <InvoiceText text={`${STATE}: ${state ?? ''}`} />

                <InvoiceText text={`${ZIPCODE}: ${zipCode ?? ''}`} />

                <InvoiceText text={COUNTRY} />
            </View>
        </SectionColumn>
    );
};

export default InvoiceBillingColumn;
