import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('exemptionDocketNumberField');

    return {
        EXEMPTION_DOCKET_NUMBER_ERROR_MESSAGE: t('exemptionDocketNumberErrorMessage'),
        MAX_EXCEEDED_ERROR_MESSAGE: t('maxExceededErrorMessage'),
        REQUIRED_ERROR_MESSAGE: t('requiredErrorMessage')
    };
};

export default useTranslation;
