import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flightLogPage');

    return {
        NO_FLIGHTS: t('noFlights'),
        TITLE: t('title'),
        UPLOAD_FLIGHT_LOG: t('uploadFlightLog')
    };
};

export default useTranslation;
