import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import MenuButton from 'components/MenuButton';
import { Items } from 'components/MenuButton/types';

import useFaaReports from 'hooks/useFaaReports';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { DOWNLOAD_FILE_NAME, EXCEL_MIME_TYPE, TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FaaListItemMenu: FunctionComponent<Props> = ({
    className = '',
    faaReportId,
    handleDelete,
    isListView = false,
    testId = TEST_ID
}) => {
    const { DELETE, EDIT, ERROR_EXPORTING, EXPORT, VIEW } = useTranslation();

    const navigate = useNavigate();

    const { createFaaReportPage, viewFaaReportPage } = usePageRoutes();

    const handleView = useCallback(() => {
        navigate(viewFaaReportPage(faaReportId));
    }, [faaReportId, navigate, viewFaaReportPage]);

    const handleEdit = useCallback(() => {
        navigate(createFaaReportPage(faaReportId));
    }, [createFaaReportPage, faaReportId, navigate]);

    const { getFaaReportExcelFile } = useFaaReports();

    const isExporting = useRef<boolean>(false);

    const handleExport = useCallback(async () => {
        try {
            if (!isExporting.current) {
                isExporting.current = true;
                const excelFile = await getFaaReportExcelFile(faaReportId);
                const blob = new Blob([excelFile], {
                    type: EXCEL_MIME_TYPE
                });
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');

                link.href = url;
                link.download = DOWNLOAD_FILE_NAME;

                link.click();

                URL.revokeObjectURL(url);
            }
        } catch (e) {
            alert(ERROR_EXPORTING);
            console.error(e);
        } finally {
            isExporting.current = false;
        }
    }, [ERROR_EXPORTING, faaReportId, getFaaReportExcelFile]);

    const items: Items = [
        {
            onClick: handleEdit,
            text: EDIT
        },
        {
            onClick: handleExport,
            text: EXPORT
        },
        {
            isDelete: true,
            onClick: handleDelete,
            text: DELETE
        }
    ];

    if (isListView) {
        items.unshift({
            onClick: handleView,
            text: VIEW
        });
    }

    return (
        <MenuButton
            className={`${className} ${styles.faaListItemMenu}`}
            items={items}
            testId={testId}
        />
    );
};

FaaListItemMenu.displayName = 'FaaListItemMenu';

export default FaaListItemMenu;
