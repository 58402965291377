import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('equipmentTabs');

    return {
        AIRCRAFT: t('aircraft'),
        CONTROLLER: t('controller')
    };
};

export default useTranslation;
