import type { Style } from './types';

const styles: Style = {
    fontFamily: 'Inter',
    fontSize: '11pt',
    fontWeight: 'bold',
    padding: '9pt'
};

export default styles;
