import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('useFlightReportUpdate');

    return {
        UPDATE_PILOT_ERROR: t('updatePilotError')
    };
};

export default useTranslation;
