import useTranslation from '../useTranslation';
import { INPUT_CHARS_MAX } from 'config';
import { object, string } from 'yup';
import 'yup-phone-lite';

const nameRegex = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-.]+$/;

const useSchema = () => {
    const { ERRORS } = useTranslation();

    return object({
        firstName: string()
            .required(ERRORS.FIRST_NAME_REQUIRED)
            .max(INPUT_CHARS_MAX, ERRORS.FIRST_NAME_MAX)
            .matches(nameRegex, ERRORS.FIRST_NAME_INVALID),
        lastName: string()
            .required(ERRORS.LAST_NAME_REQUIRED)
            .max(INPUT_CHARS_MAX, ERRORS.LAST_NAME_MAX)
            .matches(nameRegex, ERRORS.LAST_NAME_INVALID),
        phone: string().phone(null, ERRORS.PHONE_INVALID)
    });
};

export default useSchema;
