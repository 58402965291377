import MainNavigationLink from 'components/MainNavigationLink';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DesktopNavigationDropdownLink: FunctionComponent<Props> = ({
    className = '',
    link,
    testId,
    text
}) => (
    <MainNavigationLink
        className={`${styles.dropdownNavigationLink} ${className}`}
        link={link}
        testId={testId}
        text={text}
    />
);

export default DesktopNavigationDropdownLink;
