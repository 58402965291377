import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addressForm');

    return {
        ADDRESS: t('address'),
        ADDRESS_2: t('address2'),
        CITY: t('city'),
        STATE: t('state'),
        ZIP_CODE: t('zipCode')
    };
};

export default useTranslation;
