import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addressFields');

    return {
        ADDRESS_1: t('address1'),
        ADDRESS_2: t('address2'),
        CITY: t('city'),
        STATE: t('state'),
        ZIP_CODE: t('zipCode')
    };
};

export default useTranslation;
