const translations = {
    coa: 'Certificate of Authorization Number (COA)',
    coaTooltip:
        'Enter one or more alphanumeric characters (letters and numbers only). This field cannot contain spaces or special characters.',
    companyName: 'Company Name',
    exemptionDocketNumber: 'Exemption Docket Number',
    exemptionDocketNumberTooltip:
        "Enter the Docket Number in the format FAA-XXXX-XXXX, where each 'X' is a digit (0-9). Example: FAA-1234-5678.",
    exemptionNumber: 'Exemption Number',
    exemptionNumberTooltip:
        'Enter up to 6 alphanumeric characters (letters and numbers only). Example: ABC123.',
    phoneNumber: 'Phone Number'
};

export default translations;
