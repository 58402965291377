import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('locationSection');

    return {
        LOCATION: t('location')
    };
};

export default useTranslation;
