import { useEffect, useState } from 'react';

import FormSelect from 'components/deprecating/FormSelect';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, SelectData } from './types';

const FormSelectControllerMakes: FunctionComponent<Props> = ({
    className,
    disabled,
    field,
    form,
    label,
    meta,
    placeholder,
    searchable,
    selectOnBlur,
    testId = TEST_ID,
    type
}) => {
    const { fetchMakes } = useData();
    const [initializing, setInitializing] = useState(true);
    const [makes, setMakes] = useState<SelectData>([]);

    const initializeMakes = async () => {
        const response = await fetchMakes();
        const options = response.map(make => ({ label: make, value: make }));

        setMakes(options);
    };

    useEffect(() => {
        if (initializing) {
            initializeMakes();
            setInitializing(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializing]);

    return (
        <FormSelect
            className={className}
            data={makes}
            disabled={disabled}
            field={field}
            form={form}
            isLoading={initializing || makes.length === 0}
            label={label}
            meta={meta}
            placeholder={placeholder}
            searchable={searchable}
            selectOnBlur={selectOnBlur}
            testId={testId}
            type={type}
        />
    );
};

export default FormSelectControllerMakes;
