import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';

import FormSectionContainer from 'components/FormSectionContainer';
import InputLabel from 'components/InputLabel';
import CompanyContactFields from 'components/deprecating/CompanyContactFields';
import FormCheckbox from 'components/form/FormCheckbox';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type {
    ChangeEvent,
    FunctionComponent,
    Props,
    SetFormikTouched,
    SetFormikValues,
    WorkOrderForm
} from './types';

import styles from './styles.module.scss';

const GrowerInformation: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { CUSTOMER_CHECKBOX_LABEL, TITLE } = useTranslation();

    const { setFieldTouched, setFieldValue, values } = useFormikContext<WorkOrderForm>();

    const setGrowerToCustomer = useCallback(async () => {
        const newValue = {
            id: values.grower?.id,
            ...values.customer,
            sameAsCustomer: true
        };

        await setFieldValue('grower', newValue);
    }, [setFieldValue, values.customer, values.grower?.id]);

    const onCheckboxChange = useCallback(
        async (
            event: ChangeEvent<HTMLInputElement>,
            setFieldTouched: SetFormikTouched,
            setFieldValue: SetFormikValues
        ) => {
            // Change event gives old value, so need to invert value
            const checkValue = event.target.checked;

            if (checkValue) {
                await setGrowerToCustomer();

                await setFieldTouched('grower', false);
            } else {
                await setFieldValue('grower', { ...values.grower, sameAsCustomer: checkValue });
            }
        },
        [setGrowerToCustomer, values.grower]
    );

    useEffect(() => {
        if (values.grower?.sameAsCustomer) {
            setGrowerToCustomer();
        }
    }, [setFieldTouched, setGrowerToCustomer, values.grower?.sameAsCustomer]);

    return (
        <FormSectionContainer testId={testId}>
            <InputLabel isRequired={isEditable} text={TITLE} />

            {isEditable && (
                <Field
                    customOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onCheckboxChange(e, setFieldTouched, setFieldValue)
                    }
                    className={styles.sameAsCustomerCheckBox}
                    component={FormCheckbox}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={CUSTOMER_CHECKBOX_LABEL}
                    name="grower.sameAsCustomer"
                    type="checkbox"
                />
            )}

            <CompanyContactFields
                isDisabled={values.grower?.sameAsCustomer || hasProductUsageReport}
                isEditable={isEditable}
                namePrefix="grower."
            />
        </FormSectionContainer>
    );
};

export default GrowerInformation;
