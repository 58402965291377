import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('johnDeereRedirect');

    return {
        ERROR_MESSAGE: t('errorMessage')
    };
};

export default useConstants;
