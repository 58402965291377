import { format } from 'date-fns';

import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';

import useConvert from 'hooks/useConvert';
import useRoundFormat from 'hooks/useRoundFormat';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const dateFormat = 'MM/dd/yyyy';
const timeFormat = 'hh:mm:ss a';

const ProductUsageReportApplicationDetails: FunctionComponent<Props> = ({
    applicationDetails,
    applicatorName,
    title,
    workOrderKey
}) => {
    const { convertKelvinToFahrenheit, convertMetersPerSecondToMilesPerHour } = useConvert();
    const roundFormat = useRoundFormat();
    const {
        AIR_TEMPERATURE,
        APPLICATION_DATE,
        APPLICATION_SITE,
        APPLICATOR,
        DRONE,
        END_TIME,
        FAHRENHEIGHT,
        MPH,
        START_TIME,
        WIND_DIRECTION,
        WIND_GUST_SPEED,
        WIND_SPEED,
        WORK_ORDER_NUMBER
    } = useTranslation();

    const {
        airTemperatureKelvin,
        applicationEndDate,
        applicationSites,
        applicationStartDate,
        drone,
        windDirection,
        windGustSpeedMetersPerSecond,
        windSpeedMetersPerSecond
    } = applicationDetails;

    return (
        <Section>
            <SectionColumn title={title}>
                <Text>
                    {WORK_ORDER_NUMBER}

                    {`${workOrderKey}`}
                </Text>

                <Text>{APPLICATOR(applicatorName)}</Text>

                <Text>
                    {APPLICATION_SITE}

                    {applicationSites.map(site => site.siteName).join(',')}
                </Text>

                <Text>
                    {APPLICATION_DATE}

                    {format(new Date(applicationStartDate), dateFormat)}
                </Text>

                <Text>
                    {START_TIME}

                    {format(new Date(applicationStartDate), timeFormat)}
                </Text>

                <Text>
                    {END_TIME}

                    {format(new Date(applicationEndDate), timeFormat)}
                </Text>
            </SectionColumn>

            <SectionColumn>
                <Text>
                    {WIND_DIRECTION}

                    {windDirection}
                </Text>

                <Text>
                    {WIND_SPEED}

                    {`${roundFormat(convertMetersPerSecondToMilesPerHour(windSpeedMetersPerSecond))} ${MPH}`}
                </Text>

                <Text>
                    {WIND_GUST_SPEED}

                    {`${roundFormat(
                        convertMetersPerSecondToMilesPerHour(windGustSpeedMetersPerSecond)
                    )} ${MPH}`}
                </Text>

                <Text>
                    {AIR_TEMPERATURE}

                    {`${roundFormat(convertKelvinToFahrenheit(airTemperatureKelvin))} ${FAHRENHEIGHT}`}
                </Text>

                {drone && <Text>{DRONE(drone?.nickname)}</Text>}
            </SectionColumn>
        </Section>
    );
};

export default ProductUsageReportApplicationDetails;
