import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const Section: FunctionComponent<Props> = ({ children, style }) => (
    <View style={[styles, style]} wrap={false}>
        {children}
    </View>
);

export default Section;
