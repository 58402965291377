import {
    INPUT_CHARS_MAX,
    MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES,
    WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_POLYGON_ACRES,
    WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_SPREAD_ACRES,
    WORK_ORDER_APPLICATION_SITE_MAX,
    WORK_ORDER_APPLICATION_SITE_MAX_POLYGONS
} from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderApplicationSite');

    return {
        ADD_APPLICATION_SITE: t('addApplicationSite'),
        ERRORS: {
            ADDRESS1_MAX: t('errors.address1Max', { charMax: INPUT_CHARS_MAX }),
            ADDRESS1_REQUIRED: t('errors.address1Required'),
            ADDRESS2_MAX: t('errors.address2Max', { charMax: INPUT_CHARS_MAX }),
            BOUNDARY_INVALID: t('errors.boundaryInvalid'),
            BOUNDARY_MAX_AREA: t('errors.boundaryMaxArea', {
                boundaryAcreMax: WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_POLYGON_ACRES
            }),
            BOUNDARY_MAX_POLYGONS: t('errors.boundaryMaxPolygons', {
                boundaryMaxPolygons: WORK_ORDER_APPLICATION_SITE_MAX_POLYGONS
            }),
            BOUNDARY_SPREAD: t('errors.boundarySpread', {
                boundarySpreadAcres: WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_SPREAD_ACRES
            }),
            CITY_MAX: t('errors.cityMax', { charMax: INPUT_CHARS_MAX }),
            CITY_REQUIRED: t('errors.cityRequired'),
            LATITUDE_INVALID: t('errors.latitudeInvalid'),
            LATITUDE_REQUIRED: t('errors.latitudeRequired'),
            LOCATION_INVALID: t('errors.locationInvalid'),
            LONGITUDE_INVALID: t('errors.longitudeInvalid'),
            LONGITUDE_REQUIRED: t('errors.longitudeRequired'),
            MAX_BOUNDARY_DISTANCE: t('errors.maxBoundaryDistance', {
                maxMiles: MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES
            }),
            MAX_COORDINATE_DISTANCE: t('errors.maxCoordinateDistance', {
                maxMiles: MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES
            }),
            MAX_ONE_BOUNDARY: t('errors.maxOneBoundary'),
            ONLY_ONE_LOCATION_MARKER: t('errors.onlyOneLocationMarker'),
            SITE_NAME_MAX: t('errors.siteNameMax', { charMax: INPUT_CHARS_MAX }),
            SITE_NAME_REQUIRED: t('errors.siteNameRequired'),
            SITES_MAX: t('errors.sitesMax', { siteMax: WORK_ORDER_APPLICATION_SITE_MAX }),
            STATE_REQUIRED: t('errors.stateRequired'),
            ZIP_CODE_REQUIRED: t('errors.zipCodeRequired')
        }
    };
};

export default useTranslation;
