import { useCallback } from 'react';

import { ChevronDownIcon } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const AccordionHeader: FunctionComponent<Props> = ({
    children,
    isEnabled = true,
    isOpen = false,
    onClose,
    onOpen,
    testId = TEST_ID
}) => {
    const openClass = isOpen ? styles.open : '';

    const handleClick = useCallback(() => {
        const openOrClose = isOpen ? onClose : onOpen;

        openOrClose?.();
    }, [isOpen, onClose, onOpen]);

    return (
        <div className={styles.accordionHeader} data-testid={testId}>
            {children}

            {isEnabled && (
                <ChevronDownIcon
                    className={`${openClass} ${styles.chevronDownIcon}`}
                    onClick={handleClick}
                />
            )}
        </div>
    );
};

export default AccordionHeader;
