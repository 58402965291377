import flattenAddress from '@@utils/flattenAddress';

import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const WorkOrderApplicationSites: FunctionComponent<Props> = ({ site, title }) => {
    const { ADDRESS, COORDINATES, NAME } = useTranslation();

    if (!site) {
        return null;
    }

    const { coordinates, location, siteName } = site;
    const { address1, address2, city, state, zipCode } = location!;

    return (
        <SectionColumn title={title}>
            <Text>
                {NAME}

                {siteName}
            </Text>

            <Text>
                {ADDRESS}

                {flattenAddress({ address1, address2, city, state, zipCode })}
            </Text>

            <Text>
                {COORDINATES}

                {coordinates !== null ? `${coordinates?.longitude}, ${coordinates?.latitude}` : ''}
            </Text>
        </SectionColumn>
    );
};

export default WorkOrderApplicationSites;
