import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('newControllerPage');

    return {
        API_ERROR: t('apiError'),
        TITLE: t('title')
    };
};

export default useTranslation;
