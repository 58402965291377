import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const Column: FunctionComponent<Props> = ({ children, style }) => (
    <View style={[styles, style]}>{children}</View>
);

export default Column;
