import { useCallback } from 'react';

import { generatePagedQuery } from '@@utils/string';

import useFetch from 'hooks/useFetch';

import { FAA_REPORTS_URL } from './constants';
import {
    DatedFaaReport,
    FaaReportSummaryResponse,
    PagedResponseFaaReportSummaryResponse
} from './types';

const useData = () => {
    const { authenticatedGet } = useFetch();

    const fetch = useCallback(
        async (
            pageToken: string | null,
            pageSize = 25
        ): Promise<PagedResponseFaaReportSummaryResponse> => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const uri = `${FAA_REPORTS_URL}?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedGet]
    );

    const groupByMonthAndYear = useCallback(
        (reports: FaaReportSummaryResponse[]) =>
            reports.reduce((agg, item) => {
                const { createdAt, reportingPeriod } = item;
                const date = new Date(reportingPeriod ?? createdAt);

                const month = date.getUTCMonth();
                const year = date.getUTCFullYear();

                const monthYearDate = new Date(year, month);
                const monthYearDateString = monthYearDate.toISOString();

                if (!(monthYearDateString in agg)) {
                    agg[monthYearDateString] = [];
                }
                agg[monthYearDateString].push(item);

                return agg;
            }, {} as DatedFaaReport),
        []
    );

    return {
        fetch,
        groupByMonthAndYear
    };
};

export default useData;
