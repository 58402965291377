import { MAX_FILE_SIZE } from 'config';
import JSZip from 'jszip';
import { useCallback, useMemo, useRef, useState } from 'react';

import { FileButton } from '@mantine/core';

import ItemCard from 'components/ItemCard';
import MenuButton from 'components/MenuButton';
import SecondaryButton from 'components/SecondaryButton';
import SplitContainer from 'components/SplitContainer';
import UploadBar from 'components/UploadBar';
import VerticalContainer from 'components/VerticalContainer';

import useTranslation from './hooks/useTranslation';

import { MAX_NUM_FILES } from './constants';
import styles from './style.module.scss';
import type { FunctionComponent, Props } from './types';

const FormFileUpload: FunctionComponent<Props> = ({
    accept,
    disabled,
    displayFileAfterUpload = true,
    multiple,
    onClick,
    onFileDelete,
    onFileRequestChange,
    text
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const resetRef = useRef<VoidFunction>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const { DELETE, FILE_TOO_LARGE_ERROR, MULTIPLE_FILES, TOO_MANY_FILES } = useTranslation();

    const sanitizeFileName = useCallback((name: string) => {
        // Remove all white space and dots except for the extension
        const extension = name.split('.').pop();
        let sanitizedName = name.replace(`.${extension}`, '');

        sanitizedName = sanitizedName.replaceAll(/\W/g, '_');
        sanitizedName = `${sanitizedName}.${extension}`;

        return sanitizedName;
    }, []);

    const zip = useMemo(() => new JSZip(), []);

    const handleZipFiles = useCallback(
        async (filesToUpload: File[]) => {
            const zipFiles = filesToUpload.filter(file => file.type === 'application/zip');
            const nonZipFiles = filesToUpload.filter(file => file.type !== 'application/zip');
            const contentData: { file: JSZip.JSZipObject; relativePath: string }[] = [];

            for (const zipFile of zipFiles) {
                const data = await zipFile.arrayBuffer();
                const contents = await zip.loadAsync(data);
                // Note this is not a list and it doesn't seem to play nice with async
                // which is why I'm doing it this way.

                contents.forEach((relativePath, file) => {
                    if (!file.dir) {
                        contentData.push({
                            file,
                            relativePath
                        });
                    }
                });
            }
            const fileCount = contentData.length + nonZipFiles.length;

            if (contentData.length + nonZipFiles.length > MAX_NUM_FILES) {
                alert(TOO_MANY_FILES(MAX_NUM_FILES, fileCount));

                return [];
            }

            for (const { file, relativePath } of contentData) {
                const content = await file.async('blob');
                const unzippedFile = new File([content], relativePath);

                nonZipFiles.push(unzippedFile);
            }

            return nonZipFiles;
        },
        [TOO_MANY_FILES, zip]
    );

    const handleFileChange = useCallback(
        async (file: File | null | File[]) => {
            if (file == null) {
                return;
            }
            const filesToUpload = await handleZipFiles(Array.isArray(file) ? file : [file]);

            filesToUpload.forEach(async fileToUpload => {
                if (fileToUpload?.size > MAX_FILE_SIZE) {
                    // TODO add better error handling once it's part of the design
                    alert(FILE_TOO_LARGE_ERROR);

                    return;
                }
                setIsUploading(true);
                setIsComplete(false);
                const sanitizedFile = new File(
                    [fileToUpload],
                    sanitizeFileName(fileToUpload.name),
                    {
                        type: fileToUpload.type
                    }
                );

                setFiles(previousFiles => [...previousFiles, sanitizedFile]);

                try {
                    await onFileRequestChange({
                        file: sanitizedFile,
                        fileRequest: {
                            contentType: sanitizedFile.type,
                            filename: sanitizedFile.name,
                            filesize: sanitizedFile.size
                        }
                    });
                    setIsComplete(true);
                } catch (e) {
                    console.log(e);
                    alert('Error Uploading File Please Try Again');
                    setIsComplete(false);
                }
                setIsUploading(false);
            });
        },
        [FILE_TOO_LARGE_ERROR, handleZipFiles, onFileRequestChange, sanitizeFileName]
    );

    const clearFile = useCallback(() => {
        setFiles([]);
        resetRef.current?.();
    }, []);

    const handleDelete = useCallback(async () => {
        clearFile();
        if (onFileDelete) {
            await onFileDelete();
        }
    }, [clearFile, onFileDelete]);

    const displayName = useMemo(() => {
        if (files.length > 1) {
            return MULTIPLE_FILES(files.length);
        } else if (files.length === 1) {
            return files[0].name;
        }

        return text;
    }, [files, MULTIPLE_FILES, text]);

    const items = useMemo(
        () => [
            {
                isDelete: true,
                onClick: handleDelete,
                text: DELETE
            }
        ],
        [DELETE, handleDelete]
    );

    return (
        <VerticalContainer>
            <FileButton
                accept={accept}
                disabled={disabled || isUploading}
                key={`${Math.random()}${files.length}`}
                multiple={multiple}
                onChange={handleFileChange}
                resetRef={resetRef}
            >
                {props => (
                    <VerticalContainer>
                        {files.length === 0 || !displayFileAfterUpload ? (
                            <SecondaryButton onClick={props.onClick} text={text} />
                        ) : (
                            <ItemCard>
                                <SplitContainer className={styles.fileContainer}>
                                    <p onClick={onClick}>{displayName}</p>

                                    {onFileDelete && <MenuButton items={items} />}
                                </SplitContainer>
                            </ItemCard>
                        )}
                    </VerticalContainer>
                )}
            </FileButton>

            {Boolean(files.length > 0) && !isComplete && (
                <UploadBar isComplete={isComplete} startAnimation={isUploading} />
            )}
        </VerticalContainer>
    );
};

export default FormFileUpload;
