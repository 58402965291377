import DatePickerField from '../FormFields/DatePickerField';
import TimePickerField from '../FormFields/TimePickerField';
import TimeZoneField from '../FormFields/TimeZoneField';

import { constructUtcDateTime } from '@@utils/dates';

import FormRow from 'components/FormRow';

import useFormState from 'hooks/useFormState';

import {
    TEST_ID,
    TODAY,
    applicationDateFieldName,
    applicationStartTimeFieldName,
    applicationTimeZoneFieldName
} from './constants';
import type { ApplicationDateTimeFormSchema, FunctionComponent, Props } from './types';

const ApplicationDateTimeForm: FunctionComponent<Props> = ({ onDateChange, testId = TEST_ID }) => {
    const form = useFormState<ApplicationDateTimeFormSchema>({
        initialSchema: {
            applicationDate: '',
            applicationStartTime: '',
            applicationTimeZone: ''
        },
        onFormChange(data) {
            const { applicationDate, applicationStartTime, applicationTimeZone } = data.formData;

            if (
                applicationDate === '' ||
                !applicationDate ||
                applicationStartTime === '' ||
                !applicationStartTime ||
                applicationTimeZone === '' ||
                !applicationTimeZone
            ) {
                onDateChange(null);

                return;
            }

            const date = new Date(applicationDate);

            const dateTime = constructUtcDateTime(
                date,
                applicationStartTime,
                applicationTimeZone,
                new Date()
            ).toISOString();

            onDateChange(dateTime);
        }
    });

    return (
        <FormRow testId={testId}>
            <DatePickerField
                form={form}
                isRequired
                maxDate={TODAY}
                name={applicationDateFieldName}
                testId={applicationDateFieldName}
            />

            <TimePickerField
                form={form}
                isRequired
                name={applicationStartTimeFieldName}
                testId={applicationDateFieldName}
            />

            <TimeZoneField
                form={form}
                isRequired
                name={applicationTimeZoneFieldName}
                testId={applicationTimeZoneFieldName}
            />
        </FormRow>
    );
};

export default ApplicationDateTimeForm;
