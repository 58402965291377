import VerticalContainer from 'components/VerticalContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlatModal: FunctionComponent<Props> = ({ children, className = '', testId = TEST_ID }) => (
    <VerticalContainer className={`${className} ${styles.flatModal}`} testId={testId}>
        {children}
    </VerticalContainer>
);

export default FlatModal;
