import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deleteButton');

    return {
        DEFAULT_BUTTON_TEXT: t('defaultButtonText')
    };
};

export default useTranslation;
