import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('organizationTabs');

    return {
        EQUIPMENT: t('equipment'),
        ORGANIZATION: t('organization'),
        SUBSCRIPTION: t('subscription'),
        TEAM_MEMBERS: t('teamMembers')
    };
};

export default useTranslation;
