import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import '@@utils/yup';

import 'app/i18n';

import { App } from 'components/App';

import './index.scss';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
);
