import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workOrderChemicalInformation');

    return {
        ADD_CHEMICAL: t('addChemical'),
        ERRORS: {
            ACTIVE_INGREDIENT_CHARS_MAX: t('errors.activeIngredientCharsMax'),
            ACTIVE_INGREDIENT_REQUIRED: t('errors.activeIngredientRequired'),
            ACTIVE_INGREDIENTS_MAX: t('errors.activeIngredientsMax'),
            LABEL_NAME_REQUIRED: t('errors.labelNameRequired'),
            RATE_MAX: (maxRate: number, unit: string) =>
                t('errors.rateMax', { rateMax: maxRate, unit: unit })
        }
    };
};

export default useTranslation;
