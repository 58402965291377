import ColoredLabel from 'components/colored-labels/ColoredLabel';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const KmlLabel: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const { KML } = useTranslation();

    return (
        <ColoredLabel className={`${styles.kmlLabel} ${className}`} testId={testId} text={KML} />
    );
};

export default KmlLabel;
