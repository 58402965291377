import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('mapListContainer');

    return {
        APPLICATION_DATE: t('applicationDate'),
        CONTACT: t('contact'),
        GROWER: t('grower'),
        MAP_TYPE: t('mapType'),
        PRODUCT_USAGE_REPORT: t('productUsageReport'),
        SITE_NAME: t('siteName')
    };
};

export default useTranslation;
