import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('newProductUsageReportModal');

    return {
        CREATE_PRODUCT_USAGE_REPORT: t('createProductUsageReport'),
        LOADING: t('loading'),
        NEW_PRODUCT_USAGE_REPORT: t('newProductUsageReport'),
        PRODUCT_USAGE_REPORT_SUBTEXT_MESSAGE: t('productUsageReportSubtextMessage'),
        SELECT_WORK_ORDER: t('selectWorkOrder'),
        WORK_ORDER_REQUIRED_ERROR: t('workOrderRequiredError')
    };
};

export default useTranslation;
