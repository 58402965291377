const translations = {
    applicator: 'Applicator Information: ',
    attachedWorkOrder: 'Attached Work Order: ',
    expirationDate: 'Expiration Date: ',
    no: 'No',
    proposedDate: 'Proposed Date: ',
    scheduledDate: 'Scheduled Date: ',
    status: 'Work Order Status: ',
    time: 'Time: ',
    title: 'Work Order Details',
    workOrderNumber: 'Work Order Number (Third Party): ',
    yes: 'Yes'
};

export default translations;
