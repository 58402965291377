const translations = {
    chemical: 'Chemical Information',
    commodity: 'Site Commodity',
    conflict: 'A work order already exists with work order number: "{{workOrderNumber}}"',
    customer: 'Customer Information',
    deleteError: 'Unable To Delete Work Order',
    details: 'Work Order Details',
    grower: 'Grower Information',
    invoiceAssistant: 'Invoice Assistant',
    notes: 'Notes',
    productUsageReportDeleteWarning:
        "You can't delete a work order with a product usage report attached",
    productUsageReportWarning:
        'A product usage report exists for this work order. You may only edit the status option or invoice assistant page',
    saveToSchedule: 'Save To Schedule',
    site: 'Application Site',
    submissionError: 'Could not create work order. Please check your information and try again.',
    title: 'Create a Work Order'
};

export default translations;
