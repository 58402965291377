import { useCallback, useEffect, useState } from 'react';

import NotFoundErrorPage from 'components/NotFoundErrorPage';
import Page from 'components/Page';

import useBack from 'hooks/useBack';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const DisplayResourcePage: FunctionComponent<Props> = ({
    children,
    fetchResource,
    headerActions,
    notFoundText,
    onResourceFetched,
    resourceId,
    testId = TEST_ID,
    title
}) => {
    const back = useBack();

    const handleBack = useCallback(() => {
        back(false);
    }, [back]);

    const [isNotFound, setIsNotFound] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(
        () => {
            const fetch = async () => {
                const { data, error } = await fetchResource(resourceId as string);

                setLoading(false);

                const hasError = Boolean(error);

                if (!hasError) {
                    onResourceFetched(data);
                }

                setIsNotFound(hasError);
            };

            fetch();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [resourceId]
    );

    return isNotFound ? (
        <NotFoundErrorPage onBack={handleBack} resourceName={notFoundText} />
    ) : (
        <Page
            headerActions={headerActions}
            isLoading={loading}
            onBack={handleBack}
            testId={testId}
            title={title}
        >
            {children}
        </Page>
    );
};

export default DisplayResourcePage;
