import { CrownIcon } from '@rantizo-software/rantizo-ui';

import GridContainer from 'components/GridContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const UpgradePlanTip: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text = ''
}) => (
    <GridContainer className={`${className} ${styles.upgradePlanTip}`} testId={testId}>
        <div className={styles.container}>
            <CrownIcon />
        </div>

        {children || text}
    </GridContainer>
);

export default UpgradePlanTip;
