import {
    INPUT_CHARS_MAX,
    MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES,
    WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_POLYGON_ACRES,
    WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_SPREAD_ACRES,
    WORK_ORDER_APPLICATION_SITE_MAX,
    WORK_ORDER_APPLICATION_SITE_MAX_POLYGONS
} from 'config';
import { useCallback } from 'react';
import { array, mixed, number, object, string } from 'yup';

import { area, centroid, convex, distance } from '@turf/turf';

import useTranslation from 'components/work-order/ApplicationSite/hooks/useTranslation';

import useConvert from 'hooks/useConvert';
import useMapboxGeocoding from 'hooks/useMapboxGeocoding';

import type { Feature, FeatureCollection } from './types';

const useSchema = () => {
    const { ERRORS } = useTranslation();
    const { convertAreaToAcres, convertToSquareMeters } = useConvert();
    const { getAddressLocation } = useMapboxGeocoding();

    const createPointFromCoord = useCallback(
        (coord: number[]) => ({
            geometry: {
                coordinates: coord,
                type: 'Point'
            },
            properties: {},
            type: 'Feature'
        }),
        []
    );

    return object({
        applicationSites: array()
            .of(
                object({
                    boundary: array()
                        .of(
                            mixed()
                                .boundaryValid(ERRORS.BOUNDARY_INVALID)
                                .boundaryMaxArea(
                                    convertToSquareMeters(
                                        WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_POLYGON_ACRES,
                                        'ac'
                                    ),
                                    ERRORS.BOUNDARY_MAX_AREA
                                )
                        )
                        .max(WORK_ORDER_APPLICATION_SITE_MAX_POLYGONS, ERRORS.BOUNDARY_MAX_POLYGONS)
                        .test({
                            name: 'addressProximity',
                            test: async (value, context) => {
                                const features = (value as Feature[]).filter(
                                    f => f.geometry.type !== 'Point'
                                );
                                const { location } = context.parent;

                                if (features && features.length > 0 && location) {
                                    const bounds: FeatureCollection = {
                                        features: features,
                                        type: 'FeatureCollection'
                                    };
                                    // combine all features into a single polygon
                                    const boundary = convex(bounds);
                                    const center = centroid(boundary);
                                    const addressLocation = await getAddressLocation(location);

                                    if (center && addressLocation) {
                                        const addressPoint = createPointFromCoord(addressLocation);
                                        const distanceBetween = distance(
                                            center,
                                            addressPoint,
                                            'miles'
                                        );

                                        if (
                                            distanceBetween > MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES
                                        ) {
                                            return context.createError({
                                                message: ERRORS.MAX_BOUNDARY_DISTANCE
                                            });
                                        }
                                    }
                                }

                                return true;
                            }
                        })
                        .test({
                            name: 'boundarySpread',
                            test: (value, context) => {
                                const typedValue = value as Feature[];

                                if (typedValue && typedValue.length < 2) {
                                    return true;
                                }

                                // This check needs to go here to ensure if any of the features are points
                                // there can only be 1. Otherwise it'll throw an error when we calculate the area
                                for (let i = 0; i < typedValue.length; i++) {
                                    const feature = typedValue[i];

                                    if (feature?.geometry?.type === 'Point') {
                                        if (typedValue.length !== 1) {
                                            return context.createError({
                                                message: ERRORS.ONLY_ONE_LOCATION_MARKER
                                            });
                                        }
                                    }
                                }

                                const bounds: FeatureCollection = {
                                    features: typedValue,
                                    type: 'FeatureCollection'
                                };

                                const spread = convex(bounds);
                                const boundAreaSquareMeters = area(spread);
                                const boundAreaAcres = convertAreaToAcres(
                                    boundAreaSquareMeters,
                                    'm2'
                                );

                                if (
                                    !(
                                        boundAreaAcres <
                                        WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_SPREAD_ACRES
                                    )
                                ) {
                                    return context.createError({
                                        message: ERRORS.BOUNDARY_SPREAD
                                    });
                                }

                                return true;
                            }
                        })
                        .test({
                            name: 'oneFeature',
                            test: (value, context) => {
                                const features = value as Feature[];

                                if (features.length > 1) {
                                    return context.createError({
                                        message: ERRORS.MAX_ONE_BOUNDARY
                                    });
                                }

                                return true;
                            }
                        }),

                    coordinates: object({
                        latitude: number()
                            .nullable()
                            .requiredIfAnySiblingPresent(ERRORS.LATITUDE_REQUIRED)
                            .moreThan(-90, ERRORS.LATITUDE_INVALID)
                            .lessThan(90, ERRORS.LATITUDE_INVALID),
                        longitude: number()
                            .nullable()
                            .requiredIfAnySiblingPresent(ERRORS.LONGITUDE_REQUIRED)
                            .moreThan(-180, ERRORS.LONGITUDE_INVALID)
                            .lessThan(180, ERRORS.LONGITUDE_INVALID)
                    })
                        .test({
                            name: 'coordinateAddressProximity',
                            test: async (value, context) => {
                                const { location } = context.parent;

                                if (value?.latitude && value?.longitude && location) {
                                    const coordPoint = createPointFromCoord([
                                        value.longitude,
                                        value.latitude
                                    ]);

                                    const addressCoordinates = await getAddressLocation(location);

                                    if (!addressCoordinates) {
                                        return true;
                                    }

                                    const addressPoint = createPointFromCoord(addressCoordinates);

                                    const distanceBetween = distance(
                                        coordPoint,
                                        addressPoint,
                                        'miles'
                                    );

                                    if (distanceBetween > MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES) {
                                        return context.createError({
                                            message: ERRORS.MAX_COORDINATE_DISTANCE
                                        });
                                    }
                                }

                                return true;
                            }
                        })
                        .notRequired(),

                    location: object({
                        address1: string()
                            .requiredIfAnySiblingPresent(ERRORS.ADDRESS1_REQUIRED)
                            .max(INPUT_CHARS_MAX, ERRORS.ADDRESS1_MAX),
                        address2: string().notRequired().max(INPUT_CHARS_MAX, ERRORS.ADDRESS2_MAX),
                        city: string()
                            .requiredIfAnySiblingPresent(ERRORS.CITY_REQUIRED)
                            .max(INPUT_CHARS_MAX, ERRORS.CITY_MAX),
                        state: string().requiredIfAnySiblingPresent(ERRORS.STATE_REQUIRED),
                        zipCode: string().requiredIfAnySiblingPresent(ERRORS.ZIP_CODE_REQUIRED)
                    }).notRequired(),

                    siteName: string()
                        .max(INPUT_CHARS_MAX, ERRORS.SITE_NAME_MAX)
                        .test({
                            message: ERRORS.SITE_NAME_REQUIRED,
                            name: 'required',
                            test: async (value, context) => {
                                if (
                                    !value &&
                                    (context.parent.location || context.parent.coordinates)
                                ) {
                                    const { address1, address2, city, state, zipCode } =
                                        context.parent.location;
                                    const { latitude, longitude } = context.parent.coordinates;

                                    const hasLocation =
                                        address1 || address2 || city || state || zipCode;
                                    const hasCoordinates = latitude || longitude;

                                    const siblingPresent = hasLocation || hasCoordinates;

                                    if (siblingPresent) {
                                        return false;
                                    }
                                }

                                return true;
                            }
                        })
                })
            )
            .required()
            .max(WORK_ORDER_APPLICATION_SITE_MAX, ERRORS.SITES_MAX)
    });
};

export default useSchema;
