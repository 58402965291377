import { GALLONS_PER_ACRE } from 'types/units.ts';

import type { Chemical } from './types';

export const EMPTY_CHEMICAL: Chemical = {
    activeIngredients: [''],
    chemicalId: '',
    chemicalProduct: null,
    epaNumber: '',
    id: '',
    labelName: '',
    pest: '',
    signalWord: null,
    sprayRate: null,
    sprayRateUnit: GALLONS_PER_ACRE.value
};
