import type { Style } from './types';

const styles: Record<string, Style> = {
    container: {
        height: '100%'
    },
    image: {
        maxHeight: '6in',
        width: '100%'
    }
};

export default styles;
