import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('uasTypeAndModel');

    return {
        SELECT_UAS: t('selectUas')
    };
};

export default useTranslation;
