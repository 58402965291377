import type { Style } from './types';

const styles: Record<string, Style> = {
    disclaimer: {
        gap: '10pt'
    },
    invoicePageFooter: {
        alignSelf: 'flex-end',
        flexDirection: 'column',
        gap: '20pt',
        marginTop: '22pt'
    },
    logo: {
        gap: '12pt'
    }
};

export default styles;
