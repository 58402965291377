import type { FunctionComponent } from 'react';

import BackButton from 'components/BackButton';
import ErrorPage from 'components/ErrorPage';

import { TEST_ID } from './constants';
import { Props } from './types';

const NotFoundErrorPage: FunctionComponent<Props> = props => {
    const { onBack, resourceName, testId = TEST_ID, title = 'error.headers.oops' } = props;

    const text = 'error.body.notFound';
    const values = { resource: resourceName };

    return (
        <ErrorPage testId={testId} text={text} title={title} values={values}>
            {onBack && <BackButton onClick={onBack} />}
        </ErrorPage>
    );
};

export default NotFoundErrorPage;
