import type { Style } from './types';

const styles: Record<string, Style> = {
    legend: {
        flexDirection: 'column',
        flexGrow: 1
    },
    legendContainer: {
        flex: 3,
        flexDirection: 'column',
        paddingLeft: '30pt'
    }
};

export default styles;
