import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('homePage');

    return {
        BEGIN_USING: t('beginUsing'),
        CREATE_WORK_ORDER: t('createWorkOrder'),
        LICENSE_READY: t('licenseReady'),
        NO_JOBS: t('noJobs'),
        ORGANIZATION_CREATED: t('organizationCreated'),
        TITLE: (firstName: string) => t('title', { firstName })
    };
};

export default useConstants;
