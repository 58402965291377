export const TEST_ID = 'applicationDetailsForm';

export const DRONE_SERIAL_NUMBER_TEST_ID = 'droneSerialNumberInput';

export const windDirection = [
    { label: 'N', value: 'N' },
    { label: 'NE', value: 'NE' },
    { label: 'E', value: 'E' },
    { label: 'SE', value: 'SE' },
    { label: 'S', value: 'S' },
    { label: 'SW', value: 'SW' },
    { label: 'W', value: 'W' },
    { label: 'NW', value: 'NW' }
];

export const FORM_NOT_TOUCHED = {
    windDirection: false,
    windGustSpeed: false,
    windGustSpeedUnit: false,
    windSpeed: false,
    windSpeedUnit: false
};
