import type { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Props as IntlProps } from 'react-intl/src/components/message';

import type { IntlMessageKeys } from './helper';

// Creating a wrapper over the original FormattedMessage component from react-intl
// to provide type safety and intellisense autocomplete

type TextContentProps = IntlProps<Record<string, ReactNode>> & { id: IntlMessageKeys };

export const TextContent: FunctionComponent<TextContentProps> = ({ id, values, ...rest }) => (
    <FormattedMessage id={id} values={{ nbsp: <>&nbsp;</>, ...values }} {...rest} />
);
