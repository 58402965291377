import Section from 'components/pdf/Section';
import SectionColumn from 'components/pdf/SectionColumn';
import Text from 'components/pdf/Text';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const WorkOrderSiteCommodity: FunctionComponent<Props> = ({ workOrder }) => {
    const {
        DAYS,
        GPA,
        HOURS,
        PRE_HARVEST_INTERVAL,
        PROPOSED_ACRES,
        RE_ENTRY_INTERVAL,
        SITE_COMMODITY,
        TARGET_SPRAY_RATE,
        TITLE
    } = useTranslation();

    const {
        commodity,
        preHarvestInterval,
        proposedAcres,
        reEntryIntervalDays,
        reEntryIntervalHours,
        targetSprayRate
    } = workOrder;

    return (
        <Section>
            <SectionColumn title={TITLE}>
                <Text>
                    {SITE_COMMODITY}

                    {commodity ?? ''}
                </Text>

                <Text>
                    {PROPOSED_ACRES}

                    {proposedAcres ?? '-'}
                </Text>

                <Text>
                    {TARGET_SPRAY_RATE}

                    {`${targetSprayRate ?? '-'} ${GPA}`}
                </Text>

                <Text>
                    {RE_ENTRY_INTERVAL}

                    {`${reEntryIntervalDays ?? '-'} ${DAYS}, ${reEntryIntervalHours ?? '-'} ${HOURS}`}
                </Text>
            </SectionColumn>

            <SectionColumn>
                <Text>
                    {PRE_HARVEST_INTERVAL}

                    {`${preHarvestInterval ?? '-'} ${DAYS}`}
                </Text>
            </SectionColumn>
        </Section>
    );
};

export default WorkOrderSiteCommodity;
