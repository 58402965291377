import type { Style } from './types';

const styles: Style = {
    flex: 'auto',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%'
};

export default styles;
