import type { Style } from './types';

const styles: Record<string, Style> = {
    rowDivider: {
        borderBottom: '1pt solid #b9b9b9'
    },
    tableRow: {
        flexDirection: 'row',
        width: '100%'
    }
};

export default styles;
