import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('downloadShapeFile');

    return {
        DOWNLOAD_SHAPE_FILE: t('downloadShapeFile')
    };
};

export default useConstants;
