import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('mapProcessorLoader');

    return {
        LOADING_MESSAGE: t('loadingMessage'),
        WAITING_MESSAGE: t('waitingMessage')
    };
};

export default useTranslation;
