import { useCallback, useState } from 'react';

import { equipmentSortNickname } from '@@utils/sort';

import useAircraft from 'hooks/useAircraft';

import type { DropdownItemType } from './types';

const useData = () => {
    const { fetchAllAircrafts } = useAircraft();
    const [aircraft, setAircraft] = useState<DropdownItemType[]>([]);

    const fetchAircraft = useCallback(async () => {
        const { data, error } = await fetchAllAircrafts(true);

        if (error) {
            console.error(error);

            setAircraft([]);
        }

        if (data) {
            data.sort(equipmentSortNickname);

            const dropdownItems = data.map(aircraft => ({
                label: aircraft.nickname,
                type: 'string',
                value: JSON.stringify(aircraft)
            }));

            setAircraft(dropdownItems);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        aircraft,
        fetchAircraft
    };
};

export default useData;
