import { useState } from 'react';

import useFetch from 'hooks/useFetch';

import { DRONE_MAKES_API } from './constants';
import type { FetchData } from './types';

const useData = () => {
    const { authenticatedGet } = useFetch();

    const [data, setData] = useState<string[]>([]);

    const fetchData: FetchData = async () => {
        const response = await authenticatedGet(DRONE_MAKES_API, null);

        const { data } = response;

        setData(data);

        return response;
    };

    return {
        data,
        fetchData
    };
};

export default useData;
