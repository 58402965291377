import { DatedSummary, ProductUsageReportSummary } from 'pages/ProductUsageReportListPage/types';

const useData = () => {
    const groupByDate = (summaries: ProductUsageReportSummary[]) =>
        summaries.reduce((agg, item) => {
            const { applicationDate } = item;

            const localizedDate = new Date(applicationDate);

            const date = localizedDate.toLocaleDateString('en-US');

            if (!(date in agg)) {
                agg[date] = [];
            }
            agg[date].push(item);

            return agg;
        }, {} as DatedSummary);

    return { groupByDate };
};

export default useData;
