import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('siteFooter');

    return {
        COPYRIGHT: (year: string) => t('copyright', { year }),
        TERMS_OF_SERVICE: t('termsOfService')
    };
};

export default useTranslation;
