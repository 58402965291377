import type { Style } from './types';

const styles: Record<string, Style> = {
    invoiceDetails: {
        flexDirection: 'column',
        gap: '22pt',
        padding: '0.3in 0'
    },
    section: {
        padding: 0
    }
};

export default styles;
