import { useMemo } from 'react';

import MapContainer from 'components/MapContainer';
import MapLoader from 'components/MapLoader';
import Mapbox from 'components/Mapbox';
import AlternateFlightControl from 'components/Mapbox/components/AlternateFlightControl';
import FlightPathControl from 'components/Mapbox/components/FlightPathControl';
import { MapboxButton } from 'components/Mapbox/types';

import { TEST_ID } from './constants';
import type { FunctionComponent, GeoJSONSourceRaw, Props } from './types';

import styles from './styles.module.scss';

const FlightsMap: FunctionComponent<Props> = props => {
    const {
        center,
        data = [],
        isLoading = false,
        onAlternateFlightPathClick,
        onLoad,
        onPathClick,
        testId = TEST_ID
    } = props;

    const additionalButtons = useMemo(() => {
        const buttons = [
            {
                component: <FlightPathControl onClick={onPathClick} />,
                portalKey: Math.random().toString(),
                position: 'top-left'
            } as MapboxButton
        ];

        if (onAlternateFlightPathClick) {
            buttons.push({
                component: <AlternateFlightControl onClick={onAlternateFlightPathClick} />,
                portalKey: Math.random().toString(),
                position: 'top-left'
            } as MapboxButton);
        }

        return buttons;
    }, [onAlternateFlightPathClick, onPathClick]);

    return (
        <MapContainer className={styles.flightsMap} data-testid={testId}>
            {isLoading && <MapLoader />}

            {!isLoading && data && center && (
                <Mapbox
                    mapSource={
                        {
                            data,
                            type: 'geojson'
                        } as GeoJSONSourceRaw
                    }
                    additionalButtons={additionalButtons}
                    center={center}
                    onMapLoaded={onLoad}
                />
            )}
        </MapContainer>
    );
};

export default FlightsMap;
