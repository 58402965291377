import Page from 'components/pdf/Page';
import PageHeader from 'components/pdf/PageHeader';

import type { FunctionComponent, Props } from './types';

const PageWithHeaderOnly: FunctionComponent<Props> = ({
    children,
    headerText = '',
    wrap = false
}) => (
    <Page wrap={wrap}>
        <PageHeader headerText={headerText} />

        {children}
    </Page>
);

export default PageWithHeaderOnly;
