import { useCallback, useEffect, useMemo, useState } from 'react';

import Mapbox from 'components/Mapbox';

import { TEST_ID } from './constants';
import type {
    DrawCreateEvent,
    DrawDeleteEvent,
    DrawUpdateEvent,
    Feature,
    FunctionComponent,
    Map,
    MapboxEvent,
    Props
} from './types';

const FormMapBoundary: FunctionComponent<Props> = ({
    center,
    className,
    field,
    form,
    isDisabled = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { setFieldTouched, setFieldValue } = form;
    const { name, value } = field;

    const [map, setMap] = useState<Map>();
    const [boundary, setBoundary] = useState<Feature[]>(value);

    useEffect(() => {
        setFieldTouched(name);
        setFieldValue(name, boundary);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boundary]);

    useEffect(() => {
        if (map && center) {
            map.jumpTo({ center });
        }
    }, [center, map]);

    const onDrawCreate = useCallback(
        (event: DrawCreateEvent) =>
            setBoundary(currentBoundary => currentBoundary.concat(event.features)),
        []
    );

    const onDrawDelete = useCallback(
        (event: DrawDeleteEvent) =>
            setBoundary(currentBoundary => {
                const newValue = currentBoundary.filter(
                    boundary =>
                        event.features.find(
                            removedBoundary => removedBoundary.id === boundary.id
                        ) === undefined
                );

                return newValue;
            }),
        []
    );

    const onDrawUpdate = useCallback(
        (event: DrawUpdateEvent) =>
            setBoundary(currentBoundary => {
                const filteredBoundaries = currentBoundary.filter(
                    boundary =>
                        event.features.find(
                            updatedBoundaries => updatedBoundaries.id === boundary.id
                        ) === undefined
                );

                return filteredBoundaries.concat(event.features);
            }),
        []
    );

    const onMapLoaded = useCallback((event: MapboxEvent) => {
        setMap(event.target);
    }, []);

    // We only want to provide an initial boundary to Mapbox
    // Mapbox should not be aware of manipulations we do to that boundary
    const mapMemo = useMemo(
        () => (
            <Mapbox
                center={center}
                className={className}
                initialDrawState={value}
                interactive={true}
                isEditable={isEditable && !isDisabled}
                onDrawCreate={onDrawCreate}
                onDrawDelete={onDrawDelete}
                onDrawUpdate={onDrawUpdate}
                onMapLoaded={onMapLoaded}
                testId={testId}
            />
        ),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEditable]
    );

    return <>{mapMemo}</>;
};

export default FormMapBoundary;
