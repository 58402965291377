import { array, number, object, string } from 'yup';
import 'yup-phone-lite';

import useTranslation from 'components/work-order/InvoiceAssistant/hooks/useTranslation';

import {
    MAXIMUM_DESCRIPTION_LENGTH,
    MAXIMUM_FIELD_HOURS,
    MAXIMUM_INVOICE_ITEMS
} from './constants';

const useSchema = () => {
    const { ERRORS } = useTranslation();

    return object({
        appliedAcres: number().nullable(),
        chemicalCost: number().nullable(),
        estimatedTotal: number().nullable(),
        fieldApplicationFlatFee: number().nullable(),
        fieldApplicationHours: number().when('fieldApplicationRateUnit', {
            is: 'usdPerHr',
            otherwise: undefined,
            then: schema =>
                schema
                    .required(ERRORS.FIELD_APPLICATION_HOURS_REQUIRED)
                    .max(MAXIMUM_FIELD_HOURS, ERRORS.FIELD_APPLICATION_HOURS_MAX)
        }),
        fieldApplicationRate: number().nullable(),
        fieldApplicationRateUnit: string(),
        invoiceItems: array()
            .of(
                object({
                    amountDollars: number().nullable(),
                    description: string()
                })
            )
            .max(MAXIMUM_INVOICE_ITEMS),
        invoicedApplicationSites: array(),
        otherDescription: string()
            .test({
                message: ERRORS.OTHER_DESCRIPTION_REQUIRED,
                name: 'required',
                test: (value, context) => (context.parent.otherFee ? Boolean(value) : true)
            })
            .test({
                message: ERRORS.OTHER_DESCRIPTION_MAX,
                name: 'max',
                test: (value, context) =>
                    context.parent.otherFee && value
                        ? value.length < MAXIMUM_DESCRIPTION_LENGTH
                        : true
            }),
        otherFee: number()
            .nullable()
            .test({
                message: ERRORS.OTHER_FEE_REQUIRED,
                name: 'required',
                test: (value, context) => (context.parent.otherDescription ? Boolean(value) : true)
            }),
        proposedAcres: number().nullable(),
        taxAmount: number().nullable(),
        taxAmountUnit: string()
    });
};

export default useSchema;
