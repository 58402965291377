import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('connectionListItem');

    return {
        CONNECT: t('connect')
    };
};

export default useTranslation;
