const translations = {
    appliedRate: 'Estimated Applied Rate',
    appliedTotal: 'Estimated Applied Total',
    areaApplied: 'Estimated Area Applied',
    asAppliedMapTitle: 'As Applied Map',
    asCoveredMapTitle: 'As Covered Map',
    chemicalsApplied: 'Chemicals Applied',
    data: 'Application Data',
    endTime: 'End Time',
    generatedDate: 'Your As Applied Map was generated on {{date}}',
    legend: 'Legend',
    location: 'Location',
    startTime: 'Start Time',
    tbd: 'TBD'
};

export default translations;
