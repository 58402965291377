import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import WithFeatureAccess from 'components/WithFeatureAccess';

import useFeatureAccess from 'hooks/useFeatureAccess';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SplitButton: FunctionComponent<Props> = ({
    children,
    className = '',
    feature,
    isDisabled = false,
    onClick,
    onSplitClick,
    testId = TEST_ID,
    text
}) => {
    const features = useFeatureAccess();

    const isFeatureAccessible = feature ? features[feature] : true;

    return (
        <div className={styles.splitButton}>
            <PrimaryButton
                className={`${className} ${styles.button}`}
                isDisabled={isDisabled || !isFeatureAccessible}
                onClick={onClick}
                testId={testId}
            >
                {feature && (
                    <WithFeatureAccess className={styles.featureAccess} feature={feature}>
                        {text}
                    </WithFeatureAccess>
                )}

                {!feature && text}
            </PrimaryButton>

            <PrimaryButton
                className={styles.splitSection}
                isDisabled={isDisabled || !isFeatureAccessible}
                onClick={onSplitClick}
            >
                {children}
            </PrimaryButton>
        </div>
    );
};

export default SplitButton;
