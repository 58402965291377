import type { Style } from './types';

const styles: Style = {
    color: '#cacaca',
    fontFamily: 'Inter',
    fontSize: '7pt',
    fontWeight: 'medium'
};

export default styles;
