import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('controllerList');

    return {
        BUTTON: t('button')
    };
};

export default useTranslation;
