/* IMPORTANT NOTICE

Everything Mantine related is being deprecated. Please do not spend
a lot of time trying to fix/enhance this file. Any TS errors simply
suppress them.

*/
import { Clock } from 'iconoir-react';

import { type ButtonStylesParams, MantineThemeOverride } from '@mantine/core';

export const AppTheme: MantineThemeOverride = {
    colorScheme: 'light',
    colors: {
        blue: [
            '#dff1ff',
            '#b1d1ff',
            '#80b3ff',
            '#4f94fe',
            '#2376fd',
            '#105ce4',
            '#0648b2',
            '#002966',
            '#001f4f',
            '#000a1f'
        ],
        gray: [
            '#f6f6f6',
            '#d9d9d9',
            '#bfbfbf',
            '#a6a6a6',
            '#8c8c8c',
            '#737373',
            '#595959',
            '#404040',
            '#262626',
            '#120b0d'
        ],
        green: [
            '#dcfff5',
            '#b3fae4',
            '#87f5cd',
            '#5af1b2',
            '#31ed9e',
            '#48d48d',
            '#0ea778',
            '#037655',
            '#004832',
            '#001a0f'
        ],

        orange: [
            '#fff1da',
            '#ffd6ae',
            '#ffbd7d',
            '#ffa34c',
            '#ff881a',
            '#e06c00',
            '#b45600',
            '#813c00',
            '#4f2400',
            '#200900'
        ],

        // Making shade 7 our error color, since there's no way to set that elsewhere
        red: [
            '#ffe5e5',
            '#f9bbbb',
            '#ef9191',
            '#e76565',
            '#e14a4a',
            '#c52121',
            '#9a1718',
            '#ff2f2f',
            '#450708',
            '#1e0000'
        ],
        violet: [
            '#ece9ff',
            '#c7c0f4',
            '#a195e9',
            '#7c6bdf',
            '#5742d6',
            '#3e28bc',
            '#301f93',
            '#251874',
            '#14004f',
            '#07031a'
        ],
        yellow: [
            '#fff8da',
            '#ffe9ad',
            '#ffdb7d',
            '#ffcd4b',
            '#ffbe1a',
            '#f8b200',
            '#b38000',
            '#805c00',
            '#4e3700',
            '#1e1100'
        ]
    },
    components: {
        Accordion: {
            styles: () => ({
                label: {
                    fontWeight: 600
                }
            })
        },
        Button: {
            defaultProps: {
                size: 'lg'
            },
            styles: (theme, { color }: ButtonStylesParams) => ({
                root: {
                    backgroundColor: color ? theme.colors[color] : undefined,
                    borderRadius: '0.5rem',
                    fontSize: '0.875rem',
                    height: '2.875rem'
                }
            })
        },
        DateTimePicker: {
            defaultProps: {
                // @ts-expect-error implementation will later be changed
                timeInputProps: { icon: <Clock /> }
            },
            styles: () => ({
                timeInput: {
                    marginRight: '0.625rem'
                }
            })
        },
        Header: {
            styles: () => ({
                root: {
                    zIndex: 200
                }
            })
        },
        Input: {
            styles: () => ({
                input: {
                    borderRadius: '0.5rem',
                    height: '2.875rem'
                }
            })
        },
        InputWrapper: {
            defaultProps: {
                inputWrapperOrder: ['label', 'error', 'input', 'description']
            },
            styles: () => ({
                error: {
                    marginTop: 0,
                    textAlign: 'left'
                },
                label: {
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '1rem'
                }
            })
        },
        Text: {
            defaultProps: {
                align: 'center',
                color: 'blue.7',
                size: '0.875rem'
            }
        },
        Title: {
            defaultProps: {
                color: 'black'
            }
        }
    },
    cursorType: 'pointer',
    fontFamily: 'Inter',
    fontSizes: {
        lg: '1rem',
        md: '0.875rem',
        sm: '0.75rem',
        xl: '1.125rem',
        xs: '0.625rem'
    },
    globalStyles: theme => ({
        '&[data-weekend]': {
            color: `${theme.colors.blue[7]} !important`
        },
        '.mantine-Day-day[data-selected]': {
            color: `${theme.white} !important`
        },
        small: {
            color: theme.colors.gray[4],
            fontSize: '0.75rem'
        }
    }),
    headings: {
        sizes: {
            h2: {
                fontSize: '1.5rem',
                fontWeight: 600,
                lineHeight: '1.875rem'
            },
            h4: {
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '1rem'
            }
        }
    },
    loader: 'oval',

    primaryColor: 'blue',
    primaryShade: 7
};
