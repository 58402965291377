import LargeSemiboldText from 'components/LargeSemiboldText';

import { Props } from './types';

import styles from './styles.module.scss';

const MultiSelectCardsHeader = ({ selectedText, title }: Props) => (
    <div className={styles.multiSelectHeader}>
        <LargeSemiboldText text={title} />

        <LargeSemiboldText className={styles.selectedText} text={selectedText} />
    </div>
);

export default MultiSelectCardsHeader;
