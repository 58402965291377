import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfWorkOrderSiteCommodity');

    return {
        DAYS: t('days'),
        GPA: t('gpa'),
        HOURS: t('hours'),
        PRE_HARVEST_INTERVAL: t('preHarvestInterval'),
        PROPOSED_ACRES: t('proposedAcres'),
        RE_ENTRY_INTERVAL: t('reEntryInterval'),
        SITE_COMMODITY: t('siteCommodity'),
        TARGET_SPRAY_RATE: t('targetSprayRate'),
        TITLE: t('title')
    };
};

export default useTranslation;
