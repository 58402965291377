import type { Style } from './types';

const styles: Style = {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%'
};

export default styles;
