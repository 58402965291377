import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('multiSelectCards');

    return {
        SELECTED: (count: number, total: number) => t('selected', { count, total }),
        TOOLTIP_TEXT: t('tooltipText')
    };
};

export default useTranslation;
