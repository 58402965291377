import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';

import { ORGANIZATIONS_API } from './constants';
import { FetchOrganizations, OrganizationRequest, Organizations } from './types';

const useOrganizations = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useOrganizations' });

    const { authenticatedGet, authenticatedPost } = useFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveOrganizationsToStore = useCallback(setValue('organizations'), []);

    const createOrganization = useCallback(
        async (values: OrganizationRequest) => {
            const { companyName, location, phone } = values;
            const { address1, address2 = '', city, state, zipCode } = location;

            const requestData = {
                address1,
                address2: address2 ?? '',
                city,
                country: 'US',
                location: { coordinates: [-83.1552834, 42.5576608], type: 'Point' }, // remove once location is not required in API request
                name: companyName,
                phoneCountryCode: '1',
                phoneNumber: phone?.replaceAll(/[-() ]/g, ''),
                state,
                zipCode
            };

            const response = await authenticatedPost(
                ORGANIZATIONS_API,
                JSON.stringify(requestData)
            );

            return response;
        },
        [authenticatedPost]
    );

    const fetchOrganizations: FetchOrganizations = useCallback(async () => {
        const output = await authenticatedGet(ORGANIZATIONS_API);

        const organizations = output?.data?.content ?? [];

        saveOrganizationsToStore(organizations);

        return { data: organizations, error: output.error };
    }, [authenticatedGet, saveOrganizationsToStore]);

    return {
        createOrganization,
        fetchOrganizations,
        organizations: getValue('organizations') as Organizations
    };
};

export default useOrganizations;
