import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('onboardingLogout');

    return {
        LOGOUT_NOT_VERIFIED: t('logoutNotVerified'),
        LOGOUT_VERIFIED: t('logoutVerified'),
        NOT_VERIFIED_MESSAGE: t('notVerifiedMessage'),
        VERIFIED_MESSAGE: t('verifiedMessage')
    };
};

export default useTranslation;
