import type { Style } from './types';

const styles: Style = {
    borderLeft: '1pt',
    borderLeftColor: '#d9d9d9',
    marginTop: '0.5in',
    width: 0
};

export default styles;
