import { useState } from 'react';

import VerticalContainer from 'components/VerticalContainer';
import FileListHeader from 'components/form/MultiFileUpload/components/FileListHeader';
import FileListRow from 'components/form/MultiFileUpload/components/FileListRow';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FileListContainer: FunctionComponent<Props> = ({ files, onDeleteAll, testId = TEST_ID }) => {
    const { HEADER } = useTranslation();

    const [open, setOpen] = useState(true);

    return (
        <VerticalContainer className={styles.fileListContainer} testId={testId}>
            <FileListHeader
                isOpen={open}
                onClick={() => setOpen(open => !open)}
                onDeleteAll={onDeleteAll}
                text={HEADER(files.length.toString())}
            />

            <VerticalContainer
                className={`${styles.fileListContainerBody} ${!open ? styles.bodyClosed : styles.bodyOpen} `}
            >
                {files?.map(({ error, fileName, onDelete }, index) => (
                    <FileListRow
                        error={error}
                        fileName={fileName}
                        key={`${fileName}-${index}`}
                        onDelete={onDelete}
                    />
                ))}
            </VerticalContainer>
        </VerticalContainer>
    );
};

export default FileListContainer;
