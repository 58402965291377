import { useCallback, useState } from 'react';

const useModals = () => {
    const [deleteConfirmationModalStatus, setDeleteConfirmationModalStatus] = useState(false);
    const [deleteErrorModalStatus, setDeleteErrorModalStatus] = useState(false);
    const handleDeleteConfirmationModalClose = useCallback(
        () => setDeleteConfirmationModalStatus(false),
        []
    );
    const handleDeleteErrorModalClose = useCallback(() => setDeleteErrorModalStatus(false), []);

    return {
        deleteConfirmationModalStatus,
        deleteErrorModalStatus,
        handleDeleteConfirmationModalClose,
        handleDeleteErrorModalClose,
        setDeleteConfirmationModalStatus,
        setDeleteErrorModalStatus
    };
};

export default useModals;
