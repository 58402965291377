import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('dateRangePickerInputs');

    return {
        END_DATE_PLACEHOLDER: t('endDatePlaceholder'),
        START_DATE_PLACEHOLDER: t('startDatePlaceholder')
    };
};

export default useTranslation;
