import Logo from 'components/Logo';

import styles from './styles.module.scss';

const SimpleHeader = ({ className = '' }) => (
    <header className={`${className} ${styles.simpleHeader}`}>
        <Logo className={styles.logo} />
    </header>
);

export default SimpleHeader;
