import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { v4 as uuid } from 'uuid';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithTooltip: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const id = `tooltip-${uuid()}`;

    return (
        <>
            <div className={className} data-testid={testId} data-tooltip-id={id} ref={containerRef}>
                {children}
            </div>

            {createPortal(
                <Tooltip
                    className={styles.tooltip}
                    content={text}
                    disableStyleInjection
                    id={id}
                    place="top"
                />,
                document.body
            )}
        </>
    );
};

export default WithTooltip;
