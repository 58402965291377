import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import BackNextButtons from 'components/BackNextButtons';
import FormSectionContainer from 'components/FormSectionContainer';

import useTranslation from './hooks/useTranslation';

import { FunctionComponent, Props } from './types';

const ResourcePageNavigationButtons: FunctionComponent<Props> = ({
    children,
    onBack,
    onNext,
    testId
}) => {
    const { BACK } = useTranslation();

    return (
        <FormSectionContainer testId={testId}>
            {children}

            {onBack && !onNext && <PrimaryButton onClick={onBack} text={BACK} />}

            {onNext && <BackNextButtons isNextLoading={false} onBack={onBack} onNext={onNext} />}
        </FormSectionContainer>
    );
};

export default ResourcePageNavigationButtons;
