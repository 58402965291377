import SecondaryTitle from 'components/SecondaryTitle';
import SplitContainer from 'components/SplitContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const DataItem: FunctionComponent<Props> = ({ children, testId = TEST_ID, text }) => (
    <SplitContainer testId={testId}>
        <SecondaryTitle text={text} />

        {children}
    </SplitContainer>
);

export default DataItem;
