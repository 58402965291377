import Link from 'components/Link';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OnboardingLogout: FunctionComponent<Props> = ({ emailVerified = true, testId = TEST_ID }) => {
    const { accountLogoutPage } = usePageRoutes();
    const { LOGOUT_NOT_VERIFIED, LOGOUT_VERIFIED, NOT_VERIFIED_MESSAGE, VERIFIED_MESSAGE } =
        useTranslation();

    const [message, linkText] = emailVerified
        ? [VERIFIED_MESSAGE, LOGOUT_VERIFIED]
        : [NOT_VERIFIED_MESSAGE, LOGOUT_NOT_VERIFIED];

    return (
        <span className={styles.onboardingLogout} data-testid={testId}>
            {`${message} `}

            <Link text={linkText} to={accountLogoutPage} />
        </span>
    );
};

export default OnboardingLogout;
