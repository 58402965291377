import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const InputLabel: FunctionComponent<Props> = ({
    children,
    className = '',
    htmlFor,
    isDisabled = false,
    isRequired = false,
    testId = TEST_ID,
    text
}) => {
    const requiredClass = isRequired ? styles.inputRequired : '';
    const disabledClass = isDisabled ? styles.inputDisabled : '';

    return (
        <label
            className={`${className} ${styles.inputLabel} ${requiredClass} ${disabledClass}`}
            data-testid={testId}
            htmlFor={htmlFor}
        >
            {children || text}
        </label>
    );
};

export default InputLabel;
