import CloseButton from 'components/CloseButton';
import VerticalContainer from 'components/VerticalContainer';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Modal: FunctionComponent<Props> = ({
    children,
    className = '',
    onClose,
    testId = TEST_ID
}) => (
    <div className={`${className} ${styles.modal}`} data-testid={testId}>
        <CloseButton className={styles.closeButton} onClick={onClose} />

        <VerticalContainer className={styles.verticalContainer}>{children}</VerticalContainer>
    </div>
);

export default Modal;
