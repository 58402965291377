import useFileUpload from 'hooks/useFileUpload';

import { WORK_ORDER_DOCUMENTS_API, WORK_ORDER_UPLOAD_API } from './constants';

const useWorkOrderFileUpload = () => {
    const { deleteDocument, getUploadUrlResponse, saveDocument } = useFileUpload({
        documentsApi: WORK_ORDER_DOCUMENTS_API,
        uploadApi: WORK_ORDER_UPLOAD_API
    });

    return { deleteDocument, getUploadUrlResponse, saveDocument };
};

export default useWorkOrderFileUpload;
