import { format } from 'date-fns';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('asAppliedMapPdf');

    return {
        APPLIED_RATE: t('appliedRate'),
        APPLIED_TOTAL: t('appliedTotal'),
        AREA_APPLIED: t('areaApplied'),
        AS_APPLIED_MAP_TITLE: t('asAppliedMapTitle'),
        AS_COVERED_MAP_TITLE: t('asCoveredMapTitle'),
        CHEMICALS_APPLIED: t('chemicalsApplied'),
        DATA: t('data'),
        END_TIME: t('endTime'),
        GENERATED_DATE: t('generatedDate', { date: format(new Date(), 'MM/dd/yyyy') }),
        LEGEND: t('legend'),
        LOCATION: t('location'),
        START_TIME: t('startTime'),
        TBD: t('tbd')
    };
};

export default useTranslation;
