import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('jobs');

    return {
        CONTACT: t('contact'),
        GROWER: t('grower'),
        NO_JOBS: t('noJobs'),
        PHONE_NUMBER: t('phoneNumber'),
        SITE_NAME: t('siteName'),
        STATUS: t('status'),
        TIME: t('time')
    };
};

export default useTranslation;
