export const TEST_ID = 'flightLogForm';

export const AAM_FLIGHT_BOUNDS_SQ_KM = 3;
export const MAX_NUM_FLIGHTS = 100;
export const DRY = 'DRY';
export const DJI_UPLOADED = 'DJI_UPLOADED';
export const XAG_KML_UPLOADED = 'XAG_KML_UPLOADED';
export const DRY_COVERAGE = 'DRY_COVERAGE';
export const COVERAGE = 'COVERAGE';
export const AAM = 'AAM';
