export const ADDRESS_ONE_FIELD = 'addressLine1';

export const ADDRESS_TWO_FIELD = 'addressLine2';

export const CITY_FIELD = 'city';

export const STATE_FIELD = 'state';

export const TEST_ID = 'addressForm';

export const ZIP_CODE_FIELD = 'zipCode';
