import { useMemo } from 'react';

import useTranslation from 'pages/CreateFaaReportPage/hooks/useTranslation';

import type { SideBarItem, SidebarItems } from './types';

const useSidebarItems = ({ disableSideBar = false, flightDetailsDisabled = false }) => {
    const { COMPANY_DETAILS, FLIGHT_DETAILS, OPERATION_DETAILS, UAS_TYPE_AND_MODEL } =
        useTranslation();

    const sidebarItems: Record<SidebarItems, SideBarItem> = useMemo(
        () => ({
            companyDetails: {
                index: 0,
                isDisabled: disableSideBar,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'uasTypeAndModel',
                title: COMPANY_DETAILS
            },
            flightDetails: {
                index: 3,
                isDisabled: disableSideBar || flightDetailsDisabled,
                isInvalid: false,
                isViewOnly: true,
                previousSection: 'operationDetails',
                title: FLIGHT_DETAILS
            },
            operationDetails: {
                index: 2,
                isDisabled: disableSideBar,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'flightDetails',
                previousSection: 'uasTypeAndModel',
                title: OPERATION_DETAILS
            },
            uasTypeAndModel: {
                index: 1,
                isDisabled: disableSideBar,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'operationDetails',
                previousSection: 'companyDetails',
                title: UAS_TYPE_AND_MODEL
            }
        }),
        [
            COMPANY_DETAILS,
            FLIGHT_DETAILS,
            OPERATION_DETAILS,
            UAS_TYPE_AND_MODEL,
            disableSideBar,
            flightDetailsDisabled
        ]
    );

    return {
        sidebarItems
    };
};

export default useSidebarItems;
