import { InfoIcon } from '@rantizo-software/rantizo-ui';

import InputLabel from 'components/InputLabel';
import RowSpaceBetween from 'components/RowSpaceBetween';
import WithTooltip from 'components/WithTooltip';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithLabelAndInfo: FunctionComponent<Props> = ({
    children,
    className = '',
    displayTooltip = true,
    isRequired = false,
    testId = TEST_ID,
    text,
    tooltipText
}) => (
    <div className={`${className} ${styles.withLabelAndInfo}`} data-testid={testId}>
        <RowSpaceBetween>
            <InputLabel isRequired={isRequired} text={text} />

            {displayTooltip && (
                <WithTooltip text={tooltipText}>
                    <InfoIcon className={styles.infoIcon} />
                </WithTooltip>
            )}
        </RowSpaceBetween>

        {children}
    </div>
);

export default WithLabelAndInfo;
