const translations = {
    amountApplied: 'Amount Applied',
    carrier: 'Carrier: ',
    chemical: 'Chemical',
    gallons: 'Ga',
    gallonsPerAcre: 'Ga/Ac',
    rate: 'Rate Applied',
    totalAcres: 'Total Acres Applied: ',
    totalProduct: 'Total Product Used: ',
    volume: 'Volume Applied'
};

export default translations;
