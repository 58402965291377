import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Content: FunctionComponent<Props> = ({ children, className = '', testId = TEST_ID }) => (
    <main className={`${className} ${styles.content}`} data-testid={testId}>
        {children}
    </main>
);

export default Content;
