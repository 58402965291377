import {
    MAX_KILOGRAMS_PER_SQUARE_METER,
    MAX_OZ_PER_ACRE,
    MAX_RATE_POUNDS_PER_ACRE,
    WORK_ORDER_CHEMICAL_RATE_GA_AC_MAX
} from 'config';
import { useCallback } from 'react';
import { array, number, object, string } from 'yup';

import {
    FLUID_OUNCES_PER_ACRE,
    GALLONS_PER_ACRE,
    KILOGRAMS_PER_SQUARE_METER,
    OUNCES_PER_ACRE,
    PINTS_PER_ACRE,
    POUNDS_PER_ACRE,
    QUARTS_PER_ACRE
} from '@@types/units';

import useTranslation from 'components/work-order/ChemicalInformation/hooks/useTranslation';

import useConvert from 'hooks/useConvert';

import { ACTIVE_INGREDIENTS_MAX } from './constants';

const useSchema = () => {
    const { ERRORS } = useTranslation();

    const {
        convertLitersPerSquareMeterToOuncesPerAcre,
        convertLitersPerSquareMeterToPintsPerAcre,
        convertLitersPerSquareMeterToQuartsPerAcre,
        convertToLitersPerSquareMeter
    } = useConvert();

    const rateUnitMap = useCallback(
        (maxRateLitersPerSquareMeter: number) => ({
            flozPerAc: {
                maxRate: convertLitersPerSquareMeterToOuncesPerAcre(maxRateLitersPerSquareMeter),
                unit: FLUID_OUNCES_PER_ACRE.label
            },
            gaPerAc: {
                maxRate: WORK_ORDER_CHEMICAL_RATE_GA_AC_MAX,
                unit: GALLONS_PER_ACRE.label
            },
            kgPerM2: {
                maxRate: MAX_KILOGRAMS_PER_SQUARE_METER,
                unit: KILOGRAMS_PER_SQUARE_METER.label
            },
            lbPerAc: {
                maxRate: MAX_RATE_POUNDS_PER_ACRE,
                unit: POUNDS_PER_ACRE.label
            },
            ozPerAc: {
                maxRate: MAX_OZ_PER_ACRE,
                unit: OUNCES_PER_ACRE.label
            },
            pintPerAc: {
                maxRate: convertLitersPerSquareMeterToPintsPerAcre(maxRateLitersPerSquareMeter),
                unit: PINTS_PER_ACRE.label
            },
            quartPerAc: {
                maxRate: convertLitersPerSquareMeterToQuartsPerAcre(maxRateLitersPerSquareMeter),
                unit: QUARTS_PER_ACRE.label
            }
        }),
        [
            convertLitersPerSquareMeterToOuncesPerAcre,
            convertLitersPerSquareMeterToPintsPerAcre,
            convertLitersPerSquareMeterToQuartsPerAcre
        ]
    );

    return object({
        chemicals: array()
            .of(
                object({
                    activeIngredients: array().of(string().notRequired()).required(),
                    chemicalId: string()
                        .notRequired()
                        .test({
                            message: ERRORS.LABEL_NAME_REQUIRED,
                            name: 'required',
                            test: async (value, context) => {
                                if (!value) {
                                    const {
                                        epaNumber,
                                        pest,
                                        rateLitersPerSquareMeter,
                                        signalWord
                                    } = context.parent;
                                    const activeIngredients: string[] =
                                        context.parent.activeIngredients;
                                    const hasIngredients = activeIngredients
                                        ? activeIngredients.filter(
                                              ingredient =>
                                                  ingredient !== undefined &&
                                                  ingredient?.trim() !== ''
                                          ).length > 0
                                        : false;

                                    if (
                                        hasIngredients ||
                                        epaNumber ||
                                        pest ||
                                        rateLitersPerSquareMeter ||
                                        signalWord
                                    ) {
                                        return false;
                                    }
                                }

                                return true;
                            }
                        }),
                    epaNumber: string().notRequired(),
                    pest: string().notRequired(),
                    signalWord: string().notRequired(),
                    sprayRate: number()
                        .notRequired()
                        .test({
                            name: 'max',
                            test: (value, context) => {
                                const maxRateLitersPerSquareMeter = convertToLitersPerSquareMeter(
                                    WORK_ORDER_CHEMICAL_RATE_GA_AC_MAX,
                                    'gaPerAc'
                                );

                                const currentUnit = context.parent.sprayRateUnit;

                                const unitMap: Record<string, { unit: string; maxRate: number }> =
                                    rateUnitMap(maxRateLitersPerSquareMeter);

                                const { maxRate, unit } = unitMap[currentUnit];

                                if ((value ?? 0) <= maxRate) {
                                    return true;
                                }

                                return context.createError({
                                    message: ERRORS.RATE_MAX(maxRate, unit)
                                });
                            }
                        }),
                    sprayRateUnit: string().notRequired()
                })
            )
            .required()
            .max(ACTIVE_INGREDIENTS_MAX, ERRORS.ACTIVE_INGREDIENTS_MAX)
    });
};

export default useSchema;
