import { useCallback, useEffect, useMemo } from 'react';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import useAccount from 'hooks/useAccount';
import useCurrentOrganization from 'hooks/useCurrentOrganization';

import type { FunctionComponent, Props } from './types';

const FeatureProvider: FunctionComponent<Props> = ({ children }) => {
    const growthbookInstance = useMemo(
        () =>
            new GrowthBook({
                apiHost: import.meta.env.VITE_GROWTHBOOK_API_HOST,
                clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
                enableDevMode: import.meta.env.DEV,
                subscribeToChanges: true
            }),
        []
    );

    const { user } = useAccount();
    const { currentOrganization } = useCurrentOrganization();

    const loadFeatures = useCallback(async () => {
        const growthbookAttributes = {
            id: user?.id,
            organizationId: currentOrganization?.id
        };

        growthbookInstance.setAttributes(growthbookAttributes);
        await growthbookInstance.loadFeatures();
    }, [growthbookInstance, currentOrganization, user]);

    useEffect(() => {
        loadFeatures();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return <GrowthBookProvider growthbook={growthbookInstance}>{children}</GrowthBookProvider>;
};

export default FeatureProvider;
