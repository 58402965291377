import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('pdfCopyright');

    return {
        COPYRIGHT_LEFT: t('copyrightLeft'),
        COPYRIGHT_RIGHT: t('copyrightRight', { currentYear: new Date().getFullYear() })
    };
};

export default useTranslation;
