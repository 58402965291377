import { format, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const constructUtcDateTime = (
    date: Date,
    time: string,
    timeZone: string,
    referenceDate: Date
) => {
    const dateStr = format(date, 'yyyy-MM-dd');

    if (!time) {
        return zonedTimeToUtc(dateStr, timeZone);
    }

    const dateTimeStr = `${dateStr} ${time}`;
    let formatString = 'yyyy-MM-dd HH:mm';

    // account for seconds if needed
    if (time.length === 8) {
        formatString = 'yyyy-MM-dd HH:mm:ss';
    }
    const zonedDate = parse(dateTimeStr.trim(), formatString, referenceDate);

    return zonedTimeToUtc(zonedDate, timeZone);
};
