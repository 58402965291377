import { Button } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SecondaryButton: FunctionComponent<Props> = ({
    children,
    className = '',
    isDisabled = false,
    isLoading = false,
    onClick,
    testId = TEST_ID,
    text
}) => (
    <Button
        className={`${className} ${styles.secondaryButton}`}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        onClick={onClick}
        testId={testId}
    >
        {children || text}
    </Button>
);

export default SecondaryButton;
