import type { FunctionComponent } from 'react';

import { TextContent } from '@@i18n/TextContent';
import { Text, Title } from '@mantine/core';

import { TEST_ID } from './constants';
import { Props, TextValuesType } from './types';

import styles from './styles.module.scss';

const ErrorPage: FunctionComponent<Props> = ({
    children,
    testId = TEST_ID,
    text,
    title,
    values
}) => {
    const textValues: TextValuesType = { br: <br />, ...values };

    return (
        <div className={styles.errorPage} data-testid={testId}>
            <Title order={2}>
                <TextContent id={title} values={textValues} />
            </Title>

            {text && (
                <Text className={styles.subHeader}>
                    <TextContent id={text} values={textValues} />
                </Text>
            )}

            {children}
        </div>
    );
};

export default ErrorPage;
