import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDevice } from 'components/DeviceProvider';
import HorizontalContainer from 'components/HorizontalContainer';
import ItemContainer from 'components/ItemContainer';
import ListEmptyText from 'components/ListEmptyText';
import ListText from 'components/ListText';
import MapMenu from 'components/MapMenu';
import Time from 'components/Time';
import AppliedLabel from 'components/colored-labels/AppliedLabel';
import CoveredLabel from 'components/colored-labels/CoveredLabel';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, MapData, Props } from './types';

import styles from './styles.module.scss';

const MapListItem: FunctionComponent<Props> = ({
    applicationDate,
    contactName,
    growerName,
    handleDelete,
    jobId,
    mapType,
    productUsageReportId,
    siteName,
    testId = TEST_ID
}) => {
    const { deleteAsAppliedMapJob } = useAsAppliedMaps();
    const { deviceType } = useDevice();
    const navigate = useNavigate();
    const { aamPage } = usePageRoutes();
    const { MAP_DELETE_ERROR, NO_DATA } = useTranslation();

    const mapData: MapData = useMemo(
        () => ({
            AAM: <AppliedLabel />,
            COVERAGE: <CoveredLabel />,
            DRY_COVERAGE: <CoveredLabel />
        }),
        []
    );

    const onView = useCallback(() => {
        navigate(`${aamPage}/${jobId}/${mapType}`);
    }, [aamPage, jobId, mapType, navigate]);

    const onDelete = useCallback(async () => {
        try {
            await deleteAsAppliedMapJob(jobId);
            handleDelete?.(jobId);
        } catch (error) {
            console.error(error);
            alert(MAP_DELETE_ERROR);
        }
    }, [MAP_DELETE_ERROR, deleteAsAppliedMapJob, handleDelete, jobId]);

    const noData = !contactName && !growerName && !productUsageReportId && !siteName;

    const labelComponent = mapData[mapType];

    return (
        <ItemContainer className={styles.mapListItem} testId={testId}>
            <HorizontalContainer className={styles.horizontalContainer} onClick={onView}>
                <Time text={applicationDate} />

                {noData && <ListEmptyText text={NO_DATA} />}

                {!noData && (
                    <>
                        <ListText text={contactName ?? ''} />

                        {deviceType === 'desktop' && (
                            <>
                                <ListText text={growerName ?? ''} />

                                <ListText text={siteName ?? ''} />

                                <ListText text={productUsageReportId ?? ''} />
                            </>
                        )}
                    </>
                )}

                {labelComponent}
            </HorizontalContainer>

            <MapMenu onDelete={onDelete} onView={onView} />
        </ItemContainer>
    );
};

export default MapListItem;
