import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('droneReportContainer');

    return {
        DRONE: t('drone'),
        FAA_REG_NUMBER: t('faaRegNumber'),
        PILOTS: t('pilots'),
        TOTAL_FLIGHT_TIME: t('totalFlightTime'),
        TOTAL_FLIGHTS: t('totalFlights')
    };
};

export default useTranslation;
