import { useEffect, useState } from 'react';

import FormSelect from 'components/deprecating/FormSelect';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, SelectData } from './types';

const FormSelectControllerModels: FunctionComponent<Props> = ({
    className,
    controllerMake,
    disabled,
    field,
    form,
    label,
    meta,
    placeholder,
    searchable,
    selectOnBlur,
    testId = TEST_ID,
    type
}) => {
    const { fetchModels } = useData();
    const [initializing, setInitializing] = useState(true);
    const [models, setModels] = useState<SelectData>([]);

    useEffect(() => {
        const initializeModels = async () => {
            setInitializing(true);
            const response = await fetchModels(controllerMake);

            setInitializing(false);
            const options = response.map(model => ({ label: model, value: model }));

            setModels(options);
        };

        initializeModels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controllerMake]);

    return (
        <FormSelect
            className={className}
            data={models}
            disabled={disabled}
            field={field}
            form={form}
            isLoading={initializing || models.length === 0}
            label={label}
            meta={meta}
            placeholder={placeholder}
            searchable={searchable}
            selectOnBlur={selectOnBlur}
            testId={testId}
            type={type}
        />
    );
};

export default FormSelectControllerModels;
