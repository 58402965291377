import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useWorkOrder from 'hooks/useWorkOrder';

import { AGRIAN_PDF_EXTRACTION_ENDPOINT } from './constants';

const useAgrianPdfExtractor = () => {
    const { authenticatedGet } = useFetch();

    const { normalize } = useWorkOrder();

    const extractWorkOrderFromPdf = useCallback(
        async (fileId: string) => {
            const { data, error } = await authenticatedGet(AGRIAN_PDF_EXTRACTION_ENDPOINT(fileId));

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return await normalize(data);
        },
        [authenticatedGet, normalize]
    );

    return {
        extractWorkOrderFromPdf
    };
};

export default useAgrianPdfExtractor;
