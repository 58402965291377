import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FaaListItemMenu from 'components/FaaListItemMenu';
import FaaReportDeleteConfirmationModal from 'components/FaaReportDeleteConfirmationModal';
import PageWithSidebar from 'components/PageWithSidebar';

import useDroneReport from 'hooks/useDroneReport';
import useFaaReports from 'hooks/useFaaReports';
import usePageRoutes from 'hooks/usePageRoutes';

import useSidebarItems from './hooks/useSidebarItems';
import useTranslation from './hooks/useTranslation';

import CompanyDetails from './components/CompanyDetails';
import { CompanyDetailsForm } from './components/CompanyDetails/types';
import FlightDetails from './components/FlightDetails';
import OperationDetails from './components/OperationDetails';
import UasTypeAndModel from './components/UasTypeAndModel';
import type {
    FaaFileResponse,
    FaaReportRequest,
    FunctionComponent,
    Props,
    SidebarItems
} from './types';

const CreateFaaReportPage: FunctionComponent<Props> = ({ isEditable = true }) => {
    const [deleteConfirmationModalStatus, setDeleteConfirmationModalStatus] = useState(false);
    const { ERROR_FETCHING_FAA_REPORT, ERROR_UPDATING_FAA_REPORT, FORM_ERROR_MESSAGE, TITLE } =
        useTranslation();
    const navigate = useNavigate();
    const { faaReportsPage } = usePageRoutes();
    const handleExit = useCallback(() => navigate(faaReportsPage), [faaReportsPage, navigate]);

    const { deleteFaaReport, fetchFaaReport, updateFaaReport } = useFaaReports();

    const { faaReportId = '' } = useParams();

    const [isFetchingFaaReport, setIsFetchingFaaReport] = useState<boolean>(true);

    const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);

    const masterFormInitialData: FaaReportRequest = {
        companyDetails: undefined,
        droneIds: [],
        exemptions: {},
        reportingPeriod: new Date()
    };
    const masterForm = useRef<FaaReportRequest>(masterFormInitialData);

    const masterFormErrors = useRef<
        Record<keyof Omit<FaaReportRequest, 'damageReport' | 'malfunctionReport'>, boolean>
    >({
        companyDetails: false,
        droneIds: false,
        exemptions: false,
        reportingPeriod: false
    });

    const [previousFiles, setPreviousFiles] = useState<FaaFileResponse[]>([]);

    const [hasDroneReports, setHasDroneReports] = useState<boolean>(false);

    const [selectedDroneIds, setSelectedDroneIds] = useState<string[]>([]);

    const { fetchDroneReports } = useDroneReport();

    const checkForDroneReports = useCallback(
        async (droneIds: string[]) => {
            const { objects } = await fetchDroneReports(faaReportId, { droneIds }, undefined, 1);

            setHasDroneReports(objects.length > 0);
        },
        [fetchDroneReports, faaReportId]
    );

    const fetchAndSetFaaReport = useCallback(async () => {
        setIsFetchingFaaReport(true);
        try {
            const faaReport = await fetchFaaReport(faaReportId);

            setSelectedDroneIds(faaReport.droneIds);
            setPreviousFiles(faaReport.files);
            masterForm.current = {
                ...faaReport,
                reportingPeriod: faaReport.reportingPeriod ?? new Date()
            };
            checkForDroneReports(faaReport.droneIds);
        } catch (e) {
            console.log(e);
            alert(ERROR_FETCHING_FAA_REPORT);
        } finally {
            setIsFetchingFaaReport(false);
        }
    }, [ERROR_FETCHING_FAA_REPORT, checkForDroneReports, faaReportId, fetchFaaReport]);

    useEffect(() => {
        fetchAndSetFaaReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [currentSection, setCurrentSection] = useState<SidebarItems>('companyDetails');
    const [disableNavigation, setDisableNavigation] = useState<boolean>(false);

    const doesFormHaveErrors = useCallback(
        () => Object.values(masterFormErrors.current).some(hasError => hasError === true),
        []
    );

    const updateNavigation = useCallback(() => {
        setDisableNavigation(doesFormHaveErrors());
    }, [doesFormHaveErrors]);

    const { sidebarItems } = useSidebarItems({
        disableSideBar: disableNavigation,
        flightDetailsDisabled: !hasDroneReports
    });

    const handleBack = useCallback(() => {
        const previousSection = sidebarItems[currentSection].previousSection as SidebarItems;

        if (previousSection) {
            setCurrentSection(previousSection);
        }
    }, [currentSection, sidebarItems]);

    const handleSubmit = useCallback(async () => {
        if (!Object.values(masterFormErrors.current).every(hasError => hasError === false)) {
            alert(FORM_ERROR_MESSAGE);

            return;
        }

        setIsProcessingForm(true);
        try {
            await updateFaaReport(faaReportId, masterForm.current);
        } catch (e) {
            console.log(e);
            alert(ERROR_UPDATING_FAA_REPORT);
        } finally {
            setIsProcessingForm(false);
        }
    }, [ERROR_UPDATING_FAA_REPORT, FORM_ERROR_MESSAGE, faaReportId, updateFaaReport]);

    const handleFinalSubmit = useCallback(() => {
        handleSubmit();
        handleExit();
    }, [handleExit, handleSubmit]);

    const handleNext = useCallback(() => {
        const nextSection = sidebarItems[currentSection].nextSection as SidebarItems;

        if (nextSection) {
            setCurrentSection(nextSection);
        } else {
            handleSubmit();
        }
    }, [currentSection, handleSubmit, sidebarItems]);

    const handleDelete = useCallback(async () => {
        await deleteFaaReport(faaReportId);
        handleExit();
    }, [deleteFaaReport, faaReportId, handleExit]);

    const handleOperationDetailsNext = useCallback(() => {
        checkForDroneReports(selectedDroneIds);
        handleNext();
    }, [checkForDroneReports, handleNext, selectedDroneIds]);

    const contentMap: { [key in SidebarItems]: ReactNode } = useMemo(
        () => ({
            companyDetails: (
                <CompanyDetails
                    formData={{
                        companyDetails: masterForm.current.companyDetails!,
                        exemptions: masterForm.current.exemptions!
                    }}
                    onChange={({ formData, hasErrors }) => {
                        const { companyDetails, exemptions } = formData as CompanyDetailsForm;

                        masterForm.current.companyDetails = companyDetails;
                        masterForm.current.exemptions = exemptions;
                        masterFormErrors.current.companyDetails = hasErrors;
                        masterFormErrors.current.exemptions = hasErrors;
                        updateNavigation();
                    }}
                    isEditable={isEditable}
                    isSaveDisabled={isProcessingForm}
                    onNext={handleNext}
                    onSave={handleSubmit}
                />
            ),
            flightDetails: (
                <FlightDetails
                    droneIds={selectedDroneIds}
                    faaReportId={faaReportId}
                    isEditable={isEditable}
                    isSaveDisabled={isProcessingForm}
                    onBack={handleBack}
                    onNext={handleNext}
                    onSave={handleFinalSubmit}
                />
            ),
            operationDetails: (
                <OperationDetails
                    formData={{
                        files: previousFiles,
                        reportingPeriod: masterForm.current.reportingPeriod || undefined
                    }}
                    onChange={({ form, hasErrors }) => {
                        masterForm.current.reportingPeriod = form.current.reportingPeriod;
                        masterFormErrors.current.reportingPeriod = hasErrors();
                        updateNavigation();
                    }}
                    faaReportId={faaReportId}
                    isEditable={isEditable}
                    isSaveDisabled={isProcessingForm}
                    onBack={handleBack}
                    onNext={handleOperationDetailsNext}
                    onSave={handleSubmit}
                />
            ),

            uasTypeAndModel: (
                <UasTypeAndModel
                    onChange={({ form, hasErrors }) => {
                        masterForm.current.droneIds = form.current.droneIds;
                        masterFormErrors.current.droneIds = hasErrors();
                        setSelectedDroneIds(form.current.droneIds);
                        updateNavigation();
                    }}
                    formData={{ droneIds: masterForm.current.droneIds }}
                    isEditable={isEditable}
                    isSaveDisabled={isProcessingForm}
                    onBack={handleBack}
                    onNext={handleNext}
                    onSave={handleSubmit}
                />
            )
        }),
        [
            faaReportId,
            handleBack,
            handleFinalSubmit,
            handleNext,
            handleOperationDetailsNext,
            handleSubmit,
            isEditable,
            isProcessingForm,
            previousFiles,
            selectedDroneIds,
            updateNavigation
        ]
    );

    const menuComponent = useMemo(
        () =>
            !isEditable ? (
                <FaaListItemMenu
                    faaReportId={faaReportId}
                    handleDelete={() => setDeleteConfirmationModalStatus(true)}
                />
            ) : null,
        [faaReportId, isEditable]
    );

    return (
        <PageWithSidebar
            state={{
                setSideBarItem: (item: string) => {
                    setCurrentSection(item as SidebarItems);
                    if (isEditable && !isProcessingForm) {
                        handleSubmit();
                    }
                },
                sideBarItem: currentSection
            }}
            isLoading={isFetchingFaaReport}
            menuComponent={menuComponent}
            onExit={handleExit}
            sidebarItems={sidebarItems}
            title={TITLE}
        >
            {deleteConfirmationModalStatus && (
                <FaaReportDeleteConfirmationModal
                    onClose={() => setDeleteConfirmationModalStatus(false)}
                    onConfirm={handleDelete}
                />
            )}

            {contentMap[currentSection]}
        </PageWithSidebar>
    );
};

export default CreateFaaReportPage;
