import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('mapListItem');

    return {
        MAP_DELETE_ERROR: t('mapDeleteError'),
        NO_DATA: t('noData')
    };
};

export default useTranslation;
