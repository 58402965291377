import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('faaReportDeleteConfirmationModal');

    return {
        CONFIRM_DELETE_FAA_REPORT: t('confirmDeleteFaaReport'),
        CONFIRM_DELETE_FAA_REPORT_MESSAGE: t('confirmDeleteFaaReportMessage'),
        CONFIRM_DELETE_FAA_REPORT_SUBTEXT_MESSAGE: t('confirmDeleteFaaReportSubtextMessage')
    };
};

export default useTranslation;
