import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FormText: FunctionComponent<Props> = ({ testId = TEST_ID, text }) => (
    <p className={styles.formText} data-testid={testId}>
        {text}
    </p>
);

export default FormText;
