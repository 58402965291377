import { useCallback } from 'react';

import { Checkbox } from '@mantine/core';

import type { ChangeEvent, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FormCheckbox: FunctionComponent<Props> = ({
    className,
    color,
    customOnChange,
    disabled,
    field,
    isEditable = true,
    label
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onBlur, onChange, value, ...other } = field;

    const onCheckboxChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            if (customOnChange) {
                await customOnChange?.(event);
            } else {
                onChange(event);
            }
        },
        [customOnChange, onChange]
    );

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';

    return (
        <Checkbox
            checked={value}
            className={`${className} ${viewOnlyClass}`}
            color={color}
            disabled={disabled}
            label={label}
            // disable blur since it messes with map rendering and
            // we're setting everything manually anyways
            onBlur={() => null}
            onChange={onCheckboxChange}
            {...other}
        />
    );
};

export default FormCheckbox;
