import { XagDroneImportRequestFileType } from 'generatedTypes/faaReport/models';
import { useCallback, useMemo, useState } from 'react';

import useDroneReport from 'hooks/useDroneReport';
import useFileUploadHandler from 'hooks/useFileUploadHandler';

import { DJI_DRONE_REPORT_UPLOAD_ENDPOINT, XAG_DRONE_REPORT_UPLOAD_ENDPOINT } from './constants';
import type { FileUpload } from './types';

const useFileUpload = () => {
    const [droneMake, setDroneMake] = useState<string | undefined>(undefined);
    const [extension, setExtension] = useState<string | undefined | string[]>(undefined);

    const extensionMap = useMemo(
        () => ({
            DJI: 'XLSX',
            XAG: ['XLS', 'CSV']
        }),
        []
    );

    const filePickerExtensionMap = useMemo(
        () => ({
            DJI: '.zip, .xlsx',
            XAG: '.zip, .xls, .csv'
        }),
        []
    );

    const extensionToFileTypeMap: Record<string, XagDroneImportRequestFileType> = useMemo(
        () => ({
            CSV: 'CSV',
            XLS: 'EXCEL',
            XLSX: 'EXCEL'
        }),
        []
    );

    const uploadUrlMap = useMemo(
        () => ({
            DJI: DJI_DRONE_REPORT_UPLOAD_ENDPOINT,
            XAG: XAG_DRONE_REPORT_UPLOAD_ENDPOINT
        }),
        []
    );

    const updateDroneMake = useCallback(
        (make: string) => {
            setDroneMake(make);
            setExtension(extensionMap[make as keyof typeof extensionMap]);
        },
        [extensionMap]
    );

    const {
        addUploadedFile,
        getPresignedUrlFromEndpoint,
        getUploadedFiles,
        isValidFile,
        setUploadedFiles
    } = useFileUploadHandler({
        validFileExtension: extension ?? ''
    });

    const getPresignedUrl = useCallback(
        async (fileUpload: FileUpload, hashingStrings?: string[]) => {
            const fileUploadEndpoint = uploadUrlMap[droneMake as keyof typeof uploadUrlMap];

            const response = await getPresignedUrlFromEndpoint(
                fileUpload,
                fileUploadEndpoint,
                hashingStrings
            );

            return response;
        },
        [droneMake, getPresignedUrlFromEndpoint, uploadUrlMap]
    );

    const modifyFileRequest = useCallback(async (fileUpload: FileUpload) => fileUpload, []);

    const validateFile = useCallback(
        async (fileUpload: FileUpload) => {
            const file = fileUpload.file;

            if (!isValidFile(file)) {
                throw new Error('INVALID_EXTENSION');
            }

            return false;
        },
        [isValidFile]
    );

    const { importDjiDroneReport, importXagDroneReport } = useDroneReport();

    const useDroneReportMap = useMemo(
        () => ({
            DJI: importDjiDroneReport,
            XAG: importXagDroneReport
        }),
        [importDjiDroneReport, importXagDroneReport]
    );

    const addFlightReport = useCallback(
        async (faaReportId: string) => {
            const fileUploads = getUploadedFiles();

            if (fileUploads.length === 0) {
                throw new Error('NO_FILES_UPLOADED');
            }

            await Promise.all(
                fileUploads.map(async ({ fileName, objectKey }) => {
                    const extension = fileName.split('.').pop()?.toUpperCase();

                    await useDroneReportMap[droneMake as keyof typeof useDroneReportMap](
                        faaReportId,
                        {
                            fileType:
                                extensionToFileTypeMap[
                                    extension as keyof typeof extensionToFileTypeMap
                                ],
                            filename: fileName,
                            objectKey
                        }
                    );
                })
            );
        },
        [droneMake, extensionToFileTypeMap, getUploadedFiles, useDroneReportMap]
    );

    return {
        addFlightReport,
        addUploadedFile,
        droneMake,
        filePickerExtensionMap,
        getPresignedUrl,
        getUploadedFiles,
        modifyFileRequest,
        setUploadedFiles,
        updateDroneMake,
        validateFile
    };
};

export default useFileUpload;
