import { getIn } from 'formik';
import type { FunctionComponent } from 'react';

import { Loader, Select, rem } from '@mantine/core';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

/**
 * @deprecated Use `src/components/form/Dropdown` instead.
 */
const FormSelect: FunctionComponent<Props> = ({
    className,
    data,
    disabled,
    field,
    form,
    isEditable = true,
    isLoading = false,
    label,
    onChange,
    onSearchChange,
    placeholder,
    required,
    rightSection: inputRightSection,
    rightSectionWidth,
    searchable,
    selectOnBlur,
    testId = TEST_ID,
    type
}) => {
    const { name, value } = field;

    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    let rightSection;

    if (isLoading) {
        rightSection = <Loader className={styles.selectLoader} />;
    } else if (inputRightSection) {
        rightSection = inputRightSection;
    } else {
        rightSection = undefined;
    }

    const onBlur = async () => {
        await form.setFieldTouched(name);
    };

    const onValueChange = async (value: string) => {
        await form.setFieldValue(name, value);
        if (onChange) {
            onChange(value);
        }
    };

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const disabledClass = disabled ? styles.disabled : '';
    const placeholderText = isEditable ? placeholder : '';
    const hideClass = !isEditable && !value ? styles.hide : '';

    return (
        <Select
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : '0'
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                rightSection: {
                    opacity: !isEditable || disabled ? '0' : '1'
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            className={`${className} ${styles.formSelect} ${hideClass} ${viewOnlyClass} ${disabledClass}`}
            data={data}
            data-testid={testId}
            disabled={!isEditable || disabled}
            error={visibleError ? error : undefined}
            label={label}
            onSearchChange={onSearchChange}
            placeholder={placeholderText}
            readOnly={isLoading}
            required={required}
            rightSection={rightSection}
            rightSectionWidth={rightSectionWidth}
            searchable={searchable}
            selectOnBlur={selectOnBlur}
            type={type}
            {...field}
            onBlur={onBlur}
            onChange={onValueChange}
        />
    );
};

export default FormSelect;
