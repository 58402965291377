import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('withFeatureGate');

    return {
        LEARN_MORE: t('learnMore'),
        PREMIER_FEATURE: t('premierFeature')
    };
};

export default useTranslation;
