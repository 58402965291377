import OptionsButton from 'components/OptionsButton';
import OptionsItem from 'components/OptionsButton/OptionsItem';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

const MenuButton: FunctionComponent<Props> = ({ className = '', items = [], testId = TEST_ID }) => (
    <OptionsButton className={className} testId={testId}>
        {({ closeMenu }: { closeMenu: VoidFunction }) =>
            items.map(({ feature, isDelete, onClick, text }) => (
                <OptionsItem
                    onClick={() => {
                        onClick?.();
                        closeMenu();
                    }}
                    feature={feature}
                    isDelete={isDelete}
                    key={text}
                    text={text}
                />
            ))
        }
    </OptionsButton>
);

MenuButton.displayName = 'MenuButton';

export default MenuButton;
