import useAccount from 'hooks/useAccount';

const useData = () => {
    const { user } = useAccount();

    const { email, firstName, lastName, phoneNumber } = user || {};

    return {
        email,
        firstName,
        lastName,
        phoneNumber: phoneNumber?.phone
    };
};

export default useData;
