import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('productDetailsForm');

    return {
        AC: t('ac'),
        ADD_CHEMICAL: t('addChemical'),
        CARRIER: t('carrier'),
        ERRORS: {
            CARRIER_REQUIRED: t('errors.carrierRequired'),
            RATE_APPLIED_GREATER_THAN_ZERO: t('errors.rateAppliedGreaterThanZero'),
            RATE_APPLIED_REQUIRED: t('errors.rateAppliedRequired'),
            RATE_APPLIED_TOO_LARGE: (maxRate: number, unit: string) =>
                t('errors.rateAppliedTooLarge', {
                    rate: maxRate,
                    unit: unit
                }),
            RATE_APPLIED_UNITS_REQUIRED: t('errors.rateAppliedUnitsRequired'),
            TOTAL_ACRES_APPLIED_REQUIRED: t('errors.totalAcresAppliedRequired'),
            TOTAL_ACRES_GREATER_THAN_ZERO: t('errors.totalAcresGreaterThanZero'),
            VOLUME_APPLIED_GREATER_THAN_ZERO: t('errors.volumeAppliedGreaterThanZero'),
            VOLUME_APPLIED_REQUIRED: t('errors.volumeAppliedRequired'),
            VOLUME_APPLIED_TOO_LARGE: (maxVolumeApplied: number, unit: string) =>
                t('errors.volumeAppliedTooLarge', {
                    maxVolumeApplied: maxVolumeApplied,
                    unit: unit
                }),
            VOLUME_UNITS_REQUIRED: t('errors.volumeUnitsRequired')
        },
        FLUID_OUNCES_PER_ACRE_UNIT: t('fluidOuncesPerAcreUnit'),
        GALLONS: t('gallons'),
        GALLONS_PER_ACRE_UNIT: t('gallonsPerAcreUnit'),
        LITERS: t('liters'),
        LITERS_PER_HECTARE_UNIT: t('litersPerHectareUnit'),
        LITERS_PER_METER_SQUARE_UNIT: t('litersPerMeterSquaredUnit'),
        TOTAL_ACRES_APPLIED: t('totalAcresApplied'),
        TOTAL_PRODUCT_USED: t('totalProductUsed')
    };
};

export default useTranslation;
