const translations = {
    applicationDetails: 'Application Details',
    asAppliedMap: 'As Applied Map',
    asCovered: 'As Covered',
    asFlown: 'As Flown',
    flightLog: 'Flight Log',
    productUsageReport:
        'Product Usage Report (Created on {{createdDate}} for "{{customerName}}" at "{{sites}}")',
    title: 'Generate Application Map',
    viewAsAppliedMap: 'View As Applied Map',
    viewAsCoveredMap: 'View As Covered Map'
};

export default translations;
