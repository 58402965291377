import type { FlattenAddress } from './types';

const flattenAddress: FlattenAddress = ({
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    zipCode = ''
}) => {
    if (!address1 || !city || !state || !zipCode) {
        return '';
    }

    const joinAddress2 = address2 ? ` ${address2}` : '';

    return `${address1}${joinAddress2}, ${city}, ${state} ${zipCode}`;
};

export default flattenAddress;
