import { getIn } from 'formik';
import { IMaskInput } from 'react-imask';

import { Input, rem } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, ZipCodeInputProps } from './types';

import styles from './styles.module.scss';

/**
 * @deprecated Use `src/components/form/ZipCodeInput` instead.
 */
const ZipCodeInput: FunctionComponent<ZipCodeInputProps> = ({
    className,
    disabled,
    field,
    form,
    isEditable = true,
    placeholder,
    testId = TEST_ID,
    ...props
}) => {
    const { name, value } = field;

    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const disabledClass = disabled ? styles.disabled : '';
    const placeholderText = isEditable ? placeholder : '';
    const hideClass = !isEditable && !value ? styles.hide : '';

    return (
        <Input.Wrapper
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : '0'
                }
            }}
            className={`${className} ${styles.zipCodeInput} ${hideClass} ${viewOnlyClass} ${disabledClass}`}
            data-testid={testId}
            error={visibleError ? error : undefined}
            {...field}
            {...props}
        >
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Input<any>
                styles={{
                    input: {
                        marginTop: visibleError ? '0' : rem('20px')
                    },
                    wrapper: {
                        marginTop: '1px'
                    }
                }}
                component={IMaskInput}
                disabled={disabled}
                error={visibleError}
                mask="00000"
                placeholder={placeholderText}
                {...field}
            />
        </Input.Wrapper>
    );
};

export default ZipCodeInput;
