import AvatarContainer from 'components/AvatarContainer';
import StandoutText from 'components/StandoutText';

import useAvatarInitials from 'hooks/useAvatarInitials';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const AvatarInitials: FunctionComponent<Props> = ({ name, testId = TEST_ID }) => {
    const { getInitials } = useAvatarInitials();

    const initials = getInitials(name);

    return (
        <AvatarContainer className={styles.randomBackground} testId={testId}>
            <StandoutText className={styles.initials} text={initials} />
        </AvatarContainer>
    );
};

export default AvatarInitials;
