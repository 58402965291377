import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { equipmentSortNickname } from '@@utils/sort';
import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import AircraftCard from 'components/AircraftCard';
import InfiniteScroll from 'components/InfiniteScroll';
import NoAircraftCard from 'components/NoAircraftCard';
import PageLoader from 'components/PageLoader';
import VerticalContainer from 'components/VerticalContainer';

import useAircraft from 'hooks/useAircraft';
import usePageRoutes from 'hooks/usePageRoutes';
import useRoles from 'hooks/useRoles';

import useTranslation from './hooks/useTranslation';

import type { Aircraft, AircraftApiResponse, FunctionComponent, Key } from './types';

import styles from './styles.module.scss';

const AircraftList: FunctionComponent = () => {
    const { canManageEquipment } = useRoles();
    const { deleteAircraft, fetchAircrafts } = useAircraft();
    const navigate = useNavigate();
    const { organizationNewAircraft } = usePageRoutes();
    const [aircraft, setAircraft] = useState<AircraftApiResponse>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });
    const [loading, setLoading] = useState(true);
    const { BUTTON } = useTranslation();

    const fetchAircraftData = useCallback(
        async (hasMore: boolean, nextToken: string) => {
            const response = await fetchAircrafts(hasMore ? nextToken : undefined);

            const { hasNext, nextPageToken, objects: newAircraft } = response;

            setAircraft((currentState: AircraftApiResponse) => {
                const newDrones = [...currentState.objects, ...newAircraft];

                newDrones.sort(equipmentSortNickname);

                return { hasNext, nextPageToken, objects: newDrones };
            });
        },
        [fetchAircrafts, setAircraft]
    );

    const handleAddAircraft = useCallback(() => {
        navigate(organizationNewAircraft);
    }, [navigate, organizationNewAircraft]);

    const noAircraft = useMemo(() => aircraft.objects.length === 0, [aircraft.objects.length]);

    useEffect(() => {
        const initialAircraftFetch = async () => {
            await fetchAircraftData(false, '');
        };

        if (loading) {
            initialAircraftFetch();
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const deleteAndRefresh = useCallback(
        async (id: string) => {
            await deleteAircraft(id);
            setAircraft((aircraftList: AircraftApiResponse) => ({
                hasNext: aircraftList.hasNext,
                nextPageToken: aircraftList.nextPageToken,
                objects: aircraftList.objects.filter(
                    (currentAircraft: Aircraft) => currentAircraft.id !== id
                )
            }));
        },
        [setAircraft, deleteAircraft]
    );

    return (
        <VerticalContainer className={styles.aircraftList}>
            {loading && <PageLoader />}

            {noAircraft && !loading && <NoAircraftCard />}

            {!noAircraft && (
                <InfiniteScroll
                    fetchNextPage={async () =>
                        await fetchAircraftData(aircraft.hasNext, aircraft.nextPageToken)
                    }
                    className={styles.list}
                    hasNextPage={aircraft.hasNext}
                    loader={<PageLoader />}
                    nextPageToken={aircraft.nextPageToken}
                >
                    {aircraft.objects.map((aircraft: Aircraft, index: Key | null | undefined) => (
                        <AircraftCard
                            aircraft={aircraft}
                            deleteAircraft={() => deleteAndRefresh(aircraft.id)}
                            key={index}
                        />
                    ))}
                </InfiniteScroll>
            )}

            {canManageEquipment && (
                <PrimaryButton
                    className={styles.button}
                    onClick={handleAddAircraft}
                    text={BUTTON}
                />
            )}
        </VerticalContainer>
    );
};

export default AircraftList;
