export const ACCOUNT_TEST_ID = 'account';

export const MANAGE_ORGANIZATION_TEST_ID = 'manageOrganization';
export const MANAGE_CONNECTIONS_TEST_ID = 'manageConnections';
export const LOGOUT_TEST_ID = 'logout';

export const FLIGHT_LOG_FEATURE = 'flightLogs';
export const AS_COVERED_MAPS_FEATURE = 'asCoveredMaps';
export const FAA_REPORTS_FEATURE = 'faaReports';
export const PRODUCT_USAGE_REPORTS_FEATURE = 'productUsageReports';
export const WORK_ORDERS_FEATURE = 'workOrders';
