import NavigationLink from 'components/NavigationLink';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const LegalNavigationLink: FunctionComponent<Props> = ({
    className,
    link,
    onClick,
    testId = TEST_ID,
    text
}) => (
    <NavigationLink
        className={`${className} ${styles.legalNavigationLink}`}
        isExactMatch={true}
        onClick={onClick}
        testId={testId}
        text={text}
        to={link}
    />
);

export default LegalNavigationLink;
