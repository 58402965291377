import useCurrentOrganization from 'hooks/useCurrentOrganization';

import type { OrganizationRequest } from './types';

const useData = () => {
    const { fetchCurrentOrganization, updateCurrentOrganization } = useCurrentOrganization();

    const saveToServer = async (values: OrganizationRequest) => {
        const newValues = values;

        if (newValues.phone === '') {
            newValues.phone = null;
        }

        const result = await updateCurrentOrganization(newValues);

        if (result?.data) {
            await fetchCurrentOrganization();
        }

        return result;
    };

    return {
        saveToServer
    };
};

export default useData;
