import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('useFlightReportDelete');

    return {
        DELETE_FLIGHT_REPORT_ERROR: ({ flightReportIds }: { flightReportIds: string }) =>
            t('deleteFlightReportError', { flightReportIds })
    };
};

export default useTranslation;
