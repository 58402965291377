import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('aircraftCard');

    return {
        FLIGHT_CONTROLLER_SERIAL: t('flightControllerSerial'),
        REGISTRATION_NUMBER: t('registrationNumber'),
        SWATH_WIDTH: (width: number) => t('swathWidth', { width })
    };
};

export default useTranslation;
