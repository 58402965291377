import { format } from 'date-fns';
import { useCallback } from 'react';
import { DateRange } from 'react-day-picker';

import { DATE_FORMAT, DATE_PLACEHOLDER, TIME_FORMAT, TIME_PLACEHOLDER } from './constants';

const useDateFormat = () => {
    const formatDate = useCallback((date: Date | string) => {
        const dateValue = typeof date === 'string' ? new Date(date) : date;

        return date ? format(dateValue, DATE_FORMAT) : DATE_PLACEHOLDER;
    }, []);

    const formatDateRange = useCallback((range: DateRange) => {
        const { from, to } = range;

        const rangeValues = {
            from: from ? format(new Date(from), DATE_FORMAT) : from,
            to: to ? format(new Date(to), DATE_FORMAT) : to
        };

        return rangeValues;
    }, []);

    const formatTime = useCallback(
        (dateTime: string | null) =>
            dateTime ? format(new Date(dateTime), TIME_FORMAT) : TIME_PLACEHOLDER,
        []
    );

    return { formatDate, formatDateRange, formatTime };
};

export default useDateFormat;
