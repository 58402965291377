import { useMemo } from 'react';

import { useDevice } from 'components/DeviceProvider';
import MobilePageNavigationDropdown from 'components/MobilePageNavigationDropdown';
import Sidebar from 'components/SidebarV2';

import type { FunctionComponent, Props } from './types';

const SidebarMenu: FunctionComponent<Props> = ({
    currentSection,
    onSectionChange,
    sidebarItems
}) => {
    const { deviceType } = useDevice();

    const dropdownOptions = useMemo(() => {
        const dropdownData = Object.keys(sidebarItems).map(section => ({
            disabled: sidebarItems[section].isDisabled,
            label: sidebarItems[section].title,
            value: section
        }));

        return dropdownData;
    }, [sidebarItems]);

    return (
        <>
            {deviceType === 'desktop' ? (
                <Sidebar
                    currentSection={currentSection}
                    onSectionChange={onSectionChange}
                    sideBarItems={sidebarItems}
                />
            ) : (
                <MobilePageNavigationDropdown
                    data={dropdownOptions}
                    onChange={onSectionChange}
                    value={currentSection}
                />
            )}
        </>
    );
};

export default SidebarMenu;
