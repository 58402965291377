import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import BaseEquipmentCard from 'components/BaseEquipmentCard';
import ContentText from 'components/ContentText';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const AircraftCard: FunctionComponent<Props> = ({
    aircraft,
    className = '',
    deleteAircraft,
    testId = TEST_ID
}) => {
    const navigate = useNavigate();
    const { editAircraftPage } = usePageRoutes();
    const { FLIGHT_CONTROLLER_SERIAL, REGISTRATION_NUMBER, SWATH_WIDTH } = useTranslation();

    const { id } = aircraft;

    const onEdit = useCallback(() => {
        navigate(editAircraftPage(id));
    }, [editAircraftPage, id, navigate]);

    return (
        <BaseEquipmentCard
            className={className}
            equipment={aircraft}
            onDelete={deleteAircraft}
            onEdit={onEdit}
            testId={testId}
        >
            <ContentText text={`${REGISTRATION_NUMBER}: ${aircraft.registrationNumber}`} />

            {aircraft.flightControllerSerial && (
                <ContentText
                    text={`${FLIGHT_CONTROLLER_SERIAL}: ${aircraft.flightControllerSerial}`}
                />
            )}

            {aircraft.defaultSwathWidthMeters && (
                <ContentText text={SWATH_WIDTH(aircraft.defaultSwathWidthMeters)} />
            )}
        </BaseEquipmentCard>
    );
};

export default AircraftCard;
