import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextContent } from '@@i18n/TextContent';
import { Button } from '@mantine/core';

import usePageRoutes from 'hooks/usePageRoutes';

import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

// pass color as prop to get proper hover and active styles
const buttonColor = 'blue.7';

export const AamButton: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { aamPage } = usePageRoutes();

    const handleClick = useCallback(() => navigate(aamPage), [aamPage, navigate]);

    return (
        <Button
            className={styles.button}
            color={buttonColor}
            data-testid={testId}
            onClick={handleClick}
        >
            <TextContent id="error.button.newAAM" />
        </Button>
    );
};

export default AamButton;
