import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('verifyOrganizationPage');

    return {
        CREATE_ORGANIZATION: t('createOrganization'),
        CREATE_ORGANIZATION_TO_START: t('createOrganizationToStart'),
        SELECT_ORGANIZATION: t('selectOrganization'),
        TITLE: t('title')
    };
};

export default useTranslation;
