const translations = {
    applicationDate: 'Application Date',
    applicationSite: 'Application Site',
    applicator: 'Applicator',
    datePlaceholder: 'MM/DD/YYYY',
    droneSerialNumber: 'Drone',
    endTime: 'End Time',
    errors: {
        applicationDateRequired: 'Application Date Required',
        endTimeGreaterThanStartTime: 'The end time must be greater than the start time',
        endTimeRequired: 'End Time Required',
        startTimeRequired: 'Start Time Required',
        temperatureRequired: 'Temperature Required',
        windDirectionRequired: 'Wind Direction Required',
        windGustRequired: 'Wind Gust Required',
        windSpeedNotNegative: 'The Wind Speed Must be >= 0',
        windSpeedRequired: 'Wind Speed Required'
    },
    mph: 'MPH',
    noMeasurableWind: 'No measurable wind',
    startTime: 'Start Time',
    temperature: 'Temperature',
    temperatureUnit: '\u00b0F',
    weather: 'Weather',
    windDirection: 'Wind Direction',
    windGust: 'Wind Gust',
    windSpeed: 'Wind Speed',
    workOrderNumber: 'Work Order Number'
};

export default translations;
