import AvatarContainer from 'components/AvatarContainer';
import LargeSemiboldText from 'components/LargeSemiboldText';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const AvatarOverflow: FunctionComponent<Props> = ({ number, testId = TEST_ID }) => (
    <AvatarContainer testId={testId}>
        <LargeSemiboldText text={`+${number}`} />
    </AvatarContainer>
);

export default AvatarOverflow;
