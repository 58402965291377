import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { WORK_ORDER_API } from './constants';
import { Job, JobsByDate, SortOrderMap, Tab } from './types';

const groupByDate = (jobs: Job[]) =>
    jobs.reduce((agg, item) => {
        const {
            applicationSites,
            customerName,
            expirationDate,
            growerName,
            id,
            phoneNumber,
            proposedDate,
            scheduledDate,
            status
        } = item;
        // Sometimes these dates are not all filled out so we'll use the scheduled date if available
        // otherwise the proposed date will be used, else the expiration date.
        const usableDate = scheduledDate ?? proposedDate ?? expirationDate;

        if (usableDate == null) {
            return agg;
        }

        const localizedDate = new Date(usableDate);

        const date = localizedDate.toLocaleDateString('en-US');

        const time = localizedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        if (!(date in agg)) {
            agg[date] = [];
        }

        const siteName = applicationSites?.length ? applicationSites[0].siteName : null;

        agg[date].push({
            customerName,
            growerName,
            id,
            phoneNumber,
            siteName,
            status,
            time
        });

        return agg;
    }, {} as JobsByDate);

const normalize = (json: {
    hasNext: boolean;
    nextPageToken: string | null;
    objects: Array<Job>;
}) => {
    const { hasNext, nextPageToken, objects } = json;

    return {
        hasNext,
        jobs: groupByDate(objects),
        nextPageToken
    };
};

const sortOrderMap: SortOrderMap = {
    past: 'desc',
    upcoming: 'asc'
};

const useData = () => {
    const { authenticatedGet } = useFetch();
    const { addQuery } = useQuerystring({});

    const fetchRawData = useCallback(
        async (filter: Tab) => {
            const sortOrder = sortOrderMap[filter] || sortOrderMap.upcoming;

            const query = addQuery({
                filter: filter.toUpperCase(),
                sort: [`proposedDate,${sortOrder}`, `scheduledDate,${sortOrder}`]
            });

            let keepFetching = true;
            let jobs: Job[] = [];
            let pageToken = null;

            // TODO eventually do this on scroll so that we can avoid slow page start load times
            // and avoid making unnecessary calls to the api. For now though this will get all the data
            while (keepFetching) {
                if (pageToken !== null) {
                    query.set('pageToken', pageToken);
                }

                const { data, error } = await authenticatedGet(
                    `${WORK_ORDER_API}?${query.toString()}`
                );

                if (error) {
                    console.log(error);
                    break;
                }

                const { hasNext, nextPageToken, objects: newJobs } = data;

                pageToken = nextPageToken;
                keepFetching = hasNext;

                jobs = [...jobs, ...newJobs];
            }

            return jobs;
        },
        [addQuery, authenticatedGet]
    );

    const fetchData = useCallback(
        async (filter: Tab) => {
            const objects = await fetchRawData(filter);

            return {
                data: normalize({
                    hasNext: false,
                    nextPageToken: null,
                    objects
                })
            };
        },
        [fetchRawData]
    );

    return { fetchData };
};

export default useData;
