import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('verifyEmailPage');

    return {
        ACTIVATION_LINK_SENT: t('activationLinkSent'),
        API_ERROR: t('apiError'),
        EMAIL_RESENT: t('emailResent'),
        NO_LINK: t('noLink'),
        SEND_AGAIN: t('sendAgain'),
        TITLE: t('title')
    };
};

export default useTranslation;
