import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formFileUpload');

    return {
        DELETE: t('delete'),
        FILE_TOO_LARGE_ERROR: t('fileTooLargeError'),
        MULTIPLE_FILES: (count: number) => t('multipleFiles', { count }),
        TOO_MANY_FILES: (maxFiles: number, count: number) => t('tooManyFiles', { count, maxFiles })
    };
};

export default useTranslation;
