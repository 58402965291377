const translations = {
    button: 'Save Controller',
    errors: {
        makeRequired: 'Please provide a make',
        modelRequired: 'Please provide a model',
        nicknameMax: 'Nickname cannot exceed {{charMax}} characters',
        nicknameRequired: 'Please provide a nickname',
        serialNumberMax: 'Serial number cannot exceed {{charMax}} characters',
        serialNumberRequired: 'Please provide a serial number'
    },
    placeholders: {
        make: 'Controller Make',
        model: 'Controller Model',
        nickname: 'Nickname',
        serialNumber: 'Serial Number'
    }
};

export default translations;
