import { View } from '@react-pdf/renderer';

import type { FunctionComponent, Props } from './types';

import styles from './styles';

const Footer: FunctionComponent<Props> = ({ children, style }) => (
    <View fixed style={[styles, style]}>
        {children}
    </View>
);

export default Footer;
