import useTranslate from 'app/i18n/useTranslate';

const useTranlsation = () => {
    const t = useTranslate('workOrderPage');

    return {
        CHEMICAL: t('chemical'),
        COMMODITY: t('commodity'),
        CUSTOMER: t('customer'),
        DELETE_ERROR: t('deleteError'),
        DETAILS: t('details'),
        GROWER: t('grower'),
        INVOICE_ASSISTANT: t('invoiceAssistant'),
        NOTES: t('notes'),
        PRODUCT_USAGE_REPORT_DELETE_WARNING: t('productUsageReportDeleteWarning'),
        PRODUCT_USAGE_REPORT_WARNING: t('productUsageReportWarning'),
        SAVE_TO_SCHEDULE: t('saveToSchedule'),
        SITE: t('site'),
        SUBMISSION_CONFLICT: (workOrderNumber: string) => t('conflict', { workOrderNumber }),
        SUBMISSION_ERROR: t('error'),
        TITLE: t('title')
    };
};

export default useTranlsation;
