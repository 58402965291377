import {
    MAX_FLUID_OZ,
    MAX_GPA,
    MAX_KILOGRAMS,
    MAX_KILOGRAMS_PER_SQUARE_METER,
    MAX_OZ,
    MAX_OZ_PER_ACRE,
    MAX_PINTS_PER_ACRE,
    MAX_QUARTS,
    MAX_QUARTS_PER_ACRE,
    MAX_RATE_POUNDS_PER_ACRE,
    MAX_VOLUME_GALLONS,
    MAX_VOLUME_PINTS,
    MAX_WEIGHT_POUNDS
} from 'config';
import { array, number, object, string } from 'yup';

import useTranslation from 'components/NewProductUsageReport/ProductDetailsForm/hooks/useTranslation';

import useConvert from 'hooks/useConvert';

const useSchema = () => {
    const {
        ERRORS,
        FLUID_OUNCES_PER_ACRE_UNIT,
        GALLONS,
        GALLONS_PER_ACRE_UNIT,
        LITERS,
        LITERS_PER_HECTARE_UNIT,
        LITERS_PER_METER_SQUARE_UNIT
    } = useTranslation();

    const {
        convertGallonsToLiters,
        convertLitersPerSquareMeterToLitersPerHectare,
        convertLitersPerSquareMeterToOuncesPerAcre,
        convertToLitersPerSquareMeter
    } = useConvert();

    return object({
        carrier: string().ensure().required(ERRORS.CARRIER_REQUIRED),
        chemicalsApplied: array()
            .of(
                object({
                    amountApplied: number()
                        .required(ERRORS.VOLUME_APPLIED_REQUIRED)
                        .min(0, ERRORS.VOLUME_APPLIED_GREATER_THAN_ZERO)
                        .test({
                            name: 'maxVolume',
                            test: (value, context) => {
                                const unitMap: Record<string, { unit: string; maxAmount: number }> =
                                    {
                                        FLUID_OUNCES: {
                                            maxAmount: MAX_FLUID_OZ,
                                            unit: FLUID_OUNCES_PER_ACRE_UNIT
                                        },
                                        GALLONS: {
                                            maxAmount: MAX_VOLUME_GALLONS,
                                            unit: GALLONS
                                        },
                                        KILOGRAMS: {
                                            maxAmount: MAX_KILOGRAMS,
                                            unit: 'kg'
                                        },
                                        LITERS: {
                                            maxAmount: convertGallonsToLiters(MAX_VOLUME_GALLONS),
                                            unit: LITERS
                                        },
                                        OUNCES: {
                                            maxAmount: MAX_OZ,
                                            unit: 'oz'
                                        },
                                        PINTS: {
                                            maxAmount: MAX_VOLUME_PINTS,
                                            unit: 'pt'
                                        },
                                        POUNDS: {
                                            maxAmount: MAX_WEIGHT_POUNDS,
                                            unit: 'lbs'
                                        },
                                        QUARTS: {
                                            maxAmount: MAX_QUARTS,
                                            unit: 'qt'
                                        }
                                    };

                                const { maxAmount, unit } =
                                    unitMap[context.parent.preferredAmountUnits];

                                if (value <= maxAmount) {
                                    return true;
                                }

                                return context.createError({
                                    message: ERRORS.VOLUME_APPLIED_TOO_LARGE(maxAmount, unit)
                                });
                            }
                        }),
                    preferredAmountUnits: string().ensure().required(ERRORS.VOLUME_UNITS_REQUIRED),
                    preferredRateUnits: string()
                        .ensure()
                        .required(ERRORS.RATE_APPLIED_UNITS_REQUIRED),
                    rateApplied: number()
                        .required(ERRORS.RATE_APPLIED_REQUIRED)
                        .min(0, ERRORS.RATE_APPLIED_GREATER_THAN_ZERO)
                        .test({
                            name: 'maxRate',
                            test: (value, context) => {
                                const maxRateLitersPerSquareMeter = convertToLitersPerSquareMeter(
                                    MAX_GPA,
                                    'gaPerAc'
                                );

                                const unitMap: Record<string, { unit: string; maxRate: number }> = {
                                    FLUID_OUNCES_PER_ACRE: {
                                        maxRate: convertLitersPerSquareMeterToOuncesPerAcre(
                                            maxRateLitersPerSquareMeter
                                        ),
                                        unit: FLUID_OUNCES_PER_ACRE_UNIT
                                    },
                                    GALLONS_PER_ACRE: {
                                        maxRate: MAX_GPA,
                                        unit: GALLONS_PER_ACRE_UNIT
                                    },
                                    KILOGRAMS_PER_SQUARE_METER: {
                                        maxRate: MAX_KILOGRAMS_PER_SQUARE_METER,
                                        unit: 'kg/m2'
                                    },
                                    LITERS_PER_HECTARE: {
                                        maxRate: convertLitersPerSquareMeterToLitersPerHectare(
                                            maxRateLitersPerSquareMeter
                                        ),
                                        unit: LITERS_PER_HECTARE_UNIT
                                    },
                                    LITERS_PER_SQUARE_METER: {
                                        maxRate: maxRateLitersPerSquareMeter,
                                        unit: LITERS_PER_METER_SQUARE_UNIT
                                    },
                                    OUNCES_PER_ACRE: {
                                        maxRate: MAX_OZ_PER_ACRE,
                                        unit: 'oz/ac'
                                    },
                                    PINTS_PER_ACRE: {
                                        maxRate: MAX_PINTS_PER_ACRE,
                                        unit: 'pts/ac'
                                    },
                                    POUNDS_PER_ACRE: {
                                        maxRate: MAX_RATE_POUNDS_PER_ACRE,
                                        unit: 'lbs/ac'
                                    },
                                    QUARTS_PER_ACRE: {
                                        maxRate: MAX_QUARTS_PER_ACRE,
                                        unit: 'qts/ac'
                                    }
                                };

                                const { maxRate, unit } =
                                    unitMap[context.parent.preferredRateUnits];

                                if (value <= maxRate) {
                                    return true;
                                }

                                return context.createError({
                                    message: ERRORS.RATE_APPLIED_TOO_LARGE(maxRate, unit)
                                });
                            }
                        })
                })
            )
            .required(),
        totalAreaAppliedAcres: number()
            .required(ERRORS.TOTAL_ACRES_APPLIED_REQUIRED)
            .min(0, ERRORS.TOTAL_ACRES_GREATER_THAN_ZERO)
    });
};

export default useSchema;
