import { View } from '@react-pdf/renderer';

import Footer from 'components/pdf/Footer';
import FooterText from 'components/pdf/FooterText';
import GrayscaleLogo from 'components/pdf/GrayscaleLogo';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent } from './types';

import styles from './styles';

const InvoicePageFooter: FunctionComponent = () => {
    const { DISCLAIMER_1, DISCLAIMER_2, GENERATED_BY } = useTranslation();

    return (
        <Footer style={styles.invoicePageFooter}>
            <View style={styles.disclaimer}>
                <FooterText text={DISCLAIMER_1} />

                <FooterText text={DISCLAIMER_2} />
            </View>

            <View style={styles.logo}>
                <FooterText text={GENERATED_BY} />

                <GrayscaleLogo />
            </View>
        </Footer>
    );
};

export default InvoicePageFooter;
