import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createFaaReportCompanyDetails');

    return {
        COA: t('coa'),
        COA_TOOLTIP: t('coaTooltip'),
        COMPANY_NAME: t('companyName'),
        EXEMPTION_DOCKET_NUMBER: t('exemptionDocketNumber'),
        EXEMPTION_DOCKET_NUMBER_TOOLTIP: t('exemptionDocketNumberTooltip'),
        EXEMPTION_NUMBER: t('exemptionNumber'),
        EXEMPTION_NUMBER_TOOLTIP: t('exemptionNumberTooltip'),
        PHONE_NUMBER: t('phoneNumber')
    };
};

export default useTranslation;
