import { EventSource } from 'extended-eventsource';
import { useCallback, useRef } from 'react';

import useAccessToken from 'hooks/useAccessToken';
import useFetch from 'hooks/useFetch';

import { ServerSentEventHandler } from './types';

const useServerSentEvents = () => {
    const sse = useRef<EventSource>();
    const { fetchToken } = useAccessToken();

    const { resolveHost } = useFetch();

    const closeAuthenticatedConnection = useCallback(() => {
        sse.current?.close();
    }, []);

    const openAuthenticatedConnection = useCallback(
        async (url: string, handler: ServerSentEventHandler) => {
            const token = await fetchToken();
            const opts = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };

            sse.current = new EventSource(resolveHost(url), opts);

            sse.current.onmessage = handler.handleMessage;
            sse.current.onerror = handler.handleError;
        },
        [fetchToken, resolveHost]
    );

    return {
        closeAuthenticatedConnection,
        openAuthenticatedConnection
    };
};

export default useServerSentEvents;
