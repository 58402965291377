import { TEST_ID } from './constants';
import { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WorkOrderReadOnlyText: FunctionComponent<Props> = ({ testId = TEST_ID, text }) => (
    <p className={styles.workOrderReadOnlyText} data-testid={testId}>
        {text}
    </p>
);

export default WorkOrderReadOnlyText;
