import { WORK_ORDER_CHEMICAL_MAX } from 'config';
import { FieldArray, useFormikContext } from 'formik';

import ErrorMessage from 'components/ErrorMessage';
import FormSectionContainer from 'components/FormSectionContainer';
import SecondaryButton from 'components/SecondaryButton';

import useTranslation from './hooks/useTranslation';

import ChemicalInformationFields from './components/ChemicalInformationFields';
import { TEST_ID } from './constants';
import { EMPTY_CHEMICAL } from './defaults';
import type { FunctionComponent, Props, WorkOrderForm } from './types';

const ChemicalInformation: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { ADD_CHEMICAL } = useTranslation();
    const { values } = useFormikContext<WorkOrderForm>();

    return (
        <FormSectionContainer>
            <FormSectionContainer testId={testId}>
                <FieldArray name="chemicals">
                    {({ push, remove }) => (
                        <>
                            {values.chemicals.map((_, index) => (
                                <ChemicalInformationFields
                                    canClose={values.chemicals?.length > 1}
                                    hasProductUsageReport={hasProductUsageReport}
                                    index={index}
                                    isEditable={isEditable}
                                    key={index}
                                    onClose={() => remove(index)}
                                />
                            ))}

                            {isEditable && values.chemicals?.length < WORK_ORDER_CHEMICAL_MAX && (
                                <SecondaryButton
                                    isDisabled={hasProductUsageReport}
                                    onClick={() => push(EMPTY_CHEMICAL)}
                                    text={ADD_CHEMICAL}
                                />
                            )}
                        </>
                    )}
                </FieldArray>

                <ErrorMessage name="chemicals" />
            </FormSectionContainer>
        </FormSectionContainer>
    );
};

export default ChemicalInformation;
