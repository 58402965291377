import type { Style } from './types';

const styles: Style = {
    border: '1pt solid #b9b9b9',
    borderRadius: '3pt',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%'
};

export default styles;
